import { Stack, Typography } from '@mui/material';
import RechartsBox from 'components/elements/RechartsBox';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip, Treemap } from 'recharts';

import CustomizedTreeContent from './CustomizedTreeContent';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Stack
        className="treemap-custom-tooltip"
        textAlign="start"
        gap="4px"
        sx={{
          color: 'colors.text_on_primary',
          bgcolor: 'colors.dark_bg',
          borderRadius: '4px',
          padding: '8px'
        }}>
        <Typography variant="text1_Medium">{payload[0].payload.name}</Typography>
        <Typography variant="text1_Normal">{payload[0].value}</Typography>
      </Stack>
    );
  }

  return null;
};

export default function ClusterTreeMap({ data, height }) {
  const sortedData = data.sort((item1, item2) => item2.size - item1.size);
  return (
    <RechartsBox height={height}>
      <Treemap
        width={400}
        height={height}
        data={sortedData}
        dataKey="size"
        aspectRatio={4 / 3}
        stroke="#fff"
        fill="#8884d8"
        content={<CustomizedTreeContent />}>
        <Tooltip content={<CustomTooltip />} />
      </Treemap>
    </RechartsBox>
  );
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string
};

ClusterTreeMap.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number
};
