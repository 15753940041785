import { getConsensusFromData, revenueCategoryFromData } from './finance-utils';

export function barDataFromRevenuesData(revenuesData) {
  return (
    revenuesData &&
    revenuesData
      .filter(getConsensusFromData)
      .map(revenueCategoryFromData)
      .filter((category) => category)
      .sort((item1, item2) => item1.index - item2.index)
      .reduce((acc, item) => {
        if (acc?.[item?.label]?.value) {
          acc[item.label].value += 1;
        } else {
          acc[item?.label] = { value: 1, index: item.index };
        }
        return acc;
      }, {})
  );
}

const YEAR_FOUNDED_CATEGORIES = Object.freeze({
  'Pre-1980': [0, 1980],
  '1980s': [1980, 1990],
  '1990s': [1990, 2000],
  '2000s': [2000, 2010],
  '2010s': [2010, 2020],
  '2020s': [2020, 2030]
});

const FUNDING_STAGE_GROUPS = Object.freeze({
  EARLY_STAGE: {
    name: 'Early Stage (Pre-seed, Seed, Venture)',
    members: ['Pre Seed', 'Seed', 'Venture Round'],
    rank: 1
  },
  EARLY_GROWTH: {
    name: 'Early Growth (Series A, B)',
    members: ['A', 'B', 'Series A', 'Series B'],
    rank: 2
  },
  GROWTH: {
    name: 'Growth Stage (Series C, D+)',
    members: ['Series C', 'Series D', 'Series E', 'Series F', 'C', 'D', 'E', 'F', 'D+'],
    rank: 3
  },
  PE_CORP: {
    name: 'PE/Corporate',
    members: ['Private Equity', 'PE Round', 'Corporate'],
    rank: 4
  },
  EXIT: { name: 'Exit (IPO/Acquired)', members: ['IPO', 'Acquired', 'Public'], rank: 5 },
  OTHER: { name: 'Non-equity', members: ['Non-equity'], rank: 6 }
});

const mapFundingStage = (stage) => {
  const group = Object.values(FUNDING_STAGE_GROUPS).find((item) => item.members.includes(stage));
  return group || { name: 'Unknown', rank: 7 };
};

function yearToCategory(year) {
  for (const [category, [start, end]] of Object.entries(YEAR_FOUNDED_CATEGORIES)) {
    if (year >= start && year < end) {
      return category;
    }
  }
  return 'Unknown';
}
function categoryToSortValue(category) {
  return YEAR_FOUNDED_CATEGORIES?.[category]?.[0] || -1;
}

function aggregate(acc, cat) {
  if (acc?.[cat]) {
    acc[cat] += 1;
  } else {
    acc[cat] = 1;
  }
  return acc;
}

export function barDataFromYearsFoundedData(yearsFoundedData) {
  return yearsFoundedData
    .map(yearToCategory)
    .sort((item1, item2) => categoryToSortValue(item1) - categoryToSortValue(item2))
    .reduce(aggregate, {});
}

function headcountToSortValue(headcount) {
  return parseInt(headcount.split(' - ')[0].split('+')[0], 10);
}

export function barDataFromHeadcountData(headcountData) {
  return headcountData
    .sort((item1, item2) => headcountToSortValue(item1) - headcountToSortValue(item2))
    .reduce((acc, headcount) => {
      const label = headcount.replace(' - ', '-');
      return aggregate(acc, label);
    }, {});
}

export function pieDataFromFundingStageData(fundingStageData) {
  return fundingStageData
    .map(mapFundingStage)
    .sort((item1, item2) => item1.rank - item2.rank)
    .map((item) => item.name)
    .reduce(aggregate, {});
}
