import BaseClient from './BaseClient';
import { getQueryParamsString, translateFilterParams } from './Utils';

const URL_PREFIX = 'explore';

export default class ExploreClient extends BaseClient {
  fetchAllExploreLists({
    num = 50,
    all = false,
    record_only = false,
    last_fetched_date = null,
    folder_id = null,
    organizationId = 0,
    userId = 0
  }) {
    const params = getQueryParamsString(
      translateFilterParams({ num, all, organizationId, userId })
    );
    return this.get(
      `${URL_PREFIX}?record_only=${record_only ? '1' : '0'}${
        last_fetched_date ? '&last_fetched_date='.concat(last_fetched_date) : ''
      }${folder_id || folder_id === 0 ? '&folder_id='.concat(folder_id) : ''}&${params}`,
      this.token
    );
  }

  fetchExploreList(listId) {
    return this.get(`${URL_PREFIX}/${listId}`);
  }

  fetchPartialExploreList(listId, companyMetaIds) {
    return this.post(`${URL_PREFIX}/${listId}`, {
      company_meta_ids: companyMetaIds,
      company_metas_only: true
    });
  }

  getSemanticSearch(listId, searchTerm) {
    return this.get(
      `${URL_PREFIX}/${listId}/semantic_search?search_query=${searchTerm}`,
      this.token
    );
  }

  fetchExploreListDomainReport(listId) {
    return this.get(`${URL_PREFIX}/${listId}/domain_report`);
  }

  submitExploreListDomainReport(listId, company_meta_ids, filters) {
    return this.post(`${URL_PREFIX}/${listId}/domain_report`, { company_meta_ids, filters });
  }

  submitExploreListDomainReportTask(listId, domainReportId, task_type, parameters) {
    return this.post(`${URL_PREFIX}/${listId}/domain_report/${domainReportId}`, {
      task_type,
      parameters
    });
  }

  fetchExploreRunsSummary(listId) {
    return this.get(`${URL_PREFIX}/${listId}/runs_summary`);
  }
}
