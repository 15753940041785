import { Box, Dialog, DialogTitle, Stack, Tab, Tabs, Typography } from '@mui/material';
import { loadAllUserSearchesAction } from 'actions/users/load-all-user-searches';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getUserDiscoveries, getUserPersons, getUserRuns } from 'selectors/users';

import DeepDiveTab from './DeepDiveTab';
import DeepDiveTabAdmin from './DeepDiveTabAdmin';
import ExamplesTab from './ExamplesTab';
import LeadersTab from './LeadersTab';

export const ViewUser = ({ userId, setUserId, userEmail, isAdmin, users }) => {
  const { state } = useAppState();
  const runs = getUserRuns(state);
  const persons = getUserPersons(state);
  const discoveries = getUserDiscoveries(state);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (!userId) return;
    dispatch(loadAllUserSearchesAction(userId));
  }, [userId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Dialog
        open={userId}
        onClose={() => {
          setUserId(null);
        }}
        PaperProps={{
          style: {
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            maxWidth: '100%',
            overflowY: 'scroll'
          }
        }}
        id={userId}>
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: 1
          }}>
          <DialogTitle variant="text1_Bold" color="colors.primary_text" fontSize="24px">
            {userEmail ? `User ${userEmail}, Id: ${userId}` : `User Id: ${userId}`}
          </DialogTitle>
          <Tabs value={value} onChange={handleChange} textColor="secondary">
            <Tab label="Deep Dive" />
            <Tab label="Leaders" />
            <Tab label="Discoveries" />
          </Tabs>
        </Stack>
        <Box
          sx={{
            padding: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            overflow: 'auto'
          }}>
          <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            {value === 0 && runs && isAdmin && (
              <DeepDiveTabAdmin hidden={value !== 0} runs={runs}></DeepDiveTabAdmin>
            )}
            {value === 0 && runs && !isAdmin && (
              <DeepDiveTab hidden={value !== 0} runs={runs} users={users}></DeepDiveTab>
            )}
            {value === 0 && (!runs || !Object.keys(runs)?.length) && (
              <Typography align="center">No Runs Available</Typography>
            )}

            {value === 1 && persons && (
              <LeadersTab persons={persons} hidden={value !== 1}></LeadersTab>
            )}
            {value === 1 && (!persons || !Object.keys(persons)?.length) && (
              <Typography align="center">No Leaderships Available</Typography>
            )}
            {value === 2 && discoveries && (
              <ExamplesTab searches={discoveries} hidden={value !== 2} users={users}></ExamplesTab>
            )}
            {value === 2 && (!discoveries || !Object.keys(discoveries)?.length) && (
              <Typography align="center">No Discoveries Available</Typography>
            )}
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

ViewUser.propTypes = {
  userId: PropTypes.number,
  setUserId: PropTypes.func,
  userEmail: PropTypes.string,
  isAdmin: PropTypes.bool,
  users: PropTypes.object
};

ViewUser.defaultProps = {
  isAdmin: false
};
