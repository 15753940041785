import cloneDeep from 'lodash/cloneDeep';
import { getThreadKey, updatePlan } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';
import {
  getWorkflowStepAndQueryIndexesByStepId,
  getWorkflowStepQueryBystepType
} from 'utils/workflows-utils';

export function addParallelStepToPlanAction(listId, workflowId, stepId) {
  return (dispatch, getState) => {
    const threadKey = getThreadKey(listId);
    const plan = getCustomAnalyticsPlanByThreadAndPostId(getState(), threadKey, workflowId);

    if (plan?.steps) {
      const newPlan = cloneDeep(plan);
      const { stepIndex, queryIndex } = getWorkflowStepAndQueryIndexesByStepId(
        stepId,
        newPlan.steps
      );
      if (stepIndex >= 0) {
        const step = newPlan.steps[stepIndex];
        const { function_use: stepInfo } = step;
        const { action, action_variables: variables } = stepInfo;
        const { queries } = variables;
        const query = getWorkflowStepQueryBystepType(action, stepIndex + 1);
        queries.splice(queryIndex + 1, 0, query);
        dispatch(updatePlan({ threadKey, postId: workflowId, newPlan }));
      }
    }
  };
}
