import { Stack, Typography } from '@mui/material';
import { loadDomainReportDefaultConfigAction } from 'actions/domain_reports/load-domain-report-default-config';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  getDomainReportDefaultConfig,
  getLatestDomainReportByListId
} from 'selectors/domain-reports';
import { getCompanyListNameByListId } from 'selectors/explore';
import NewDomainReportGraphic from 'svg/NewDomainReportGraphic';

import DomainReportButton from './DomainReportButton';
import DomainReportHeader from './DomainReportHeader';
import DomainReportCluster from './cluster/DomainReportCluster';
import DomainReportMarket from './market/DomainReportMarket';
import DomainReportProcess from './process/DomainReportProcess';
import DomainReportTopProblems from './top-problems/DomainReportTopProblems';

export const DOMAIN_REPORT_PANEL_ID = 'domain-report-panel';

function DomainReportTab({ listId }) {
  const [exporting, setExporting] = React.useState(false);
  const domainReport = useSelector((state) => getLatestDomainReportByListId(state, listId));
  const name = useSelector((state) => getCompanyListNameByListId(state, listId));
  const defaultConfig = useSelector(getDomainReportDefaultConfig);
  const dispatch = useDispatch();
  const contentRef = useRef();
  const reactToPrintFn = useReactToPrint({
    contentRef,
    documentTitle: `${name || 'untitled'}_report`
  });
  const toPDF = () => {
    setExporting(true);
    setTimeout(() => {
      reactToPrintFn();
      setExporting(false);
    }, 2000);
  };
  useEffect(() => {
    if (!defaultConfig) {
      dispatch(loadDomainReportDefaultConfigAction());
    }
  }, [dispatch, defaultConfig]);

  if (!domainReport) {
    return (
      <Stack gap="12px" sx={{ marginTop: '100px', paddingBottom: '32px', marginX: 'auto' }}>
        <Stack gap="16px" justifyContent="center" alignItems="center">
          <NewDomainReportGraphic />
          <Typography variant="h3_Bold" color="colors.primary_text">
            Discover domain insights
          </Typography>
          <Typography
            variant="text1_Normal"
            color="colors.primary_text"
            width="415px"
            sx={{ marginBottom: '16px' }}>
            Start by creating your first report, and update it anytime based on the latest data in
            the list.
          </Typography>
          <DomainReportButton listId={listId} />
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack
      gap="12px"
      sx={{
        marginTop: '16px',
        paddingBottom: '32px',
        maxWidth: '802px',
        marginX: 'auto'
      }}
      textAlign="left">
      <DomainReportHeader listId={listId} toPDF={toPDF} exporting={exporting} />
      <Stack gap="12px" ref={contentRef}>
        <DomainReportProcess listId={listId} />
        <DomainReportMarket listId={listId} />
        <DomainReportTopProblems listId={listId} />
        <DomainReportCluster listId={listId} />
      </Stack>
    </Stack>
  );
}

DomainReportTab.propTypes = {
  listId: PropTypes.number.isRequired
};

export default DomainReportTab;
