import { Stack, Typography } from '@mui/material';
import { updateSavedQueryAction } from 'actions/saved_queries/update-saved-query-action';
import MLModal from 'design-system/MLModal';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

function ForgotSaveWorkflowDialog({ workflowId, plan, open, onClose }) {
  const dispatch = useDispatch();
  const handleSaveChanges = () => {
    dispatch(updateSavedQueryAction(workflowId, plan));
  };

  const onSaveAndExit = () => {
    handleSaveChanges();
    onClose();
  };
  return (
    <MLModal
      open={open}
      onClose={onClose}
      title={'There are unsaved changes'}
      primaryButtonProps={{
        label: 'Save Changes',
        onClick: onSaveAndExit
      }}
      secondaryButtonProps={{
        label: 'Exit without saving',
        onClick: onClose
      }}>
      <Stack py="24px">
        <Typography variant="paragraph">
          Save your changes before exiting the workflow to avoid losing them.
        </Typography>
      </Stack>
    </MLModal>
  );
}

ForgotSaveWorkflowDialog.propTypes = {
  workflowId: PropTypes.string,
  plan: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default ForgotSaveWorkflowDialog;
