import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { copyCompaniesToExistListAction } from 'actions/company_lists/copy-companies-to-exist-list-action';
import { loadExplore } from 'actions/explore/load-explore-action';
import LoaderComponent from 'components/LoaderComponent';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllExploreLists } from 'selectors/explore';
import { getExploreFilteredCompanies } from 'selectors/explore-table-ui';
import { isEmpty } from 'utils/objects-utils';
import { formatTimestamp } from 'utils/time-utils';

export default function SelectExistingListDialog({ open, setOpen, listId, addToast }) {
  const dispatch = useDispatch();
  const [selectedListId, setSelectedListId] = useState(null);
  const filteredCompanies = useSelector((state) => getExploreFilteredCompanies(state, listId));
  const allExplores = useSelector((state) => getAllExploreLists(state));
  const lists = useMemo(() => {
    if (isEmpty(allExplores)) return null;
    if (!allExplores?.[listId]) {
      // for the case of dev user looking at another user's list -
      // loading all explore lists will remove the list from the state so
      //  will need to load it again.
      dispatch(loadExplore(listId, null, false));
    }

    return Object.values(allExplores)
      .sort((val_1, val_2) => new Date(val_2.timeSubmitted) - new Date(val_1.timeSubmitted))
      .filter((list) => list.id !== listId);
  }, [allExplores, listId, dispatch]);

  const onSubmitSelectedDiscoveryToAddCompanies = () => {
    dispatch(copyCompaniesToExistListAction(selectedListId, listId, addToast, filteredCompanies));
    setOpen(false);
    setSelectedListId(null);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedListId(null);
  };

  return (
    <ModalComponent
      open={open}
      onClose={onClose}
      title="Select a list to add the companies to"
      width={600}
      maxWidth={600}>
      <FormControl
        sx={{
          width: '100%',
          height: '500px',
          overflowY: 'scroll',
          alignItems: lists && lists.length ? 'flex-start' : 'center'
        }}>
        {lists && lists.length ? (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            sx={{ gap: '16px', width: '100%' }}>
            {lists.map((companyList) => (
              <Stack key={companyList.id} direction="row" alignItems="center" padding="0px 24px">
                <FormControlLabel
                  value={companyList.name}
                  control={
                    <Radio
                      checked={companyList.id === selectedListId}
                      onClick={() => setSelectedListId(companyList.id)}
                    />
                  }
                  label={companyList.name}></FormControlLabel>
                <Stack marginLeft="auto" width="fit-content">
                  <Typography>{formatTimestamp(companyList.timeSubmitted)}</Typography>
                </Stack>
              </Stack>
            ))}
          </RadioGroup>
        ) : (
          <LoaderComponent />
        )}
      </FormControl>

      <Stack
        direction="row"
        justifyContent="space-between"
        padding="16px 0px"
        bgcolor="colors.gray_bg"
        width="100%"
        sx={{
          borderTop: '1px solid',
          borderColor: 'colors.ui_border'
        }}>
        <Button variant="outlined" onClick={onClose} sx={{ marginLeft: '42px' }}>
          Cancel
        </Button>
        <Button
          onClick={onSubmitSelectedDiscoveryToAddCompanies}
          variant="contained"
          disabled={!selectedListId}
          sx={{ marginRight: '42px' }}>
          Add To List
        </Button>
      </Stack>
    </ModalComponent>
  );
}

SelectExistingListDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  listId: PropTypes.number,
  addToast: PropTypes.func
};
