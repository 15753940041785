import { Avatar, Box, Divider, Link, Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

export default function RenderMultipleLogos({ value }) {
  return (
    <Stack direction="row" alignItems="center">
      {value?.map((item, index) => (
        <Tooltip key={index} title={item.text}>
          <Link
            href={item.link_url}
            underline="none"
            target="_blank"
            rel="noopener"
            color="colors.dark_bg">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: item.link_url ? 'pointer' : 'unset'
              }}>
              {(item.logo_url || item.show_also_if_empty) && (
                <Avatar
                  src={item.logo_url}
                  sx={{ width: 24, height: 24, boxShadow: '0px 1px 6px -3px rgba(0,0,0,0.75)' }}
                  variant="rounded"
                />
              )}
              {index < value.length - 1 && (
                <Divider orientation="vertical" sx={{ marginRight: '8px' }} flexItem />
              )}
            </Box>
          </Link>
        </Tooltip>
      ))}
    </Stack>
  );
}

RenderMultipleLogos.propTypes = {
  value: PropTypes.array
};
