import styled from '@emotion/styled';
import { Box, Popover, TextareaAutosize } from '@mui/material';
import MaxLinesTypography from 'components/MaxLinesTypography';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { appTheme } from 'theme';

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: ${appTheme.typography.text1_Normal.fontFamily};
    font-size: ${appTheme.typography.text1_Normal.fontSize}px;
    font-weight: ${appTheme.typography.text1_Normal.fontWeight};
    line-height: ${appTheme.typography.text1_Normal.lineHeight};
    padding: 8px 12px;
    border-radius: 8px;
    white-space: pre-wrap;
    color: ${theme.vars.palette.colors.primary_text};
  `
);

function EditableMultilineTextarea({
  text,
  placeholder,
  maxLines,
  allowEdit,
  maxWidth,
  onTextChange
}) {
  const [textAnchorEl, setTextAnchorEl] = useState(null);

  const handleReadOnlyTextClick = (event) => {
    if (allowEdit) {
      setTextAnchorEl(event.target);
    }
  };

  const handleEditableTextClick = () => {
    setTextAnchorEl(null);
  };

  const onDownKey = (event) => {
    if (event?.key === 'Enter') {
      setTextAnchorEl(null);
    }
  };

  return (
    <Box sx={{ position: 'relative', cursor: 'default' }}>
      <MaxLinesTypography
        variant="text1_Medium"
        color="colors.primary_text"
        maxLines={maxLines}
        onClick={handleReadOnlyTextClick}>
        {text}
      </MaxLinesTypography>
      {Boolean(textAnchorEl) && allowEdit && (
        <Popover
          id="dependencies"
          open={Boolean(textAnchorEl)}
          anchorEl={textAnchorEl}
          onClose={handleEditableTextClick}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          slotProps={{
            paper: {
              sx: {
                padding: 0,
                margin: 0,
                backgroundColor: 'white',
                borderRadius: '8px',
                boxShadow: 'none',
                width: textAnchorEl.offsetWidth,
                maxWidth: maxWidth
              }
            }
          }}>
          <Textarea
            variant="outlined"
            value={text}
            onChange={onTextChange}
            placeholder={placeholder}
            onKeyPress={onDownKey}
            sx={{ ...appTheme.typography.text1_Medium, width: '100%', padding: '8px' }}
          />
        </Popover>
      )}
    </Box>
  );
}

EditableMultilineTextarea.propTypes = {
  text: PropTypes.string,
  maxLines: PropTypes.number,
  maxWidth: PropTypes.number,
  allowEdit: PropTypes.bool,
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default EditableMultilineTextarea;
