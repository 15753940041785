import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import AdminTabOrgLogo from './AdminTabOrgLogo';
import { ViewUser } from './ViewUser';

function UsersTabDev({ users, hidden, filter }) {
  const [viewUserId, setViewUserId] = useState(null);
  const [viewUserEmail, setViewUserEmail] = useState(null);

  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        <ViewUser
          userId={viewUserId}
          setUserId={setViewUserId}
          userEmail={viewUserEmail}
          users={users}></ViewUser>
        {Object.keys(users)
          .filter((key) => {
            return filter ? filter(users, key) : true;
          })
          .sort((key1, key2) => new Date(users[key2].signDate) - new Date(users[key1].signDate))
          .map((key) => {
            const user = users[key];
            return (
              <Stack
                key={key}
                direction="row"
                gap="32px"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button
                  onClick={() => {
                    setViewUserId(user.id);
                    setViewUserEmail(user.email);
                  }}
                  size="small"
                  variant="contained"
                  sx={{ width: '60px' }}>
                  {user?.id ? user.id : key}
                </Button>
                <AdminTabOrgLogo logoUrl={user.organizationLogoUrl} />
                <Typography width="240px" variant="text1_Normal">
                  {user.email}
                </Typography>
                {(user.isAdmin || user.isDev) && (
                  <Button size="small" disabled={true} sx={{ marginLeft: 'auto', width: '100px' }}>
                    {user?.isAdmin && 'ADMIN'}
                    {user.isAdmin && user.isDev && ', '}
                    {user?.isDev && 'DEV'}
                  </Button>
                )}
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default UsersTabDev;

UsersTabDev.propTypes = {
  users: PropTypes.object,
  hidden: PropTypes.bool,
  filter: PropTypes.func
};
