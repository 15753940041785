import { RESOURCE_TYPE_TO_ICON } from 'components/domain-knowledge/DomainKnowledgeResourceTile';
import { DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS } from 'constants/domain-knowledge';

import { isEmpty } from './objects-utils';

export const promptConfigToSearchPlaces = (promptConfig) => {
  if (!promptConfig) return [];
  const searchItems = [];
  if (promptConfig.searchTheWeb) {
    searchItems.push('web');
  }

  if (promptConfig.useTable) {
    searchItems.push('table');
  }
  if (promptConfig.useDomainKnowledge) {
    searchItems.push('domainKnowledge');
  }
  return searchItems;
};

export const getContextTextAndTooltip = (useTable, useDomainKnowledge, contextSections) => {
  const selectedList = [];
  (contextSections || []).forEach((section) => {
    if (section.selectItemsSet) {
      section.selectItemsSet.forEach((item) => {
        const itemName = section.items.find((elem) => elem.id === item)?.name;
        selectedList.push(itemName);
      });
    }
  });

  let text = '';
  let tooltipText = null;
  if (useTable && useDomainKnowledge) {
    if (selectedList.length > 0) {
      text = `Use selected context (${selectedList.length})`;
    } else {
      text = `Use specific context`;
    }
  } else if (useTable) {
    if (selectedList.length > 0) {
      text = `Use ${selectedList.length} column${selectedList.length > 1 ? 's' : ''}`;
    } else {
      text = `Use specific columns`;
    }
  } else if (useDomainKnowledge) {
    if (selectedList.length > 1) {
      text = `Use  ${selectedList.length} resources`;
    } else if (selectedList.length === 1) {
      text = `Use ${selectedList[0]}`;
    } else {
      text = `Use specific knowledge`;
    }
  } else {
    text = `Use specific context`;
    tooltipText = `Using specific context is possible only when searching within the table or domain knowledge`;
  }
  if (tooltipText === null && selectedList.length > 0) {
    tooltipText = selectedList.join('\n');
  }
  return { text, tooltipText };
};

export const getContextSections = ({
  allColumns,
  useDomainKnowledge,
  columns,
  resources,
  setPromptConfig,
  knowledge,
  useTable
}) => {
  const sections = [];
  if (useTable) {
    sections.push({
      id: 'columns',
      type: 'multiple',
      title: 'Columns',
      items: Object.values(allColumns),
      selectItemsSet: new Set(columns),
      onSelectionsChange: (selectedColumns) => {
        setPromptConfig((prev) => ({
          ...prev,
          columns: selectedColumns,
          useColumns: !isEmpty(selectedColumns)
        }));
      }
    });
  }

  if (useDomainKnowledge) {
    const dkTypesSet = Object.values(knowledge).reduce((set, item) => {
      if (item.supporting_resource_type) {
        set.add(item.supporting_resource_type);
      }
      return set;
    }, new Set());
    const domainKnowledgeOptions = [
      ...Object.values(knowledge || {})
        .sort((item1, item2) => {
          if (!item1.supporting_resource_type && !item2.supporting_resource_type) {
            // for items without type (all files/all answers..), keep the original order
            return 1;
          }
          const typeCompare = item1.supporting_resource_type?.localeCompare(
            item2.supporting_resource_type
          );
          if (typeCompare === 0) {
            return item1.name?.localeCompare(item2.name) || -1;
          }
          return typeCompare || -1;
        })
        .map((dk) => ({
          ...dk,
          disabled: !dkTypesSet.has(dk.supporting_resource_type) && !dkTypesSet.has(dk.id),
          icon: RESOURCE_TYPE_TO_ICON[dk.supporting_resource_type] || RESOURCE_TYPE_TO_ICON[dk.id]
        }))
    ];

    sections.push({
      id: 'domain',
      type: 'multiple',
      title: 'Domain Knowledge',
      items: domainKnowledgeOptions,
      selectItemsSet: new Set(resources ? new Set(resources) : null),
      onSelectionsChange: (selectedResourceType, addedResource) => {
        const filterOutSpecificWhenAllExist = selectedResourceType.filter((dkId) => {
          if (
            dkTypesSet.has(addedResource) &&
            knowledge[dkId]?.supporting_resource_type === addedResource
          ) {
            return false;
          }
          if (
            !dkTypesSet.has(addedResource) &&
            knowledge[addedResource]?.supporting_resource_type === dkId
          ) {
            return false;
          }
          return true;
        });
        setPromptConfig((prev) => ({ ...prev, resources: filterOutSpecificWhenAllExist }));
      }
    });
  }
  return sections;
};

const handleSearchPlaceChange = (setPromptConfig, newSearchPlaces, knowledge) => {
  const searchPlaceSet = new Set(newSearchPlaces);
  const dkTypesSet = Object.values(knowledge || {}).reduce((set, item) => {
    if (item.supporting_resource_type) {
      set.add(item.supporting_resource_type);
    }
    return set;
  }, new Set());

  const relevantDomainKnowledge = DOMAIN_KNOWLEDGE_RESOURCE_OPTIONS.filter((dkOption) =>
    dkTypesSet.has(dkOption.id)
  )?.[0];

  setPromptConfig((prev) => ({
    ...prev,
    searchTheWeb: searchPlaceSet.has('web'),
    useTable: searchPlaceSet.has('table'),
    columns: searchPlaceSet.has('table') ? prev.columns || [] : [],
    useColumns: searchPlaceSet.has('table') ? prev.useColumns : false,
    useDomainKnowledge: searchPlaceSet.has('domainKnowledge'),
    resources: searchPlaceSet.has('domainKnowledge')
      ? prev.resources || [relevantDomainKnowledge?.id]
      : null
  }));
};

export const getHandleSearchPlaceChange = (setPromptConfig, knowledge) => {
  return (newSearchPlaces) => {
    handleSearchPlaceChange(setPromptConfig, newSearchPlaces, knowledge);
  };
};
