import { Stack, Typography } from '@mui/material';
import React from 'react';

function WorkflowFormEmptyState() {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap="48px"
      sx={{ height: 'calc(100% - 60px)' }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="187"
        height="162"
        viewBox="0 0 187 162"
        fill="none">
        <path
          d="M86 117C91.6041 136.614 116.104 168.808 171 159.057"
          stroke="url(#paint0_linear_3087_58319)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray="8 8"
        />
        <g filter="url(#filter0_d_3087_58319)">
          <rect x="7" width="167" height="32" rx="8" fill="#E9F2FF" />
          <rect x="7.5" y="0.5" width="166" height="31" rx="7.5" stroke="white" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.035 14.6828C30.035 12.2824 28.0879 10.3365 25.6861 10.3365C23.2842 10.3365 21.3371 12.2824 21.3371 14.6828C21.3371 17.0831 23.2842 19.029 25.6861 19.029C28.0879 19.029 30.035 17.0831 30.035 14.6828ZM31.0387 14.6828C31.0387 11.7284 28.6422 9.3335 25.6861 9.3335C22.7299 9.3335 20.3335 11.7284 20.3335 14.6828C20.3335 17.6371 22.7299 20.032 25.6861 20.032C26.8121 20.032 27.857 19.6845 28.7191 19.0909C28.7252 19.0966 28.7315 19.1021 28.738 19.1075L32.8425 22.5495C33.0548 22.7275 33.3713 22.6998 33.5494 22.4877C33.7276 22.2755 33.6999 21.9592 33.4876 21.7812L29.4993 18.4367C30.4512 17.471 31.0387 16.1454 31.0387 14.6828Z"
          fill="#579BFC"
        />
        <rect x="49" y="15" width="105" height="3" rx="1.5" fill="white" />
        <g filter="url(#filter1_d_3087_58319)">
          <rect x="7" y="40" width="167" height="33" rx="8" fill="#EEEEFD" />
          <rect x="7.5" y="40.5" width="166" height="32" rx="7.5" stroke="white" />
        </g>
        <path
          d="M20.788 51.0002C20.788 50.3307 21.3307 49.788 22.0002 49.788H24.7779H29.2224H32.0002C32.6696 49.788 33.2123 50.3307 33.2123 51.0002V61.0002C33.2123 61.6696 32.6696 62.2123 32.0002 62.2123H29.2224H24.7779H22.0002C21.3307 62.2123 20.788 61.6696 20.788 61.0002V51.0002Z"
          stroke="#807EF1"
          strokeWidth="0.909091"
          strokeMiterlimit="16"
          strokeLinejoin="round"
        />
        <rect x="49" y="55" width="105" height="3" rx="1.5" fill="white" />
        <g filter="url(#filter2_d_3087_58319)">
          <rect
            x="8.55698"
            y="100.702"
            width="166"
            height="31"
            rx="7.5"
            transform="rotate(-6.97042 8.55698 100.702)"
            fill="#FFEAD4"
            stroke="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5143 107.113C35.4605 106.674 35.0607 106.361 34.6213 106.415L23.3484 107.793C22.9089 107.847 22.5962 108.246 22.65 108.686L22.8312 110.168C22.8545 110.359 22.9454 110.535 23.0873 110.664L28.2133 115.33L28.6831 119.172C28.7277 119.537 29.0151 119.825 29.3802 119.87L31.3492 120.114C31.8668 120.178 32.3069 119.739 32.2436 119.221L31.7154 114.901L35.5666 109.138C35.6732 108.978 35.7191 108.786 35.6957 108.595L35.5143 107.113ZM34.691 107.246L34.8671 108.685L31.016 114.448C30.9093 114.608 30.8635 114.8 30.8868 114.991L31.4115 119.282L29.5073 119.046L29.0391 115.217C29.0158 115.026 28.9249 114.85 28.7829 114.721L23.6569 110.055L23.481 108.616L34.691 107.246Z"
            fill="#FF8811"
          />
          <rect
            x="51.51"
            y="110.059"
            width="105"
            height="3"
            rx="1.5"
            transform="rotate(-6.97042 51.51 110.059)"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_3087_58319"
            x="3"
            y="0"
            width="175"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3087_58319"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3087_58319"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_3087_58319"
            x="3"
            y="40"
            width="175"
            height="41"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3087_58319"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3087_58319"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_3087_58319"
            x="0.910889"
            y="75.9109"
            width="185.827"
            height="68.2083"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1" dy="4" />
            <feGaussianBlur stdDeviation="4.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0519 0 0 0 0 0.00554985 0 0 0 0 0.561751 0 0 0 0.12 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3087_58319"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3087_58319"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_3087_58319"
            x1="91.5"
            y1="128"
            x2="196"
            y2="153"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#C9CCEF" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
      <Typography variant="text1_Normal" color="colors.primary_text">
        Workflow steps will be generated here
      </Typography>
    </Stack>
  );
}

export default WorkflowFormEmptyState;
