import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const DREQDKeyValue = ({ title, children }) => (
  <Stack gap="8px">
    <Typography variant="text1_Semibold">{title}</Typography>
    {typeof children === 'string' ? (
      <Typography variant="text1_Normal">{children}</Typography>
    ) : (
      children
    )}
  </Stack>
);

DREQDKeyValue.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default DREQDKeyValue;
