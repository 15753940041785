import { Box, CircularProgress, Menu, MenuItem, Stack } from '@mui/material';
import { makeRunFiles } from 'actions/runs/make-new-download-run-files-action';
import MLIconButton from 'design-system/MLIconButton';
import { useAppState } from 'hooks/state-context';
import DownloadIcon from 'icons/DownloadIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getIsLoadingRunFiles } from 'selectors/runs';

function DownloadRunFilesButton({ searchId, recordId, services }) {
  const { state } = useAppState();
  const isLoadingDownload = getIsLoadingRunFiles(state);
  const [anchorEl, setAnchorEl] = useState(null);
  const reduxDispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (event) => {
    handleClose();
    const service = event.nativeEvent.target.outerText;
    reduxDispatch(makeRunFiles(searchId, service, recordId));
  };

  const handleClick = (event) => {
    if (services.length === 1) {
      reduxDispatch(makeRunFiles(searchId, services[0], recordId));
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack>
      {isLoadingDownload[searchId] ? (
        <Box sx={{ display: 'flex', marginLeft: '5px' }}>
          <CircularProgress size="20px" />
        </Box>
      ) : (
        <MLIconButton tooltipText="Download Run Files" variant="secondary" onClick={handleClick}>
          <DownloadIcon />
        </MLIconButton>
      )}
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {services.map((service) => {
          return (
            <MenuItem key={service} value={service} onClick={handleSelect}>
              {service}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
}

DownloadRunFilesButton.propTypes = {
  recordId: PropTypes.number,
  searchId: PropTypes.number,
  services: PropTypes.array
};

export default DownloadRunFilesButton;
