import { pusherBindToCARecordId } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { PROMPT_PREFIX } from 'constants/custom-analytics';
import {
  addPostQueryToThread,
  addPostRecordIdToThread,
  getThreadKey
} from 'reducer/custom-analytics';
import { updateWorkflowSubmittedRecordsByIdAndListId } from 'reducer/workflows-slice';
import { getCompanyMetasByListId } from 'selectors/explore';
import { getWorkflowIdByRecordId } from 'selectors/workflows';
import { isEmpty } from 'utils/objects-utils';

export function updateWorkflowSubmittedRecordsAction({
  listId,
  customAnalyticsRecordId,
  recordIds,
  postIds,
  stepId
}) {
  return (dispatch, getState, apiClient) => {
    const state = getState();
    const workflowId = getWorkflowIdByRecordId(state, listId, customAnalyticsRecordId);
    if (!workflowId) {
      return;
    }
    if (!isEmpty(postIds)) {
      const threadKey = getThreadKey(listId);
      const companyMetas = getCompanyMetasByListId(state, listId);
      const total_num_companies = companyMetas?.length;
      const addColumnMessages = companyMetas?.map((company) => ({
        company_meta_id: company.company_meta_id,
        loading: true
      }));

      postIds.forEach(async (postId) => {
        const res = await apiClient.runs.getCustomAnalyticsRecordByPostId(postId);
        if (!res?.data) {
          return;
        }
        const postRecord = res.data;
        const post = postRecord.parameters;
        const isForEach = post?.question?.includes(`{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`);
        let messages = null;
        const body = { ...post, hidePost: true, timeSubmitted: postRecord.time_submitted };
        if (isForEach) {
          messages = addColumnMessages;
          body.total_num_companies = total_num_companies;
        }

        dispatch(
          addPostQueryToThread({
            threadKey,
            postId,
            query: post.question,
            messages,
            body
          })
        );
        dispatch(
          addPostRecordIdToThread({ threadKey, postId, customAnalyticsRecordId: postRecord.id })
        );
        dispatch(
          pusherBindToCARecordId({
            listId,
            recordId: postRecord.id,
            threadKey,
            postId
          })
        );
      });
    }

    dispatch(
      updateWorkflowSubmittedRecordsByIdAndListId({
        listId,
        workflowId,
        postIds,
        recordIds,
        stepId
      })
    );
  };
}
