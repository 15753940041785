import React from 'react';

function WorkflowDiscoveryIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0346 8.68275C13.0346 6.28237 11.0875 4.33648 8.68559 4.33648C6.28372 4.33648 4.33662 6.28237 4.33662 8.68275C4.33662 11.0831 6.28372 13.029 8.68559 13.029C11.0875 13.029 13.0346 11.0831 13.0346 8.68275ZM14.0382 8.68275C14.0382 5.72844 11.6417 3.3335 8.68559 3.3335C5.72944 3.3335 3.33301 5.72844 3.33301 8.68275C3.33301 11.6371 5.72944 14.032 8.68559 14.032C9.81166 14.032 10.8565 13.6845 11.7186 13.0909C11.7247 13.0966 11.731 13.1021 11.7375 13.1075L15.842 16.5495C16.0543 16.7275 16.3708 16.6998 16.5489 16.4877C16.7271 16.2755 16.6994 15.9592 16.4871 15.7812L12.4988 12.4367C13.4507 11.471 14.0382 10.1454 14.0382 8.68275Z"
        fill="#579BFC"
      />
    </svg>
  );
}

export default WorkflowDiscoveryIcon;
