import { ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { addStepToPlanAction } from 'actions/workflows/add-step-to-plan-action';
import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkflowSelectedPostIdByListId } from 'selectors/workflows';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkflowFormAddStepMenu({ listId, anchorEl, stepId, onClose }) {
  const dispatch = useDispatch();

  const selectedWorkflowId = useSelector((state) =>
    getWorkflowSelectedPostIdByListId(state, listId)
  );

  const handleAddStep = (step) => {
    dispatch(addStepToPlanAction(listId, selectedWorkflowId, step.id, stepId));
    onClose();
  };

  const getStepItem = (step) => {
    return (
      <MenuItem onClick={() => handleAddStep(step)}>
        <ListItemIcon>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '24px',
              height: '24px',
              borderRadius: '4px',
              color: step.color,
              backgroundColor: applyThemeColorOpacity(step.color, 12)
            }}>
            <step.Icon size={20} />
          </Stack>
        </ListItemIcon>
        <Typography variant="text1_Normal" color="colors.primary_text">
          {step.label}
        </Typography>
      </MenuItem>
    );
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {getStepItem(WORK_FLOW_STEP_TYPES.discovery)}
      {getStepItem(WORK_FLOW_STEP_TYPES.information)}
      {getStepItem(WORK_FLOW_STEP_TYPES.filter)}
      {getStepItem(WORK_FLOW_STEP_TYPES.analysis)}
    </Menu>
  );
}

WorkflowFormAddStepMenu.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  anchorEl: PropTypes.object,
  stepId: PropTypes.string,
  onClose: PropTypes.func
};

export default WorkflowFormAddStepMenu;
