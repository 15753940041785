import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'utils/objects-utils';

const initialSliceState = {
  workflows: {}
};

export const workflowsSlice = createSlice({
  name: 'workflows',
  initialState: initialSliceState,
  reducers: {
    updateSelectedWorkflowIdByListId: (sliceState, { payload }) => {
      const { listId, selectedWorkflowId } = payload;
      if (!sliceState.workflows[listId]) {
        sliceState.workflows[listId] = {};
      }
      sliceState.workflows[listId].selectedWorkflowId = selectedWorkflowId;
    },
    updateWorkflowStatusByIdAndListId: (sliceState, { payload }) => {
      const { listId, workflowId, status, info, customAnalyticsRecordId } = payload;

      if (!sliceState.workflows[listId]) {
        sliceState.workflows[listId] = {};
      }
      if (!sliceState.workflows[listId]?.[workflowId]) {
        sliceState.workflows[listId][workflowId] = {};
      }

      sliceState.workflows[listId][workflowId].status = status;
      if (info) {
        sliceState.workflows[listId][workflowId].info = info;
      }
      if (customAnalyticsRecordId) {
        sliceState.workflows[listId][workflowId].customAnalyticsRecordId = customAnalyticsRecordId;
      }
    },
    updateWorkflowEditModeByIdAndListId: (sliceState, { payload }) => {
      const { listId, workflowId, isEditMode } = payload;

      if (!sliceState.workflows[listId]) {
        sliceState.workflows[listId] = {};
      }
      if (!sliceState.workflows[listId]?.[workflowId]) {
        sliceState.workflows[listId][workflowId] = {};
      }

      sliceState.workflows[listId][workflowId].isEditMode = isEditMode;
    },
    updateWorkflowSubmittedRecordsByIdAndListId: (sliceState, { payload }) => {
      const { listId, workflowId, postIds, recordIds, stepId } = payload;
      if (!workflowId || (isEmpty(postIds) && isEmpty(recordIds))) {
        return;
      }

      sliceState.workflows[listId] = sliceState.workflows[listId] || {};
      sliceState.workflows[listId][workflowId] = sliceState.workflows[listId][workflowId] || {};
      sliceState.workflows[listId][workflowId].stepsStatuses =
        sliceState.workflows[listId][workflowId].stepsStatuses || {};
      sliceState.workflows[listId][workflowId].stepsStatuses[stepId] =
        sliceState.workflows[listId][workflowId].stepsStatuses[stepId] || {};

      if (!isEmpty(postIds)) {
        const newPostIds = new Set(
          sliceState.workflows[listId][workflowId].stepsStatuses[stepId].postIds || []
        );
        postIds.forEach((postId) => newPostIds.add(postId));
        sliceState.workflows[listId][workflowId].stepsStatuses[stepId].postIds =
          Array.from(newPostIds);
      }

      if (!isEmpty(recordIds)) {
        const newRecordIds = new Set(
          sliceState.workflows[listId][workflowId].stepsStatuses[stepId].recordIds || []
        );
        recordIds.forEach((recordId) => newRecordIds.add(recordId));
        sliceState.workflows[listId][workflowId].stepsStatuses[stepId].recordIds =
          Array.from(newRecordIds);
      }
    }
  }
});

export const {
  updateSelectedWorkflowIdByListId,
  updateWorkflowStatusByIdAndListId,
  updateWorkflowEditModeByIdAndListId,
  updateWorkflowSubmittedRecordsByIdAndListId
} = workflowsSlice.actions;

export default workflowsSlice.reducer;
