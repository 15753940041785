import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { USER_SELECTOR_ALL_VALUE } from 'constants/users';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React from 'react';
import { getUsers } from 'selectors/users';

const initialUser = { id: USER_SELECTOR_ALL_VALUE };

function UserSelector({ filterFunction, filtersData, setFiltersData }) {
  const { state } = useAppState();
  const users = getUsers(state);

  const handleChange = (event) => {
    const newUser =
      event.target.value === USER_SELECTOR_ALL_VALUE
        ? { ownerOrganizationId: filtersData.organizationId, ...initialUser }
        : users[event.target.value];
    setFiltersData(() => ({
      organizationId: newUser.ownerOrganizationId,
      userId: newUser.id
    }));
  };

  return (
    <FormControl sx={{ width: '200px' }}>
      <InputLabel id="user-select-label">User</InputLabel>
      <Select
        labelId="user-select-label"
        id="user-select"
        value={filtersData.userId}
        label="User"
        onChange={handleChange}>
        <MenuItem value={USER_SELECTOR_ALL_VALUE}>All</MenuItem>
        {users &&
          Object.keys(users)
            .filter((key) => {
              return filterFunction ? filterFunction(users, key) : true;
            })
            .map((key) => {
              return (
                <MenuItem key={users[key].id} value={users[key].id}>
                  {users[key].email}
                </MenuItem>
              );
            })}
      </Select>
    </FormControl>
  );
}

UserSelector.propTypes = {
  filterFunction: PropTypes.func,
  filtersData: PropTypes.object,
  setFiltersData: PropTypes.func
};
export default UserSelector;
