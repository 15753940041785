import LoopIcon from '@mui/icons-material/Loop';
import { Stack, TextField } from '@mui/material';
import { removeNotFoundCompany } from 'actions/company_lists/delete-companies-list-action';
import { replaceCompanyInListAction } from 'actions/company_lists/replace-company-in-list-action';
import MLIconButton from 'design-system/MLIconButton';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

function RenderNotFoundCompanyName({ value, isLoading }) {
  const originalName = value.name;
  const [name, setName] = useState(originalName);
  const { companyListId } = value;
  const dispatch = useDispatch();

  const reload = () =>
    dispatch(replaceCompanyInListAction(companyListId, originalName, name.trim()));

  const onDownKey = (event) => {
    if (event?.key === 'Enter' && name.trim().length) {
      reload();
      return;
    }
    event.stopPropagation();
  };

  const rotateSx = {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
      to: { transform: 'rotate(0deg)' },
      from: { transform: 'rotate(360deg)' }
    }
  };
  const basicSx = {
    fontSize: '1.2rem'
  };
  return (
    <Stack flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
      <TextField
        value={name}
        onChange={(event) => setName(event.target.value)}
        variant="outlined"
        disabled={isLoading}
        InputProps={{ sx: { height: 30 } }}
        onKeyDown={onDownKey}
      />
      <MLIconButton
        tooltipText="reload"
        disabled={isLoading}
        sx={{ color: 'colors.dark_bg' }}
        onClick={reload}>
        <LoopIcon sx={isLoading ? { ...basicSx, ...rotateSx } : basicSx}></LoopIcon>
      </MLIconButton>
      <MLIconButton
        disabled={isLoading}
        sx={{ color: 'red' }}
        onClick={() => dispatch(removeNotFoundCompany(companyListId, originalName))}>
        <DeleteIcon></DeleteIcon>
      </MLIconButton>
    </Stack>
  );
}

RenderNotFoundCompanyName.propTypes = {
  value: PropTypes.object,
  isLoading: PropTypes.bool
};

export default React.memo(RenderNotFoundCompanyName);
