import { Avatar, Box, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import RenderStr from './RenderStr';

export default function RenderLogo({ value }) {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  const values = value?.length ? value : [value];
  return (
    <React.Fragment>
      {values.map((item, index) => (
        <Box
          key={index}
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '8px' }}
          onClick={() => openInNewTab(item.link_url)}>
          {item.image_url && (
            <Avatar src={item.image_url} sx={{ width: 24, height: 24 }} variant="rounded" />
          )}
          <RenderStr variant="text1_Bold" color="colors.link" value={item.value} />
          {index < values.length - 1 && (
            <Divider orientation="vertical" sx={{ marginRight: '8px' }} flexItem />
          )}
        </Box>
      ))}
    </React.Fragment>
  );
}

RenderLogo.propTypes = {
  value: PropTypes.object
};
