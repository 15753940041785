import { Button, Stack, Typography } from '@mui/material';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';

function ResolvedMismatchMessage({ resolvedCompanyName, setResolvedCompany }) {
  const onGotItClick = () => {
    setResolvedCompany(null);
  };

  return (
    <Stack
      alignSelf="center"
      direction="row"
      alignItems="center"
      width="fit-content"
      sx={{
        padding: '24px',
        gap: '8px',
        backgroundColor: 'rgba(4, 189, 0, 0.05)',
        border: '1px solid',
        borderColor: 'colors.hover_on_positive_green',
        borderRadius: '4px'
      }}>
      <ToastIcons type="success" />
      <Typography width="700px" variant="text1_Normal">
        {resolvedCompanyName}’s website and LinkedIn were successfully matched. This company has
        been added to your discovery companies list.
      </Typography>

      <Button
        variant="outlined"
        onClick={onGotItClick}
        sx={{
          color: 'colors.hover_on_positive_green',
          border: '1px solid',
          '&:hover': {
            color: 'colors.hover_on_positive_green',
            borderColor: 'colors.hover_on_positive_green'
          }
        }}>
        <Typography variant="text1_Semibold">Got it</Typography>
      </Button>
    </Stack>
  );
}

ResolvedMismatchMessage.propTypes = {
  resolvedCompanyName: PropTypes.string,
  setResolvedCompany: PropTypes.func
};

export default ResolvedMismatchMessage;
