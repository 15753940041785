import { Divider, Stack, Typography } from '@mui/material';
import CustomAvatar from 'components/tile/CustomAvatar';
import PropTypes from 'prop-types';
import React from 'react';

function CustomAnalyticsPlatterItemTooltip({ isInList, title, question, isMine, resourceOwner }) {
  return (
    <Stack>
      {isInList && <Divider sx={{ borderColor: 'white', opacity: '35%', paddingTop: '8px' }} />}
      <Typography paddingTop={isInList ? '8px' : 0} paddingBottom="4px" variant="text1_Semibold">
        {title}
      </Typography>
      <Typography variant="text1_Normal" sx={{ maxWidth: 800 }}>
        Query: {question?.split('}} ').pop()}
      </Typography>
      {!isMine && resourceOwner && (
        <Divider sx={{ borderColor: 'white', opacity: '35%', paddingTop: '8px' }} />
      )}
      {!isMine && resourceOwner && (
        <Stack direction="row" gap="8px" alignItems="center" paddingTop="8px">
          <CustomAvatar
            name={resourceOwner.username}
            size={24}
            pictureUrl={resourceOwner.picture}
          />
          <Typography variant="text1_Normal">Created by {resourceOwner.username}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

CustomAnalyticsPlatterItemTooltip.propTypes = {
  isInList: PropTypes.bool,
  title: PropTypes.string,
  question: PropTypes.string,
  resourceOwner: PropTypes.object,
  isMine: PropTypes.bool
};

export default CustomAnalyticsPlatterItemTooltip;
