import { Button, Stack, TextField, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { addDiscoveryDomainKnowledgeResourceAction } from 'actions/explore/add-discovery-domain-knowledge-resource-action';
import ModalComponent from 'components/modal/ModalComponent';
import { DOMAIN_KNOWLEDGE_RESOURCE_TYPE } from 'constants/domain-knowledge';
import { TOAST_TYPES } from 'constants/toasts';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

function AddDomainKnowledgeLinkDialog({
  listId,
  folderId,
  open,
  onClose,
  initialLink,
  initialDescription
}) {
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();
  const [description, setDescription] = useState(initialDescription);
  const [link, setLink] = useState(initialLink);

  const closeDialog = () => {
    setDescription(initialDescription);
    setLink(initialLink);
    onClose();
  };

  const handleAddLink = () => {
    const actualLink = link.startsWith('http') ? link : `https://${link}`;
    dispatch(
      addDiscoveryDomainKnowledgeResourceAction({
        listId,
        folderId,
        resourceType: DOMAIN_KNOWLEDGE_RESOURCE_TYPE.LINKS,
        resourceName: description && description.length > 0 ? description : link,
        data: {
          description: description && description.length > 0 ? description : link,
          link: actualLink
        },
        onFinish: (success) => {
          if (success) {
            addToast('Link added successfully', TOAST_TYPES.SUCCESS);
            return;
          }
          addToast('Link failed to be added', TOAST_TYPES.ERROR);
        }
      })
    );

    closeDialog();
  };

  return (
    <ModalComponent
      title="Add New Link"
      open={open}
      onClose={closeDialog}
      maxWidth={600}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="contained" disabled={link.length <= 0} onClick={handleAddLink}>
            Add Link
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="32px" sx={{ padding: '24px' }}>
        <Stack direction="column" gap="8px">
          <Typography variant="text1_Semibold">Link description</Typography>
          <TextField
            variant="outlined"
            placeholder="Add short description for the link here"
            width="100%"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Stack>

        <Stack direction="column" gap="8px">
          <Typography variant="text1_Semibold">The Link</Typography>
          <TextField
            variant="outlined"
            placeholder="Add the link here"
            width="100%"
            value={link}
            onChange={(event) => {
              setLink(event.target.value);
            }}
          />
        </Stack>
      </Stack>
    </ModalComponent>
  );
}

AddDomainKnowledgeLinkDialog.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  folderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  initialLink: PropTypes.string,
  initialDescription: PropTypes.string,
  onClose: PropTypes.func
};

AddDomainKnowledgeLinkDialog.defaultProps = {
  initialLink: '',
  initialDescription: ''
};

export default AddDomainKnowledgeLinkDialog;
