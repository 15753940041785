import FolderIcon from '@mui/icons-material/Folder';
import LockIcon from '@mui/icons-material/Lock';
import { Stack, Tooltip, Typography } from '@mui/material';
import CustomAvatar from 'components/tile/CustomAvatar';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'icons/CloseIcon';
import PropTypes from 'prop-types';
import React from 'react';

function CollaborateChip({ name, picture, onRevoke, isOwner, isViaFolder, folderName }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap="4px"
      sx={{
        border: '1px solid',
        borderColor: 'colors.disabled_text',
        borderRadius: '4px',
        paddingLeft: '8px',
        height: '32px',
        backgroundColor: 'colors.selected'
      }}>
      <Stack direction="row" gap="8px" alignItems="center">
        <CustomAvatar name={name} size={24} pictureUrl={picture} />
        <Typography variant="text1_Normal" color="colors.primary_text">
          {name}
        </Typography>
      </Stack>
      {isOwner && (
        <Tooltip title="Resource Owner">
          <LockIcon
            sx={{
              width: '20px',
              height: '20px',
              paddingX: '4px',
              color: 'colors.primary_text'
            }}
          />
        </Tooltip>
      )}
      {isViaFolder && (
        <Tooltip title={`Has access via folder "${folderName || '?'}"`}>
          <FolderIcon
            sx={{
              width: '20px',
              height: '20px',
              paddingX: '4px',
              color: 'colors.primary_text'
            }}
          />
        </Tooltip>
      )}
      {!isOwner && !isViaFolder && onRevoke && (
        <MLIconButton
          tooltipText="Revoke Access"
          sx={{ width: '32px', height: '32px' }}
          onClick={onRevoke}>
          <CloseIcon />
        </MLIconButton>
      )}
    </Stack>
  );
}

CollaborateChip.propTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.string,
  onRevoke: PropTypes.func,
  isOwner: PropTypes.bool,
  isViaFolder: PropTypes.bool,
  folderName: PropTypes.string
};

export default CollaborateChip;
