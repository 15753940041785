import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { insightTypeToTitle } from 'utils/insights-utils';

export default function InsightTextComponent({ text, type, sx, mode }) {
  const [textParts, setTextParts] = useState([]);

  const determineColor = () => {
    switch (mode) {
      case 'overview': {
        return 'colors.informative_blue';
      }
      case 'tab': {
        return null;
      }
      case 'modal': {
        return 'colors.primary_text';
      }
      default: {
        return null;
      }
    }
  };

  const specialColor = determineColor();

  useEffect(() => {
    if (!text || text.length === 0) return;

    setTextParts(text.split('*'));
  }, [text]);

  return (
    <Typography
      className={text ? 'insightMessage' : null}
      variant="text1_Normal"
      textAlign="start"
      color="colors.primary_text"
      sx={sx}>
      <Typography variant="text1_Bold" color="colors.informative_blue" sx={{ display: 'block' }}>
        {insightTypeToTitle(type)}
      </Typography>
      {textParts.map((textPart, index) => {
        if (index % 2 === 0) {
          return textPart;
        }

        return (
          <Typography
            key={index}
            variant={mode === 'modal' ? 'text1_Bold' : 'text1_Normal'}
            color={specialColor}>
            {textPart}
          </Typography>
        );
      })}
    </Typography>
  );
}

InsightTextComponent.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  sx: PropTypes.object,
  mode: PropTypes.oneOf(['overview', 'tab', 'modal'])
};

InsightTextComponent.defaultProps = {
  sx: {},
  mode: 'overview'
};
