import { Button, Stack, Typography } from '@mui/material';
import { makeRunFiles } from 'actions/runs/make-new-download-run-files-action';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import MLIconButton from 'design-system/MLIconButton';
import DownloadIcon from 'icons/DownloadIcon';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { formatTimestamp } from 'utils/time-utils';

import AdminTabOrgLogo from './AdminTabOrgLogo';

function ClustersTab({ clusters, hidden, filter }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickRun = (companyListId) => {
    navigate(`${EXPLORE_PREFIX}/${companyListId}`);
  };

  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        {Object.keys(clusters)
          .filter((key) => {
            return filter ? filter(clusters, key) : true;
          })
          .sort((key1, key2) => clusters[key2].id - clusters[key1].id)
          .map((key) => {
            const run = clusters[key];
            return (
              <Stack
                key={key}
                direction="row"
                gap="32px"
                alignItems="center"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button
                  onClick={() => onClickRun(run.companyListId)}
                  size="small"
                  variant="contained"
                  sx={{ width: '60px' }}>
                  {run?.id ? run.id : key}
                </Button>
                <AdminTabOrgLogo logoUrl={run?.ownerOrganizationLogoUrl} />
                <MLIconButton
                  tooltipText="Download run files"
                  onClick={() => {
                    dispatch(makeRunFiles(run.companyListId, 'tagging', run.recordId));
                  }}>
                  <DownloadIcon />
                </MLIconButton>
                <Typography variant="text1_Normal">{run?.recordId}</Typography>
                <Typography variant="text1_Normal">
                  {run?.timeSubmitted ? formatTimestamp(run.timeSubmitted) : 'NA'}
                </Typography>
                <Typography variant="text1_Normal">{run.name}</Typography>

                <Typography variant="text1_Normal" sx={{ marginLeft: 'auto' }}>
                  {run.owner}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default ClustersTab;

ClustersTab.propTypes = {
  clusters: PropTypes.object,
  hidden: PropTypes.bool,
  filter: PropTypes.func
};
