import { getThreadKey, updatePlan } from 'reducer/custom-analytics';

export function updateWorkflowPlanAction(listId, postId, newPlanStr) {
  return (dispatch) => {
    const threadKey = getThreadKey(listId);
    let newPlan = null;
    try {
      newPlan = JSON.parse(newPlanStr);
    } catch {
      newPlan = null;
    }
    if (newPlan) {
      dispatch(updatePlan({ threadKey, postId, newPlan }));
    }
  };
}
