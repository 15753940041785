import { Button, Tooltip as ButtonTooltip, Stack, Typography } from '@mui/material';
import RechartsBox from 'components/elements/RechartsBox';
import InfoIcon from 'icons/InfoIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CartesianGrid,
  Cell,
  Legend,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { updateOriginalPredicted, updatePredicted } from 'reducer/art-slice';
import { getOriginalPredicted, getPredicted } from 'selectors/art';
import { appTheme } from 'theme';
import { isEmpty } from 'utils/objects-utils';
import { toTypeTitle } from 'utils/string-utils';

import ArtScatterShape from './ArtScatterShape';
import ArtScatterTooltip from './ArtScatterTooltip';

const typeToColor = (artType) => {
  switch (artType) {
    case 'target_sold':
      return appTheme.vars.palette.graphColors.graph1;
    case 'peer_sold':
      return appTheme.vars.palette.graphColors.graph6;
    case 'target_re-sold':
      return appTheme.vars.palette.graphColors.graph3;
    case 'peer_re-sold':
      return appTheme.vars.palette.graphColors.graph4;
    default:
      return appTheme.vars.palette.graphColors.graph5;
  }
};

function ArtScatter({
  finalPredictions,
  similarArtworks,
  predictionComponents,
  onRePredict,
  showPrediction,
  setShowPrediction
}) {
  const [clickedDot, setClickedDot] = React.useState(null);
  const [clickedSelect, setClickedSelect] = React.useState(false);
  const [removedPeers, setRemovedPeers] = React.useState({});
  const dispatch = useDispatch();
  const predicted = useSelector(getPredicted);
  const originalPredicted = useSelector(getOriginalPredicted);

  const predictionsData =
    finalPredictions &&
    Object.keys(finalPredictions).map((year) => ({
      year: parseInt(year, 10),
      price: finalPredictions[year]
    }));

  const similarData =
    !isEmpty(similarArtworks) &&
    similarArtworks.reduce((acc, artwork, index) => {
      const type = artwork.type.includes('peer_re-sold') ? 'peer_re-sold' : artwork.type;
      if (!acc?.[type]) {
        acc[type] = [];
      }
      acc[type].push({
        ...artwork,
        year: artwork.auction_year,
        execution_year: artwork.execution_year,
        price: artwork.exp_premium_price_usd,
        label: `${artwork.title} | ${artwork.artist}`,
        artType: type,
        similarArtworksIndex: index
      });
      return acc;
    }, {});

  const predictionComponentsData =
    predictionComponents?.mean_index &&
    Object.keys(predictionComponents?.mean_index).map((year) => ({
      year: parseInt(year, 10),
      price: predictionComponents?.mean_index[year]
    }));

  const onClickedOutside = (...args) => {
    if (Object.values(args[1].target.classList).includes('recharts-surface')) {
      setClickedDot(null);
    }
  };

  const onClickRePredict = useCallback(() => {
    onRePredict?.(removedPeers);
    dispatch(updateOriginalPredicted({ data: predicted }));
    setRemovedPeers({});
    setClickedSelect(false);
  }, [removedPeers, onRePredict, dispatch, predicted]);

  const onBackToOriginal = useCallback(() => {
    dispatch(updatePredicted({ data: originalPredicted }));
    dispatch(updateOriginalPredicted({ data: {} }));
  }, [dispatch, originalPredicted]);

  const title = useMemo(() => {
    const text = `Growth Over Time - ${
      predictionsData ? 'Final Prediction' : 'AI Model Prediction'
    }`;
    if (!finalPredictions) {
      return text;
    }
    if (!isEmpty(originalPredicted)) {
      return (
        <Stack direction="row" alignItems="center" justifyContent="center" gap="6px">
          {text}
          <Button variant="outlined" sx={{ padding: '2px 4px' }} onClick={onBackToOriginal}>
            Back To Original
          </Button>
        </Stack>
      );
    }

    return (
      <Stack direction="row" alignItems="center" justifyContent="center" gap="6px">
        <ButtonTooltip title={`${showPrediction ? 'Hide' : 'Show'} AI Model Prediction`}>
          <Button onClick={() => setShowPrediction(!showPrediction)}>
            {showPrediction && (
              <Typography variant="h1_Bold" fontSize="10px">
                Hide AI Model Prediction
              </Typography>
            )}
            {!showPrediction && <InfoIcon />}
          </Button>
        </ButtonTooltip>
        {text}
        {!clickedSelect && (
          <Button
            variant="outlined"
            sx={{ padding: '2px 4px' }}
            onClick={() => setClickedSelect(true)}>
            <Typography variant="text1_Bold"> Select Peers To Remove</Typography>
          </Button>
        )}
        {clickedSelect && (
          <Button variant="outlined" sx={{ padding: '2px 4px' }} onClick={onClickRePredict}>
            Re-Predict
          </Button>
        )}
        {clickedSelect && (
          <Button
            sx={{ padding: '2px 4px' }}
            onClick={() => {
              setClickedSelect(false);
              setRemovedPeers({});
            }}>
            Cancel
          </Button>
        )}
      </Stack>
    );
  }, [
    setClickedSelect,
    setRemovedPeers,
    clickedSelect,
    finalPredictions,
    predictionsData,
    onClickRePredict,
    originalPredicted,
    onBackToOriginal,
    showPrediction,
    setShowPrediction
  ]);

  const onClickedDot = (dot) => {
    setClickedDot(dot);
    if (!clickedSelect) {
      return;
    }
    const localRemovedPeers = { ...removedPeers };
    if (localRemovedPeers[dot.similarArtworksIndex]) {
      delete localRemovedPeers[dot.similarArtworksIndex];
    } else {
      localRemovedPeers[dot.similarArtworksIndex] = true;
    }
    setRemovedPeers(localRemovedPeers);
  };

  return (
    <React.Fragment>
      <RechartsBox title={title} height={400} mode="overview">
        <ScatterChart
          onClick={onClickedOutside}
          margin={{
            top: 30,
            right: 30,
            bottom: 30,
            left: 30
          }}>
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="year"
            name="year"
            allowDecimals={false}
            domain={['dataMin-1', 'dataMax+1']}
          />
          <YAxis
            type="number"
            dataKey="price"
            name="price"
            tickFormatter={(tick) => `${Math.floor(tick / 1000)}k`}
            allowDecimals={false}
            label={{
              value: 'Value (USD)',
              position: 'insideLeft',
              angle: -90,
              style: {
                textAnchor: 'middle'
              },
              fill: appTheme.vars.palette.colors.secondary_text,
              offset: -10
            }}
            domain={['dataMin - 2', 'dataMax + 2']}
          />

          {predictionsData && (
            <Scatter
              name="Final Prediction"
              data={predictionsData}
              fill="#8884d8"
              line
              shape={<ArtScatterShape fill={'#8884d8'} />}
              strokeWidth={3}
            />
          )}

          {predictionComponentsData && (
            <Scatter
              name="AI Model Prediction"
              data={predictionComponentsData}
              fill="#579BFC"
              line
              shape={<ArtScatterShape fill={'#579BFC'} />}
              strokeWidth={3}
            />
          )}
          {similarData &&
            Object.values(similarData).map((arts, artIndex) => (
              <Scatter
                key={`similar-${artIndex}`}
                name={toTypeTitle(arts[0].artType)}
                data={arts}
                fill={typeToColor(arts[0].artType)}>
                {arts.map((entry, entryIndex) => (
                  <Cell
                    key={`cell-${arts[0].artType}-${entryIndex}`}
                    fill={
                      removedPeers?.[entry.similarArtworksIndex]
                        ? appTheme.vars.palette.colors.hover_on_selected
                        : typeToColor(arts[0].artType)
                    }
                    onClick={() => onClickedDot(entry)}
                    cursor="pointer"
                  />
                ))}
              </Scatter>
            ))}

          <Legend
            wrapperStyle={{ padding: 10 }}
            verticalAlign="bottom"
            align="center"
            iconType={'circle'}
          />

          <Tooltip
            wrapperStyle={{ pointerEvents: 'auto' }}
            cursor={false}
            content={<ArtScatterTooltip removedPeers={removedPeers} />}
          />
        </ScatterChart>
      </RechartsBox>
      {clickedDot && <ArtScatterTooltip clickedDot={clickedDot} removedPeers={removedPeers} />}
    </React.Fragment>
  );
}

ArtScatter.propTypes = {
  finalPredictions: PropTypes.object,
  similarArtworks: PropTypes.array,
  predictionComponents: PropTypes.object,
  onRePredict: PropTypes.func,
  showPrediction: PropTypes.bool,
  setShowPrediction: PropTypes.func
};

export default ArtScatter;
