import { Checkbox, FormControl, MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import SelectMultipleMenuItemInner from './SelectMultipleMenuItemInner';
import SelectedChip from './SelectedChip';
import ShowMoreContainerComponent from './ShowMoreContainerComponent';

const selectStyle = {
  minWidth: '200px',
  width: 'auto',
  maxWidth: '500px',
  height: '40px',
  textAlign: 'left',
  backgroundColor: 'colors.primary_bg',
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'colors.ui_border'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'colors.ui_border'
  }
};

const MenuProps = {
  PaperProps: {
    style: {
      minWidth: 200,
      maxWidth: 300
    }
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 100
  }
};

export function MultiSelectComponent({
  maxWidth,
  selectedValues,
  allValues,
  allTag,
  emptyTag,
  onValueChange
}) {
  const allValuesMap = useMemo(
    () =>
      allValues.reduce((map, value) => {
        map[value.id] = value;
        return map;
      }, {}),
    [allValues]
  );

  const handleChange = (event) => {
    const newHaveAll = event.target.value.includes(allTag);
    if (newHaveAll) {
      onValueChange(
        selectedValues.length === allValues.length ? [] : allValues.map((value) => value.id)
      );
    } else {
      const value = event.target?.value;
      // On autofill we get a stringified value.
      const newValue = typeof value === 'string' ? value.split(',') : value;
      onValueChange(newValue);
    }
  };

  const handleRemoveChip = (event, value) => {
    event.stopPropagation();
    onValueChange(selectedValues.filter((selectedValue) => selectedValue !== value));
  };

  return (
    <FormControl>
      <Select
        value={selectedValues || []}
        multiple
        displayEmpty
        MenuProps={MenuProps}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <Typography variant="text1_Medium" color="colors.disabled_text">
                {emptyTag}
              </Typography>
            );
          }
          if (selected.length === allValues.length) {
            return (
              <Typography variant="text1_Medium" color="colors.primary_text">
                {allTag}
              </Typography>
            );
          }
          return (
            <ShowMoreContainerComponent
              sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 0.5 }}
              maxWidth={maxWidth}>
              {selected.map((value) => (
                <SelectedChip
                  key={value}
                  value={value}
                  label={allValuesMap[value]?.name}
                  handleCloseClick={(event) => handleRemoveChip(event, value)}
                />
              ))}
            </ShowMoreContainerComponent>
          );
        }}
        sx={selectStyle}
        onChange={handleChange}>
        {allValues && (
          <MenuItem
            value={allTag}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '8px'
            }}>
            <Checkbox checked={selectedValues?.length === allValues.length} />
            <Typography variant="text1_Medium" color="colors.primary_text">
              Select All
            </Typography>
          </MenuItem>
        )}
        {allValues &&
          allValues.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px'
              }}>
              <SelectMultipleMenuItemInner
                value={item.id}
                label={item.name}
                selectedValues={selectedValues || []}
                checkbox
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

MultiSelectComponent.propTypes = {
  maxWidth: PropTypes.number,
  selectedValues: PropTypes.array,
  allValues: PropTypes.array,
  onValueChange: PropTypes.func,
  allTag: PropTypes.string,
  emptyTag: PropTypes.string
};

MultiSelectComponent.defaultProps = {
  emptyTag: ''
};

export default MultiSelectComponent;
