import { Stack, Typography } from '@mui/material';
import { submitAssistantActionAction } from 'actions/assistant/submit-assistant-action-action';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startNewThread } from 'reducer/assistant-slice';
import { getAssistantThreadById, getAssistantThreadMessagesById } from 'selectors/assistant';

import AssistantMessageComponent from './AssistantMessageComponent';
import MessageComponent from './MessageComponent';
import UserMessageComponent from './UserMessageComponent';

function AssistantConversationThread({ threadId }) {
  const thread = useSelector((state) => getAssistantThreadById(state, threadId));
  const messages = useSelector((state) => getAssistantThreadMessagesById(state, threadId));
  const dispatch = useDispatch();

  const onYes = () => {
    dispatch(submitAssistantActionAction());
  };
  const onNo = () => {
    dispatch(startNewThread());
  };
  return (
    <Stack
      gap="8px"
      textAlign="start"
      sx={{ borderTop: '1px solid', padding: '16px', borderColor: 'colors.ui_border' }}>
      <Typography variant="text2_Normal">
        New thread for list: {thread.company_list_id} - {thread.id}
      </Typography>
      {messages.map((message, index) => {
        return message.role === 'user' ? (
          <UserMessageComponent key={index} message={message} />
        ) : (
          <AssistantMessageComponent key={index} message={message} />
        );
      })}
      {thread.action && (
        <MessageComponent role="final action" action={thread.action} onYes={onYes} onNo={onNo} />
      )}
    </Stack>
  );
}

AssistantConversationThread.propTypes = { threadId: PropTypes.string };

export default AssistantConversationThread;
