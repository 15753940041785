/* eslint-disable max-lines */
import { Box, Button, Link, Stack, TextField, Typography } from '@mui/material';
import ModalComponent from 'components/modal/ModalComponent';
import MLIconButton from 'design-system/MLIconButton';
import DeleteIcon from 'icons/DeleteIcon';
import GoToIcon from 'icons/GoToIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateOriginalPredicted } from 'reducer/art-slice';
import { isEmpty } from 'utils/objects-utils';
import { toTitleCase } from 'utils/string-utils';

const FIELD_TO_TYPE = {
  execution_year: 'int',
  sold_price: 'int',
  sold_year: 'int',
  size: 'array_float'
};
// eslint-disable-next-line max-lines-per-function
function Artwork({ id, artwork, onPredict, predictLoading, setArtwork, resetArtWork }) {
  const [isOpen, setIsOpen] = useState(false);
  const [clickedPredict, setClickedPredict] = useState(false);

  const dispatch = useDispatch();

  const formattedArtwork = useMemo(() => {
    const hasBothSellingDetails = artwork.sold_year && artwork.sold_price;
    const hasBothSizes = artwork.size?.[0] && artwork.size?.[1];
    return {
      ...artwork,
      provenance: artwork.provenance && artwork.provenance.filter((item) => !isEmpty(item)),
      size: hasBothSizes && artwork.size?.map((item) => parseFloat(item)),
      execution_year: parseInt(artwork.execution_year, 10),
      sold_price: hasBothSellingDetails ? parseInt(artwork.sold_price, 10) : null,
      sold_year: hasBothSellingDetails ? parseInt(artwork.sold_year, 10) : null
    };
  }, [artwork]);

  const isFieldValid = useCallback(
    (field) => {
      const requiredFields = ['size', 'description', 'title', 'artist_name'];

      if (!formattedArtwork[field] && requiredFields.includes(field)) {
        return false;
      }
      if (field === 'execution_year' || field === 'sold_year') {
        if (
          formattedArtwork[field] &&
          (formattedArtwork[field] > 9999 || formattedArtwork[field] < 1000)
        ) {
          return false;
        }
      }
      return true;
    },
    [formattedArtwork]
  );

  const isInValid = useMemo(() => {
    if (Object.keys(formattedArtwork).some((field) => !isFieldValid(field))) {
      return true;
    }
    return false;
  }, [formattedArtwork, isFieldValid]);

  const onClickPredict = () => {
    setClickedPredict(true);
    if (isInValid) {
      return;
    }
    setArtwork(formattedArtwork);
    dispatch(updateOriginalPredicted({ data: {} }));
    setIsOpen(false);
    onPredict(id, formattedArtwork);
    setClickedPredict(false);
  };

  const onEdit = (field, event) => {
    const type = FIELD_TO_TYPE[field];
    if (!type || event.target.value === '') {
      return setArtwork({ ...artwork, id: null, [field]: event.target.value });
    }

    if (type === 'int') {
      const number = Number(event.target.value);
      if (!isNaN(number)) {
        setArtwork({ ...artwork, id: null, [field]: number });
      }
      return;
    }

    if (field === 'size') {
      const [first, second] = event.target.value;
      if (!isNaN(Number(first)) && !isNaN(Number(second))) {
        setArtwork({ ...artwork, id: null, [field]: [first, second] });
      }
    }
  };

  return (
    <React.Fragment>
      <Stack
        direction="row"
        gap="32px"
        alignItems="center"
        sx={{
          padding: '16px',
          boxSizing: 'border-box',
          width: '600px',
          borderRadius: '4px',
          boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'colors.primary_bg',
          gap: '16px',
          '&:hover': {
            backgroundColor: 'colors.gray_bg'
          },
          cursor: 'pointer'
        }}
        onClick={() => setIsOpen(true)}>
        <Box
          component="img"
          src={
            artwork?.image_url?.split(' ')[0] ||
            'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png'
          }
          width={100}
          height={100}
        />

        <Stack gap="8px" justifyContent="start" textAlign="left">
          <Typography variant="h6">{artwork.title}</Typography>
          <Typography variant="text1_Bold">{artwork.artist_name}</Typography>
          {artwork.medium && (
            <Typography variant="text1_Normal">
              <em>{toTitleCase(artwork.medium)}</em>
            </Typography>
          )}
        </Stack>
        <Button
          sx={{ marginLeft: 'auto' }}
          variant="contained"
          onClick={onClickPredict}
          disabled={predictLoading}>
          {predictLoading === id ? 'Predicting...' : 'Predict'}
        </Button>
      </Stack>
      <ModalComponent
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={<Typography variant="h6">{`${artwork.title} - ${artwork.artist_name}`}</Typography>}>
        <Stack
          direction="row"
          gap="32px"
          alignItems="flex-start"
          sx={{
            padding: '16px',
            boxSizing: 'border-box',
            borderRadius: '4px',
            backgroundColor: 'colors.primary_bg',
            gap: '16px'
          }}
          onClick={() => setIsOpen(true)}>
          <Box
            component="img"
            src={
              artwork?.image_url?.split(' ')[0] ||
              'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png'
            }
            width={500}
            height={500}
          />

          <Stack gap="8px" justifyContent="start" textAlign="left" width="100%">
            <Stack direction="row">
              <Link href={artwork.link} underline="none" target="_blank" rel="noreferrer">
                <Stack direction="row" alignItems="center" gap="6px">
                  <Typography variant="h1_Bold" color="red">
                    {artwork.title}
                  </Typography>
                  <GoToIcon />
                </Stack>
              </Link>
              <Stack direction="row" sx={{ marginLeft: 'auto' }} alignItems="center" gap="16px">
                <Button
                  sx={{ marginLeft: 'auto' }}
                  onClick={() => {
                    resetArtWork();
                    setClickedPredict(false);
                  }}>
                  Clear Changes
                </Button>
                <Button
                  sx={{ marginLeft: 'auto' }}
                  variant="contained"
                  onClick={onClickPredict}
                  disabled={predictLoading}>
                  {predictLoading === id ? 'Predicting...' : 'Predict'}
                </Button>
              </Stack>
            </Stack>
            <Typography variant="text1_Bold">{artwork.artist_name}</Typography>
            {artwork.medium && (
              <Typography variant="text1_Normal">
                <em>{toTitleCase(artwork.medium)}</em>
              </Typography>
            )}
            <Typography paddingTop="8px" variant="text1_Bold">
              Execution Year:
            </Typography>
            <TextField
              value={artwork?.execution_year || ''}
              size="small"
              sx={{
                width: '100px'
              }}
              onChange={(event) => onEdit('execution_year', event)}
              error={clickedPredict && !isFieldValid('execution_year')}
            />

            <Typography paddingTop="8px" variant="text1_Bold">
              Size (cm):{' '}
              {
                <Typography paddingTop="8px" variant="text1_Normal" color="red">
                  {!(artwork.size?.[0] && !artwork.size?.[1]) &&
                  !(!artwork.size?.[0] && artwork.size?.[1])
                    ? ''
                    : 'Please fill both fields'}
                </Typography>
              }
            </Typography>
            <Stack direction="row" alignItems="center" gap="8px">
              <TextField
                value={artwork.size?.[0] || ''}
                size="small"
                multiline
                sx={{
                  width: '100px',
                  input: {
                    color: 'colors.primary_text'
                  }
                }}
                onChange={(event) => {
                  onEdit('size', {
                    target: { value: [event.target.value, artwork.size?.[1] || ''] }
                  });
                }}
                error={clickedPredict && !isFieldValid('size')}
              />
              <Typography>X</Typography>
              <TextField
                value={artwork.size?.[1] || ''}
                size="small"
                multiline
                sx={{
                  width: '100px',
                  input: {
                    color: 'colors.primary_text'
                  }
                }}
                onChange={(event) => {
                  onEdit('size', {
                    target: { value: [artwork.size?.[0] || '', event.target.value] }
                  });
                }}
                error={clickedPredict && !isFieldValid('size')}
              />
            </Stack>

            <Typography paddingTop="8px" variant="text1_Bold">
              Description:
            </Typography>
            <TextField
              value={artwork.description || ''}
              size="small"
              multiline
              sx={{
                width: '100%',
                input: {
                  color: 'colors.primary_text'
                }
              }}
              onChange={(event) => onEdit('description', event)}
              error={clickedPredict && !isFieldValid('description')}
            />

            <Stack paddingTop="8px" direction="row" alignItems="center">
              <Typography variant="text1_Bold">Provenance:</Typography>
              <Button
                onClick={() => {
                  const newProvenance = artwork.provenance ? [...artwork.provenance] : [];
                  newProvenance.push('');
                  onEdit('provenance', { target: { value: newProvenance } });
                }}>
                Add +
              </Button>
            </Stack>

            {artwork.provenance?.map((item, index) => (
              <TextField
                key={index}
                value={item}
                size="small"
                multiline
                sx={{
                  width: '100%',
                  input: {
                    color: 'colors.primary_text'
                  }
                }}
                onChange={(event) => {
                  const newProvenance = artwork.provenance ? [...artwork.provenance] : [];
                  newProvenance[index] = event.target.value;
                  onEdit('provenance', { target: { value: newProvenance } });
                }}
                InputProps={{
                  endAdornment: (
                    <MLIconButton
                      sx={{ padding: '0px' }}
                      onClick={() => {
                        const newProvenance = artwork.provenance ? [...artwork.provenance] : [];
                        newProvenance.splice(index, 1);
                        onEdit('provenance', { target: { value: newProvenance } });
                      }}>
                      <DeleteIcon />
                    </MLIconButton>
                  )
                }}
              />
            ))}

            <Typography paddingTop="8px" variant="text1_Bold">
              Selling Details:{' '}
              {
                <Typography paddingTop="8px" variant="text1_Normal" color="red">
                  {!(artwork.sold_price && !artwork.sold_year) &&
                  !(!artwork.sold_price && artwork.sold_year)
                    ? ''
                    : 'Please fill both fields'}
                </Typography>
              }
            </Typography>
            <Stack direction="row" alignItems="center" gap="8px">
              <Typography paddingTop="8px" variant="text1_Bold">
                Sold Price (USD):
              </Typography>
              <TextField
                value={artwork.sold_price || ''}
                size="small"
                multiline
                sx={{
                  width: '100px',
                  input: {
                    color: 'colors.primary_text'
                  }
                }}
                onChange={(event) => onEdit('sold_price', event)}
                error={!artwork.sold_price && artwork.sold_year}
              />
              <Typography paddingTop="8px" variant="text1_Bold">
                Sold Year:
              </Typography>
              <TextField
                value={artwork.sold_year || ''}
                size="small"
                multiline
                sx={{
                  width: '100px',
                  input: {
                    color: 'colors.primary_text'
                  }
                }}
                onChange={(event) => onEdit('sold_year', event)}
                error={
                  (clickedPredict && !isFieldValid('sold_year')) ||
                  (artwork.sold_price && !artwork.sold_year)
                }
              />
            </Stack>
          </Stack>
        </Stack>
      </ModalComponent>
    </React.Fragment>
  );
}

Artwork.propTypes = {
  id: PropTypes.number,
  artwork: PropTypes.object,
  onPredict: PropTypes.func,
  predictLoading: PropTypes.number,
  setArtwork: PropTypes.func,
  resetArtWork: PropTypes.func
};

export default Artwork;
