import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { updateResourcePermissionsAction } from 'actions/permissions/update-resource-permissions-action';
import SaveQueryDialog from 'components/discovery/SaveQueryDialog';
import { RESOURCE_TYPES } from 'constants/resources';
import MLIconButton from 'design-system/MLIconButton';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import MenuIcon from 'icons/MenuIcon';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';
import { getSavedQueryIsShared } from 'selectors/saved-queries';
import { getUserOrganizationId } from 'selectors/user';

function WorkflowsCenterDialogRowMenu({ query, listId }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const nestedComponentRef = useRef();
  const { addToast } = useAppUtils();

  const plan = useSelector((state) => {
    if (query.savedQueryId) {
      return query.parameters;
    }
    if (query.postId) {
      return getCustomAnalyticsPlanByThreadAndPostId(state, getThreadKey(listId), query.postId);
    }
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const organizationId = useOldStateSelector(getUserOrganizationId);

  const [sharedWithOrg, setSharedWithOrg] = useState(false);
  const isSharedFromDb = useSelector((state) => getSavedQueryIsShared(state, query?.savedQueryId));

  useEffect(() => {
    setSharedWithOrg(isSharedFromDb);
  }, [isSharedFromDb]);

  const handleShareToOrg = () => {
    dispatch(
      updateResourcePermissionsAction(
        query.savedQueryId,
        RESOURCE_TYPES.SAVED_QUERY,
        [],
        sharedWithOrg ? [] : [organizationId],
        [],
        addToast
      )
    );
    setSharedWithOrg(!sharedWithOrg);
  };

  const menuItems = [
    {
      id: 'save',
      onClick: () => {
        if (nestedComponentRef.current) {
          nestedComponentRef.current.notifyFromParent();
        }
      },
      startIcon: (
        <SaveQueryDialog
          prompt={{ ...plan, saved_query_id: query.savedQueryId }}
          existColumnName={query.name}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          listId={listId}
          ref={nestedComponentRef}
          showIsBookmarked={false}
          isWorkflow={true}
        />
      ),
      text: null,
      // use function to avoid re-rendering when isSaved changes
      getText: () => (isSaved ? 'Remove from Workflow Library' : 'Save to Workflow Library')
    },
    {
      id: 'share',
      text: sharedWithOrg ? 'Change to private' : 'Share with account',
      startIcon: <ShareIcon />,
      onClick: handleShareToOrg,
      hidden: !query.savedQueryId
    }
  ];

  return (
    <React.Fragment>
      <MLIconButton sx={{ padding: 0 }} onClick={handleOpen}>
        <MenuIcon color="currentColor" />
      </MLIconButton>

      <Menu
        MenuListProps={{ sx: { py: 0 } }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItems
          .filter(({ hidden }) => !hidden)
          .map(({ id, startIcon, onClick, text, getText }) => (
            <MenuItem key={id} onClick={onClick}>
              <ListItemIcon>{startIcon}</ListItemIcon>
              <ListItemText>{text || getText()}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  );
}

WorkflowsCenterDialogRowMenu.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  query: PropTypes.object
};

export default WorkflowsCenterDialogRowMenu;
