import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { makeRerunPersonsSearchAction } from 'actions/persons/make-new-persons-search-action';
import { makeNewSearchRerunAction } from 'actions/runs/make-new-search-run-action';
import CollaborateDialog from 'components/collaborate/CollaborateDialog';
import MoveResearchToFolderDialog from 'components/folders/MoveResearchToFolderDialog';
import { SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { RESOURCE_TYPES } from 'constants/resources';
import { TILE_TYPE } from 'constants/tiles';
import MLIconButton from 'design-system/MLIconButton';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import CollaborateIcon from 'icons/CollaborateIcon';
import DeleteIcon from 'icons/DeleteIcon';
import MenuIcon from 'icons/MenuIcon';
import MoveResourceIcon from 'icons/MoveResourceIcon';
import RerunIcon from 'icons/RerunIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DeleteSearchDialog from '../DeleteSearchDialog';

function HomePageTile({
  Icon,
  showDelete,
  recordId,
  searchId,
  recordType,
  resourceType,
  researchName,
  folderId,
  isMine,
  shared,
  isOnHover,
  showMove,
  showRerun,
  setIsOnHover,
  onClick,
  content,
  actions
}) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [openCollaborateDialog, setOpenCollaborateDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleActionsClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const onDeleteClick = (event) => {
    handleMenuClick(event);
    setOpenDeleteDialog(true);
  };

  const onMoveClick = (event) => {
    handleMenuClick(event);
    setOpenMoveDialog(true);
  };

  const onCollaborateClick = (event) => {
    handleMenuClick(event);
    setOpenCollaborateDialog(true);
  };

  const onRerunClick = (event) => {
    handleMenuClick(event);
    if (recordType === TILE_TYPE.RUN) {
      oldDispatch(makeNewSearchRerunAction(searchId));
    } else if (recordType === TILE_TYPE.PERSON) {
      oldDispatch(makeRerunPersonsSearchAction(searchId));
    }
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData('recordId', recordId);
    event.dataTransfer.setData('recordType', recordType);
    event.dataTransfer.setData('folderId', folderId);
    event.dataTransfer.setData('researchName', researchName);
    event.dataTransfer.setData('canMove', folderId !== SHARED_WITH_ME_FOLDER_ID);
  };

  const openActionsMenu = Boolean(anchorEl);
  return (
    <Card
      draggable
      onDragStart={handleDragStart}
      onMouseEnter={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
      onClick={onClick}
      id="home-page-tile"
      sx={{
        position: 'relative',
        boxSizing: 'border-box',
        height: '236px',
        borderRadius: '4px',
        boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
        backgroundColor: 'colors.primary_bg',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          backgroundColor: 'colors.gray_bg'
        },
        cursor: 'pointer'
      }}>
      <MLIconButton
        tooltipText="Actions"
        variant="secondary"
        id="tile-actions-button"
        sx={{
          padding: 0,
          display: isOnHover ? 'block' : 'none',
          position: 'absolute',
          top: '16px',
          right: '16px'
        }}
        onClick={handleActionsClick}>
        <MenuIcon />
      </MLIconButton>
      <CardContent
        sx={{
          flex: 1,
          padding: 0,
          border: '1px solid',
          borderColor: 'colors.hover_on_primary_bg',
          borderRadius: '2px'
        }}>
        {content}
      </CardContent>
      <CardActions
        sx={{
          padding: 0,
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '8px'
        }}>
        <Divider flexItem sx={{ borderColor: 'colors.ui_border' }} />
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          width="100%"
          sx={{ boxSizing: 'border-box', padding: '8px 16px' }}>
          <Box>
            <Icon />
          </Box>
          {actions}
        </Stack>
      </CardActions>
      {(showDelete || showMove) && (
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={openActionsMenu}
          onClose={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setAnchorEl(null);
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}>
          {showDelete && (
            <MenuItem onClick={onDeleteClick}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <Typography variant="text1_Normal">Delete</Typography>
            </MenuItem>
          )}
          {showMove && folderId !== SHARED_WITH_ME_FOLDER_ID && (
            <MenuItem onClick={onMoveClick} disabled={!isMine}>
              <ListItemIcon>
                <MoveResourceIcon />
              </ListItemIcon>
              <Typography variant="text1_Normal">
                {isMine ? 'Move' : 'Research belongs to another user'}
              </Typography>
            </MenuItem>
          )}
          {showMove && (
            <MenuItem onClick={onCollaborateClick}>
              <ListItemIcon>
                <CollaborateIcon />
              </ListItemIcon>
              <Typography variant="text1_Normal">Collaborate</Typography>
            </MenuItem>
          )}
          {showRerun && folderId !== SHARED_WITH_ME_FOLDER_ID && (
            <MenuItem onClick={onRerunClick}>
              <ListItemIcon>
                <RerunIcon />
              </ListItemIcon>
              <Typography variant="text1_Normal">Rerun</Typography>
            </MenuItem>
          )}
        </Menu>
      )}
      <DeleteSearchDialog
        open={openDeleteDialog}
        recordId={searchId || recordId}
        onClose={() => setOpenDeleteDialog(false)}
        recordType={recordType}
        shared={shared}
        folderId={folderId}
        actualRecordId={recordId}
      />
      <MoveResearchToFolderDialog
        open={openMoveDialog}
        recordId={recordId}
        recordType={recordType}
        currentFolderId={folderId}
        onClose={() => setOpenMoveDialog(false)}
      />
      {showMove && (
        <CollaborateDialog
          open={openCollaborateDialog}
          onClose={() => setOpenCollaborateDialog(false)}
          id={recordId}
          searchId={searchId}
          resourceType={resourceType}
          folderId={folderId}
        />
      )}
    </Card>
  );
}

HomePageTile.propTypes = {
  Icon: PropTypes.func,
  showDelete: PropTypes.bool,
  isMine: PropTypes.bool,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  recordType: PropTypes.oneOf(Object.values(TILE_TYPE)),
  resourceType: PropTypes.oneOf(Object.values(RESOURCE_TYPES)),
  researchName: PropTypes.string,
  folderId: PropTypes.number,
  shared: PropTypes.bool,
  isOnHover: PropTypes.bool,
  showMove: PropTypes.bool,
  showRerun: PropTypes.bool,
  setIsOnHover: PropTypes.func,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  content: PropTypes.node,
  actions: PropTypes.any,
  searchId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default HomePageTile;
