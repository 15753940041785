import { Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

import PromptEndAdornment from './PromptEndAdornment';

function PromptTextFieldComponent({
  getPlaceholder,
  getIsInputDisabled,
  handleSubmit,
  isProccessing,
  setPromptConfig,
  promptConfig,
  isDev,
  dontAllowSubmitCA,
  setShowError,
  height,
  listId
}) {
  return (
    <Stack width="100%" paddingLeft="4px">
      <TextField
        maxRows={6}
        minRows={3}
        multiline
        variant="standard"
        placeholder={getPlaceholder()}
        value={promptConfig.question}
        size="small"
        autoFocus
        disableUnderline={true}
        disabled={getIsInputDisabled()}
        slotProps={{
          input: {
            disableUnderline: true,
            sx: {
              width: '100%',
              minHeight: height,
              color: 'colors.secondary_text',
              ...appTheme.typography.text1_Normal
            }
          }
        }}
        sx={{
          flex: 1,
          minWidth: '100%',
          alignItems: 'center',
          ...appTheme.typography.text1_Normal
        }}
        onChange={(event) => {
          setShowError(false);
          setPromptConfig((prev) => ({ ...prev, question: event.target.value }));
        }}
        onKeyDown={(event) => {
          if (!dontAllowSubmitCA && event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
          }
        }}
      />
      <PromptEndAdornment
        handleSubmit={handleSubmit}
        isProccessing={isProccessing}
        setPromptConfig={setPromptConfig}
        promptConfig={promptConfig}
        isDev={isDev}
        listId={listId}
      />
    </Stack>
  );
}

PromptTextFieldComponent.propTypes = {
  getPlaceholder: PropTypes.func,
  getIsInputDisabled: PropTypes.func,
  handleSubmit: PropTypes.func,
  isProccessing: PropTypes.bool,
  setPromptConfig: PropTypes.func,
  promptConfig: PropTypes.object,
  isDev: PropTypes.bool,
  dontAllowSubmitCA: PropTypes.bool,
  setShowError: PropTypes.func,
  height: PropTypes.number,
  listId: PropTypes.number
};

PromptTextFieldComponent.defaultProps = {
  height: 42
};

export default PromptTextFieldComponent;
