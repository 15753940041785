import { createSlice } from '@reduxjs/toolkit';
import { createNewThreadForState } from 'models/assistant/AssistantThread';

const initialThread = createNewThreadForState();

const initialSliceState = {
  threads: {
    [initialThread.id]: initialThread
  }
};

export const assistantSlice = createSlice({
  name: 'assistant',
  initialState: initialSliceState,
  reducers: {
    updateThread: (sliceState, { payload }) => {
      const { thread } = payload;
      sliceState.threads[thread.id] = thread;
    },
    updateThreadFolderId: (sliceState, { payload }) => {
      const { threadId, folder_id } = payload;
      if (sliceState.threads[threadId].messages.length > 0) {
        const newThread = createNewThreadForState({ folder_id: parseInt(folder_id, 10) });
        sliceState.threads[newThread.id] = newThread;
      } else {
        sliceState.threads[threadId].folder_id = parseInt(folder_id, 10);
      }
    },
    updateThreadCompanyListId: (sliceState, { payload }) => {
      const { threadId, company_list_id } = payload;
      if (sliceState.threads[threadId].messages.length > 0) {
        const newThread = createNewThreadForState({
          company_list_id: parseInt(company_list_id, 10)
        });
        sliceState.threads[newThread.id] = newThread;
      } else {
        sliceState.threads[threadId].company_list_id = parseInt(company_list_id, 10);
      }
    },
    addMessageToThread: (sliceState, { payload }) => {
      const { threadId, message, action } = payload;
      sliceState.threads[threadId].messages.push(message);
      if (action) {
        sliceState.threads[threadId].action = action;
      }
    },
    startNewThread: (sliceState, { payload }) => {
      const newThread = createNewThreadForState(payload);
      sliceState.threads[newThread.id] = newThread;
    }
  }
});

export const {
  updateThread,
  startNewThread,
  updateThreadFolderId,
  updateThreadCompanyListId,
  addMessageToThread
} = assistantSlice.actions;

export default assistantSlice.reducer;
