import { Box, Button, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import RerunIcon from 'icons/RerunIcon';
import PropTypes from 'prop-types';

export default function NoClustersComponent({
  clustersId,
  isDisabled,
  isFailed,
  onTriggerClustersRun,
  isLoadedToState,
  progress,
  isListEmpty,
  headerHeight
}) {
  const onClick = () => {
    onTriggerClustersRun();
  };

  const heights = {
    paddingBottom: 24,
    paddingTop: 16
  };
  const paddingY = heights.paddingBottom + heights.paddingTop;
  return (
    <Stack paddingBottom={`${heights.paddingBottom}px`} paddingTop={`${heights.paddingTop}px`}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        gap="60px"
        sx={{
          width: '100%',
          backgroundColor: 'colors.gray_bg',
          height: `calc(100vh - ${headerHeight + paddingY}px)`
        }}>
        {!clustersId && (
          <Box sx={{ width: '500px' }}>
            <Typography variant="text1_Normal" fontSize="20px">
              View clusters based on similarity between companies and explore connections among the
              companies on your list
            </Typography>
          </Box>
        )}
        {isFailed && (
          <Typography variant="text1_Normal" fontSize="20px">
            Failed to create clusters view
          </Typography>
        )}
        {isDisabled && !isFailed && (
          <Typography variant="text1_Normal" fontSize="20px">
            Creating Clusters View...
          </Typography>
        )}

        {isDisabled && !isFailed && (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={progress} size="100px" />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Typography fontSize={20} component="div" color="text.secondary">
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          </Box>
        )}
        {(isFailed || (!clustersId && !(clustersId && !isLoadedToState))) && (
          <Tooltip
            title={
              isListEmpty ? (
                <Typography variant="text1_Normal">Add companies to the list first</Typography>
              ) : (
                ''
              )
            }>
            <Box>
              <Button
                variant="contained"
                onClick={onClick}
                disabled={isDisabled || isListEmpty}
                sx={{
                  gap: '8px',
                  backgroundColor: isDisabled ? 'colors.selected' : 'default',
                  color: isDisabled ? 'colors.disabled_text' : 'default'
                }}>
                {isFailed && 'try again'}
                {!clustersId && 'Create Clusters View'}
                {isFailed && <RerunIcon />}
                {clustersId && !isLoadedToState && <CircularProgress size={'16px'} />}
              </Button>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
}

NoClustersComponent.propTypes = {
  clustersId: PropTypes.number,
  isDisabled: PropTypes.bool,
  isFailed: PropTypes.bool,
  onTriggerClustersRun: PropTypes.func,
  isLoadedToState: PropTypes.bool,
  progress: PropTypes.number,
  isListEmpty: PropTypes.bool,
  headerHeight: PropTypes.number
};
