import { addPostQueryToThread, getThreadKey } from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowEditModeByIdAndListId
} from 'reducer/workflows-slice';

export function addEmptyWorkflowAction(listId, startFromScratch) {
  return (dispatch) => {
    const threadKey = getThreadKey(listId);
    const newPostId = new Date().getTime();
    const plan = { question: startFromScratch ? null : '' };
    dispatch(
      addPostQueryToThread({
        threadKey,
        postId: newPostId,
        plan,
        query: '',
        body: {},
        discoveryId: 1,
        messages: [],
        isWorkflow: true
      })
    );
    dispatch(updateSelectedWorkflowIdByListId({ listId, selectedWorkflowId: newPostId }));
    dispatch(
      updateWorkflowEditModeByIdAndListId({ listId, workflowId: newPostId, isEditMode: true })
    );
  };
}
