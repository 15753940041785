import { getLatestThread } from 'selectors/assistant';

import { submitAssistantHelperAction } from './submit-assistant-helper-action';

export function submitAssistantActionAction() {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const thread = getLatestThread(state);
    dispatch(submitAssistantHelperAction(thread.id, thread));
  };
}
