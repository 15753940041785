import { Button, Snackbar } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import { usePusher } from 'hooks/PusherProvider';
import CloseIcon from 'icons/CloseIcon';
import React, { useEffect, useState } from 'react';
import { getGeneralChannelName } from 'utils/pusher-utils';

/*
  IMPORTANT! this is a channel that ALL THE USERS are subscribed to.
  It is used to broadcast messages to all the users.
  Must not be used for sensitive data.
*/
export default function PusherGeneralListener() {
  const pusher = usePusher();
  const channelName = getGeneralChannelName();
  const [hasNewVersion, setHasNewVersion] = useState(false);

  useEffect(() => {
    if (!pusher) {
      return;
    }
    const channel = pusher.subscribe(channelName);

    channel.bind('new-version', (data) => {
      // Method to be dispatched on trigger.
      const { force_refresh } = data;
      if (force_refresh) {
        window.location.reload();
      }
      setHasNewVersion(true);
    });
    // return will be called upon Component Unmount
    return () => {
      pusher.unsubscribe(channelName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setHasNewVersion(false);
  };

  const action = (
    <React.Fragment>
      <Button
        sx={{ color: 'colors.secondary_text' }}
        size="small"
        onClick={() => window.location.reload()}>
        REFRESH
      </Button>
      <MLIconButton onClick={handleClose}>
        <CloseIcon color="white" />
      </MLIconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={hasNewVersion}
      //   autoHideDuration={6000}
      //   onClose={handleClose}
      alertMessage="New version is now available! We suggest to refresh the page"
      action={action}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    />
  );
}
