import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useScrollPositionFilterByDirection } from 'hooks/useScrollPositionFilterByDirection';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getDeepDiveIsPublicMode } from 'selectors/deep-dive';
import { appTheme } from 'theme';

function TabsComponent({ children, activeTabName, tabPrefix }) {
  const smallViewport = useMediaQuery(appTheme.breakpoints.up('small'));
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [top, setTop] = useState(0);
  const containerRef = useRef();
  const isPublicMode = useSelector(getDeepDiveIsPublicMode);

  const scrollPosition = useScrollPositionFilterByDirection(
    'TabsComponent',
    isPublicMode ? 'root' : 'deep-dive-screen'
  );
  const topOffset = isPublicMode ? 1 : 51;

  useEffect(() => {
    const currentTabs = [];
    React.Children.forEach(children, (element, index) => {
      if (!React.isValidElement(element)) return;
      const { group, title } = element.props;
      if (!group) return;
      currentTabs.push({ id: index, title: title, path: group, children: element });
    });
    setTabs(currentTabs);
  }, [children]);

  useEffect(() => {
    const activeTabIndex = tabs.findIndex((tabInfo) => tabInfo.path === activeTabName);
    setActiveTab(activeTabIndex >= 0 ? activeTabIndex : 0);
  }, [activeTabName, tabs]);

  useLayoutEffect(() => {
    setTop(containerRef.current.offsetTop - topOffset);
  }, [containerRef.current?.offsetTop, scrollPosition, topOffset]);
  return (
    <Box
      ref={containerRef}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      }}>
      <Box
        sx={{
          display: 'flex',
          padding: '0 16px',
          boxShadow: '0px 3px 8px 3px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'colors.primary_text',
          position: top === 0 ? 'initial' : 'sticky',
          top,
          zIndex: 'overviewTabs'
        }}>
        <Tabs
          variant={smallViewport ? 'standard' : 'scrollable'}
          value={activeTab}
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            flex: 1,
            '.MuiTabs-scrollButtons': {
              color: 'colors.text_on_primary'
            }
          }}>
          {tabs.map((tabInfo) => (
            <Tab
              key={tabInfo.id}
              component={RouterLink}
              label={tabInfo.title}
              to={`${tabPrefix}/${tabInfo.path}`}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          backgroundColor: 'colors.hover_on_gray_bg',
          flexGrow: 1,
          padding: { xs: '24px 16px', md: '24px' }
        }}>
        {tabs[activeTab] && tabs[activeTab].children}
      </Box>
    </Box>
  );
}

TabsComponent.propTypes = {
  activeTabName: PropTypes.string,
  tabPrefix: PropTypes.string,
  children: PropTypes.any
};

export default TabsComponent;
