import { Box, Card, CardContent, CardMedia, Divider, Link, Stack, Typography } from '@mui/material';
import LinkedinButton from 'components/LinkedinButton';
import AniversaryIcon from 'icons/AniversaryIcon';
import HeadcountIcon from 'icons/HeadcountIcon';
import TrustIcon from 'icons/TrustIcon';
import PropTypes from 'prop-types';
import React from 'react';

const MAX_DESCRIPTION_LENGTH = 800;

export default function CompanySummaryCard({ label }) {
  const companyDescription = label?.metadata?.description;
  const reasons = label?.metadata?.reasons ? JSON.parse(label?.metadata?.reasons) : {};
  const tags = reasons?.industries;
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <CardMedia
            sx={{ width: '40px' }}
            component="img"
            image={label.image_url}
            alt={label.name}
          />
          <Stack>
            <Typography gutterBottom variant="h2_Bold" color="colors.primary_text">
              {label.name}
            </Typography>
            <Box>
              {label.metadata && (
                <React.Fragment>
                  <AniversaryIcon />
                  <Typography
                    variant="text1_Normal"
                    sx={{ marginRight: '24px', marginLeft: '8px' }}>
                    {label?.metadata?.year_founded}{' '}
                  </Typography>
                </React.Fragment>
              )}
              {label.x && (
                <React.Fragment>
                  <HeadcountIcon />
                  <Typography variant="text1_Normal" sx={{ marginLeft: '8px' }}>
                    {parseFloat(label?.x.toPrecision(2)).toFixed(0)}{' '}
                  </Typography>
                </React.Fragment>
              )}
            </Box>
          </Stack>
        </Box>
        <br />
        {label.url && label.name && label.metadata && (
          <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            <LinkedinButton linkedinUrl={label.metadata.linkedin_url} />
            <Link href={label.url} target="_blank" rel="noreferrer">
              {label.url}
            </Link>
            {label.metadata && label.metadata?.search_type === 'consensus' && (
              <TrustIcon height="20px" />
            )}
          </Box>
        )}
        <br />
        <Divider sx={{ marginBottom: '32px' }} />
        {tags && tags.length > 0 && (
          <React.Fragment>
            <Typography variant="text1_Bold" color="colors.primary_text" sx={{ marginTop: '32px' }}>
              Common Tags:
            </Typography>
            <Box margin={2}>
              {tags.map((tag, index) => (
                <Box key={index} padding={0.1}>
                  <Typography
                    variant="text1_Medium"
                    sx={{
                      backgroundColor: 'colors.hover_on_gray_bg',
                      color: 'colors.primary_text',
                      padding: '2px 4px'
                    }}>
                    {tag}
                  </Typography>
                </Box>
              ))}
            </Box>
          </React.Fragment>
        )}
        {companyDescription && (
          <Typography
            variant="text1_Normal"
            color="text.secondary"
            align="left"
            marginTop={1}
            sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {companyDescription.length > MAX_DESCRIPTION_LENGTH
              ? `${companyDescription.substring(0, MAX_DESCRIPTION_LENGTH)}...`
              : companyDescription}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

CompanySummaryCard.propTypes = { label: PropTypes.object };
