import MLIconButton from 'design-system/MLIconButton';
import PropTypes from 'prop-types';

export default function RenderButton({ value }) {
  return (
    <MLIconButton onClick={value.onClick} sx={value.sx || {}}>
      {value.icon}
    </MLIconButton>
  );
}

RenderButton.propTypes = {
  value: PropTypes.any
};
