import { Alert, Button, Input, Stack } from '@mui/material';
import {
  clearCurrentSearchAutocomplete,
  clearCurrentSearchAutocompleteAfterFailure,
  makeNewSearchAutocompleteAction
} from 'actions/autocomplete/make-new-search-autocomplete-action';
import { makeNewSearchRunAction } from 'actions/runs/make-new-search-run-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import ModalComponent from 'components/modal/ModalComponent';
import SuggestionTile from 'components/tile/SuggestionTile';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import BigSearchIcon from 'icons/BigSearchIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getCurrentSearchAutocompleteStatus,
  getCurrentSearchAutocompleteSuggestions
} from 'selectors/current-search';
import { appTheme } from 'theme';

export default function CreateResearchDialog({ open, onClose, folderId }) {
  const { state } = useAppState();
  const reduxDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState();
  const searchSuggestions = getCurrentSearchAutocompleteSuggestions(state);
  const autocompleteStatus = getCurrentSearchAutocompleteStatus(state);

  const clearSearch = () => {
    setName('');
    setIsLoading(false);
    dispatch(clearCurrentSearchAutocomplete());
  };

  const clearSearchAfterFailure = () => {
    setName('');
    setIsLoading(false);
    dispatch(clearCurrentSearchAutocompleteAfterFailure());
  };

  const closeDialog = () => {
    if (searchSuggestions) {
      Object.keys(searchSuggestions).forEach((suggestion_type) => {
        searchSuggestions[suggestion_type].forEach((suggestion) => {
          const { cb_permalink, company_website, linkedin_url } = suggestion;
          dispatch(
            sendUserEvent('deep_dive_dialog_closed', {
              term: name,
              suggestion_type,
              cb_permalink,
              company_website,
              linkedin_url
            })
          );
        });
      });
    }
    clearSearch();
    onClose?.();
  };

  useEffect(() => {
    if (searchSuggestions) {
      setIsLoading(false);
    }
  }, [searchSuggestions]);

  const makeSearch = () => {
    setIsLoading(true);
    dispatch(makeNewSearchAutocompleteAction(name, true));
  };

  const runSearch = (suggestionKey) => {
    const suggestion = searchSuggestions[suggestionKey]?.[0];
    delete suggestion.existing_record_id;
    dispatch(makeNewSearchRunAction(suggestion, folderId, reduxDispatch));
    dispatch(clearCurrentSearchAutocomplete());

    closeDialog();
  };

  const onDownKey = (event) => {
    if (event?.key === 'Enter') {
      makeSearch();
    }
  };

  useEffect(() => {
    if (autocompleteStatus === 'failure') {
      clearSearchAfterFailure();
    }
  }, [autocompleteStatus]);

  if (searchSuggestions?.existing_suggestions?.length) {
    const newSuggestion = searchSuggestions?.new_suggestions?.[0];
    if (newSuggestion) {
      Object.keys(newSuggestion).forEach((key) => {
        if (newSuggestion[key] === null) {
          delete newSuggestion[key];
        }
      });
      searchSuggestions.existing_suggestions = [
        {
          ...searchSuggestions.existing_suggestions[0],
          ...newSuggestion
        }
      ];
    }

    delete searchSuggestions.new_suggestions;
  }

  const haveSuggestions =
    searchSuggestions &&
    autocompleteStatus === 'success' &&
    (searchSuggestions?.existing_suggestions?.length || searchSuggestions?.new_suggestions?.length);

  return (
    <ModalComponent
      title="New Research"
      open={open}
      onClose={closeDialog}
      maxWidth={560}
      backgroundColor={haveSuggestions ? 'colors.hover_on_gray_bg' : null}>
      <Stack
        direction="column"
        gap="32px"
        padding="24px"
        width="90%"
        sx={{ backgroundColor: haveSuggestions ? 'colors.hover_on_gray_bg' : null }}>
        <Stack direction="row" gap="16px" alignItems="center" width="100%">
          <BigSearchIcon />
          <Input
            placeholder="Enter company name or website"
            value={name}
            size="small"
            autoFocus
            disabled={isLoading || haveSuggestions}
            disableUnderline={true}
            onKeyDown={onDownKey}
            sx={{
              flex: 1,
              color: 'colors.primary_text',
              ...appTheme.typography.h3_Bold,
              backgroundColor: haveSuggestions ? 'colors.hover_on_gray_bg' : null
            }}
            onChange={(event) => setName(event.target.value)}
          />
          {haveSuggestions ? (
            <Button disabled={isLoading} onClick={clearSearch}>
              Clear
            </Button>
          ) : (
            <Button
              disabled={isLoading}
              onClick={makeSearch}
              sx={{ visibility: name?.length > 0 ? 'visible' : 'hidden' }}>
              Search
            </Button>
          )}
        </Stack>
        {autocompleteStatus === 'failure' && (
          <Alert severity="error">No companies found! Please try a different search term.</Alert>
        )}
        {searchSuggestions?.new_suggestions && searchSuggestions.new_suggestions?.length > 0 && (
          <SuggestionTile
            type={'new_suggestions'}
            onRunSearch={() => runSearch('new_suggestions')}
          />
        )}
        {searchSuggestions?.existing_suggestions &&
          searchSuggestions.existing_suggestions?.length > 0 && (
            <SuggestionTile
              type={'existing_suggestions'}
              onRunSearch={() => runSearch('existing_suggestions')}
            />
          )}
      </Stack>
    </ModalComponent>
  );
}

CreateResearchDialog.propTypes = {
  open: PropTypes.bool,
  folderId: PropTypes.number,
  onClose: PropTypes.func
};
