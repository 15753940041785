import { Button, Link, Stack, Typography } from '@mui/material';
import { deleteDomainKnowledgeResourceAction } from 'actions/explore/delete-domain-knowledge-resource-action';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

function DeleteDomainKnowledgeResourceDialog({ listId, supportingResource, open, onClose }) {
  const dispatch = useDispatch();

  const handleDeleteResource = () => {
    dispatch(deleteDomainKnowledgeResourceAction(listId, supportingResource.id));
    onClose();
  };

  return (
    <ModalComponent
      title="Delete Supporting Resource"
      open={open}
      onClose={onClose}
      maxWidth={600}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDeleteResource}>
            Delete
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="32px" sx={{ padding: '24px' }}>
        <Typography variant="text1_Normal">
          {'Are you sure you want to delete the '}
          <Typography variant="text1_Semibold" textTransform="capitalize">
            {supportingResource?.supporting_resource_type}
          </Typography>
          {' resource '}
          <Typography variant="text1_Semibold">{supportingResource?.name}</Typography>
          {'?'}
        </Typography>
        <Typography variant="text1_Semibold" textTransform="capitalize">
          details:
        </Typography>
        {supportingResource?.supporting_resource_type === 'files' && (
          <Typography variant="text1_Normal">{`File name: ${supportingResource.data?.file_name}`}</Typography>
        )}
        {supportingResource?.supporting_resource_type === 'answers' && (
          <Typography variant="text1_Normal">{`Custom Analytics: ${supportingResource.data?.title}`}</Typography>
        )}
        {supportingResource?.supporting_resource_type === 'links' && (
          <Stack direction="row" alignItems="center" flexWrap="wrap" gap="4px">
            <Link
              href={supportingResource.data?.link}
              underline="none"
              target="_blank"
              rel="noreferrer">
              {supportingResource.data?.link}
            </Link>
            <Typography variant="text1_Normal">{` with the description ${supportingResource.data?.description}`}</Typography>
          </Stack>
        )}
      </Stack>
    </ModalComponent>
  );
}

DeleteDomainKnowledgeResourceDialog.propTypes = {
  listId: PropTypes.number,
  supportingResource: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default DeleteDomainKnowledgeResourceDialog;
