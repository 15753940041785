import { Grid2, Skeleton, Stack, Typography } from '@mui/material';
import CustomMarkdown from 'components/CustomMarkdown';
import BarChartComponent from 'components/elements/bar/BarChartComponent';
import PieChartComponent from 'components/elements/pie/PieChartComponent';
import CountryMap from 'components/elements/table/quick-view/CountryMap';
import { DOMAIN_REPORT_TASK_TYPES } from 'constants/domain-reports';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLatestDRCompanyMetaIdsByListId,
  getLatestDRTaskByListId
} from 'selectors/domain-reports';
import { getCompanyMetasByListId } from 'selectors/explore';
import { appTheme } from 'theme';
import { formatAnswerBody } from 'utils/custom-analytics-utils';
import { formatNumber, revenueFromData } from 'utils/finance-utils';
import {
  barDataFromHeadcountData,
  barDataFromRevenuesData,
  barDataFromYearsFoundedData,
  pieDataFromFundingStageData
} from 'utils/graph-utils';

import DomainReportBox from '../DomainReportBox';
import DomainReportEditButton from '../edit/DomainReportEditButton';

const gridItemStyle = {
  borderRadius: '8px',
  border: '1px solid',
  borderColor: 'colors.hover_on_gray_bg',
  padding: '16px',
  color: 'colors.primary_text'
};

const GridItem = ({ title, percent, size, sx, taskType, listId, children }) => {
  const [hover, setHover] = React.useState(false);
  return (
    <Grid2
      item
      size={size}
      sx={{ ...gridItemStyle, ...sx }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <Stack gap="8px" height="100%" sx={{ pageBreakInside: 'avoid' }}>
        <Stack direction="row" gap="12px" alignItems="center" height="20px">
          <Typography variant="text1_Semibold">
            {title}
            {percent ? ` (${percent}%)` : ''}
          </Typography>
          {taskType && listId && hover && (
            <DomainReportEditButton listId={listId} taskType={taskType} />
          )}
        </Stack>
        {children}
      </Stack>
    </Grid2>
  );
};

const getAllValues = (companies, keyFunc) => {
  return companies.map(keyFunc).filter(Boolean);
};

function DomainReportMarket({ listId }) {
  const companyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const companyMetaIds = useSelector((state) => getLatestDRCompanyMetaIdsByListId(state, listId));
  const { data } =
    useSelector((state) =>
      getLatestDRTaskByListId(state, listId, DOMAIN_REPORT_TASK_TYPES.MARKET_OVERVIEW)
    ) || {};
  const filteredCompanies = companyMetas.filter(
    (company) => companyMetaIds && companyMetaIds.includes(company.company_meta_id)
  );
  const countryData = getAllValues(
    filteredCompanies,
    (company) => company.enrichment_parameters.hq_location_country
  );
  const revenuesData = getAllValues(
    filteredCompanies,
    (company) => company.company_extra_data?.FINANCE?.data?.revenue
  );
  const yearData = getAllValues(
    filteredCompanies,
    (company) => company.enrichment_parameters.year_founded
  );
  const headcountData = getAllValues(
    filteredCompanies,
    (company) => company.enrichment_parameters.size
  );
  const fundingStageData = getAllValues(
    filteredCompanies,
    (company) => company.enrichment_parameters.last_equity_round_type
  );
  const revenuesBarData = barDataFromRevenuesData(revenuesData);
  const yearsBarData = barDataFromYearsFoundedData(yearData);
  const headcountBarData = barDataFromHeadcountData(headcountData);
  const fundingStagePieData = pieDataFromFundingStageData(fundingStageData);
  const fundingTotalData = getAllValues(
    filteredCompanies,
    (company) => company.enrichment_parameters.total_funding
  );
  const revenueDataNoNull = revenuesData.map(revenueFromData).filter(Boolean);
  const totalRevenue = formatNumber((revenueDataNoNull || []).reduce((acc, item) => acc + item, 0));
  const totalFunding = formatNumber((fundingTotalData || []).reduce((acc, item) => acc + item, 0));
  return (
    <DomainReportBox
      title="Market Landscape"
      listId={listId}
      taskType={DOMAIN_REPORT_TASK_TYPES.MARKET_OVERVIEW}>
      <Grid2 container spacing={2} sx={{ marginTop: '8px' }}>
        <GridItem
          title="Market Overview"
          size={12}
          listId={listId}
          taskType={DOMAIN_REPORT_TASK_TYPES.MARKET_OVERVIEW}>
          {data?.answer ? (
            <Typography id="domain-report-market-overview" variant="text1_Normal">
              <CustomMarkdown
                body={formatAnswerBody(data.answer)}
                color="colors.primary_text"
                listId={listId}
              />
            </Typography>
          ) : (
            <Skeleton variant="rounded" width="100%" height="150px" />
          )}
        </GridItem>
        <GridItem title="Headcount" size={6}>
          <BarChartComponent
            labels={Object.keys(headcountBarData)}
            values={Object.values(headcountBarData)}
            color={appTheme.vars.palette.graphColors.graph10}
            xAxisName="Company count"
            barWidth={12}
            vertical
          />
        </GridItem>
        <GridItem title="Annual revenue $M" size={6}>
          <BarChartComponent
            labels={Object.keys(revenuesBarData)}
            values={Object.values(revenuesBarData).map((item) => item.value)}
            color={appTheme.vars.palette.graphColors.graph2}
            xAxisName="Company count"
            barWidth={12}
            vertical
          />
        </GridItem>
        <GridItem title="Foundation Year" size={8}>
          <BarChartComponent
            labels={Object.keys(yearsBarData)}
            values={Object.values(yearsBarData)}
            color={appTheme.vars.palette.graphColors.graph11}
            yAxisName="Company count"
            noXLabel
            height={200}
          />
        </GridItem>
        <Grid2 size={4}>
          <Grid2 container spacing={2} sx={{ height: '100%' }}>
            <GridItem title="Total revenues" size={12}>
              <Typography sx={{ marginY: 'auto' }} variant="mediumNumber">
                ${totalRevenue}
              </Typography>
            </GridItem>
            <GridItem title="Total funding" size={12}>
              <Typography sx={{ marginY: 'auto' }} variant="mediumNumber">
                ${totalFunding}
              </Typography>
            </GridItem>
          </Grid2>
        </Grid2>
        <GridItem title="Funding Stage" size={12}>
          <PieChartComponent
            shouldCollapseLegend
            orderByCount={false}
            labels={Object.keys(fundingStagePieData)}
            values={Object.values(fundingStagePieData)}
            showAsDonut={true}
            showLegend={true}
          />
        </GridItem>
        <GridItem title="Company locations" size={12}>
          <CountryMap countryData={countryData} />
        </GridItem>
      </Grid2>
    </DomainReportBox>
  );
}
GridItem.propTypes = {
  title: PropTypes.string,
  size: PropTypes.number,
  sx: PropTypes.object,
  percent: PropTypes.number,
  taskType: PropTypes.string,
  listId: PropTypes.number,
  children: PropTypes.node
};

DomainReportMarket.propTypes = {
  listId: PropTypes.number
};

export default DomainReportMarket;
