import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { addEmptyWorkflowAction } from 'actions/workflows/add-empty-workflow-action';
import { ICON_VARIANTS } from 'constants/icons';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import AddIcon from 'icons/AddIcon';
import { ListIcon } from 'icons/ListIcon';
import PointerIcon from 'icons/PointerIcon';
import PromptTextIcon from 'icons/PromptTextIcon';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import {
  getCustomAnalyticsThreadPostsById,
  getIsWorkflowPlanning
} from 'selectors/custom-analytics';
import { getAllSavedQueries } from 'selectors/saved-queries';
import { getUser, getUserIsDev } from 'selectors/user';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import WorkflowsCenterDialogRow from './WorkflowsCenterDialogRow';

function AllWorkflowsDialogStep({ listId }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const isDev = useOldStateSelector(getUserIsDev);
  const allSavedQueries = useSelector(getAllSavedQueries);
  const threadKey = getThreadKey(listId);
  const caThreadPosts = useSelector((state) => getCustomAnalyticsThreadPostsById(state, threadKey));
  const currentUser = useOldStateSelector(getUser);

  const workflowSaveQueries = useMemo(() => {
    const queries = [];
    const savedQueryQuestions = allSavedQueries
      ? new Set(
          Object.values(allSavedQueries)
            .filter((query) => query.type === SAVED_QUERY_ACTION_TYPES.WORKFLOW)
            .map((query) => query.parameters.question)
        )
      : new Set();

    if (caThreadPosts && Object.keys(caThreadPosts).length > 0) {
      Object.values(caThreadPosts)
        .filter(
          (post) => getIsWorkflowPlanning(post) && !savedQueryQuestions.has(post.plan?.question)
        )
        .forEach((post) => {
          queries.push({
            id: post.postId,
            name: post.plan.question,
            owner: currentUser,
            postId: post.postId
          });
        });
    }

    if (allSavedQueries && Object.keys(allSavedQueries).length > 0) {
      Object.values(allSavedQueries)
        .filter((query) => query.type === SAVED_QUERY_ACTION_TYPES.WORKFLOW)
        .sort((q1, q2) => new Date(q2.time_created) - new Date(q1.time_created))
        .forEach((query) => {
          queries.push({
            ...query,
            Icon: query.isShared ? ShareIcon : ListIcon,
            shouldAddToState: true,
            savedQueryId: query.id
          });
        });
    }
    return queries;
  }, [allSavedQueries, caThreadPosts, currentUser]);

  const onCreateNewWorkflowClick = (startFromScratch) => {
    dispatch(addEmptyWorkflowAction(listId, startFromScratch));
  };

  const handleCreateWorkflowClick = (event) => {
    event.stopPropagation();
    if (isDev) {
      setAnchorEl(event.currentTarget);
    } else {
      onCreateNewWorkflowClick(false);
    }
  };

  const handleCloseCreateWorkflow = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <Stack direction="column" gap="16px" sx={{ padding: '16px' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="8px">
        <Typography variant="paragraphMedium" color="primary.primary150">
          {`${workflowSaveQueries.length} saved workflows`}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon variant={ICON_VARIANTS.SECONDARY} />}
          onClick={handleCreateWorkflowClick}>
          Create workflow
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseCreateWorkflow}>
          <MenuItem onClick={() => onCreateNewWorkflowClick(false)}>
            <ListItemIcon>
              <PromptTextIcon />
            </ListItemIcon>
            <Stack>
              <Typography variant="text1_Bold" color="colors.primary_text">
                Start with a prompt
              </Typography>
              <Typography variant="text1_Normal" color="colors.secondary_text">
                Generate a workflow using a main prompt
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={() => onCreateNewWorkflowClick(true)}>
            <ListItemIcon>
              <PointerIcon />
            </ListItemIcon>
            <Stack>
              <Typography variant="text1_Bold" color="colors.primary_text">
                Start manually
              </Typography>
              <Typography variant="text1_Normal" color="colors.secondary_text">
                Start from scratch and add steps as needed
              </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Created on </TableCell>
            <TableCell align="right">Created by</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workflowSaveQueries.map((query) => (
            <WorkflowsCenterDialogRow key={query.id} query={query} listId={listId} />
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}

AllWorkflowsDialogStep.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AllWorkflowsDialogStep;
