/* eslint-disable require-unicode-regexp */
import { Button, OutlinedInput, Stack, Typography } from '@mui/material';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';

function SetQueryNameDialog({
  title,
  open,
  columnName,
  setColumnName,
  onSaveQuery,
  onClose,
  isDisabled,
  actionName
}) {
  const handleColumnNameChange = (event) => {
    setColumnName(event.target.value);
  };

  const onKeyDown = (event) => {
    if (event?.key === 'Enter') {
      onSaveQuery();
      event.preventDefault();
      return;
    }
    event.stopPropagation();
  };

  return (
    <ModalComponent
      title={title}
      open={open}
      onClose={onClose}
      maxWidth={500}
      bottomBar={
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          justifyContent="space-between"
          padding="0 24px"
          sx={{
            width: '100%'
          }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={onSaveQuery} disabled={columnName.length <= 0}>
            Save
          </Button>
        </Stack>
      }>
      <Stack padding="24px" gap="16px">
        <Typography variant="text1_Medium" color="colors.primary_text">
          {actionName} name
        </Typography>
        <OutlinedInput
          size="small"
          disabled={isDisabled}
          value={columnName}
          onChange={handleColumnNameChange}
          placeholder={`Write the new  ${actionName} name here`}
          autoFocus
          sx={{
            width: '100%',
            height: '32px'
          }}
          onKeyDown={onKeyDown}
        />
      </Stack>
    </ModalComponent>
  );
}

SetQueryNameDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  columnName: PropTypes.string,
  setColumnName: PropTypes.func,
  onSaveQuery: PropTypes.func,
  onClose: PropTypes.func,
  isDisabled: PropTypes.bool,
  actionName: PropTypes.string
};

export default SetQueryNameDialog;
