import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function ForbiddenResourceScreen({ title, message, buttonUrl, buttonText }) {
  const navigate = useNavigate();
  const goToUrl = () => navigate(buttonUrl);
  return (
    <Stack
      sx={{
        height: 'calc(100vh - 50px)',
        width: '100%'
      }}
      alignItems="center"
      justifyContent="center"
      gap="32px">
      <Stack gap="16px">
        <Typography variant="h1_Bold">{title}</Typography>
        <Typography variant="text1_Semibold">{message}</Typography>
      </Stack>
      <Button variant="contained" onClick={goToUrl}>
        {buttonText}
      </Button>
    </Stack>
  );
}

ForbiddenResourceScreen.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string
};

ForbiddenResourceScreen.defaultProps = {
  title: 'Access Denied',
  message: 'Oops... sometheing went wrong!',
  buttonUrl: '/',
  buttonText: 'Go to Home Page'
};

export default ForbiddenResourceScreen;
