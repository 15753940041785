import { WORKFLOW_STATUSES } from 'constants/workflows';
import { createSelector } from 'reselect';

export const getWorkflowsSlice = (state) => state.workflows;
export const getWorkflows = (state) => getWorkflowsSlice(state).workflows;
export const getWorkflowsByListId = (state, listId) => getWorkflows(state)[listId];
export const getWorkflowSelectedPostIdByListId = (state, listId) =>
  getWorkflowsByListId(state, listId)?.selectedWorkflowId;
export const getWorkflowByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowsByListId(state, listId)?.[workflowId];
export const getWorkflowStatusByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowByListIdAndWorkflowId(state, listId, workflowId)?.status;
export const getWorkflowInfoByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowByListIdAndWorkflowId(state, listId, workflowId)?.info;
export const getWorkflowEditModeByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowByListIdAndWorkflowId(state, listId, workflowId)?.isEditMode;
export const getWorkflowStepsStatusesByListIdAndWorkflowId = (state, listId, workflowId) =>
  getWorkflowByListIdAndWorkflowId(state, listId, workflowId)?.stepsStatuses;

export const getCurrentlyRunningWorkflowsByListId = createSelector(
  getWorkflowsByListId,
  (workflows) => {
    return workflows
      ? Object.keys(workflows).reduce((acc, workflowId) => {
          if (
            [WORKFLOW_STATUSES.LAUNCHING, WORKFLOW_STATUSES.LAUNCHED].includes(
              workflows[workflowId]?.status
            )
          ) {
            acc[workflowId] = workflows[workflowId];
          }
          return acc;
        }, {})
      : {};
  }
);

export const getWorkflowIdByRecordId = (state, listId, customAnalyticsRecordId) => {
  const workflows = getWorkflowsByListId(state, listId);
  return workflows
    ? Object.keys(workflows).find(
        (workflowId) => workflows[workflowId]?.customAnalyticsRecordId === customAnalyticsRecordId
      )
    : null;
};
