import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import AssistantWandIcon from './AssistantWandIcon';

export default function AssistantWandAvatarIcon({ size }) {
  const appTheme = useTheme();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={appTheme.vars.palette.colors.primary} />
      <AssistantWandIcon size={size} color={'white'} />
    </svg>
  );
}

AssistantWandAvatarIcon.propTypes = {
  size: PropTypes.number
};

AssistantWandAvatarIcon.defaultProps = {
  size: 24
};
