import { Stack } from '@mui/material';
import { submitAssistantMessageAction } from 'actions/assistant/submit-assistant-message-action';
import AssistantPromptConfig from 'models/assistant/AssistantPromptConfig';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { optionsFromCompaniesColumnsSupportingResources } from 'utils/assistant/text';

import AssistantActionsBar from './AssistantActionsBar';
import AssistantTextField from './text/AssistantTextField';

const defaultPromptConfig = new AssistantPromptConfig();

function AssistantPrompt({ columns, companies, supportingResources }) {
  const [promptConfig, setPromptConfig] = React.useState(defaultPromptConfig);
  const dispatch = useDispatch();

  const updateQuestion = React.useCallback((question, mentions) => {
    setPromptConfig((prev) => {
      const newPromptConfig = new AssistantPromptConfig(prev);
      newPromptConfig.updateQuestion(question, mentions);
      return newPromptConfig;
    });
  }, []);

  const resetPromptConfig = () => {
    setPromptConfig(defaultPromptConfig);
  };

  const handleSubmit = React.useCallback(() => {
    dispatch(submitAssistantMessageAction(promptConfig.toMessage()));
    resetPromptConfig();
  }, [dispatch, promptConfig]);

  const options = React.useMemo(
    () =>
      optionsFromCompaniesColumnsSupportingResources({ columns, companies, supportingResources }),
    [columns, companies, supportingResources]
  );

  return (
    <Stack
      justifyContent="space-between"
      gap="12px"
      sx={{
        minHeight: '100px',
        borderRadius: '16px',
        border: '1px solid',
        padding: '16px',
        borderColor: 'colors.ui_border'
      }}>
      <AssistantTextField
        updateQuestion={updateQuestion}
        options={options}
        handleSubmit={handleSubmit}
      />
      <AssistantActionsBar handleSubmit={handleSubmit} />
    </Stack>
  );
}

AssistantPrompt.propTypes = {
  columns: PropTypes.array,
  companies: PropTypes.array,
  supportingResources: PropTypes.array
};

export default AssistantPrompt;
