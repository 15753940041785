import { Stack, Typography } from '@mui/material';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';
import React from 'react';

function PromptErrorComponent({ errorStatus, showError }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="4px"
      alignSelf="flex-end"
      visibility={showError ? 'visible' : 'hidden'}
      height="20px">
      <ToastIcons type="error" size="16" />
      <Stack direction="row" gap="4px">
        {errorStatus.map((part, index) => (
          <Typography
            key={part}
            variant={index % 2 === 0 ? 'text2_Normal' : 'text2_Bold'}
            color="colors.primary_text">
            {part}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
}

PromptErrorComponent.propTypes = {
  errorStatus: PropTypes.array.isRequired,
  showError: PropTypes.bool.isRequired
};

export default PromptErrorComponent;
