import { useMediaQuery } from '@mui/material';
import { ELEMENT_HEIGHTS, MODES } from 'constants/components';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { appTheme } from 'theme';
import { dateStringToTick, getMultiBarColorByIndex } from 'utils/charts-utils';
import { limitLength } from 'utils/string-utils';

import RechartsBox from '../RechartsBox';
import StackedBarTooltipContent from './StackedBarTooltipContent';
import {
  BenchmarkAxisTick,
  CustomizedAxisTick,
  renderBarChartLabel,
  renderBarChartLabelBenchmark,
  renderBarChartLegend
} from './bar-chart-utils';

const date = null;

/* eslint-disable id-length */
export default function MultiBarChartComponent({
  title,
  labels,
  benchmarkLabels,
  values,
  xAxisName,
  vertical,
  optionalFilters,
  setCardFilters,
  mode
}) {
  const mediumViewport = useMediaQuery(appTheme.breakpoints.up('md'));
  const dataKeys = Object.keys(values);
  const chars_per_label = Math.floor(50 / labels.length);

  const filterMap = useMemo(
    () =>
      optionalFilters?.reduce((map, filter) => {
        map[filter.mapping.stackbar] = filter;
        return map;
      }, {}) || {},
    [optionalFilters]
  );

  const data = useMemo(() => {
    return labels.map((xVal, index) => {
      return {
        label: xVal,
        benchmarkLabel: benchmarkLabels[index],
        ...Object.keys(values).reduce((acc, key) => {
          return { ...acc, [key]: values[key][index] };
        }, {})
      };
    });
  }, [labels, values, benchmarkLabels]);

  const maxCount = useMemo(
    () =>
      data.reduce((totalCount, category) => {
        const categoryCount = Object.values(category)
          .filter((item) => item !== category.label)
          .reduce((count, categorySectionCount) => count + categorySectionCount, 0);
        return Math.max(totalCount, categoryCount);
      }, 0),
    [data]
  );

  const determineLegendPadding = () => {
    if (mode === 'benchmark') {
      return 28;
    }
    return chars_per_label < 10 ? 40 : 0;
  };

  const determineTick = () => {
    if (mode === 'benchmark') {
      return <BenchmarkAxisTick benchmarkLabels={benchmarkLabels} />;
    }
    return chars_per_label < 10 ? <CustomizedAxisTick /> : { textOverflow: 'elipsis' };
  };

  return (
    <RechartsBox
      title={title}
      mode={mode}
      height={mode === 'powerpoint' ? '100%' : ELEMENT_HEIGHTS.NORMAL}>
      <BarChart
        style={{
          fontSize: appTheme.typography.text1_Normal.fontSize,
          color: appTheme.vars.palette.colors.ui_border,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.text1_Normal.fontWeight
        }}
        data={data}
        layout={vertical ? 'vertical' : 'horizontal'}
        margin={{
          top: mode === 'benchmark' || !mediumViewport ? 0 : 20,
          right: mode === 'benchmark' || !mediumViewport ? 0 : 40,
          bottom: mode === 'benchmark' || !mediumViewport ? 0 : 40,
          left: mode === 'benchmark' || !mediumViewport ? 0 : 40
        }}>
        {vertical ? (
          <React.Fragment>
            <XAxis
              allowDecimals={false}
              tickCount={Math.min(5, maxCount + 1)}
              axisLine={false}
              type="number"
              tick={
                mode === 'benchmark' ? false : { fill: appTheme.vars.palette.colors.secondary_text }
              }
              tickLine={{ stroke: 'transparent' }}
            />
            <YAxis hide={true} type="category" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <XAxis
              dataKey="label"
              name={xAxisName}
              label={{
                value: xAxisName,
                position: 'bottom',
                fill: appTheme.vars.palette.colors.secondary_text
              }}
              tickFormatter={
                date
                  ? (tick) => dateStringToTick(tick, labels)
                  : (tick) => limitLength(tick, chars_per_label)
              }
              stroke={appTheme.vars.palette.colors.secondary_text}
              tick={determineTick(benchmarkLabels)}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              stroke={appTheme.vars.palette.colors.secondary_text}
              axisLine={false}
              tickLine={false}
            />
          </React.Fragment>
        )}
        <Tooltip cursor={false} content={<StackedBarTooltipContent />} />
        <Legend
          wrapperStyle={{ paddingBottom: 10, paddingTop: determineLegendPadding() }}
          verticalAlign={xAxisName ? 'top' : 'bottom'}
          align={xAxisName ? 'center' : 'right'}
          iconType="circle"
          content={renderBarChartLegend}
        />
        <CartesianGrid
          vertical={false}
          horizontal={!vertical}
          stroke={appTheme.vars.palette.colors.hover_on_gray_bg}
        />
        {dataKeys.map((dataKey, index) => (
          <Bar
            isAnimationActive={!vertical}
            barSize={18}
            key={dataKey}
            dataKey={dataKey}
            label={dataKey}
            onClick={({ label }) => {
              if (optionalFilters) {
                setCardFilters([
                  { key: filterMap.dataKey, value: dataKey },
                  { key: filterMap.label, value: label }
                ]);
              }
            }}
            fill={getMultiBarColorByIndex(index)}
            radius={2}>
            {index === 0 && vertical && (
              <LabelList
                dataKey={mode === 'benchmark' ? 'benchmarkLabel' : 'label'}
                content={mode === 'benchmark' ? renderBarChartLabelBenchmark : renderBarChartLabel}
              />
            )}
          </Bar>
        ))}
      </BarChart>
    </RechartsBox>
  );
}

MultiBarChartComponent.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.array,
  benchmarkLabels: PropTypes.array,
  values: PropTypes.object,
  xAxisName: PropTypes.string,
  vertical: PropTypes.bool,
  optionalFilters: PropTypes.array,
  setCardFilters: PropTypes.func,
  mode: PropTypes.oneOf(MODES).isRequired
};
