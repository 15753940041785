import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function AssistantActionsBar({ handleSubmit }) {
  return (
    <Stack
      direction="row"
      gap="8px"
      alignItems="center"
      justifyContent="space-between"
      width="100%">
      <Stack direction="row" gap="8px" alignItems="center">
        <Button variant="outlined">Action</Button>
      </Stack>
      <Stack direction="row" gap="8px" alignItems="center">
        <Button variant="outlined">Library</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Send
        </Button>
      </Stack>
    </Stack>
  );
}

AssistantActionsBar.propTypes = {
  handleSubmit: PropTypes.func
};

export default AssistantActionsBar;
