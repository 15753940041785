import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BaseTooltipComponent from '../BaseTooltipComponent';

/* eslint-disable id-length */
function ScatterTooltipContent({ payload }) {
  const prettifyNumber = (num) => {
    if (num > 1) {
      return Math.round(num).toString();
    }
    return num.toPrecision(2);
  };

  if (payload.length < 1) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <BaseTooltipComponent label={payload[0]?.payload?.label}>
      <Typography variant="text1_Normal">
        {payload[0].name}: {prettifyNumber(payload[0].payload.x)}
      </Typography>
      <br />
      <Typography variant="text1_Normal">
        {payload[1].name}: {prettifyNumber(payload[1].payload.y)}
      </Typography>
    </BaseTooltipComponent>
  );
}

ScatterTooltipContent.propTypes = {
  payload: PropTypes.array
};

export default ScatterTooltipContent;
