import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { unixTimeToStringDay } from 'utils/charts-utils';

import BaseTooltipComponent from '../BaseTooltipComponent';

/* eslint-disable id-length */
function TimelineTooltipContent({ payload }) {
  if (payload.length < 1) {
    return <React.Fragment></React.Fragment>;
  }

  if (!payload[0].payload.label || !payload[0].payload.sublabel) {
    return (
      <BaseTooltipComponent label={unixTimeToStringDay(payload[0].payload.date)} maxWidth={400} />
    );
  }

  return (
    <BaseTooltipComponent
      label={`${payload[0].payload.label}, ${unixTimeToStringDay(payload[0].payload.date)}`}
      maxWidth={400}>
      <Typography variant="text1_Semibold">
        <em>{`"${payload[0].payload.sublabel}"`}</em>
      </Typography>
    </BaseTooltipComponent>
  );
}

TimelineTooltipContent.propTypes = {
  payload: PropTypes.array
};

export default TimelineTooltipContent;
