import { Checkbox, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function SelectMultipleMenuItemInner({ label, value, checkbox, selectedValues }) {
  return (
    <Stack direction="row" gap="4px" alignItems="center">
      {checkbox && selectedValues && (
        <Checkbox checked={(selectedValues || []).indexOf(value) > -1} />
      )}
      <Typography variant="text1_Medium" color="colors.primary_text">
        {label}
      </Typography>
    </Stack>
  );
}

SelectMultipleMenuItemInner.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedValues: PropTypes.array,
  checkbox: PropTypes.bool
};

export default SelectMultipleMenuItemInner;
