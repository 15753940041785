import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { loadAllExploreLists } from 'actions/explore/load-all-explores-action';
import { loadAllFoldersAction } from 'actions/folders/load-all-folders-action';
import { loadAllPersons } from 'actions/persons/load-all-persons-action';
import { loadAllRuns } from 'actions/runs/load-all-runs-action';
import LoaderComponent from 'components/LoaderComponent';
import PersonCardDialog from 'components/card/people/PersonCardDialog';
import DomainKnowledgeTab from 'components/domain-knowledge/DomainKnowledgeTab';
import HomePageHeaderComponent from 'components/home-page/HomePageHeaderComponent';
import HomePageFolderTile from 'components/tile/home-page/HomePageFolderTile';
import { DISCOVERY_FILTERS } from 'constants/discovery';
import { HOME_PAGE_FOLDER_ID, SHARED_WITH_ME_FOLDER_ID } from 'constants/folders';
import { RESEARCH_TYPES } from 'constants/researches';
import { TOAST_TYPES } from 'constants/toasts';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { updateCurrentFolderId } from 'reducer/folders-slice';
import { updateCurrentOpenResourceName } from 'reducer/resources-slice';
import { getAllExploreLists } from 'selectors/explore';
import {
  getAllFolderCompanyListIds,
  getAllFolderRecordIds,
  getAllFolders,
  getFolderCompanyListIdsById,
  getFolderHasMoreResourcesByFolderId,
  getFolderRecordIdsById
} from 'selectors/folders';
import { getPersons } from 'selectors/persons';
import {
  getIsDeepDivesLoaded,
  getIsExploresLoaded,
  getIsFoldersLoaded,
  getIsLeadershipLoaded
} from 'selectors/resources';
import { getRuns } from 'selectors/runs';
import { getUserId, getUserIsBasic } from 'selectors/user';

const TAB_VALUES = Object.freeze({
  RESEARCHES: 0,
  SUPPORTING_RESOURCES: 1
});

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
function HomePageScreen() {
  const { folderId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { addToast, cancelToast } = useAppUtils();
  const [tabValue, setTabValue] = useState(TAB_VALUES.RESEARCHES);
  const [windowWidth] = useWindowSize();
  const userId = useOldStateSelector(getUserId);
  const runs = useOldStateSelector(getRuns);
  const allExploreLists = useSelector((state) => getAllExploreLists(state));
  const persons = useOldStateSelector(getPersons);
  const isBasicUser = useOldStateSelector(getUserIsBasic);
  const folders = useSelector(getAllFolders);
  const isExploresLoaded = useSelector(getIsExploresLoaded);
  const isDeepDiveLoaded = useOldStateSelector(getIsDeepDivesLoaded);
  const isLeadershipLoaded = useOldStateSelector(getIsLeadershipLoaded);
  const isFoldersLoaded = useSelector(getIsFoldersLoaded);
  const [nameFilter, setNameFilter] = useState('');
  const [researchTypes, setResearchTypes] = useState(RESEARCH_TYPES.ALL.id);
  const [leadershipId, setLeadershipId] = useState(null);
  const [tilesContainerWidth, setTilesContainerWidth] = useState(1400);
  const folderRecordIds = useSelector((state) => getFolderRecordIdsById(state, folderId));
  const folderCompanyListIds = useSelector((state) => getFolderCompanyListIdsById(state, folderId));
  const allFolderRecordIds = useSelector(getAllFolderRecordIds);
  const allFolderCompanyListIds = useSelector(getAllFolderCompanyListIds);
  const showLoadMore = useSelector((state) =>
    getFolderHasMoreResourcesByFolderId(state, folderId || HOME_PAGE_FOLDER_ID)
  );
  const filterValue = DISCOVERY_FILTERS.ALL.value;
  const actualFolderId = useMemo(() => {
    if (!folderId) return null;

    return folderId === SHARED_WITH_ME_FOLDER_ID ? folderId : parseInt(folderId, 10);
  }, [folderId]);

  const personId = searchParams.get('personId');

  useEffect(() => {
    if (personId) {
      setLeadershipId(personId);
    } else {
      setLeadershipId(null);
    }
  }, [personId]);

  useEffect(() => {
    if (!windowWidth) return;

    if (windowWidth < 692) {
      setTilesContainerWidth(330);
    } else if (windowWidth < 1038) {
      setTilesContainerWidth(676);
    } else if (windowWidth < 1384) {
      setTilesContainerWidth(1022);
    } else {
      setTilesContainerWidth(1368);
    }
  }, [windowWidth, setTilesContainerWidth]);

  useEffect(() => {
    dispatch(updateCurrentFolderId({ folderId: actualFolderId }));
    setTabValue(TAB_VALUES.RESEARCHES);
  }, [actualFolderId, dispatch]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredRuns = useMemo(() => {
    if (!runs) return [];

    return Object.values(runs)
      .filter((run) => {
        if (!run) return false;
        if (actualFolderId === SHARED_WITH_ME_FOLDER_ID) {
          return !allFolderRecordIds.includes(run.recordId) && run?.user?.id !== userId;
        }
        if (actualFolderId) {
          return folderRecordIds.includes(run.recordId);
        }
        return !allFolderRecordIds.includes(run.recordId) && run?.user?.id === userId;
      })
      .map((run) => ({
        type: RESEARCH_TYPES.DEEP_DIVE,
        id: run.id,
        name: run.name,
        timeSubmitted: run.timeSubmitted
      }));
  }, [runs, actualFolderId, folderRecordIds, allFolderRecordIds, userId]);

  const filteredExploreLists = useMemo(() => {
    const items = Object.values(
      Object.values(allExploreLists)
        .filter((list) => {
          if (!list) return false;
          if (actualFolderId === SHARED_WITH_ME_FOLDER_ID) {
            return !allFolderCompanyListIds.includes(list.id) && list?.user?.id !== userId;
          }
          if (actualFolderId) {
            return folderCompanyListIds.includes(list.id);
          }
          return !allFolderCompanyListIds.includes(list.id) && list.user.id === userId;
        })
        .filter((list) => {
          if (filterValue === DISCOVERY_FILTERS.ALL.value) {
            return true;
          } else if (filterValue === DISCOVERY_FILTERS.PRIVATE.value) {
            return !list?.isShared;
          } else if (filterValue === DISCOVERY_FILTERS.SHARED.value) {
            return list?.isShared;
          }
          return true;
        })
        .sort((val_1, val_2) => new Date(val_1.timeSubmitted) - new Date(val_2.timeSubmitted))
        .reduce((prev, explore) => {
          return { ...prev, [explore.id]: explore };
        }, {})
    );
    return items
      .sort((val_1, val_2) => {
        return new Date(val_2.timeSubmitted) - new Date(val_1.timeSubmitted);
      })
      .map((list) => ({
        type: RESEARCH_TYPES.EXPLORE,
        id: list.id,
        name: list.name,
        timeSubmitted: list.timeSubmitted
      }));
  }, [
    allExploreLists,
    filterValue,
    actualFolderId,
    userId,
    folderCompanyListIds,
    allFolderCompanyListIds
  ]);

  const filteredPersons = useMemo(() => {
    if (!persons) return [];
    return Object.values(persons)
      .filter((run) => {
        if (!run) return false;
        if (actualFolderId === SHARED_WITH_ME_FOLDER_ID) {
          return !allFolderRecordIds.includes(run.recordId) && run?.user?.id !== userId;
        }
        if (actualFolderId) {
          return folderRecordIds.includes(run.recordId);
        }
        return !allFolderRecordIds.includes(run.recordId) && run?.user?.id === userId;
      })
      .map((run) => ({
        type: RESEARCH_TYPES.LEADERSHIP,
        id: run.id,
        name: run.name,
        timeSubmitted: run.timeSubmitted
      }));
  }, [persons, userId, actualFolderId, allFolderRecordIds, folderRecordIds]);

  const allResearches = useMemo(() => {
    const items = [];
    if (researchTypes === RESEARCH_TYPES.ALL.id || researchTypes === RESEARCH_TYPES.DEEP_DIVE.id) {
      items.push(...filteredRuns);
    }

    if (researchTypes === RESEARCH_TYPES.ALL.id || researchTypes === RESEARCH_TYPES.EXPLORE.id) {
      items.push(...filteredExploreLists);
    }

    if (researchTypes === RESEARCH_TYPES.ALL.id || researchTypes === RESEARCH_TYPES.LEADERSHIP.id) {
      items.push(...filteredPersons);
    }
    return items
      .filter(
        (research) =>
          research.name && research.name.toLowerCase().includes(nameFilter.toLowerCase())
      )
      .sort((val_1, val_2) => new Date(val_2.timeSubmitted) - new Date(val_1.timeSubmitted));
  }, [filteredRuns, filteredExploreLists, filteredPersons, nameFilter, researchTypes]);

  const folderIdToNumOfResearches = useMemo(() => {
    const foldersMap = {};
    const runRecordIds = Object.values(runs).map((run) => run.recordId);
    const personsRecordIds = Object.values(persons).map((person) => person.recordId);
    Object.values(folders).forEach((folder) => {
      foldersMap[folder.id] = { total: 0, icons: new Set() };
      folder?.folder_resources?.forEach((resource) => {
        if (resource.record_id) {
          if (
            runRecordIds.includes(resource.record_id) &&
            (researchTypes === RESEARCH_TYPES.ALL.id ||
              researchTypes === RESEARCH_TYPES.DEEP_DIVE.id)
          ) {
            foldersMap[folder.id].icons.add(RESEARCH_TYPES.DEEP_DIVE.Icon);
            foldersMap[folder.id].total++;
          } else if (
            personsRecordIds.includes(resource.record_id) &&
            (researchTypes === RESEARCH_TYPES.ALL.id ||
              researchTypes === RESEARCH_TYPES.LEADERSHIP.id)
          ) {
            foldersMap[folder.id].icons.add(RESEARCH_TYPES.LEADERSHIP.Icon);
            foldersMap[folder.id].total++;
          }
        } else if (
          resource.company_list_id &&
          (researchTypes === RESEARCH_TYPES.ALL.id || researchTypes === RESEARCH_TYPES.EXPLORE.id)
        ) {
          foldersMap[folder.id].icons.add(RESEARCH_TYPES.EXPLORE.Icon);
          foldersMap[folder.id].total++;
        }
      });
    });

    return foldersMap;
  }, [runs, persons, folders, researchTypes]);

  const { total: numOfSharedResearches, iconsList: sharedResearchesIcons } = useMemo(() => {
    if (actualFolderId !== null) return 0;
    const sharedExplored =
      researchTypes === RESEARCH_TYPES.ALL.id || researchTypes === RESEARCH_TYPES.EXPLORE.id
        ? Object.values(allExploreLists).filter(
            (explore) => !allFolderCompanyListIds.includes(explore.id) && explore.user.id !== userId
          )
        : [];
    const sharedRuns =
      researchTypes === RESEARCH_TYPES.ALL.id || researchTypes === RESEARCH_TYPES.DEEP_DIVE.id
        ? Object.values(runs).filter(
            (run) => !allFolderRecordIds.includes(run.recordId) && run?.user?.id !== userId
          )
        : [];
    const sharedLeaderships =
      researchTypes === RESEARCH_TYPES.ALL.id || researchTypes === RESEARCH_TYPES.LEADERSHIP.id
        ? Object.values(persons).filter(
            (person) => !allFolderRecordIds.includes(person.recordId) && person?.user?.id !== userId
          )
        : [];
    const iconsList = [];
    if (sharedExplored.length > 0) {
      iconsList.push(RESEARCH_TYPES.EXPLORE.Icon);
    }
    if (sharedRuns.length > 0) {
      iconsList.push(RESEARCH_TYPES.DEEP_DIVE.Icon);
    }
    if (sharedLeaderships.length > 0) {
      iconsList.push(RESEARCH_TYPES.LEADERSHIP.Icon);
    }
    return {
      total: sharedExplored.length + sharedRuns.length + sharedLeaderships.length,
      iconsList
    };
  }, [
    allExploreLists,
    runs,
    persons,
    userId,
    actualFolderId,
    allFolderCompanyListIds,
    allFolderRecordIds,
    researchTypes
  ]);

  const isAllResourcesLoaded = useMemo(
    () => isExploresLoaded && isDeepDiveLoaded && isLeadershipLoaded && isFoldersLoaded,
    [isExploresLoaded, isDeepDiveLoaded, isLeadershipLoaded, isFoldersLoaded]
  );

  useEffect(() => {
    dispatch(
      loadAllExploreLists({
        num: 50,
        all: false,
        record_only: false,
        folder_id: folderId
      })
    );
    dispatch(
      loadAllExploreLists({
        num: 50,
        all: false,
        record_only: false,
        folder_id: SHARED_WITH_ME_FOLDER_ID
      })
    );
  }, [dispatch, folderId]);

  useEffect(() => {
    oldDispatch(loadAllRuns());
    oldDispatch(loadAllPersons());
    dispatch(loadAllFoldersAction());
    dispatch(
      updateCurrentOpenResourceName({
        resourceName: null,
        resourceType: null
      })
    );
  }, [dispatch]);

  const closeLeadershipDialog = () => {
    setLeadershipId(null);
    searchParams.delete('personId');
    setSearchParams(searchParams);
  };

  const handleShowMoreClick = () => {
    const toastId = addToast('Fetching more researches', TOAST_TYPES.INFO);
    dispatch(
      loadAllExploreLists(
        {
          num: 50,
          all: false,
          record_only: false,
          folder_id: folderId
        },
        true,
        () => {
          cancelToast(toastId);
          addToast('We fetched more researches', TOAST_TYPES.SUCCESS);
        }
      )
    );
  };

  return (
    <Stack
      height="calc(100vh - 50px)"
      padding="24px"
      alignItems="center"
      gap="24px"
      sx={{
        overflowY: 'auto',
        boxSizing: 'border-box'
      }}>
      {isAllResourcesLoaded ? (
        <React.Fragment>
          <HomePageHeaderComponent
            folderId={actualFolderId}
            nameFilter={nameFilter}
            researchTypes={researchTypes}
            isEmpty={allResearches.length <= 0}
            isSubfolder={Boolean(actualFolderId)}
            allowAddResearch={actualFolderId !== SHARED_WITH_ME_FOLDER_ID}
            addResearchDisabledReseaon={isBasicUser ? 'You are in basic plan' : null}
            setNameFilter={setNameFilter}
            setResearchTypes={setResearchTypes}
          />
          <Stack direction="column" alignItems="center" gap="40px" width="100%">
            {((folders && Object.keys(folders).length > 0) || numOfSharedResearches > 0) &&
              actualFolderId === null && (
                <Stack direction="column" alignItems="flex-start" gap="16px">
                  <Typography variant="text1_Medium" color="colors.dark_bg">
                    Folders
                  </Typography>
                  <Stack
                    direction="row"
                    gap="16px"
                    flexWrap="wrap"
                    sx={{ width: `${tilesContainerWidth}px` }}>
                    {Object.keys(folders).length > 0 &&
                      Object.values(folders).map((folder) => {
                        return (
                          <Box key={folder.id} width="330px">
                            <HomePageFolderTile
                              id={folder.id}
                              isShared={folder.is_shared}
                              numberOfResearches={folderIdToNumOfResearches[folder.id]?.total || 0}
                              researchIcons={
                                folderIdToNumOfResearches[folder.id]?.icons
                                  ? Array.from(folderIdToNumOfResearches[folder.id]?.icons)
                                  : []
                              }
                            />
                          </Box>
                        );
                      })}
                    {numOfSharedResearches > 0 && (
                      <Box width="330px">
                        <HomePageFolderTile
                          id={SHARED_WITH_ME_FOLDER_ID}
                          numberOfResearches={numOfSharedResearches}
                          researchIcons={sharedResearchesIcons}
                        />
                      </Box>
                    )}
                  </Stack>
                </Stack>
              )}
            {allResearches && allResearches.length > 0 && (
              <Stack direction="column" alignItems="flex-start" gap="16px">
                {actualFolderId ? (
                  <Tabs value={tabValue} onChange={handleChange} textColor="secondary">
                    <Tab label="Researches" />
                    <Tab label="Knowledge" />
                  </Tabs>
                ) : (
                  <Typography variant="text1_Medium" color="colors.dark_bg">
                    Researches
                  </Typography>
                )}

                {tabValue === TAB_VALUES.RESEARCHES && (
                  <Stack
                    direction="row"
                    gap="16px"
                    flexWrap="wrap"
                    sx={{ width: `${tilesContainerWidth}px` }}>
                    {allResearches.map((research) => (
                      <Box key={`${research.id}-${research.type.id}`} width="330px">
                        <research.type.Tile
                          id={research.id}
                          onClick={() =>
                            setLeadershipId(
                              research.type.id === RESEARCH_TYPES.LEADERSHIP.id ? research.id : null
                            )
                          }
                        />
                      </Box>
                    ))}
                    {showLoadMore && (
                      <Button
                        variant="outlined"
                        onClick={handleShowMoreClick}
                        sx={{ width: '330px', height: '236px' }}>
                        Show More
                      </Button>
                    )}
                  </Stack>
                )}
                {tabValue === TAB_VALUES.SUPPORTING_RESOURCES && (
                  <Stack textAlign="left" sx={{ width: `${tilesContainerWidth}px` }} gap="16px">
                    <DomainKnowledgeTab folderId={actualFolderId} />
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
          {leadershipId && (
            <PersonCardDialog
              personId={leadershipId}
              open={Boolean(leadershipId)}
              closeModal={closeLeadershipDialog}
            />
          )}
        </React.Fragment>
      ) : (
        <LoaderComponent />
      )}
    </Stack>
  );
}

export default HomePageScreen;
