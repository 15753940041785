import { Box, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { SENTIMENT_COLORS } from 'utils/charts-utils';

function SentimentComponent({ sentiment }) {
  const theme = useTheme();
  const color =
    SENTIMENT_COLORS[sentiment.toLowerCase()] || theme.vars.palette.colors.disabled_text;
  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <Box
        component="span"
        sx={{ width: '8px', height: '8px', backgroundColor: color, borderRadius: '50%' }}
      />
      <Typography variant="text1_Semibold" color={color}>
        {sentiment}
      </Typography>
    </Stack>
  );
}

SentimentComponent.propTypes = {
  sentiment: PropTypes.string
};

export default SentimentComponent;
