import ArtClient from './ArtClient';
import AssistantClient from './AssistantClient';
import AutocompleteClient from './AutocompleteClient';
import CardsClient from './CardsClient';
import CompaniesListsClient from './CompaniesListsClient';
import CompanyExtraDataClient from './CompanyExtraDataClient';
import CompanyListMetasClient from './CompanyListMetasClient';
import CompanyMetasClient from './CompanyMetasClient';
import CustomAnalyticsClient from './CustomAnalyticsClient';
import DiscoveriesClient from './DiscoveriesClient';
import DomainReportsClient from './DomainReportsClient';
import ExploreClient from './ExploreClient';
import FilesUploaderManager from './FilesUploaderManager';
import FolderResourcesClient from './FolderResourcesClient';
import FoldersClient from './FoldersClient';
import LLMQueriesClient from './LLMQueriesClient';
import NotificationsClient from './NotificationsClient';
import OpinionsClient from './OpinionsClient';
import OrganizationsClient from './OrganizationsClient';
import PermissionsClient from './PermissionsClient';
import PersonsClient from './PersonsClient';
import PublicLinksClient from './PublicLinksClient';
import PusherAuthClient from './PusherAuthClient';
import RunsClient from './RunsClient';
import SavedQueriesClient from './SavedQueriesClient.js';
import SchedulesClient from './SchedulesClient';
import SearchesClient from './SearchesClient';
import SubdomainsClient from './SubdomainsClient';
import SupportingResourcesClient from './SupportingResourcesClient';
import TaggingClient from './TaggingClient';
import TeamsClient from './TeamsClient';
import UnauthorizedClient from './UnauthorizedClient';
import UsersClient from './UsersClient';

const apiClient = {};

export function initAPIClient(getAccessTokenSilently, setErrorConfig) {
  apiClient.runs = new RunsClient(setErrorConfig);
  apiClient.users = new UsersClient(setErrorConfig);
  apiClient.cards = new CardsClient(setErrorConfig);
  apiClient.autocomplete = new AutocompleteClient(setErrorConfig);
  apiClient.opinions = new OpinionsClient(setErrorConfig);
  apiClient.customAnalytics = new CustomAnalyticsClient(setErrorConfig);
  apiClient.organizations = new OrganizationsClient(setErrorConfig);
  apiClient.persons = new PersonsClient(setErrorConfig);
  apiClient.companiesLists = new CompaniesListsClient(setErrorConfig);
  apiClient.discoveries = new DiscoveriesClient(setErrorConfig);
  apiClient.companyListMetas = new CompanyListMetasClient(setErrorConfig);
  apiClient.searches = new SearchesClient(setErrorConfig);
  apiClient.companyMetas = new CompanyMetasClient(setErrorConfig);
  apiClient.tagging = new TaggingClient(setErrorConfig);
  apiClient.publicLinks = new PublicLinksClient(setErrorConfig);
  apiClient.pusherAuth = new PusherAuthClient(setErrorConfig);
  apiClient.explore = new ExploreClient(setErrorConfig);
  apiClient.teams = new TeamsClient(setErrorConfig);
  apiClient.companyExtraData = new CompanyExtraDataClient(setErrorConfig);
  apiClient.folders = new FoldersClient(setErrorConfig);
  apiClient.filesUploaderManager = new FilesUploaderManager();
  apiClient.art = new ArtClient(setErrorConfig);
  apiClient.permissions = new PermissionsClient(setErrorConfig);
  apiClient.folderResources = new FolderResourcesClient(setErrorConfig);
  apiClient.getAccessTokenSilently = getAccessTokenSilently;
  apiClient.schedules = new SchedulesClient(setErrorConfig);
  apiClient.domainReports = new DomainReportsClient(setErrorConfig);
  apiClient.notifications = new NotificationsClient(setErrorConfig);
  apiClient.llm_queries = new LLMQueriesClient(setErrorConfig);
  apiClient.supportingResources = new SupportingResourcesClient(setErrorConfig);
  apiClient.saved_queries = new SavedQueriesClient(setErrorConfig);
  apiClient.subdomains = new SubdomainsClient(setErrorConfig);
  apiClient.assistant = new AssistantClient(setErrorConfig);
}

export function initUnauthorizedAPIClient() {
  apiClient.unauthorizedRoutes = new UnauthorizedClient();
}

export function getAPIClient() {
  return apiClient;
}

export async function getAuth0Token() {
  if (apiClient.getAccessTokenSilently) {
    const token = await apiClient.getAccessTokenSilently({
      authorizationParams: {
        // eslint-disable-next-line no-undef
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      }
    });
    return token;
  }
  return null;
}
