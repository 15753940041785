import { Box, Button, Link, Menu, Stack, Typography } from '@mui/material';
import GoToIcon from 'icons/GoToIcon';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { appTheme } from 'theme';
import {
  CONSENSUS_KEY,
  formatNumber,
  formatRevenueFromData,
  getConsensusFromData,
  getIsEstimatedFromData
} from 'utils/finance-utils';

import TableRowLoading from './TableRowLoading';

export default function RenderRevenues({ value, color }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const financeType = value?.financeType;
  const financeTypeTitle =
    financeType &&
    financeType
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = value?.data;
  const consensus = getConsensusFromData(data);
  const isEstimated = getIsEstimatedFromData(data);
  const finalValue = useMemo(() => {
    return formatRevenueFromData(consensus);
  }, [consensus]);

  const filteredData = data?.filter(
    (source) =>
      source && source.source_name !== CONSENSUS_KEY && source.is_estimated === isEstimated
  );
  function parseSource(sourceData) {
    let sourceStr = null;
    if (sourceData) {
      const range_lower = sourceData.value_range_lower;
      const range_upper = sourceData.value_range_upper;
      if (range_lower && range_upper) {
        sourceStr = `$${formatNumber(range_lower)}-$${formatNumber(range_upper)}`;
      } else if (range_lower) {
        sourceStr = `$>${formatNumber(range_lower)}`;
      } else if (range_upper) {
        sourceStr = `$<${formatNumber(range_upper)}`;
      } else if (sourceData.clean_value_amount) {
        sourceStr = `$${formatNumber(sourceData.clean_value_amount)}`;
      }
    }
    return sourceStr;
  }

  const loading = value?.loading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  if (!finalValue) return null;

  return (
    <Stack width="100%" padding={0}>
      <Button
        onClick={handleClick}
        fullWidth
        disableRipple
        sx={{
          '&:hover': { backgroundColor: 'unset' }
        }}>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          onMouseEnter={() => setShowTooltip(!showTooltip)}
          onMouseLeave={() => setShowTooltip(false)}>
          <Typography variant="text1_Normal" color={color} noWrap display="block">
            {finalValue}
          </Typography>
          {consensus && (
            <Box
              sx={{
                marginLeft: 'auto',
                backgroundColor: 'colors.hover_on_gray_bg',
                borderRadius: '2px',
                padding: '6px'
              }}>
              <Typography color="colors.primary_text" variant="h4">
                {isEstimated ? 'E' : 'A'}
              </Typography>
            </Box>
          )}
        </Stack>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        PaperProps={{
          style: { backgroundColor: appTheme.vars.palette.colors.dark_bg }
        }}
        MenuListProps={{
          style: { backgroundColor: appTheme.vars.palette.colors.dark_bg }
        }}>
        <Stack
          sx={{
            backgroundColor: 'colors.dark_bg',
            color: 'white',
            padding: '4px'
          }}>
          <Box
            sx={{
              backgroundColor: '#374B71',
              borderRadius: '2px',
              padding: '4px',
              width: 'fit-content'
            }}>
            <Typography color="colors.text_on_primary" variant="h4">
              {isEstimated ? 'E' : 'A'}
            </Typography>
          </Box>
          <Typography
            color="colors.text_on_primary"
            variant="text1_Semibold"
            paddingBottom="8px"
            paddingTop="4px">
            {isEstimated ? 'Estimated' : 'Actual'} {financeTypeTitle}
          </Typography>
          {filteredData?.map((source, idx) => (
            <Stack
              key={source.source_name}
              borderBottom={idx < filteredData.length - 1 ? '1px solid' : 'none'}
              borderColor="colors.primary_text">
              <Typography
                variant="text1_Medium"
                color="colors.secondary_text_on_primary"
                paddingTop="4px">
                Source:
              </Typography>
              <Stack direction="row" gap="8px" padding="8px" alignItems="center">
                {source.link_to_source && (
                  <Link
                    minHeight={0}
                    href={source.link_to_source}
                    underline="none"
                    target="_blank"
                    rel="noopener"
                    color="colors.text_on_primary"
                    padding="2px">
                    <GoToIcon />
                  </Link>
                )}
                <Typography variant="text1_Medium">
                  {source.source_label
                    .split('_')
                    .map((word) => word[0].toUpperCase() + word.substring(1))
                    .join(' ')}
                </Typography>
                <Typography marginLeft="auto" variant="text1_Medium">
                  {parseSource(source)}
                </Typography>
              </Stack>
              {source?.year && (
                <Typography paddingLeft="8px" variant="text1_Normal" color="#A8B2C2">
                  Year: {source.year}
                </Typography>
              )}
            </Stack>
          ))}
        </Stack>
      </Menu>
    </Stack>
  );
}

RenderRevenues.propTypes = {
  value: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderRevenues.defaultProps = {
  color: 'colors.primary_text',
  variant: 'text1_Normal'
};
