import { CardHeader, CardMedia, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import { TILE_TYPE } from 'constants/tiles';
import MLIconButton from 'design-system/MLIconButton';
import { useAppState } from 'hooks/state-context';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getRunCompanyLogoUrlById, getRunCompanyNameById } from 'selectors/runs';
import {
  getSuggestionCompanyLogoUrlByType,
  getSuggestionCompanyNameByType
} from 'selectors/suggestions';

import DeleteSearchDialog from '../DeleteSearchDialog';

function TitleStack({ type, id, showDelete }) {
  const { state } = useAppState();
  const [open, setOpen] = useState(false);

  const companyName =
    type === 'run' ? getRunCompanyNameById(state, id) : getSuggestionCompanyNameByType(state, type);
  const logoUrl =
    type === 'run'
      ? getRunCompanyLogoUrlById(state, id)
      : getSuggestionCompanyLogoUrlByType(state, type);

  const handleClickOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CardHeader
        sx={{ padding: 0, '.MuiCardHeader-content': { overflow: 'hidden' } }}
        avatar={
          <CardMedia
            sx={{ width: '48px', height: '48px', borderRadius: '4px' }}
            component="img"
            image={logoUrl}
            alt={companyName}
          />
        }
        title={
          <Typography
            gutterBottom
            variant="h3_Bold"
            textAlign="left"
            color="colors.hover_on_primary"
            flex={1}
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginBottom: 0
            }}>
            {companyName}
          </Typography>
        }
        action={
          showDelete && (
            <MLIconButton
              tooltipText="delete"
              variant="secondary"
              onClick={handleClickOpen}
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                width: 24,
                height: 24,
                padding: 0
              }}>
              <DeleteIcon />
            </MLIconButton>
          )
        }
      />
      <DeleteSearchDialog
        open={open}
        recordId={id}
        onClose={handleClose}
        recordType={TILE_TYPE.RUN}
      />
    </React.Fragment>
  );
}

TitleStack.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  showDelete: PropTypes.bool
};

export default wrapWithError(TitleStack);
