import { Message } from 'models/assistant/types';
import { updateThread } from 'reducer/assistant-slice';
import { getLatestThread } from 'selectors/assistant';

import { submitAssistantHelperAction } from './submit-assistant-helper-action';

export function submitAssistantMessageAction(message: Message) {
  return (dispatch: any, getState: any) => {
    // We must either be submitting
    const state = getState();
    const thread = getLatestThread(state);
    const { folder_id, company_list_id, messages } = thread;
    // Update the thread with the new message for state purposes
    const updatedThread = { ...thread, messages: [...messages, message] };
    // Payload is stripped down version, with no action
    const payload = {
      folder_id,
      company_list_id,
      messages: [...messages, message]
    };
    dispatch(updateThread({ thread: updatedThread }));
    dispatch(submitAssistantHelperAction(thread.id, payload));
  };
}
