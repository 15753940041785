import FolderIcon from '@mui/icons-material/Folder';
import {
  Button,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import CreatePersonsSearchDialog from 'components/CreatePersonsSearchDialog';
import CreateResearchDialog from 'components/CreateResearchDialog';
import CollaborateDialog from 'components/collaborate/CollaborateDialog';
import { CreateListDialog } from 'components/discovery/CreateListDialog';
import CreateFoldersDialog from 'components/folders/CreateFoldersDialog';
import SharedStack from 'components/tile/SharedStack';
import { ICON_VARIANTS } from 'constants/icons';
import { RESEARCH_TYPES } from 'constants/researches';
import { RESOURCE_TYPES } from 'constants/resources';
import MLIconButton from 'design-system/MLIconButton';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import AddIcon from 'icons/AddIcon';
import SearchIcon from 'icons/SearchIcon';
import WarningIcon from 'icons/WarningIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFolderCollaborateInfo, getFolderNameById } from 'selectors/folders';
import { getUserCanCollaborate } from 'selectors/user';
import { appTheme } from 'theme';

/* eslint-disable max-lines */
// eslint-disable-next-line max-lines-per-function
function HomePageHeaderComponent({
  folderId,
  nameFilter,
  researchTypes,
  isEmpty,
  isSubfolder,
  allowAddResearch,
  addResearchDisabledReseaon,
  setNameFilter,
  setResearchTypes
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNewFolder, setOpenNewFolder] = useState(false);
  const [collaborateOpen, setCollaborateOpen] = useState(false);
  const [openNewDeepDive, setOpenNewDeepDive] = useState(false);
  const [openNewExplore, setOpenNewExplore] = useState(false);
  const [openNewLeadership, setOpenNewLeadership] = useState(false);
  const [hoverMenuItemId, setHoverMenuItemId] = useState(null);
  const [hoverEmptyItemId, setHoverEmptyItemId] = useState(null);
  const folderName = useSelector((state) => getFolderNameById(state, folderId));
  const { resourceUsers, resourceOrgs, resourceTeams } = useSelector((reduxState) =>
    getFolderCollaborateInfo(reduxState, folderId)
  );
  const isSharedFolder =
    resourceUsers?.length > 0 || resourceOrgs?.length > 0 || resourceTeams?.length > 0;

  const canCollaborate = useOldStateSelector(getUserCanCollaborate);

  const doCollaborate = () => {
    setCollaborateOpen(true);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNewResearch = (researchTypeId) => {
    setAnchorEl(null);
    if (researchTypeId === RESEARCH_TYPES.DEEP_DIVE.id) {
      setOpenNewDeepDive(true);
    } else if (researchTypeId === RESEARCH_TYPES.EXPLORE.id) {
      setOpenNewExplore(true);
    } else if (researchTypeId === RESEARCH_TYPES.LEADERSHIP.id) {
      setOpenNewLeadership(true);
    }
  };
  return (
    <Stack direction="column" gap="24px" width="100%">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap="8px">
          {allowAddResearch && (
            <Tooltip title={addResearchDisabledReseaon}>
              <span>
                <Button
                  variant="contained"
                  aria-haspopup="true"
                  onClick={handleClick}
                  disabled={Boolean(addResearchDisabledReseaon)}>
                  New Research
                </Button>
              </span>
            </Tooltip>
          )}
          {!isSubfolder && (
            <Button variant="outlined" onClick={() => setOpenNewFolder(true)}>
              New Folder
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleNewResearch}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            sx={{ maxWidth: '480px' }}>
            <Typography variant="text1_Medium" color="colors.dark_bg">
              New Research
            </Typography>
            {isSharedFolder && (
              <Stack direction="row" alignItems="flex-start" gap="4px" sx={{ padding: '8px 0' }}>
                <WarningIcon size={16} />
                <Typography variant="text2_Normal" color="colors.secondary_text">
                  {
                    "The current folder has collaborators. The folder's collaborators will have access to new research"
                  }
                </Typography>
              </Stack>
            )}
            {Object.values(RESEARCH_TYPES)
              .filter((researchType) => researchType.id !== RESEARCH_TYPES.ALL.id)
              .map((researchType) => (
                <MenuItem
                  key={researchType.id}
                  onMouseEnter={() => setHoverMenuItemId(researchType.id)}
                  onMouseLeave={() => setHoverMenuItemId(null)}
                  sx={{
                    height: '124px',
                    margin: '8px',
                    padding: '16px',
                    border: '1px solid',
                    borderColor: 'colors.hover_on_gray_bg',
                    borderRadius: '4px',
                    alignItems: 'flex-start',
                    '&:hover': {
                      backgroundColor:
                        hoverMenuItemId === researchType.id
                          ? researchType.primaryColor
                          : 'colors.text_on_primary'
                    }
                  }}
                  onClick={() => handleNewResearch(researchType.id)}>
                  <ListItemIcon>
                    <researchType.Icon
                      variant={
                        hoverMenuItemId === researchType.id
                          ? ICON_VARIANTS.SECONDARY
                          : ICON_VARIANTS.PRIMARY
                      }
                    />
                  </ListItemIcon>
                  <Stack direction="column" gap="8px" sx={{ marginTop: '2px' }}>
                    <Typography
                      variant="text1_Medium"
                      color={
                        hoverMenuItemId === researchType.id
                          ? 'colors.text_on_primary'
                          : 'colors.dark_bg'
                      }>
                      {researchType.title}
                    </Typography>
                    <Typography
                      variant="text2_Normal"
                      color={
                        hoverMenuItemId === researchType.id
                          ? 'colors.text_on_primary'
                          : 'colors.secondary_text'
                      }
                      sx={{
                        textWrap: 'wrap'
                      }}>
                      {researchType.description}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
          </Menu>
          {folderName && (
            <Stack direction="row" justifyContent="start" alignItems="center" gap="16px">
              <Stack direction="row" alignItems="center" gap="16px" paddingX="32px">
                <FolderIcon
                  sx={{
                    width: '32px',
                    height: '32px',
                    paddingX: '4px',
                    color: 'colors.primary_text'
                  }}
                />
                <Typography variant="h1_Bold" color="colors.dark_bg">
                  {folderName}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" gap="16px">
                {canCollaborate && (
                  <Button variant="outlined" onClick={doCollaborate}>
                    Collaborate
                  </Button>
                )}
                {canCollaborate && (
                  <SharedStack id={folderId} resourceType={RESOURCE_TYPES.FOLDER} size={32} />
                )}
              </Stack>
              {folderId && canCollaborate && (
                <CollaborateDialog
                  open={collaborateOpen}
                  onClose={() => setCollaborateOpen(false)}
                  id={folderId}
                  resourceType={RESOURCE_TYPES.FOLDER}
                />
              )}
            </Stack>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" gap="8px">
          <Stack direction="row" alignItems="center" gap="8px">
            <OutlinedInput
              placeholder={'search...'}
              value={nameFilter}
              disableunderline="true"
              startAdornment={<SearchIcon />}
              onChange={(event) => setNameFilter(event.target.value)}
              sx={{
                backgroundColor: 'transparent',
                ...appTheme.typography.text1_Normal,
                '.MuiInputBase-input': { padding: '8px' }
              }}
            />
            <Select
              size="small"
              value={researchTypes}
              onChange={(event) => setResearchTypes(event.target.value)}
              sx={{ width: '120px', '.MuiSelect-select': { padding: '6px 8px' } }}>
              <MenuItem value={RESEARCH_TYPES.ALL.id} key={RESEARCH_TYPES.ALL.id}>
                <Typography variant="text1_Normal">{RESEARCH_TYPES.ALL.title}</Typography>
              </MenuItem>
              <MenuItem value={RESEARCH_TYPES.DEEP_DIVE.id} key={RESEARCH_TYPES.DEEP_DIVE.id}>
                <Typography variant="text1_Normal">{RESEARCH_TYPES.DEEP_DIVE.title}</Typography>
              </MenuItem>
              <MenuItem value={RESEARCH_TYPES.EXPLORE.id} key={RESEARCH_TYPES.EXPLORE.id}>
                <Typography variant="text1_Normal">{RESEARCH_TYPES.EXPLORE.title}</Typography>
              </MenuItem>
              <MenuItem value={RESEARCH_TYPES.LEADERSHIP.id} key={RESEARCH_TYPES.LEADERSHIP.id}>
                <Typography variant="text1_Normal">{RESEARCH_TYPES.LEADERSHIP.title}</Typography>
              </MenuItem>
            </Select>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      {isEmpty && (
        <Stack direction="column" alignItems="center" gap="16px" paddingTop="32px" width="100%">
          <Typography variant="text1_Bold">How do you want to start your research?</Typography>
          {isSharedFolder && (
            <Stack direction="row" alignItems="flex-start" gap="4px" sx={{ padding: '0 16px' }}>
              <WarningIcon size={16} />
              <Typography variant="text2_Normal" color="colors.secondary_text">
                {
                  "The current folder has collaborators. The folder's collaborators will have access to new research"
                }
              </Typography>
            </Stack>
          )}
          <Stack direction="row" gap="16px" flexWrap="wrap">
            {Object.values(RESEARCH_TYPES)
              .filter((research) => research.description)
              .map((research) => (
                <Stack
                  key={research.id}
                  onMouseEnter={() => setHoverEmptyItemId(research.id)}
                  onMouseLeave={() => setHoverEmptyItemId(null)}
                  direction="column"
                  gap="4px"
                  sx={{
                    flex: 1,
                    maxWidth: '300px',
                    minWidth: '220px',
                    height: '160px',
                    cursor: 'pointer',
                    padding: '16px',
                    border: '1px solid',
                    borderColor: 'colors.hover_on_gray_bg',
                    borderRadius: '4px',
                    backgroundColor:
                      hoverEmptyItemId === research.id ? research.primaryColor : 'colors.primary_bg'
                  }}
                  onClick={() => handleNewResearch(research.id)}>
                  <Stack direction="row" alignItems="center" gap="8px">
                    <research.Icon
                      variant={
                        hoverEmptyItemId === research.id
                          ? ICON_VARIANTS.SECONDARY
                          : ICON_VARIANTS.PRIMARY
                      }
                    />
                    <Typography
                      variant="text1_Medium"
                      color={
                        hoverEmptyItemId === research.id
                          ? 'colors.text_on_primary'
                          : 'colors.dark_bg'
                      }>
                      {research.title}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="text2_Normal"
                    color={
                      hoverEmptyItemId === research.id
                        ? 'colors.text_on_primary'
                        : 'colors.secondary_text'
                    }
                    textAlign="left"
                    sx={{ flex: 1, paddingLeft: '32px' }}>
                    {research.description}
                  </Typography>
                  <Stack direction="row" alignItems="flex-end" justifyContent="flex-end">
                    <MLIconButton
                      sx={{
                        border: '1px solid',
                        borderColor:
                          hoverEmptyItemId === research.id
                            ? 'colors.text_on_primary'
                            : 'colors.primary',
                        borderRadius: '4px'
                      }}
                      onClick={() => handleNewResearch(research.id)}>
                      <AddIcon
                        variant={
                          hoverEmptyItemId === research.id
                            ? ICON_VARIANTS.SECONDARY
                            : ICON_VARIANTS.PRIMARY
                        }
                      />
                    </MLIconButton>
                  </Stack>
                </Stack>
              ))}
          </Stack>
        </Stack>
      )}
      <CreateResearchDialog
        open={openNewDeepDive}
        onClose={() => setOpenNewDeepDive(false)}
        folderId={folderId}
      />
      <CreateListDialog
        open={openNewExplore}
        onClose={() => setOpenNewExplore(false)}
        folderId={folderId}
      />
      <CreatePersonsSearchDialog
        open={openNewLeadership}
        folderId={folderId}
        onClose={() => setOpenNewLeadership(false)}
      />
      <CreateFoldersDialog open={openNewFolder} onClose={() => setOpenNewFolder(false)} />
    </Stack>
  );
}

HomePageHeaderComponent.propTypes = {
  folderId: PropTypes.number,
  nameFilter: PropTypes.string,
  researchTypes: PropTypes.string,
  isEmpty: PropTypes.bool,
  isSubfolder: PropTypes.bool,
  allowAddResearch: PropTypes.bool,
  addResearchDisabledReseaon: PropTypes.string,
  setNameFilter: PropTypes.func,
  setResearchTypes: PropTypes.func
};

export default HomePageHeaderComponent;
