import { Box, Card, Stack, Typography } from '@mui/material';
import CardComponent from 'components/card/CardComponent';
import CompanySummaryComponent from 'components/summary/company/CompanySummaryComponent';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import { useAppState } from 'hooks/state-context';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getCardByRecordIdAndInsightId } from 'selectors/cards';
import { insightTypeToTitle } from 'utils/insights-utils';

export default function CardScreen() {
  const { state } = useAppState();
  const { runId, cardId } = useParams();
  const card = getCardByRecordIdAndInsightId(state, runId, cardId);
  const { insight } = card;

  return (
    <Stack direction="column">
      <CompanySummaryComponent
        recordId={runId}
        backTag="Go to company details"
        backHref={`${COMPANY_INSIGHTS_PREFIX}/${runId}/${OVERVIEW_TAB.path}`}
        addBottomPadding={true}
        is_deep_dive_screen={false}
      />
      <Card sx={{ margin: '32px' }}>
        <Box
          sx={{
            width: '100%',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'colors.gray_bg',
            borderBottom: '1px solid',
            borderColor: 'colors.ui_border'
          }}>
          <Typography variant="h3_Bold" color="colors.primary_text" sx={{ paddingLeft: '24px' }}>
            {insightTypeToTitle(insight?.insight_id || cardId)}
          </Typography>
        </Box>
        <CardComponent
          recordId={runId}
          insightId={cardId}
          sectionId={cardId}
          mode="stretch"
          sx={{ height: '100%' }}
        />
      </Card>
    </Stack>
  );
}
