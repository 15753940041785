import { Box, Button, Input, Stack } from '@mui/material';
import { addNewFolderAction } from 'actions/folders/add-new-folder-action';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { appTheme } from 'theme';

export default function CreateFoldersDialog({ open, onClose }) {
  const dispatch = useDispatch();

  const [folderName, setFolderName] = useState('');

  const closeDialog = () => {
    setFolderName('');
    onClose();
  };

  const handleCreateFolder = () => {
    dispatch(addNewFolderAction(folderName));
    closeDialog();
  };

  const onDownKey = (event) => {
    if (event?.key === 'Enter' && folderName.length > 0) {
      event.preventDefault();
      handleCreateFolder();
    }
  };

  return (
    <ModalComponent
      title="New Folder"
      open={open}
      onClose={closeDialog}
      maxWidth={738}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateFolder}
            disabled={folderName.length <= 0}>
            Create Folder
          </Button>
        </Stack>
      }>
      <Box sx={{ padding: '24px' }}>
        <Input
          placeholder="Enter new folder name here......"
          value={folderName}
          size="small"
          autoFocus
          disableUnderline={true}
          onKeyDown={onDownKey}
          sx={{
            flex: 1,
            color: 'colors.primary_text',
            ...appTheme.typography.h1_Bold,
            width: '100%'
          }}
          onChange={(event) => setFolderName(event.target.value)}
        />
      </Box>
    </ModalComponent>
  );
}

CreateFoldersDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
