import FolderIcon from '@mui/icons-material/Folder';
import { Link, ListItemIcon, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { downloadDomainKnowledgeResourceFileAction } from 'actions/explore/download-domain-knowledge-resource-file-action';
import { createListFromDomainKnowledgeAction } from 'actions/llm_queries/check-values-contains-names-action';
import CustomAnalyticsThread from 'components/discovery/CustomAnalyticsThread';
import ModalComponent from 'components/modal/ModalComponent';
import { DOMAIN_KNOWLEDGE_RESOURCE_TYPE } from 'constants/domain-knowledge';
import { TOAST_TYPES } from 'constants/toasts';
import MLIconButton from 'design-system/MLIconButton';
import { useCustomAnalytics } from 'hooks/useCustomAnalytics';
import AnswerIcon from 'icons/AnswerIcon';
import CustomAnalyticsIcon from 'icons/CustomAnalyticsIcon';
import DeleteIcon from 'icons/DeleteIcon';
import DownloadIcon from 'icons/DownloadIcon';
import ExtendIcon from 'icons/ExtendIcon';
import FilesIcon from 'icons/FilesIcon';
import InfoIcon from 'icons/InfoIcon';
import LinkIcon from 'icons/LinkIcon';
import MenuIcon from 'icons/MenuIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getThreadKey, updatePromptClipboard } from 'reducer/custom-analytics';
import { NULL_PROMPT_CONFIG } from 'utils/prompts-utils';

export const RESOURCE_TYPE_TO_ICON = {
  links: <LinkIcon />,
  files: <FilesIcon />,
  answers: <AnswerIcon />
};

function DomainKnowledgeResourceTile({ listId, folderId, resource, resourceType, onDelete }) {
  const { addToast } = useAppUtils();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [, setCustomeAnalyticsOpen] = useCustomAnalytics();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onDelete();
  };

  const handleDownloadFile = () => {
    addToast('Starting download the file', TOAST_TYPES.INFO);

    dispatch(
      downloadDomainKnowledgeResourceFileAction({
        listId,
        folderId,
        resourceId: resource.id,
        onFinish: (isSuccess) => {
          if (isSuccess) {
            addToast('finished download the file', TOAST_TYPES.SUCCESS);
          } else {
            addToast('failed to download the file', TOAST_TYPES.ERROR);
          }
        }
      })
    );
  };

  const handleCustomAnalytics = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const threadKey = getThreadKey(listId);
    dispatch(
      updatePromptClipboard({
        threadKey,
        promptConfig: {
          ...NULL_PROMPT_CONFIG,
          useDomainKnowledge: true,
          resource: resource.id
        }
      })
    );
    setCustomeAnalyticsOpen(true);
  };

  const onError = (message) => {
    addToast(message, TOAST_TYPES.ERROR);
  };
  const onCreateNewList = (event) => {
    dispatch(createListFromDomainKnowledgeAction(listId, resource, onError));
    event.stopPropagation();
  };

  const innerTile = (
    <Stack
      direction="row"
      gap="16px"
      alignItems="center"
      sx={{
        width: '100%',
        color: 'colors.primary'
      }}>
      {resource.fromFolder && (
        <Tooltip title="From folder" placement="top">
          <FolderIcon
            sx={{
              width: '26px',
              height: '26px',
              paddingX: '4px',
              color: 'colors.primary_text'
            }}
          />
        </Tooltip>
      )}
      {resourceType && RESOURCE_TYPE_TO_ICON[resourceType]}
      <Typography variant="text1_Medium" color="colors.primary_text" textAlign="left">
        {resource.name}
      </Typography>
    </Stack>
  );

  const wrappedInnerTile =
    resourceType === 'links' ? (
      <Tooltip title={resource.data.link} placement="top">
        <Link
          href={resource.data.link}
          textAlign="start"
          underline="none"
          target="_blank"
          width="100%"
          rel="noreferrer">
          {innerTile}
        </Link>
      </Tooltip>
    ) : (
      innerTile
    );

  return (
    <React.Fragment>
      <Stack
        onClick={
          resourceType === 'files'
            ? handleDownloadFile
            : () => {
                setOpen(true);
              }
        }
        sx={{
          borderRadius: '4px',
          border: '1px solid',
          cursor: 'pointer',
          paddingLeft: '8px',
          paddingY: '4px',
          borderColor: 'colors.selected',
          background: 'colors.gray_bg'
        }}>
        <Stack direction="row" alignItems="center">
          {wrappedInnerTile}
          <Stack direction="row" alignItems="center" sx={{ marginLeft: 'auto' }}>
            {resourceType === 'files' && (
              <MLIconButton tooltipText="Download File">
                <DownloadIcon />
              </MLIconButton>
            )}
            {listId && (
              <MLIconButton
                onClick={handleCustomAnalytics}
                tooltipText="Explore with Custom Analytics">
                <CustomAnalyticsIcon color="currentColor" />
              </MLIconButton>
            )}
            {resourceType === DOMAIN_KNOWLEDGE_RESOURCE_TYPE.ANSWERS ? (
              <MLIconButton variant="secondary" id="tile-actions-button" onClick={handleMenuClick}>
                <MenuIcon />
              </MLIconButton>
            ) : (
              <MLIconButton onClick={handleDelete} tooltipText="Delete">
                <DeleteIcon />
              </MLIconButton>
            )}
          </Stack>
        </Stack>
      </Stack>
      {resourceType === 'answers' && (
        <ModalComponent
          title={resource.name}
          open={open}
          onClose={() => setOpen(false)}
          maxWidth={600}>
          <Stack sx={{ padding: '16px' }}>
            <CustomAnalyticsThread post={resource.data} listId={listId} />
          </Stack>
        </ModalComponent>
      )}
      {resourceType === 'answers' && (
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setAnchorEl(null);
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}>
          <MenuItem onClick={handleDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant="text1_Normal">Delete</Typography>
          </MenuItem>
          <MenuItem onClick={onCreateNewList}>
            <ListItemIcon>
              <ExtendIcon />
            </ListItemIcon>
            <Typography variant="text1_Normal">Create new list</Typography>
            <Tooltip title="Will extract names and websites of companies if the data contains any.">
              <ListItemIcon sx={{ paddingLeft: '16px' }}>
                <InfoIcon contained={false} />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        </Menu>
      )}
    </React.Fragment>
  );
}

DomainKnowledgeResourceTile.propTypes = {
  listId: PropTypes.number,
  folderId: PropTypes.number,
  resource: PropTypes.object.isRequired,
  resourceType: PropTypes.string.isRequired,
  onDelete: PropTypes.func
};

export default DomainKnowledgeResourceTile;
