import { v4 as uuid4 } from 'uuid';

import { ActionType, Message, SuggestionWithoutName, UserMessageContent } from './types';

interface AssistantPromptConfigParams {
  action_type?: ActionType;
  question?: string;
  company_meta_ids?: number[];
  supporting_resource_ids?: number[];
  columns_ids?: string[];
  urls?: string[];
  use_web?: boolean;
  use_table?: boolean;
  answer_type?: 'text' | 'number' | 'boolean' | 'json' | null;
}

class AssistantPromptConfig {
  action_type: ActionType | null = null;
  question: string = '';
  company_meta_ids: number[] = [];
  supporting_resource_ids: number[] = [];
  columns_ids: string[] = [];
  urls: string[] = [];
  use_web: boolean = true;
  use_table: boolean = true;
  answer_type: 'text' | 'number' | 'boolean' | 'json' | null = null;

  constructor(params: AssistantPromptConfigParams = {}) {
    Object.assign(this, params);
  }

  // Method to update the question
  updateQuestion(newQuestion: string, mentions: SuggestionWithoutName[] = []) {
    this.question = newQuestion;
    this.supporting_resource_ids = mentions
      .filter((mention) => mention.suggestionIdType === 'supporting_resource_id')
      .map((mention) => parseInt(mention.id));
    this.company_meta_ids = mentions
      .filter((mention) => mention.suggestionIdType === 'company_meta_id')
      .map((mention) => parseInt(mention.id));
    this.columns_ids = mentions
      .filter((mention) => mention.suggestionIdType === 'column_id')
      .map((mention) => mention.id);
  }

  _toUserMessageContent(): UserMessageContent {
    return {
      action_type: this.action_type,
      text: this.question,
      use_web: this.use_web,
      use_table: this.use_table,
      answer_type: this.answer_type
    };
  }

  // Method to convert the AssistantPromptConfig object to a Message object
  // ready for backend
  toMessage(): Message {
    return {
      id: uuid4(),
      role: 'user',
      content: this._toUserMessageContent()
    };
  }
}

export default AssistantPromptConfig;
export type { AssistantPromptConfigParams };
