import PropTypes from 'prop-types';
import React from 'react';

import MessageComponent from './MessageComponent';

function AssistantMessageComponent({ message }) {
  return <MessageComponent role="assistant" text={message.content.text} />;
}

AssistantMessageComponent.propTypes = {
  message: PropTypes.object
};

export default AssistantMessageComponent;
