import { Typography } from '@mui/material';
import RunningSearchActionBase from 'components/tile/RunningSearchAction';
import { PROMPT_PREFIX } from 'constants/custom-analytics';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCustomAnalyticsThreadSpecificPostById } from 'selectors/custom-analytics';
import { isEmpty } from 'utils/objects-utils';

export default function RunningSearchAction({ id, listId }) {
  const post = useSelector((state) =>
    getCustomAnalyticsThreadSpecificPostById(state, getThreadKey(listId), id)
  );
  const searchTimestamp = post?.body?.timeSubmitted;
  const totalNumCompanies = post?.body?.total_num_companies || 0;
  const messagesCount = post?.messages?.filter((mes) => mes?.message?.answer)?.length || 0;
  const isCurrentlyAddColumn = post?.query?.includes(
    `{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`
  );
  const progressExistInState = Boolean(post?.status || totalNumCompanies);

  const areAllRecordsFinished = useMemo(() => {
    if (isEmpty(post?.records)) {
      return false;
    }
    return Object.values(post.records).every((record) => !record.isRunning);
  }, [post]);

  const progress = useMemo(() => {
    if (!post) {
      return;
    }
    if (
      isCurrentlyAddColumn &&
      !areAllRecordsFinished &&
      messagesCount > 0 &&
      (parseFloat(post.status?.replace('%', '')) < (messagesCount * 100) / totalNumCompanies ||
        post.status === '100%')
    ) {
      return Math.round((messagesCount * 100) / totalNumCompanies);
    }

    return parseFloat(post.status?.replace('%', '')) || 0;
  }, [isCurrentlyAddColumn, areAllRecordsFinished, messagesCount, totalNumCompanies, post]);

  const progressMessage = useMemo(() => {
    if (!post) {
      return;
    }
    if (isCurrentlyAddColumn) {
      if (messagesCount === 0) {
        return post.statusMessage || `Waiting for ${totalNumCompanies} companies`;
      }
      return `${messagesCount} / ${totalNumCompanies} company results`;
    }

    return post.statusMessage;
  }, [isCurrentlyAddColumn, messagesCount, totalNumCompanies, post]);
  return (
    <RunningSearchActionBase {...{ progress, progressExistInState, searchTimestamp }}>
      {progressMessage && (
        <Typography variant="text2_Normal" color="colors.primary_text" textAlign="left">
          {progressMessage}
        </Typography>
      )}
    </RunningSearchActionBase>
  );
}

RunningSearchAction.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mode: PropTypes.oneOf(['tile', 'monitor']),
  recentlyFinished: PropTypes.bool,
  listId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

RunningSearchAction.defaultProps = {
  mode: 'tile'
};
