import { Box, Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import BottomBarComponent from 'components/BottomBarComponent';
import MLIconButton from 'design-system/MLIconButton';
import PropTypes from 'prop-types';
import React from 'react';

export const DomainResearchBottomBar = ({ selectedDomains, bottomBarActions }) => {
  const numDomainsSelected = selectedDomains?.size;

  if (numDomainsSelected <= 0) {
    return null;
  }
  return (
    <BottomBarComponent>
      <Stack flexDirection="row" padding="0px 16px" gap="8px" alignItems="center" width={170}>
        <Typography variant="h3_Bold" color="colors.primary_text">
          {numDomainsSelected}
        </Typography>
        <Typography variant="text1_Medium" color="colors.dark_bg">
          {`Item${numDomainsSelected === 1 ? '' : 's'} selected`}
        </Typography>
      </Stack>
      <Divider sx={{ marginRight: '16px' }} orientation="vertical" variant="middle" flexItem />

      <Stack flexDirection="row" gap="16px" width="fit-content">
        {bottomBarActions.map(
          ({ title, onClick, icon, endIcon, disabled, disabledMessage }, index) => {
            return title ? (
              <Tooltip key={index} arrow title={disabled ? disabledMessage : ''} placement="top">
                <Box>
                  <Button startIcon={icon} endIcon={endIcon} onClick={onClick} disabled={disabled}>
                    {title}
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <MLIconButton key={index} onClick={onClick}>
                {icon}
              </MLIconButton>
            );
          }
        )}
      </Stack>
    </BottomBarComponent>
  );
};

DomainResearchBottomBar.propTypes = {
  selectedDomains: PropTypes.array,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onNewList: PropTypes.func,
  onDeepDive: PropTypes.func,
  noRunsLeft: PropTypes.bool,
  onExtend: PropTypes.func,
  submitSubdomains: PropTypes.func,
  bottomBarActions: PropTypes.array
};

export default React.memo(DomainResearchBottomBar);
