import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';

import { isStringIsNumber } from './string-utils';

export function getWorkflowStepAndQueryIndexesByStepId(stepId, steps) {
  const stepParts = stepId.split('-');
  const stepIndex = steps.findIndex((step) => step.step_id === stepParts[0]);
  const queryIndex =
    stepParts.length > 1 && isStringIsNumber(stepParts[1]) ? parseInt(stepParts[1], 10) : 0;
  return { stepIndex, queryIndex };
}

export function getWorkflowStepQueryBystepType(stepType, stepNumber) {
  const customAnalyticsQuery = {
    user_question: '',
    column_ids: [],
    domain_knowledge_ids: [],
    search_the_web: true,
    is_table_question: true,
    answer_type: 'text'
  };
  const query = {};
  switch (stepType) {
    case WORK_FLOW_STEP_TYPES.information.id:
      query.attribute_name = `${stepNumber} ${WORK_FLOW_STEP_TYPES[stepType].label}`;
      query.query = customAnalyticsQuery;
      query.depends_on = [];
      break;
    case WORK_FLOW_STEP_TYPES.discovery.id:
      query.query = '';
      break;
    case WORK_FLOW_STEP_TYPES.analysis.id:
      query.analysis_name = `${stepNumber} ${WORK_FLOW_STEP_TYPES[stepType].label}`;
      query.query = customAnalyticsQuery;
      query.depends_on = [];
      break;
    case WORK_FLOW_STEP_TYPES.filter.id:
      query.analysis_name = `${stepNumber} ${WORK_FLOW_STEP_TYPES[stepType].label}`;
      query.condition = 'yes';
      query.query = customAnalyticsQuery;
      query.depends_on = [];
      break;
    default:
      break;
  }
  return query;
}

export function getWorkflowStepByTypeAndSteps(stepType, steps) {
  const newStepId = steps.length > 0 ? `step_${steps.length}` : 'start';
  const query = getWorkflowStepQueryBystepType(stepType, steps.length + 1);
  const newStep = {
    step_id: newStepId,
    function_use: {
      action: stepType,
      action_variables: {
        queries: [query]
      }
    },
    objective: stepType,
    if_failed: 'end',
    if_successful: 'end'
  };

  return newStep;
}
