import { ACTIONS } from 'constants/actions';

import { translateAdminCompanyObjectForState } from './load-all-admin-company-lists-action';

export function updateAdminCompanyList(listId, list) {
  return { type: ACTIONS.UPDATE_ADMIN_COMPANY_LIST, listId, list };
}

export function loadAdminListAction(listId) {
  return (_state, dispatch, apiClient) => {
    apiClient.companiesLists.getAdminCompaniesListById(listId).then((res) => {
      if (res.ok && res.data) {
        dispatch(updateAdminCompanyList(listId, translateAdminCompanyObjectForState(res.data)));
      }
    });
  };
}
