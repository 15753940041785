import { translateFilterParams } from 'api/Utils';
import { updateCustomAnalytics } from 'reducer/admin-slice';

export function translateCustomAnalyticsRecord(record) {
  if (!record) {
    return {};
  }
  return {
    companyListId: record.company_list_id,
    jobId: record.job_id,
    ownerOrganizationId: record.organization_id,
    parameters: record.parameters,
    recordId: record.record_id,
    response: record.response,
    status: record.status,
    timeSubmitted: record.time_submitted,
    user: record.user
  };
}

export function translateAdminCustomAnalyticsForState(records) {
  if (!records) {
    return [];
  }
  return records.map((record) => translateCustomAnalyticsRecord(record));
}

export function loadAllAdminCustomAnalytics(params) {
  return (dispatch, _getState, apiClient) => {
    apiClient.customAnalytics
      .fetchAdminCustomAnalytics(translateFilterParams(params))
      .then((res) => {
        if (res.ok && res.data.records) {
          dispatch(
            updateCustomAnalytics({
              records: translateAdminCustomAnalyticsForState(res.data.records)
            })
          );
        }
      });
  };
}
