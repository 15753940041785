import PropTypes from 'prop-types';
import React from 'react';

function CodeIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18 17L23 12L18 7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 7L1 12L6 17"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

CodeIcon.propTypes = {
  color: PropTypes.string
};

CodeIcon.defaultProps = {
  color: '#5C5E76'
};

export default CodeIcon;
