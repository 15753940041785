import { Link } from '@mui/material';
import LinkedinIcon from 'icons/LinkedinIcon';
import PropTypes from 'prop-types';
import React from 'react';

import MLIconButton from '../design-system/MLIconButton';

export default function LinkedinButton({ linkedinUrl }) {
  return (
    <Link href={linkedinUrl} target="_blank" rel="noopener">
      <MLIconButton tooltipText="Go to Linkedin" sx={{ padding: 0 }}>
        <LinkedinIcon />
      </MLIconButton>
    </Link>
  );
}

LinkedinButton.propTypes = { linkedinUrl: PropTypes.string };
