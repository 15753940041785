import { Typography } from '@mui/material';
import BaseTooltipComponent from 'components/elements/BaseTooltipComponent';
import PropTypes from 'prop-types';
import React from 'react';

function ArtBartChartTooltip({ payload }) {
  const data = payload?.[0]?.payload;
  if (!data) {
    return <React.Fragment></React.Fragment>;
  }
  return (
    <BaseTooltipComponent>
      <Typography variant="text1_Bold">Holding Years: {data.year}</Typography>
      <br />
      <Typography variant="text1_Normal">
        Predicted Price: {`${Math.floor(data.predictedPrice / 1000)}k`}{' '}
      </Typography>
      <br />

      <Typography variant="text1_Normal">
        Should buy today at maximun: {`${Math.floor(data.price / 1000)}k`}{' '}
      </Typography>
    </BaseTooltipComponent>
  );
}

ArtBartChartTooltip.propTypes = {
  payload: PropTypes.array
};

export default ArtBartChartTooltip;
