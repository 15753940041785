import { Box, Grow, Stack, Typography } from '@mui/material';
import FlagIcon from 'icons/FlagIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import { isEmpty } from 'utils/objects-utils';
import { DOMAIN_RESEARCH_COMPONENT_TYPES, EXTEND_DOMAIN_TYPES } from 'utils/subdomains';

import CategoryActionsMenu from './CategoryActionsMenu';
import SubdomainsCatrgory from './SubdomainsCategory';

function SubdomainsLevel({ listId, levelDomains, idx, selectedDomains, onClickSubdomain }) {
  const colors = Object.freeze(['#E0DAFF', '#CFE7FF', '#CAE4DE', '#FFD9DF']);

  const color = colors[idx % colors.length];

  const categories = levelDomains.reduce((acc, { parentId, parentDomains }) => {
    if (!parentId) {
      acc.push({
        prefix: '',
        parentId,
        domains: parentDomains
      });
      return acc;
    }
    acc.push(
      ...parentDomains.map(({ prefix, prefixDomains }) => ({
        prefix,
        parentId,
        domains: prefixDomains
      }))
    );
    return acc;
  }, []);

  const flattedDomains = categories.reduce((acc, { domains }) => {
    acc.push(...domains);
    return acc;
  }, []);

  const extend_type = flattedDomains?.[0]?.parameters?.extend_type;
  const firstFollowupTitle =
    idx === 1 &&
    (extend_type === EXTEND_DOMAIN_TYPES.USER_INPUT ? 'Custom Query Items' : extend_type);
  const titles = Object.freeze(['Main Domain', firstFollowupTitle, 'follow-ups']);
  const levelTitle = `${titles?.[idx] || titles[titles.length - 1]} ${
    idx >= titles.length ? `(${idx - titles.length + 1})` : ''
  }`;

  return (
    <Stack gap="16px">
      <Box
        display="flex"
        alignItems="center"
        padding="6px"
        sx={{ backgroundColor: color, borderRadius: '50px' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="4px 6px"
          gap="6px"
          width="100%">
          {idx === 0 && <FlagIcon color="black" />}
          <Typography textAlign="center" variant="text1_Medium" noWrap>
            {levelTitle}
          </Typography>
        </Box>
        {!isEmpty(flattedDomains) && (
          <Stack color="secondary.secondary5_100" paddingRight="8px" justifySelf="flex-end">
            <CategoryActionsMenu
              categorySubdomains={flattedDomains}
              listId={listId}
              componentType={
                idx === 0
                  ? DOMAIN_RESEARCH_COMPONENT_TYPES.MAIN_DOMAIN
                  : DOMAIN_RESEARCH_COMPONENT_TYPES.LEVEL
              }
            />
          </Stack>
        )}
      </Box>
      <Stack direction="row" gap="16px" overflow="hidden">
        <TransitionGroup component={null}>
          {categories.map(({ parentId, prefix, domains }) => (
            <Grow
              key={`${parentId}-${prefix}`}
              timeout={{ enter: 0, exit: parentId && parentId > 0 ? 200 : 0 }}
              unmountOnExit>
              <Stack direction="row" gap="8px" key={`${parentId}-${prefix}`} width="100%">
                <SubdomainsCatrgory
                  listId={listId}
                  categoryDomains={domains}
                  backgroundColor={color}
                  parentId={parentId}
                  prefix={prefix}
                  idx={idx}
                  onClickSubdomain={onClickSubdomain}
                  selectedDomains={selectedDomains}
                />
              </Stack>
            </Grow>
          ))}
        </TransitionGroup>
      </Stack>
    </Stack>
  );
}

SubdomainsLevel.propTypes = {
  listId: PropTypes.number,
  levelDomains: PropTypes.array,
  namesMap: PropTypes.object,
  idx: PropTypes.number,
  selectedDomains: PropTypes.object,
  onClickSubdomain: PropTypes.func,
  exploredDomains: PropTypes.object
};

export default SubdomainsLevel;
