import React from 'react';

import MLBasicIconButton, { MLBasicIconButtonProps } from './base/MLBasicIconButton';
import MLButtonWrapper, { MLButtonWrapperProps } from './base/MLButtonWrapper';

interface MLIconButtonProps extends MLBasicIconButtonProps, MLButtonWrapperProps {
  children: React.ReactNode;
}

export default function MLIconButton({
  id,
  variant = 'text',
  color = 'primary',
  size = 'medium',
  tooltipText,
  tooltipPlacement = 'bottom',
  tooltipArrow = true,
  alertMessage,
  alertSeverity,
  disabled,
  onClick,
  sx,
  children,
  ...extraProps
}: MLIconButtonProps) {
  const inner = (
    <MLBasicIconButton
      id={id}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      color={color}
      size={size}
      sx={sx}
      {...extraProps}>
      {children}
    </MLBasicIconButton>
  );
  return (
    <MLButtonWrapper
      tooltipText={tooltipText}
      tooltipPlacement={tooltipPlacement}
      tooltipArrow={tooltipArrow}
      alertMessage={alertMessage}
      alertSeverity={alertSeverity}>
      {inner}
    </MLButtonWrapper>
  );
}
