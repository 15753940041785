import { Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { addDiscoveryColumnsFromCsvAction } from 'actions/explore/add-discovery-columns-from-csv-action';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function AddColumnsFromCsvDialog({ listId, newColumns, open, onClose }) {
  const reduxDispatch = useDispatch();
  const [selectedColumns, setSelectedColumns] = useState({});

  useEffect(() => {
    setSelectedColumns(
      newColumns?.reduce((result, column) => {
        result[column.index] = true;
        return result;
      }, {}) || {}
    );
  }, [newColumns]);

  const closeDialog = () => {
    onClose();
  };

  const handleAddColumns = () => {
    reduxDispatch(addDiscoveryColumnsFromCsvAction(listId, selectedColumns));
    closeDialog();
  };

  const handleSelectedColumnsChange = (event, column) => {
    setSelectedColumns((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (event.target.checked) {
        newSelected[column.index] = true;
      } else {
        delete newSelected[column.index];
      }
      return newSelected;
    });
  };

  return (
    <ModalComponent
      title="Add Columns"
      open={open}
      onClose={closeDialog}
      maxWidth={600}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAddColumns}
            disabled={Object.keys(selectedColumns).length <= 0}>
            Add Columns
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="32px" sx={{ padding: '24px' }}>
        <Stack direction="column" gap="8px">
          <Typography variant="text1_Semibold">
            We notice that in CSV file you have uploaded you have new columns, do you want to add
            those columns?
          </Typography>
          <FormGroup>
            {newColumns?.map((column) => (
              <FormControlLabel
                key={column.index}
                control={
                  <Checkbox
                    checked={Boolean(selectedColumns[column.index])}
                    onChange={(event) => handleSelectedColumnsChange(event, column)}
                  />
                }
                label={column.name}
              />
            ))}
          </FormGroup>
        </Stack>
      </Stack>
    </ModalComponent>
  );
}

AddColumnsFromCsvDialog.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newColumns: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddColumnsFromCsvDialog;
