import { AssitantResponse, PostAssitantPayload } from 'models/assistant/api';
import { Message } from 'models/assistant/types';
import { addMessageToThread, startNewThread, updateThread } from 'reducer/assistant-slice';
import { getLatestThread } from 'selectors/assistant';

export function submitAssistantHelperAction(threadId: string, payload: PostAssitantPayload) {
  return (dispatch: any, getState: any, apiClient: any) => {
    apiClient.assistant.submit(payload).then((res: any) => {
      if (res.ok && res.data) {
        const data = res.data as AssitantResponse;
        const { message, action, submission_info } = data;
        if (submission_info) {
          // If we have submission info, we need to update the thread and start a new one
          const thread = getLatestThread(getState());
          dispatch(updateThread({ thread: { ...thread, submission_info } }));
          dispatch(startNewThread({}));
        } else {
          dispatch(addMessageToThread({ threadId, message, action }));
        }
      }
    });
  };
}
