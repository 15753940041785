import { Typography } from '@mui/material';
import { MuiMarkdown } from 'mui-markdown';
import PropTypes from 'prop-types';
import React from 'react';

import LinkWithAddToDomainKnowledge from './domain-knowledge/LinkWithAddToDomainKnowledge';

/* eslint-disable id-length */
function CustomMarkdown({ body, listId, context }) {
  return (
    <MuiMarkdown
      overrides={{
        h1: {
          component: Typography,
          props: {
            variant: 'h1_Normal'
          }
        },
        h2: {
          component: Typography,
          props: {
            variant: 'h2_Normal'
          }
        },
        h3: {
          component: Typography,
          props: {
            variant: 'h3_Normal'
          }
        },
        h4: {
          component: Typography,
          props: {
            variant: 'h3_Normal'
          }
        },
        h5: {
          component: Typography,
          props: {
            variant: 'text1_Bold'
          }
        },
        h6: {
          component: Typography,
          props: {
            variant: 'text1_Bold'
          }
        },
        p: {
          component: Typography,
          props: {
            variant: 'text1_Normal'
          }
        },
        a: {
          component: LinkWithAddToDomainKnowledge,
          props: {
            listId,
            context
          }
        }
      }}>
      {body}
    </MuiMarkdown>
  );
}

CustomMarkdown.propTypes = {
  body: PropTypes.string,
  listId: PropTypes.number,
  context: PropTypes.string
};

export default CustomMarkdown;
