import cloneDeep from 'lodash/cloneDeep';
import { getThreadKey, updatePlan } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';
import {
  getWorkflowStepAndQueryIndexesByStepId,
  getWorkflowStepByTypeAndSteps
} from 'utils/workflows-utils';

export function addStepToPlanAction(listId, workflowId, workflowStepId, belowStepId) {
  return (dispatch, getState) => {
    const threadKey = getThreadKey(listId);
    const state = getState();
    const plan = getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, workflowId);

    const newPlan = cloneDeep(plan);
    if (!plan.steps) {
      // init workflow plan fields
      if (!newPlan.question) {
        newPlan.question = 'Manual Workflow';
      }
      if (typeof newPlan.is_full_plan !== 'boolean') {
        newPlan.is_full_plan = true;
      }
      if (typeof newPlan.is_workflow !== 'boolean') {
        newPlan.is_workflow = true;
      }
      if (!newPlan.model) {
        newPlan.model = 'human';
      }
      if (!newPlan.preparation_steps) {
        newPlan.preparation_steps = [];
      }
      if (!newPlan.time_taken_to_generate) {
        newPlan.time_taken_to_generate = [];
      }
      if (!newPlan.cost) {
        newPlan.cost = 0;
      }
      if (!newPlan.steps) {
        newPlan.steps = [];
      }
    }

    let newStepIndex = newPlan.steps.length;
    if (belowStepId) {
      const { stepIndex } = getWorkflowStepAndQueryIndexesByStepId(belowStepId, newPlan.steps);
      newStepIndex = stepIndex + 1;
    }
    const newStep = getWorkflowStepByTypeAndSteps(workflowStepId, newPlan.steps);
    newPlan.steps.splice(newStepIndex, 0, newStep);
    newPlan.steps.forEach((step, index) => {
      step.if_successful =
        index < newPlan.steps.length - 1 ? newPlan.steps[index + 1].step_id : 'end';
    });
    dispatch(updatePlan({ threadKey, postId: workflowId, newPlan }));
  };
}
