import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { DISCOVERY_TYPES } from 'constants/discovery';
import MLIconButton from 'design-system/MLIconButton';
import ArrowIcon from 'icons/ArrowIcon';
import AssistantWandIcon from 'icons/AssistantWandIcon';
import InfoIcon from 'icons/InfoIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function DiscoverySuggestionsComponent({ suggestions, setPromptConfig }) {
  const [expanded, setExpanded] = useState(true);

  const onClickSuggestion = (suggestion) => {
    setPromptConfig((prev) => {
      return {
        ...prev,
        question: suggestion.prompt,
        useDiscover: true,
        discoverMethod: DISCOVERY_TYPES.TEXT,
        useColumn: false,
        useColumns: false,
        columns: [],
        forEachCompany: false,
        useDomainKnowledge: false,
        domainKnowledge: null,
        searchTheWeb: false,
        useTable: false
      };
    });
  };

  return (
    <Stack gap="8px" paddingLeft="50px">
      <Stack direction="row" alignItems="center" gap="8px">
        <AssistantWandIcon />
        <Typography textAlign="left" variant="text1_Normal" color="colors.secondary_text">
          Related questions
        </Typography>
        <MLIconButton
          sx={{ transform: expanded ? '' : 'rotate(180deg)', marginLeft: 'auto' }}
          onClick={() => setExpanded(!expanded)}>
          <ArrowIcon />
        </MLIconButton>
      </Stack>
      {suggestions && expanded && (
        <Stack
          direction="column"
          sx={{
            gap: '8px',
            width: '100%'
          }}>
          {suggestions?.map((suggestion, index) => (
            <Stack
              key={index}
              onClick={() => onClickSuggestion(suggestion)}
              border="1px solid"
              borderRadius="4px"
              direction="row"
              gap="4px"
              alignItems="center"
              padding="8px 16px"
              borderColor="colors.ui_border"
              color="colors.secondary_text"
              sx={{
                cursor: 'pointer',
                ':hover': { backgroundColor: 'colors.gray_bg', color: 'colors.dark_bg' }
              }}>
              <Typography variant="text1_Normal">{suggestion?.prompt}</Typography>
              <Tooltip title={suggestion?.reasoning}>
                <Box
                  sx={{
                    marginLeft: 'auto'
                  }}>
                  <InfoIcon contained={false} />
                </Box>
              </Tooltip>
            </Stack>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

export default DiscoverySuggestionsComponent;

DiscoverySuggestionsComponent.propTypes = {
  suggestions: PropTypes.array,
  setPromptConfig: PropTypes.func
};
