import { Box, ClickAwayListener, Input, Stack, Tooltip } from '@mui/material';
import RenderStr from 'components/elements/table/RenderStr';
import MLIconButton from 'design-system/MLIconButton';
import EditIcon from 'icons/EditIcon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { appTheme } from 'theme';

export function DiscoveryTitle({ title, onTitleChange, numCompanies }) {
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [localTitle, setLocalTitle] = useState(title);
  useEffect(() => {
    setLocalTitle(title);
  }, [title]);

  const updateTitle = (event) => {
    setLocalTitle(event.target.value);
  };

  const onDownKey = () => {
    if (!editTitleMode) return;
    if (event?.type === 'click' || event?.key === 'Enter') {
      setEditTitleMode(false);
      if (localTitle?.length) {
        onTitleChange?.(localTitle);
      } else {
        setLocalTitle(title);
      }
    }
  };

  return (
    <Tooltip
      title={
        numCompanies && !editTitleMode
          ? `${numCompanies} compan${numCompanies === 1 ? 'y' : 'ies'}`
          : ''
      }>
      <Box>
        {editTitleMode ? (
          <ClickAwayListener onClickAway={onDownKey}>
            <Stack direction="row" alignItems="center">
              <Input
                value={localTitle}
                disableUnderline={true}
                autoFocus={true}
                onChange={(event) => updateTitle(event)}
                onKeyDown={onDownKey}
                sx={{
                  input: {
                    color: 'colors.primary_text'
                  },
                  backgroundColor: 'transparent',
                  ...appTheme.typography.h3_Bold
                }}
              />
            </Stack>
          </ClickAwayListener>
        ) : (
          <Stack direction="row" gap="8px" alignItems="center" maxWidth="300px">
            <RenderStr variant="h4" color="colors.primary_text" value={localTitle} />
            {onTitleChange && (
              <MLIconButton onClick={() => setEditTitleMode(true)} sx={{ padding: '4px' }}>
                <EditIcon />
              </MLIconButton>
            )}
          </Stack>
        )}
      </Box>
    </Tooltip>
  );
}

DiscoveryTitle.propTypes = {
  title: PropTypes.string,
  onTitleChange: PropTypes.func,
  numCompanies: PropTypes.number
};
