import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import { getListCustomColumnsByListId, getListDomainKnowledgeByListId } from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import { getCurrentFolderSupportingResources } from 'selectors/folders';
import { combineDomainKnowledge } from 'utils/domain-knowledge-utils';
import { getCompaniesAndColumns } from 'utils/prompts-utils';

import AssistantPrompt from './AssistantPrompt';

function AssistantPromptConnector({ listId, folderId }) {
  // companies
  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));

  // columns
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));

  // supportingResources
  const listDomainKnowledge = useSelector((state) => getListDomainKnowledgeByListId(state, listId));
  const folderDomainKnowledge = useSelector((state) =>
    getCurrentFolderSupportingResources(state, folderId)
  );
  const domainKnowledge = useMemo(
    () => combineDomainKnowledge(listDomainKnowledge, folderDomainKnowledge),
    [listDomainKnowledge, folderDomainKnowledge]
  );

  // Infer columns and companies from the rows and settings.
  const { columns: columnsObj, companies: companiesMap } = useMemo(() => {
    return getCompaniesAndColumns(listId, rows, customColumns, columnsSettings);
  }, [rows, customColumns, listId, columnsSettings]);

  // Convert the objs/maps to arrays.
  const companies = useMemo(() => {
    return Array.from(companiesMap.values());
  }, [companiesMap]);
  const columns = useMemo(() => {
    return Object.values(columnsObj);
  }, [columnsObj]);

  return (
    <AssistantPrompt
      companies={companies}
      columns={columns}
      supportingResources={domainKnowledge}
    />
  );
}

AssistantPromptConnector.propTypes = {
  listId: PropTypes.number,
  folderId: PropTypes.string
};

export default AssistantPromptConnector;
