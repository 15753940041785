import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { loadUserOrganizationUsersAction } from 'actions/users/load-user-organization-users-action';
import LoaderComponent from 'components/LoaderComponent';
import CustomAvatar from 'components/tile/CustomAvatar';
import AddViewerUserDialog from 'components/viewers/AddViewerUserDialog';
import DeleteViewerUserDialog from 'components/viewers/DeleteViewerUserDialog';
import MLIconButton from 'design-system/MLIconButton';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import DeleteIcon from 'icons/DeleteIcon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getUser, getUserOrganizationMaxViewers, getUserOrganizationUsers } from 'selectors/user';

function OrganizationScreen() {
  const { state } = useAppState();
  const { addToast } = useAppUtils();
  const user = getUser(state);
  const orgUsers = getUserOrganizationUsers(state);
  const maxViewers = getUserOrganizationMaxViewers(state);

  const [openAddViewerUser, setOpenAddViewerUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState();
  const [isAddButtonHover, setIsAddButtonHover] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    dispatch(loadUserOrganizationUsersAction());
  }, []);

  const orgViewerUsers = useMemo(
    () => orgUsers?.filter((orgUser) => orgUser.is_basic) || [],
    [orgUsers]
  );

  const closeAddBasicUserModal = () => {
    setOpenAddViewerUser(false);
  };

  const onActionStart = useCallback(() => setShowLoader(true), []);
  const onActionEnd = useCallback(
    (type, message) => {
      setShowLoader(false);
      addToast(message, type);
    },
    [addToast]
  );
  const canAddViewers = maxViewers > orgViewerUsers.length;

  return (
    <Stack
      sx={{
        padding: '32px',
        minHeight: 'calc(100vh - 50px)'
      }}
      gap="32px"
      alignItems="center">
      <Typography variant="h1_Bold">Organization</Typography>
      <Stack
        direction="column"
        gap="48px"
        sx={{
          padding: '24px',
          boxSizing: 'border-box',
          borderRadius: '4px',
          width: '450px',
          textAlign: 'left',
          boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
          backgroundColor: 'colors.primary_bg'
        }}>
        <Stack gap="8px">
          <Typography variant="text1_Semibold">Organization:</Typography>
          <Stack gap="8px" direction="row" alignItems="center">
            <CustomAvatar
              name={user.organization.name}
              pictureUrl={user.organization.logo_url}
              size={24}
              org
            />
            <Typography variant="text1_Normal">{user.organization.name}</Typography>
          </Stack>
        </Stack>
        <Stack direction="column" gap="24px">
          <Stack direction="row" justifyContent="space-between" gap="8px">
            <Typography variant="text1_Bold">{`Viewer Users (${orgViewerUsers.length} / ${maxViewers})`}</Typography>
            <Tooltip
              open={isAddButtonHover && !canAddViewers}
              title="Your organization has reached the maximum number of viewers' users">
              <Button
                onPointerEnter={() => setIsAddButtonHover(true)}
                onPointerLeave={() => setIsAddButtonHover(false)}
                variant="contained"
                startIcon={<AddIcon />}
                disabled={!canAddViewers}
                sx={{
                  '&.Mui-disabled': {
                    pointerEvents: 'auto',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.12);'
                    }
                  }
                }}
                onClick={() => setOpenAddViewerUser(true)}>
                Add Viewer
              </Button>
            </Tooltip>
          </Stack>
          <Stack direction="column" gap="8px" sx={{ position: 'relative' }}>
            <Stack direction="row" justifyContent="space-between" gap="8px">
              <Typography variant="text1_Semibold">Email</Typography>
              <Typography variant="text1_Semibold">Actions</Typography>
            </Stack>
            {orgViewerUsers.map((orgUser) => (
              <Stack
                key={orgUser.id}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="8px">
                <Typography variant="text1_Normal">{orgUser.email}</Typography>
                <MLIconButton
                  tooltipText="Delete this viewer user"
                  onClick={() => setDeleteUser(orgUser)}>
                  <DeleteIcon />
                </MLIconButton>
              </Stack>
            ))}
            {showLoader && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ position: 'absolute', width: '100%', height: '100%' }}>
                <LoaderComponent />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <AddViewerUserDialog
        open={openAddViewerUser}
        onClose={closeAddBasicUserModal}
        onAddUserStart={onActionStart}
        onAddUserEnd={onActionEnd}
      />
      <DeleteViewerUserDialog
        user={deleteUser}
        open={Boolean(deleteUser)}
        onClose={() => setDeleteUser(null)}
        onDeleteStart={onActionStart}
        onDeleteEnd={onActionEnd}
      />
    </Stack>
  );
}

export default OrganizationScreen;
