import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Box, Stack, Typography } from '@mui/material';
import ModalComponent from 'components/modal/ModalComponent';
import MLIconButton from 'design-system/MLIconButton';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getUserFeatureFlags } from 'selectors/user';

import CustomAnalyticsSourceItemComponent from './CustomAnalyticsSourceItemComponent';

export default function CustomAnalyticsAnswerComponent({ value: payload, listId, deepDiveId }) {
  const { sources, reasoning, toolCallHistory, placement } = payload?.value || {};
  const [sourcesExpanded, setSourcesExpanded] = useState(false);
  const [ReasonExpanded, setReasonExpanded] = useState(false);
  const [toolCallHistoryOpen, setToolCallHistoryOpen] = useState(false);

  const featureFlags = useOldStateSelector(getUserFeatureFlags);

  const handleSourcesExpandedChange = () => setSourcesExpanded(!sourcesExpanded);
  const handleReasonExpandedChange = () => setReasonExpanded(!ReasonExpanded);

  const sourcesList = sources && Object.values(sources);

  const handleToolCallHistoryClose = () => setToolCallHistoryOpen(false);
  const handleToolHistory = (event) => {
    setToolCallHistoryOpen(true);
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <Stack justifyContent="center" width="100%" height="100%" gap="8px">
      {sourcesList?.length > 0 && (
        <Stack border="1px solid" borderColor="colors.hover_on_selected" borderRadius="4px">
          <Stack
            direction="row"
            alignItems="center"
            gap="4px"
            padding="8px"
            onClick={handleSourcesExpandedChange}
            sx={{ cursor: 'pointer', ':hover': { backgroundColor: 'colors.hover_on_primary_bg' } }}>
            <Box sx={{ transform: sourcesExpanded ? 'rotate(180deg)' : 'rotate(90deg)' }}>
              <ArrowIcon />
            </Box>
            <Typography variant="text1_Semibold" textTransform="capitalize">
              Sources
            </Typography>
          </Stack>
          {sourcesExpanded && (
            <Stack padding="16px" gap="8px" sx={{ backgroundColor: 'colors.hover_on_primary_bg' }}>
              {sourcesList?.map((source) => (
                <CustomAnalyticsSourceItemComponent
                  key={source}
                  listId={listId}
                  deepDiveId={deepDiveId}
                  source={source}
                  placement={placement}
                />
              ))}
            </Stack>
          )}
        </Stack>
      )}
      {reasoning && (
        <Stack border="1px solid" borderColor="colors.hover_on_selected" borderRadius="4px">
          <Stack
            direction="row"
            alignItems="center"
            gap="4px"
            padding="8px"
            onClick={handleReasonExpandedChange}
            sx={{ cursor: 'pointer', ':hover': { backgroundColor: 'colors.hover_on_primary_bg' } }}>
            <Box sx={{ transform: ReasonExpanded ? 'rotate(180deg)' : 'rotate(90deg)' }}>
              <ArrowIcon />
            </Box>
            <Typography variant="text1_Semibold">Reasoning</Typography>
            {toolCallHistory && featureFlags.includes('ca_planner') && (
              <MLIconButton
                sx={{ marginLeft: 'auto', height: '24px' }}
                tooltipText="Show tool tree"
                onClick={handleToolHistory}>
                <AccountTreeIcon />
              </MLIconButton>
            )}
          </Stack>
          {ReasonExpanded && (
            <Typography
              variant="text1_Normal"
              color="colors.primary_text"
              textAlign="left"
              sx={{ padding: '8px', backgroundColor: 'colors.hover_on_primary_bg' }}>
              {reasoning}
            </Typography>
          )}
        </Stack>
      )}
      {!reasoning && toolCallHistory && featureFlags.includes('ca_planner') && (
        <Stack width="100%">
          <MLIconButton
            sx={{ marginLeft: 'auto', height: '24px' }}
            tooltipText="Show tool tree"
            onClick={handleToolHistory}>
            <AccountTreeIcon />
          </MLIconButton>
        </Stack>
      )}
      {toolCallHistory && (
        <ModalComponent open={toolCallHistoryOpen} onClose={handleToolCallHistoryClose}>
          <Stack padding="16px">
            <pre>{JSON.stringify(toolCallHistory, null, 2)}</pre>
          </Stack>
        </ModalComponent>
      )}
    </Stack>
  );
}

CustomAnalyticsAnswerComponent.propTypes = {
  value: PropTypes.object,
  listId: PropTypes.number,
  deepDiveId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
