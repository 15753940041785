import { Box, Divider, Stack, Typography } from '@mui/material';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ScreeningFiltersItemComponent, { COLUMN_FILTER } from './ScreeningFiltersItemComponent';

function TableFiltersSectionComponent({
  listId,
  viewId,
  sectionTitle,
  sectionColumns,
  rows,
  filters,
  isLast,
  defaultExpand
}) {
  const [expanded, setExpanded] = useState(defaultExpand);

  const handleExpandedChange = () => setExpanded(!expanded);

  const numFiltersSelected = sectionColumns.filter((column) => filters?.[column.id])?.length;
  const columnsFromFilters = sectionColumns.filter(
    (column) => COLUMN_FILTER[column.id] || COLUMN_FILTER[column.data_type]
  );
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        alignItems="center"
        gap="4px"
        onClick={handleExpandedChange}
        sx={{ cursor: 'pointer', padding: '16px 24px' }}>
        <Box sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(90deg)' }}>
          <ArrowIcon />
        </Box>
        <Typography variant="text1_Semibold" textTransform="capitalize">
          {`${sectionTitle} ${numFiltersSelected ? `(${numFiltersSelected})` : ''}`}
        </Typography>
      </Stack>
      {expanded && (
        <Stack
          direction="column"
          padding="12px 16px 24px 16px"
          gap="16px"
          sx={{ backgroundColor: 'colors.gray_bg' }}>
          {columnsFromFilters.map((column, index) => (
            <React.Fragment key={column.id}>
              <ScreeningFiltersItemComponent
                title={column.title}
                listId={listId}
                id={column.id}
                rows={rows}
                viewId={viewId}
                filter={filters?.[column.id]}
                data_type={column.data_type}
              />
              {index < columnsFromFilters.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Stack>
      )}
      {!isLast && <Divider />}
    </Stack>
  );
}

TableFiltersSectionComponent.propTypes = {
  listId: PropTypes.number,
  sectionTitle: PropTypes.string,
  sectionColumns: PropTypes.array,
  rows: PropTypes.array,
  filters: PropTypes.object,
  isLast: PropTypes.bool,
  defaultExpand: PropTypes.bool,
  viewId: PropTypes.string
};

export default TableFiltersSectionComponent;
