import { Stack, Typography } from '@mui/material';
import { WORK_FLOW_STEP_TYPES } from 'constants/workflows';
import MLInlineStack from 'design-system/MLInlineStack';
import PropTypes from 'prop-types';
import React from 'react';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkflowFromStepMetaComponent({ step }) {
  const { data, parentId } = step;
  const { Icon: StepIcon, color, stepIndex, action, queryIndex } = data;
  const { label } = WORK_FLOW_STEP_TYPES[action];
  return (
    <MLInlineStack key={step.step_id}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '24px',
          height: '24px',
          borderRadius: '4px',
          color,
          backgroundColor: applyThemeColorOpacity(color, 12)
        }}>
        <StepIcon />
      </Stack>
      <Typography variant="text1_Bold" color={color}>
        {`${stepIndex + 1}${parentId ? '.' : ''}${parentId ? queryIndex + 1 : ''} ${label}`}
      </Typography>
    </MLInlineStack>
  );
}

WorkflowFromStepMetaComponent.propTypes = {
  step: PropTypes.object
};

export default WorkflowFromStepMetaComponent;
