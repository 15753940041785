import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { moveFolderResourceAction } from 'actions/folder-resources/move-folder-resource-action';
import ModalComponent from 'components/modal/ModalComponent';
import { RESEARCH_TYPES } from 'constants/researches';
import { TILE_TYPE } from 'constants/tiles';
import { TOAST_TYPES } from 'constants/toasts';
import { useAppState } from 'hooks/state-context';
import HomePageIcon from 'icons/HomePageIcon';
import WarningIcon from 'icons/WarningIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExploreListByListId } from 'selectors/explore';
import { getAllFolders, getFolderCollaborateInfo } from 'selectors/folders';
import { getPersonById } from 'selectors/persons';
import { getRunById } from 'selectors/runs';

export default function MoveResearchToFolderDialog({
  open,
  recordId,
  recordType,
  onClose,
  currentFolderId
}) {
  const dispatch = useDispatch();
  const { state } = useAppState();
  const toastRef = useRef();
  const { addToast, cancelToast } = useAppUtils();

  const [newFolder, setNewFolder] = useState(-1);

  const folders = useSelector(getAllFolders);
  const leadershipPerson = getPersonById(state, recordId);
  const explore = useSelector((reduxState) => getExploreListByListId(reduxState, recordId));
  const deepDive = getRunById(state, recordId);
  const {
    resourceUsers: originFolderUsers,
    resourceOrgs: originFolderOrgs,
    resourceTeams: originFolderTeams
  } = useSelector((reduxState) => getFolderCollaborateInfo(reduxState, currentFolderId));
  const {
    resourceUsers: targetFolderUsers,
    resourceOrgs: targetFolderOrgs,
    resourceTeams: targetFolderTeams
  } = useSelector((reduxState) => getFolderCollaborateInfo(reduxState, newFolder));

  const isOriginFolderShared =
    originFolderUsers?.length > 0 || originFolderOrgs?.length > 0 || originFolderTeams?.length > 0;
  const isTargetFolderShared =
    targetFolderUsers?.length > 0 || targetFolderOrgs?.length > 0 || targetFolderTeams?.length > 0;

  const research = useMemo(() => {
    const result = {};
    result.id = recordId;
    result.type = recordType;
    if (recordType === TILE_TYPE.PERSON) {
      result.name = leadershipPerson.name;
      result.folderId = leadershipPerson.folderId;
      result.Icon = RESEARCH_TYPES.LEADERSHIP.Icon;
    } else if (recordType === TILE_TYPE.DISCOVERY) {
      result.name = explore.name;
      result.folderId = explore.folderId;
      result.Icon = RESEARCH_TYPES.EXPLORE.Icon;
    } else {
      result.name = deepDive.name;
      result.folderId = deepDive.folderId;
      result.Icon = RESEARCH_TYPES.DEEP_DIVE.Icon;
    }
    return result;
  }, [recordId, recordType, leadershipPerson, explore, deepDive]);

  const closeDialog = () => {
    onClose();
  };

  const handleChangeFolder = (event) => {
    event.stopPropagation();
    setNewFolder(event.target.value);
  };

  const handleMoveResearch = () => {
    toastRef.current = addToast(
      `Moving ${research.name} to ${
        newFolder !== -1 && newFolder !== '-1' ? folders[newFolder]?.name : 'Home Page'
      }`,
      TOAST_TYPES.INFO
    );
    dispatch(
      moveFolderResourceAction(
        research.id,
        research.type,
        currentFolderId,
        newFolder === -1 || newFolder === '-1' ? null : newFolder,
        (isSucceed) => {
          cancelToast(toastRef.current);
          toastRef.current = null;
          const toastMessage = isSucceed
            ? `${research.name} moved successfully to ${
                newFolder !== -1 && newFolder !== '-1' ? folders[newFolder]?.name : 'Home Page'
              }`
            : `${research.name} failed to moved to ${
                newFolder !== -1 && newFolder !== '-1' ? folders[newFolder]?.name : 'Home Page'
              }`;
          const id = addToast(toastMessage, isSucceed ? TOAST_TYPES.SUCCESS : TOAST_TYPES.ERROR);
          setTimeout(() => cancelToast(id), 4000);
        }
      )
    );
    closeDialog();
  };

  return (
    <ModalComponent
      title="Move Research"
      open={open}
      onClose={closeDialog}
      maxWidth={738}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="contained" disabled={!newFolder} onClick={handleMoveResearch}>
            Move
          </Button>
        </Stack>
      }>
      <Stack direction="row" alignItems="center" gap="8px" sx={{ padding: '16px' }}>
        <Typography variant="text1_Normal">{`To which folder do you want to move`}</Typography>
        <research.Icon />
        <Typography variant="text1_Semibold">{research.name}</Typography>
      </Stack>
      {isOriginFolderShared && (
        <Stack direction="row" alignItems="flex-start" gap="4px" sx={{ padding: '0 16px' }}>
          <WarningIcon size={16} />
          <Typography variant="text2_Normal" color="colors.secondary_text">
            {`The current folder, "${folders[currentFolderId]?.name}", has collaborators. Moving to other folders might revoke access to the folder's collaborators`}
          </Typography>
        </Stack>
      )}
      {isTargetFolderShared && (
        <Stack direction="row" alignItems="flex-start" gap="4px" sx={{ padding: '0 16px' }}>
          <WarningIcon size={16} />
          <Typography variant="text2_Normal" color="colors.secondary_text">
            {`The target folder, "${folders[newFolder]?.name}", has collaborators. Moving to that folder will grant access to the folder's collaborators`}
          </Typography>
        </Stack>
      )}
      <Box sx={{ padding: '16px' }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={research.folderId || -1}
            name="radio-buttons-group"
            value={newFolder}
            onChange={handleChangeFolder}>
            <FormControlLabel
              value={-1}
              control={<Radio size="small" />}
              label={
                <Stack direction="row" alignItems="center" gap="4px">
                  <HomePageIcon />
                </Stack>
              }
            />
            {folders &&
              Object.values(folders).map((folder) => (
                <FormControlLabel
                  key={folder.id}
                  value={folder.id}
                  control={<Radio size="small" />}
                  label={
                    <Typography variant="text1_Normal" color="colors.primary_text">
                      {folder.name}
                    </Typography>
                  }
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </ModalComponent>
  );
}

MoveResearchToFolderDialog.propTypes = {
  open: PropTypes.bool,
  recordId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recordType: PropTypes.oneOf(Object.values(TILE_TYPE)),
  onClose: PropTypes.func,
  currentFolderId: PropTypes.number
};
