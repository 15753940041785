import { Stack, Typography } from '@mui/material';
import CustomAvatar from 'components/tile/CustomAvatar';
import PropTypes from 'prop-types';
import React from 'react';

function RenderUserOption(props) {
  const thisUser = props?.users?.find((user) => user.username === props.option);
  return (
    <li {...props}>
      <Stack direction="row" gap="8px" alignItems="center" width="100%">
        {props.users && (
          <CustomAvatar name={thisUser.username} pictureUrl={thisUser.picture} size={24} />
        )}
        <Typography variant="text1_Normal" color="colors.primary_text">
          {props.option}
        </Typography>
      </Stack>
    </li>
  );
}

RenderUserOption.propTypes = {
  users: PropTypes.object,
  option: PropTypes.string
};

export default RenderUserOption;
