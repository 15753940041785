import { Button } from '@mui/material';
import { submitDomainReportAction } from 'actions/explore/submit-domain-report-action';
import RefreshIcon from 'icons/RefreshIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestDomainReportByListId } from 'selectors/domain-reports';
import { getCompanyMetasByListId, getExploreFiltersById } from 'selectors/explore';
import { getExploreFilteredCompanies } from 'selectors/explore-table-ui';

function DomainReportButton({ listId, status }) {
  const dispatch = useDispatch();
  const companyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const filteredListMetaIds = useSelector((state) => getExploreFilteredCompanies(state, listId));
  const currentFilteredCompanies = companyMetas.filter((company) =>
    filteredListMetaIds.includes(company.company_list_meta_id)
  );
  const currentFilters = useSelector((state) => getExploreFiltersById(state, listId));
  const currentFilteredCompanyMetaIds = currentFilteredCompanies.map(
    (company) => company.company_meta_id
  );
  const allCompanyMetaIds = companyMetas.map((company) => company.company_meta_id);
  const domainReport = useSelector((state) => getLatestDomainReportByListId(state, listId));
  const submitDomainReport = () => {
    dispatch(
      submitDomainReportAction({
        listId,
        companyMetaIds: currentFilteredCompanyMetaIds,
        filters: currentFilters,
        allCompanyMetaIds
      })
    );
  };
  if (!domainReport) {
    return (
      <Button id="domain-report-button" variant="contained" onClick={submitDomainReport}>
        Create a report
      </Button>
    );
  }
  return (
    <Button
      id="domain-report-button"
      size="small"
      onClick={submitDomainReport}
      startIcon={<RefreshIcon />}
      disabled={status === 'RUNNING'}>
      {status === 'FAILED' ? 'Retry' : 'Update'}
    </Button>
  );
}

DomainReportButton.propTypes = {
  listId: PropTypes.string.isRequired,
  status: PropTypes.string
};

export default DomainReportButton;
