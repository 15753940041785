import cloneDeep from 'lodash/cloneDeep';
import { getThreadKey, updatePlan } from 'reducer/custom-analytics';
import { getCustomAnalyticsPlanByThreadAndPostId } from 'selectors/custom-analytics';
import { getWorkflowStepAndQueryIndexesByStepId } from 'utils/workflows-utils';

export function removeStepFromPlanAction(listId, workflowId, stepId) {
  return (dispatch, getState) => {
    const threadKey = getThreadKey(listId);
    const plan = getCustomAnalyticsPlanByThreadAndPostId(getState(), threadKey, workflowId);

    if (plan?.steps) {
      const newPlan = cloneDeep(plan);
      const { stepIndex, queryIndex } = getWorkflowStepAndQueryIndexesByStepId(
        stepId,
        newPlan.steps
      );
      if (stepIndex >= 0) {
        const step = newPlan.steps[stepIndex];
        const { function_use: stepInfo } = step;
        const { action_variables: variables } = stepInfo;
        const { queries } = variables;
        queries.splice(queryIndex, 1);
        if (queries.length <= 0) {
          if (stepIndex > 0) {
            newPlan.steps[stepIndex - 1].if_successful =
              stepIndex < newPlan.steps.length - 1 ? newPlan.steps[stepIndex + 1].step_id : 'end';
          }
          newPlan.steps.splice(stepIndex, 1);
        }
        dispatch(updatePlan({ threadKey, postId: workflowId, newPlan }));
      }
    }
  };
}
