import { Button, Stack, Typography } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import PropTypes from 'prop-types';
import React from 'react';

function MessageComponent({ role, text, action, onYes, onNo }) {
  return (
    <Stack
      gap="8px"
      sx={{ padding: '8px', borderRadius: '8px', backgroundColor: 'accent_colors.lavender' }}>
      <Typography variant="text1_Bold" color="colors.text_on_primary">
        {role}
      </Typography>
      {text && <Typography variant="text1_Normal">{text}</Typography>}
      {action && (
        <Typography variant="text2_Normal">
          <pre>{JSON.stringify(action, null, 2)}</pre>
        </Typography>
      )}
      {action && onYes && onNo && (
        <MLInlineStack>
          <Button variant="contained" onClick={onYes}>
            Yes
          </Button>
          <Button variant="outlined" onClick={onNo}>
            No
          </Button>
        </MLInlineStack>
      )}
    </Stack>
  );
}

MessageComponent.propTypes = {
  text: PropTypes.string,
  role: PropTypes.string,
  action: PropTypes.string,
  onYes: PropTypes.func,
  onNo: PropTypes.func
};

export default MessageComponent;
