import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import MLIconButton from 'design-system/MLIconButton';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getAllDiscoveries, getLastDiscoveryIdByExploreList } from 'selectors/discoveries';
import { formatTimestamp } from 'utils/time-utils';

import AdminTabOrgLogo from './AdminTabOrgLogo';
import CopyRunToUserDialog from './CopyRunToUserDialog';
import DownloadRunFilesButton from './DownloadRunFilesButton';

function ExploreTab({ exploreLists, hidden, users }) {
  const navigate = useNavigate();
  const { addToast } = useAppUtils();
  const [selectedListId, setSelectedListId] = useState(null);
  const discoveries = useSelector((state) => getAllDiscoveries(state));

  const onClickDiscovery = (listId) => {
    navigate(`${EXPLORE_PREFIX}/${listId}`);
  };

  const renderParameters = (parameters) => {
    return Object.keys(parameters)
      .filter((param) => parameters[param] && typeof parameters[param] !== 'object')
      .map((param) => (
        <Typography variant="text1_Normal" key={param}>
          <Typography variant="text1_Semibold" sx={{ marginRight: '8px' }}>
            {param}:
          </Typography>
          {parameters[param]}
        </Typography>
      ));
  };

  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center', overflowX: 'scroll' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        {exploreLists &&
          Object.keys(exploreLists)
            .sort((key1, key2) => exploreLists[key2].id - exploreLists[key1].id)
            .map((key) => {
              const list = exploreLists[key];
              const lastDiscoveryId = getLastDiscoveryIdByExploreList(list);
              const discovery = discoveries[lastDiscoveryId];
              return (
                <Stack
                  key={key}
                  direction="row"
                  gap="32px"
                  alignItems="center"
                  sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                  <Button
                    onClick={() => onClickDiscovery(key)}
                    size="small"
                    variant="contained"
                    sx={{ width: '60px' }}>
                    {list?.id ? list.id : key}
                  </Button>
                  <AdminTabOrgLogo logoUrl={discovery?.ownerOrganizationLogoUrl} />
                  <MLIconButton
                    tooltipText="Share discovery with other users"
                    onClick={() => setSelectedListId(list.id)}>
                    <ShareIcon />
                  </MLIconButton>
                  <DownloadRunFilesButton
                    searchId={list.id}
                    recordId={list.id}
                    services={['discovery', 'tagging']}></DownloadRunFilesButton>
                  <Typography>iteration: {list.lastIteration || 0}</Typography>
                  <Typography fontSize={12}>
                    {list.clustersId ? 'Has Clusters' : 'No Clusters'}
                  </Typography>
                  <Typography>
                    {discovery?.timeSubmitted ? formatTimestamp(discovery.timeSubmitted) : 'NA'}
                  </Typography>
                  <Typography variant="paragraph">{users[list.user.id].email}</Typography>
                  <Tooltip
                    title={
                      <Stack>
                        {discovery?.statuses && renderParameters(discovery.statuses)}
                        {discovery?.parameters && renderParameters(discovery.parameters)}
                        {discovery?.parameters?.filters &&
                          renderParameters(
                            Object.values(discovery.parameters.filters)
                              .filter((param) => !param?.labels?.labels)
                              .reduce(
                                (prev, param) => ({
                                  ...prev,
                                  [param]: param?.labels?.join(', ')
                                }),
                                {}
                              )
                          )}
                      </Stack>
                    }>
                    <Typography variant="text1_Semibold">{list.name}</Typography>
                  </Tooltip>
                  <Typography sx={{ marginLeft: 'auto' }}>{discovery?.owner}</Typography>
                </Stack>
              );
            })}
      </Stack>
      {selectedListId && users && (
        <CopyRunToUserDialog
          open={Boolean(selectedListId)}
          onClose={() => {
            setSelectedListId(null);
          }}
          runs={exploreLists}
          selectedRunId={selectedListId}
          type="discovery"
          users={users}
          addToast={addToast}
        />
      )}
    </Stack>
  );
}

export default ExploreTab;

ExploreTab.propTypes = {
  exploreLists: PropTypes.object,
  hidden: PropTypes.bool,
  users: PropTypes.object
};
