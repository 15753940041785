import { Button, Slider, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getSemanticSearchAction } from 'actions/company_lists/get-semantic-search-action';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import SendIcon from 'icons/SendIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getExploreFiltersById } from 'selectors/explore';
import { getUserIsDev } from 'selectors/user';
import { appTheme } from 'theme';

function DescriptionColumnFilter({ listId, onUpdateFilter }) {
  const dispatch = useDispatch();
  const userIsDev = useOldStateSelector(getUserIsDev);
  const existingFilters = useSelector((reduxState) => getExploreFiltersById(reduxState, listId));
  const savedDescription = existingFilters?.description?.data?.searchTerm || '';
  const savedType = existingFilters?.description?.data?.type || 'semantic';

  const [filterType, setFilterType] = React.useState('');
  const [description, setDescription] = useState('');
  const [semanticThreshold, setSemanticThreshold] = useState(0.8);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (_event, newType) => {
    setFilterType(newType);
  };

  useEffect(() => {
    setDescription(savedDescription);
  }, [savedDescription]);

  useEffect(() => {
    setFilterType(savedType);
  }, [savedType]);

  const handleSliderChange = (_event, value) => {
    setSemanticThreshold(value / 100);
    onUpdateFilter({ ...existingFilters?.description?.data, semanticThreshold: value / 100 });
  };

  const runFilter = () => {
    if (filterType === 'semantic') {
      dispatch(
        getSemanticSearchAction(listId, description, (scores, companiesIds) => {
          onUpdateFilter({
            searchTerm: description,
            scores,
            companiesIds,
            semanticThreshold,
            type: filterType
          });
        })
      );
    } else {
      onUpdateFilter({ searchTerm: description, type: filterType });
    }
    setIsSubmitted(true);
  };

  const onKeyDown = (event) => {
    if (event?.key === 'Enter') {
      if (!description || !description.trim().length) {
        onUpdateFilter(null);
        return;
      }
      event.target.blur();
      runFilter();
    }
  };

  const handleTermChange = (event) => {
    setDescription(event.target.value);
    setIsSubmitted(false);
  };

  const toogleButtonStyle = { padding: 0, width: '120px' };

  return (
    <Stack direction="column" gap="8px">
      <Stack direction="row" alignItems="center" gap="8px" marginRight="6px">
        <ToggleButtonGroup value={filterType} exclusive onChange={handleChange} size="small">
          <ToggleButton sx={toogleButtonStyle} value="semantic">
            Semantic
          </ToggleButton>
          <ToggleButton sx={toogleButtonStyle} value="contains">
            Contains
          </ToggleButton>
          <ToggleButton sx={toogleButtonStyle} value="not_contains">
            {"Doesn't Contain"}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <TextField
        value={description}
        multiLine={true}
        autoFocus={false}
        rows={2}
        onChange={handleTermChange}
        onKeyDown={onKeyDown}
        InputProps={{
          endAdornment: (
            <Button onClick={runFilter}>
              <SendIcon />
            </Button>
          )
        }}
        size="small"
        sx={{
          '.MuiInputBase-input': {
            ...(isSubmitted ? appTheme.typography.text1_Bold : appTheme.typography.text1_Normal),
            color: isSubmitted ? 'colors.primary' : 'colors.primary_text'
          }
        }}
      />
      {userIsDev && filterType === 'semantic' && (
        <React.Fragment>
          <Slider
            min={0}
            max={100}
            value={semanticThreshold * 100}
            onChange={handleSliderChange}
            size="small"
          />
          <span>{semanticThreshold}</span>
        </React.Fragment>
      )}
    </Stack>
  );
}

DescriptionColumnFilter.propTypes = {
  listId: PropTypes.number,
  onUpdateFilter: PropTypes.func
};

export default DescriptionColumnFilter;
