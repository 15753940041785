import { Link, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function FieldWrapper({ href, startAdornment, endAdornment, children }) {
  const inner = (
    <Stack direction="row" gap="4px" alignItems="center" minWidth="100px">
      {startAdornment && startAdornment}
      {children}
      {endAdornment && endAdornment}
    </Stack>
  );
  if (href) {
    return (
      <Link href={href} underline="none" target="_blank" rel="noopener" minWidth="132px">
        {inner}
      </Link>
    );
  }
  return inner;
}

function QuickViewFieldValue({
  field,
  value,
  renderVal,
  direction,
  href,
  startAdornment,
  endAdornment,
  ...args
}) {
  const wrapperProps = { href, startAdornment, endAdornment };
  if (direction === 'column') {
    return (
      <Stack {...args}>
        <FieldWrapper {...wrapperProps}>
          <Typography variant="text1_Semibold">{field}</Typography>
        </FieldWrapper>
        {renderVal && renderVal(value) ? (
          renderVal(value)
        ) : (
          <Typography variant="text1_Normal">{value || 'Unknown'}</Typography>
        )}
      </Stack>
    );
  }
  return (
    <Stack direction="row" width="256px">
      <FieldWrapper {...wrapperProps}>
        <Typography variant="text1_Bold">{field}</Typography>
      </FieldWrapper>
      <Typography variant="text1_Normal">{value}</Typography>
    </Stack>
  );
}

FieldWrapper.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node
};

QuickViewFieldValue.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  direction: PropTypes.string,
  renderVal: PropTypes.func,
  href: PropTypes.string,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node
};

QuickViewFieldValue.defaultProps = {
  direction: 'column'
};

export default QuickViewFieldValue;
