import { useTheme } from '@mui/material';
import React from 'react';

export default function AddFileIcon() {
  const appTheme = useTheme();
  return (
    <svg height="24" viewBox="0 -960 960 960" width="24">
      <path
        d="M450.001-250.001h59.998v-120h120v-59.998h-120v-120h-59.998v120h-120v59.998h120v120Zm-197.692 150q-30.308 0-51.308-21t-21-51.308v-615.382q0-30.308 21-51.308t51.308-21h317.692l209.998 209.998v477.692q0 30.308-21 51.308t-51.308 21H252.309Zm287.692-520V-800H252.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v615.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h455.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-447.692H540.001ZM240-800v179.999V-800v640V-800Z"
        fill={appTheme.vars.palette.colors.primary}
      />
    </svg>
  );
}
