import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Link,
  Stack,
  Typography
} from '@mui/material';
import LocationIcon from 'icons/LocationIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

export default function B2DUserSummaryCard({ rowData }) {
  return (
    <Card sx={{ height: 'calc(100% - 52px)' }}>
      <CardContent>
        <Stack direction="column" gap="16px" padding="24px">
          <Stack direction="row" gap="16px" alignItems="center">
            <CardMedia
              sx={{ width: '40px', alignSelf: 'flex-start' }}
              component="img"
              image={rowData.metadata.value.profile_image}
              alt={rowData.display_name.value}
            />
            <Divider orientation="vertical" flexItem sx={{ borderColor: 'colors.ui_border' }} />
            <Stack gap="4px">
              <Link
                href={rowData.metadata.value.link}
                underline="none"
                target="_blank"
                rel="noreferrer">
                <Typography gutterBottom variant="h2_Bold" color="colors.link">
                  {rowData.display_name.value}
                </Typography>
              </Link>
              {rowData.metadata.value.location && (
                <Stack direction="row" gap="8px">
                  <LocationIcon />
                  <Typography variant="text1_Normal" color="colors.primary_text">
                    {rowData.metadata.value.location}
                  </Typography>
                </Stack>
              )}
              <Stack>
                <Typography variant="text1_Semibold" color="colors.primary_text">
                  Badge Points
                </Typography>
                <Stack direction="row" gap="8px">
                  {Object.keys(rowData.metadata.value.badge_counts).map((badge) => (
                    <Typography
                      key={badge}
                      variant="text1_Normal"
                      color="colors.primary_text">{`${badge}: ${rowData.metadata.value.badge_counts[badge]}`}</Typography>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
          {rowData.metadata.value.tags.length > 0 && (
            <Stack gap="12px">
              <Typography variant="text1_Bold" color="colors.primary_text" marginBottom="16px">
                {'Tags:'}
              </Typography>
              <Stack direction="row" gap="12px" flexWrap="wrap">
                {rowData.metadata.value.tags.map((tag) => (
                  <Chip
                    key={tag.name}
                    label={`${tag.name}  ${tag.count}`}
                    sx={{
                      ...appTheme.typography.text1_Medium
                    }}
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {rowData.metadata.value.questions.length > 0 && (
            <Stack gap="12px">
              <Typography variant="text1_Bold" color="colors.primary_text" marginBottom="16px">
                Recent Interactions:
              </Typography>
              <Stack gap="12px">
                {rowData.metadata.value.questions.map((question, index) => (
                  <Link
                    key={index}
                    href={question.link}
                    underline="none"
                    target="_blank"
                    rel="noreferrer">
                    <Typography variant="text1_Semibold" color="colors.link">
                      {question.title}
                    </Typography>
                  </Link>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

B2DUserSummaryCard.propTypes = {
  rowData: PropTypes.object
};
