import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { idToSuggestionWithoutName } from 'utils/assistant/text';

import Suggestion from './Suggestion';

const TRIGGER_CHAR = '/';
const PLACEHOLDER = `Describe your full request, type ${TRIGGER_CHAR} to add context `;

const renderSuggestion = ({ id, display }, _search, _highlightedDisplay, _index, focused) => {
  return <Suggestion id={id} display={display} focused={focused} />;
};

// Basic inline styles for the MentionsInput (you can also load a CSS file)
const mentionsInputStyle = (theme) => ({
  control: {
    ...theme.typography.text1_Normal
  },
  highlighter: {
    // Make the highlighter text transparent so it doesn’t show up on top.
    color: 'transparent',
    overflow: 'hidden'
  },
  input: {
    margin: 0,
    outline: 0,
    border: 0
  },
  suggestions: {
    list: {
      borderRadius: '8px',
      padding: '4px',
      boxShadow: '0px 2px 7px 0px rgba(66, 82, 110, 0.41)',
      maxHeight: '400px',
      overflowY: 'scroll'
    },
    item: {
      margin: '4px'
    }
  }
});

const mentionStyle = (theme) => ({
  backgroundColor: theme.vars.palette.accent_colors.bubble,
  borderRadius: '4px'
});

const AssistantTextField = ({ updateQuestion, handleSubmit, options }) => {
  const theme = useTheme();
  const [value, setValue] = useState('');
  const [mentions, setMentions] = useState([]);

  // When the input changes, we update the value.
  const handleChange = (_event, newValue, _text, event_mentions) => {
    setValue(newValue);
    setMentions(event_mentions.map(({ id }) => idToSuggestionWithoutName(id)));
  };

  useEffect(() => {
    updateQuestion(value, mentions);
  }, [value, mentions, updateQuestion]);

  return (
    <MentionsInput
      id="assistant-prompt-text-field"
      value={value}
      onChange={handleChange}
      forceSuggestionsAboveCursor
      placeholder={PLACEHOLDER}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSubmit();
          setValue('');
        }
      }}
      style={mentionsInputStyle(theme)}>
      <Mention
        trigger={TRIGGER_CHAR}
        data={options}
        displayTransform={(_id, display) => {
          return ` ${display} `;
        }}
        renderSuggestion={renderSuggestion}
        style={mentionStyle(theme)}
        appendSpaceOnAdd
      />
    </MentionsInput>
  );
};

AssistantTextField.propTypes = {
  updateQuestion: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AssistantTextField;
