import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import UploadIcon from 'icons/UploadIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FileDrop } from 'react-file-drop';

function FileDropComponentOnTable({ handleFiles, children, isFullScreen, open }) {
  const [dragging, setDragging] = useState(false);

  const onDrop = (files) => {
    handleFiles?.(files);
    setDragging(false);
  };

  const bottomGap = isFullScreen ? 0 : 24;
  if (!open) {
    return children;
  }
  return (
    <Box position="relative" height="100%">
      <FileDrop
        onDrop={onDrop}
        onDragOver={() => setDragging(true)}
        onDragLeave={() => setDragging(false)}
        style={{ position: 'relative' }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="24px"
          sx={{
            borderColor: dragging ? 'colors.link' : 'colors.hover_on_selected',
            borderRadius: '4px'
          }}>
          {children}
          <Stack
            height={`calc(100% - ${bottomGap}px)`}
            borderRadius="4px"
            border={'3px dashed'}
            bottom={bottomGap}
            right={0}
            left={0}
            sx={{ borderColor: 'colors.link' }}
            position="absolute"
            alignItems="center"
            justifyContent="center"
            visibility={dragging ? 'visible' : 'hidden'}>
            <Box
              backgroundColor="rgba(214, 220, 237, 0.8)"
              height="100%"
              width="100%"
              position="absolute"
              sx={{
                backdropFilter: 'blur(2px)'
              }}></Box>
            <UploadIcon
              style={{ zIndex: 1, color: 'rgba(160, 169, 189, 1)', width: '64px', height: '64px' }}
            />
            <Typography color="rgba(160, 169, 189, 1)" variant="h3_Bold" zIndex={2}>
              Drop files here to add more
            </Typography>
          </Stack>
        </Stack>
      </FileDrop>
    </Box>
  );
}

FileDropComponentOnTable.propTypes = {
  handleFiles: PropTypes.func,
  children: PropTypes.any,
  isFullScreen: PropTypes.bool,
  open: PropTypes.bool
};

export default FileDropComponentOnTable;
