import {
  Column,
  Company,
  MentionId,
  MentionItem,
  Suggestion,
  SuggestionIdType,
  SuggestionWithoutName,
  SupportingResource
} from 'models/assistant/types';

export const suggestionToId = (suggestion: Suggestion): MentionId =>
  `${suggestion.suggestionIdType}:${suggestion.id}`;

export const idToSuggestionWithoutName = (id: MentionId): SuggestionWithoutName => {
  const [suggestionIdType, idString] = id.split(':');
  return {
    suggestionIdType: suggestionIdType as SuggestionIdType,
    id: idString
  };
};

export const generateSuggestionSearchFilter = (
  searchString: string
): ((suggestion: Suggestion) => boolean) => {
  if (!searchString) {
    return () => true;
  }
  return (suggestion: Suggestion) => suggestion.name.toLowerCase().startsWith(searchString);
};

type OptionsFromCompaniesColumnsSupportingResourcesParams = {
  companies: Company[];
  columns: Column[];
  supportingResources: SupportingResource[];
};

export const optionsFromCompaniesColumnsSupportingResources = ({
  companies,
  columns,
  supportingResources
}: OptionsFromCompaniesColumnsSupportingResourcesParams): MentionItem[] => {
  const companySuggestions = companies.map(
    (company) =>
      ({
        suggestionIdType: 'company_meta_id',
        id: company.id,
        name: company.name
      } as Suggestion)
  );
  const columnSuggestions = columns.map(
    (column) =>
      ({
        suggestionIdType: 'column_id',
        id: column.id,
        name: column.name
      } as Suggestion)
  );
  const supportingResourceSuggestions = supportingResources.map(
    (supportingResource) =>
      ({
        suggestionIdType: 'supporting_resource_id',
        id: supportingResource.id,
        name: supportingResource.name
      } as Suggestion)
  );
  return [...companySuggestions, ...columnSuggestions, ...supportingResourceSuggestions].map(
    (item: Suggestion) => ({ id: suggestionToId(item), display: item.name })
  );
};
