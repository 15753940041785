import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { limitLength } from 'utils/string-utils';

import TableRowLoading from './TableRowLoading';

export default function RenderDescriptionWrap({ value, color, variant }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const helper = (event) => {
    const targetElement = event.target;
    if (!showTooltip && targetElement.scrollWidth > targetElement.clientWidth) {
      setShowTooltip(true);
    } else if (showTooltip) {
      setShowTooltip(false);
    }
  };
  if (!value) {
    return (
      <Typography variant={variant} color={color} noWrap display="block">
        {value}
      </Typography>
    );
  }
  const loading = value?.loading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  return (
    <Tooltip title={value?.text} arrow={true} open={showTooltip}>
      <Typography
        textAlign="left"
        variant={variant}
        color={value?.color || color}
        noWrap
        onMouseEnter={helper}
        onMouseLeave={() => setShowTooltip(false)}
        display="block"
        sx={{
          whiteSpace: 'pre-wrap',
          padding: '8px',
          overflowY: 'ellipsis'
        }}>
        {limitLength(value?.text, 350)}
      </Typography>
    </Tooltip>
  );
}

RenderDescriptionWrap.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderDescriptionWrap.defaultProps = {
  color: 'colors.primary_text',
  variant: 'text1_Normal'
};
