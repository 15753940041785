import { Divider, ListItemIcon, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { updateQueryInPlanAction } from 'actions/custom-analytics/update-query-in-plan-action';
import { addParallelStepToPlanAction } from 'actions/workflows/add-parallel-step-to-plan-action';
import { removeStepFromPlanAction } from 'actions/workflows/remove-step-from-plan-action';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import AddIcon from 'icons/AddIcon';
import ArrowIcon from 'icons/ArrowIcon';
import DeleteIcon from 'icons/DeleteIcon';
import MenuIcon from 'icons/MenuIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { appTheme } from 'theme';

import WorkflowFormAddStepMenu from './WorkflowFormAddStepMenu';

function WorkflowFromStepInfoComponent({ listId, workflowId, step }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [addNewStepAnchorEl, setAddNewStepAnchorEl] = useState(null);

  const { data } = step;
  const { query, stepId } = data;

  const [isHover, setIshover] = useState(false);
  const stepQuery = typeof query === 'object' ? query.user_question : query;

  const onValueChange = (event) => {
    dispatch(
      updateQueryInPlanAction(listId, workflowId, stepId, { query: event.target.value || '' })
    );
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const closeNewStepMenu = () => {
    setAddNewStepAnchorEl(null);
    closeMenu();
  };

  const handleAddParallelStep = () => {
    dispatch(addParallelStepToPlanAction(listId, workflowId, step.id));
    closeMenu();
  };

  const handleDeleteStep = () => {
    dispatch(removeStepFromPlanAction(listId, workflowId, step.id));
    closeMenu();
  };

  return (
    <React.Fragment>
      <MLInlineStack
        onMouseEnter={() => setIshover(true)}
        onMouseLeave={() => setIshover(false)}
        justifyContent="space-between"
        sx={{ minHeight: '30px' }}>
        {isHover || stepQuery?.length <= 0 ? (
          <TextField
            value={stepQuery}
            onChange={onValueChange}
            placeholder="What this step should do?"
            sx={{
              flex: 1,
              '.MuiInputBase-input': { padding: '8px 16px', ...appTheme.typography.text1_Normal }
            }}
          />
        ) : (
          <Typography variant="text1_Normal" color="colors.primary_text">
            {stepQuery}
          </Typography>
        )}
        <MLIconButton
          sx={{ visibility: isHover ? 'visible' : 'hidden' }}
          onClick={(event) => setAnchorEl(event.target)}>
          <MenuIcon />
        </MLIconButton>
      </MLInlineStack>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem onClick={(event) => setAddNewStepAnchorEl(event.target)}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <Typography variant="text1_Normal" color="colors.primary_text">
            Add new step below
          </Typography>
          <ListItemIcon sx={{ transform: 'rotate(90deg)', justifyContent: 'center' }}>
            <ArrowIcon />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={handleAddParallelStep}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <Typography variant="text1_Normal" color="colors.primary_text">
            Add parallel step below
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteStep}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography variant="text1_Normal" color="colors.primary_text">
            Delete
          </Typography>
        </MenuItem>
      </Menu>
      <WorkflowFormAddStepMenu
        listId={listId}
        anchorEl={addNewStepAnchorEl}
        onClose={closeNewStepMenu}
        stepId={stepId}
      />
    </React.Fragment>
  );
}

WorkflowFromStepInfoComponent.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workflowId: PropTypes.number,
  step: PropTypes.object
};

export default WorkflowFromStepInfoComponent;
