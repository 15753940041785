import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import GroupsIcon from '@mui/icons-material/Groups';
import LanguageIcon from '@mui/icons-material/Language';
import LinkIcon from '@mui/icons-material/Link';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import QuestionAnswerRounded from '@mui/icons-material/QuestionAnswerRounded';
import TableChartIcon from '@mui/icons-material/TableChart';

import { DISCOVERY_TYPES } from './discovery';

export const OPTIONS = Object.freeze({
  COMPANIES: 'COMPANIES',
  COLUMN: 'COLUMN',
  ASK: 'ASK'
});
export const COMPANIES_OPTIONS = Object.freeze({
  ...Object.keys(DISCOVERY_TYPES).reduce((acc, key) => {
    if (key !== DISCOVERY_TYPES.PARTNERS) {
      acc[key] = key;
    }
    return acc;
  }, {})
});

export const CA_OPTIONS = Object.freeze({
  COLUMN: 'COLUMN',
  KNOWLEDGE: 'KNOWLEDGE'
});

export const OPTION_OBJECTS = Object.freeze({
  [OPTIONS.COMPANIES]: {
    label: 'Add Companies',
    adornment: () => 'Find companies',
    icon: <AddBusinessRoundedIcon />
  },
  [OPTIONS.COLUMN]: {
    label: 'Add Column',
    adornment: (companies) =>
      `Add column${companies ? ` for ${Object.keys(companies).length} selected companies` : ''}`,
    icon: <PlaylistAddRoundedIcon sx={{ fontSize: '2rem' }} />,
    placeholder: 'Type a question to create a column, or select one from the column library...'
  },
  [OPTIONS.ASK]: {
    label: 'Ask',
    adornment: () => 'Ask a question',
    icon: <QuestionAnswerRounded />,
    placeholder: 'Ask anything...'
  }
});

export const COMPANIES_OPTION_OBJECTS = Object.freeze({
  [COMPANIES_OPTIONS.TEXT]: {
    label: 'Query',
    adornment: 'via query',
    icon: <ManageSearchIcon />,
    placeholder: 'Type a description that defines the companies you are looking for...'
  },
  [COMPANIES_OPTIONS.PEERS]: {
    label: 'Peers',
    adornment: 'via peers',
    icon: <GroupsIcon />
  },
  [COMPANIES_OPTIONS.URL]: {
    label: 'From URL',
    value: 'url',
    adornment: 'from URL',
    icon: <LinkIcon />,
    placeholder: 'Enter a URL...'
  }
});

export const CA_OPTION_OBJECTS = Object.freeze({
  [CA_OPTIONS.COLUMN]: {
    label: 'Use Columns',
    adornment: (columns) => {
      if (!columns || !columns.length) {
        return '';
      }
      if (columns.length === 1) {
        return `using column "${columns}"`;
      }
      return `using ${columns.length} columns`;
    },
    icon: <TableChartIcon />
  },
  [CA_OPTIONS.KNOWLEDGE]: {
    label: 'Use Knowledge',
    adornment: (knowledge) => {
      if (!knowledge) {
        return '';
      }
      return `using knowledge "${knowledge}"`;
    },
    icon: <LanguageIcon />
  }
});

export const PROMPT_DEFAULT_SOURCES = [
  { id: 'web', name: 'Web' },
  { id: 'table', name: 'Table' }
];

export const PROMPT_SELECT_STYLE = {
  width: 'auto',
  maxWidth: '300px',
  height: '32px',
  textAlign: 'left',
  backgroundColor: 'colors.primary_bg',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'colors.ui_border'
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'colors.disabled_text',
    borderWidth: '1px'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'colors.disabled_text'
  }
};
