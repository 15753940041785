import RechartsBox from 'components/elements/RechartsBox';
import PropTypes from 'prop-types';
import {
  Cell,
  LabelList,
  ReferenceArea,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts';
import { appTheme } from 'theme';

import ClustersScatterTooltipContent from './ClustersScatterTooltipContent';

function ClustersScatterPlotComponent({
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  showZoomBox,
  data,
  zoomArea,
  currentClusterLabel,
  onDataPointClick,
  headerHeight
}) {
  return (
    <RechartsBox height={`calc(100vh - ${headerHeight + 24 + 24 + 8}px)`}>
      <ScatterChart
        style={{
          fontSize: appTheme.typography.text1_Normal.fontSize,
          color: appTheme.vars.palette.colors.ui_border,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.text1_Normal.fontWeight
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        cursor="crosshair">
        <XAxis
          type="number"
          dataKey="x"
          name={'x'}
          tick={false}
          domain={['dataMin', 'dataMax']}
          padding={{ left: 60, right: 60 }}
          axisLine={{ visibility: 'hidden' }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name={'y'}
          tick={false}
          domain={['dataMin', 'dataMax']}
          padding={{ bottom: 60, top: 60 }}
          axisLine={{ visibility: 'hidden' }}
        />
        <ZAxis type="number" dataKey="size" range={[400, 2200]} name="size" unit=" companies" />
        {showZoomBox && (
          <ReferenceArea
            ifOverflow="visible"
            x1={zoomArea?.x1}
            x2={zoomArea?.x2}
            y1={zoomArea?.y1}
            y2={zoomArea?.y2}
          />
        )}
        <Tooltip cursor={false} content={<ClustersScatterTooltipContent />} />
        <Scatter
          isAnimationActive={false}
          data={data}
          onClick={onDataPointClick}
          cursor={onDataPointClick ? 'pointer' : 'auto'}>
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                entry.label === currentClusterLabel
                  ? appTheme.vars.palette.colors.hover_on_selected
                  : appTheme.vars.palette.colors.hover_on_gray_bg
              }
              stroke={
                entry.label === currentClusterLabel
                  ? appTheme.vars.palette.colors.disabled_text
                  : appTheme.vars.palette.colors.hover_on_selected
              }
              strokeWidth={2}
              filter={
                entry.label === currentClusterLabel
                  ? 'drop-shadow(0px 0px 4px rgba(132, 143, 162, 0.47))'
                  : null
              }
            />
          ))}
          <LabelList
            dataKey="label"
            position="bottom"
            fontSize={12}
            fill={appTheme.vars.palette.colors.disabled_text}
            fontWeight={600}
            pointerEvents="none"
            formatter={(value) => {
              const limit = 30;
              if (value.length < limit) return value;
              return `${value.substring(0, limit)}...`;
            }}
            style={{ userSelect: 'none' }}
            stroke={null}
          />
        </Scatter>
      </ScatterChart>
    </RechartsBox>
  );
}

ClustersScatterPlotComponent.propTypes = {
  currentClusterLabel: PropTypes.string,
  handleMouseDown: PropTypes.func,
  handleMouseMove: PropTypes.func,
  handleMouseUp: PropTypes.func,
  showZoomBox: PropTypes.bool,
  data: PropTypes.array,
  zoomArea: PropTypes.object,
  onDataPointClick: PropTypes.func,
  headerHeight: PropTypes.number
};

export default ClustersScatterPlotComponent;
