import { Box, Button, Stack, Typography } from '@mui/material';
import PeerFinanceCard from 'components/discovery/PeerFinanceCard';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { formatRevenueForPeers } from 'utils/finance-utils';

import TableRowLoading from './TableRowLoading';

export default function RenderPeerRevenuesV2({ value, color }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { revenue } = value || {};
  const isEstimated = true;

  const finalValue = useMemo(() => {
    return formatRevenueForPeers(revenue);
  }, [revenue]);

  const loading = value?.loading;
  if (loading) {
    return <TableRowLoading></TableRowLoading>;
  }
  if (!finalValue) return null;

  return (
    <Stack width="100%" padding={0}>
      <Button
        onClick={handleClick}
        fullWidth
        disableRipple
        sx={{
          '&:hover': { backgroundColor: 'unset' }
        }}>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          onMouseEnter={() => setShowTooltip(!showTooltip)}
          onMouseLeave={() => setShowTooltip(false)}>
          <Typography variant="text1_Normal" color={color} noWrap display="block">
            {finalValue}
          </Typography>
          {revenue && (
            <Box
              sx={{
                marginLeft: 'auto',
                backgroundColor: 'colors.hover_on_gray_bg',
                borderRadius: '2px',
                padding: '6px'
              }}>
              <Typography color="colors.primary_text" variant="h4">
                {isEstimated ? 'E' : 'A'}
              </Typography>
            </Box>
          )}
        </Stack>
      </Button>
      {open && <PeerFinanceCard open={open} handleClose={handleClose} data={value} />}
    </Stack>
  );
}

RenderPeerRevenuesV2.propTypes = {
  value: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string
};

RenderPeerRevenuesV2.defaultProps = {
  color: 'colors.primary_text',
  variant: 'text1_Normal'
};
