import PropTypes from 'prop-types';
import React from 'react';

export function WorkflowIcon({ color }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 7H7L7 10H17V7ZM7 6C6.44772 6 6 6.44772 6 7V10C6 10.5523 6.44772 11 7 11H17C17.5523 11 18 10.5523 18 10V7C18 6.44772 17.5523 6 17 6H7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 14H7L7 17H17V14ZM7 13C6.44772 13 6 13.4477 6 14V17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V14C18 13.4477 17.5523 13 17 13H7Z"
        fill={color}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5 13.5V11H12.5V13.5H11.5Z" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17C12.2761 17 12.5 17.2239 12.5 17.5V19.5C12.5 19.7761 12.2761 20 12 20C11.7239 20 11.5 19.7761 11.5 19.5V17.5C11.5 17.2239 11.7239 17 12 17Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C12.2761 4 12.5 4.22386 12.5 4.5V6.5C12.5 6.77614 12.2761 7 12 7C11.7239 7 11.5 6.77614 11.5 6.5V4.5C11.5 4.22386 11.7239 4 12 4Z"
        fill={color}
      />
    </svg>
  );
}

WorkflowIcon.propTypes = {
  color: PropTypes.string
};

WorkflowIcon.defaultProps = {
  color: 'currentColor'
};
