import BaseClient from './BaseClient';
import { getQueryParamsString } from './Utils';

const URL_PREFIX = 'custom_analytics';

export default class CustomAnalyticsClient extends BaseClient {
  fetchAdminCustomAnalytics(params) {
    return this.get(`${URL_PREFIX}?${getQueryParamsString(params)}`);
  }

  queryCustomAnalyticsAssistantAsync(body) {
    return this.post(`${URL_PREFIX}/async_custom_analytics`, body);
  }

  getCustomAnalyticsRecordData(recordId) {
    return this.get(`${URL_PREFIX}/async_custom_analytics/${recordId}`);
  }
}
