import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { DisableListActionTooltip } from 'components/DisableListActionTooltip';
import { LIST_ACTIONS, maxCompaniesFromActionType } from 'constants/discovery';
import DeepDiveIcon from 'icons/DeepDiveIcon';
import ExploreIcon from 'icons/ExploreIcon';
import { NewActionIcon } from 'icons/NewActionIcon';
import PropTypes from 'prop-types';
import { useState } from 'react';

export const NewListActionsMenu = ({ onDeepDive, onExtend, numSelected, noRunsLeft }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const actionsDisabled = (actionType) => {
    return numSelected > maxCompaniesFromActionType(actionType);
  };
  const menuItemsProps = [
    {
      onClick: onDeepDive,
      tag: 'Deep Dive',
      icon: <DeepDiveIcon />,
      actionType: LIST_ACTIONS.DEEP_DIVE
    },
    {
      onClick: onExtend,
      tag: 'Peer Group',
      icon: <ExploreIcon />,
      actionType: LIST_ACTIONS.EXTEND
    }
  ].filter((prop) => prop.onClick);

  return (
    <Stack alignItems="center">
      <Menu
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        {menuItemsProps.map((menuItemProp, index) => (
          <DisableListActionTooltip
            actionDisabled={actionsDisabled(menuItemProp.actionType)}
            actionType={menuItemProp.actionType}
            noRunsLeft={noRunsLeft}
            key={index}>
            <MenuItem
              onClick={menuItemProp.onClick}
              disabled={actionsDisabled(menuItemProp.actionType) || noRunsLeft}>
              <Stack
                direction="row"
                gap="8px"
                padding="6px 0px"
                color="colors.primary_text"
                alignItems="center">
                {menuItemProp.icon}
                <Typography variant="text1_Normal">{menuItemProp.tag}</Typography>
              </Stack>
            </MenuItem>
          </DisableListActionTooltip>
        ))}
      </Menu>
      <Button onClick={handleClick} startIcon={<NewActionIcon />} endIcon={<KeyboardArrowUpIcon />}>
        New
      </Button>
    </Stack>
  );
};

NewListActionsMenu.propTypes = {
  onDeepDive: PropTypes.func,
  numSelected: PropTypes.number,
  noRunsLeft: PropTypes.bool,
  onExtend: PropTypes.func
};
