import { Link } from '@mui/material';
import PropTypes from 'prop-types';

import RenderStr from './RenderStr';

export default function RenderUrl({ value }) {
  return (
    <Link
      href={value?.url ? value.url : '#'}
      underline="none"
      target="_blank"
      rel="noreferrer"
      display="block"
      width="100%">
      <RenderStr
        variant="text1_Bold"
        color={value?.color || 'colors.primary'}
        value={value.value}
      />
    </Link>
  );
}

RenderUrl.propTypes = {
  value: PropTypes.object
};
