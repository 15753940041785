import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { addSubdomainsAction } from 'actions/domain-research/add-subdomains-action';
import { sendUserEvent } from 'actions/users/send-user-event-action';
import LoadingAnimation from 'components/LoadingAnimation';
import RenderStr from 'components/elements/table/RenderStr';
import { DOMAIN_RESEARCH_EVENTS } from 'constants/userEvents';
import OutlinedInfoIcon from 'icons/OutlinedInfoIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { highlightExploreSubdomains } from 'reducer/explore-slice';
import {
  getDomainResearchCategoryIsCATriggered,
  getExploredDomains,
  getIsSubdomainHighlighted,
  getRanSubdomains,
  getSubdomainsNames
} from 'selectors/explore';
import { isEmpty } from 'utils/objects-utils';
import { DOMAIN_RESEARCH_COMPONENT_TYPES } from 'utils/subdomains';

import CategoryActionsMenu from './CategoryActionsMenu';
import SubdomainItem from './SubdomainItem';

function SubdomainsCatrgory({
  listId,
  parentId,
  prefix,
  categoryDomains,
  backgroundColor,
  idx,
  selectedDomains,
  onClickSubdomain
}) {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [inputName, setInputName] = useState('');
  const isLoading = categoryDomains?.some((sd) => sd.loading);
  const exploredDomains = useSelector((state) => getExploredDomains(state, listId));
  const ranSubdomains = useSelector((state) => getRanSubdomains(state, listId));
  const namesMap = useSelector((state) => getSubdomainsNames(state, listId));
  const shouldHightlight = useSelector((state) =>
    getIsSubdomainHighlighted(state, listId, parentId)
  );
  const [isHovered, setIsHovered] = useState(false);
  const [isCategoryEmpty, setIsCategoryEmpty] = useState(false);

  const isCaTriggered = useSelector((state) =>
    getDomainResearchCategoryIsCATriggered(state, listId, parentId, prefix)
  );

  useEffect(() => {
    setTimeout(() => {
      setIsCategoryEmpty(isEmpty(categoryDomains));
    }, 50);
  }, [categoryDomains]);

  useEffect(() => {
    if (shouldHightlight) {
      setTimeout(() => {
        dispatch(
          highlightExploreSubdomains({
            listId,
            subdomainId: Number(parentId),
            shouldHightlight: false
          })
        );
      }, 1000);
    }
  }, [shouldHightlight, listId, parentId, dispatch]);

  const onCloseAdd = () => {
    setInputName('');
    setIsAdding(false);
  };

  const submitUserSubdomain = () => {
    if (!inputName) return;

    dispatch(
      sendUserEvent(DOMAIN_RESEARCH_EVENTS.MANUALLY_ADD_SUBDOMAINS, {
        company_list_id: listId,
        name: inputName,
        level: idx
      })
    );

    dispatch(
      addSubdomainsAction(
        listId,
        parentId,
        [{ name: inputName, is_user_added: true }],
        idx,
        onCloseAdd,
        false,
        { prompt_prefix: prefix }
      )
    );
  };

  const backgroundStyle = {
    backgroundColor: shouldHightlight ? '#86B8FF' : backgroundColor,
    transition: (theme) =>
      theme.transitions.create('background-color', {
        duration: 1000
      })
  };

  const scrollToAddItem = () => {
    const element = document.getElementById('add-followup-button');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const subdomainsCountText = `${
    !isLoading && categoryDomains?.length > 0 ? `(${categoryDomains.length})` : ''
  }`;

  // menu icon + prefix icon + gap + paddingRight
  const categoryOnHoverBoxSize = 24 + (prefix ? 24 + 4 : 0) + 8;
  return (
    <Stack id={`subdomain-category-${parentId}-${prefix}`} overflow="hidden" sx={{ width: '100%' }}>
      <Stack
        overflow="hidden"
        padding="16px"
        paddingRight="2px"
        gap="16px"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        boxSizing="border-box"
        sx={{ ...backgroundStyle, borderRadius: '8px' }}>
        {idx > 0 && namesMap?.[parentId] && (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center" padding="2px" gap="4px">
              <Box maxWidth={idx === 0 ? 'fit-content' : 350 - categoryOnHoverBoxSize + 16}>
                <RenderStr
                  value={namesMap[parentId]}
                  variant="text1_Medium"
                  color="colors.primary_text"
                />
              </Box>

              {subdomainsCountText && (
                <Typography variant="text1_Medium" textAlign="left">
                  {subdomainsCountText}
                </Typography>
              )}
            </Stack>

            {isHovered ? (
              <Stack
                direction="row"
                color="secondary.secondary5_100"
                alignItems="center"
                justifyContent="flex-end"
                paddingRight="8px"
                gap="4px">
                {prefix && (
                  <Tooltip
                    title={
                      <Stack>
                        <Typography variant="text1_Bold">Query:</Typography>
                        <Typography variant="text1_Medium">{prefix}</Typography>
                      </Stack>
                    }>
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', width: '24px', height: '24px' }}>
                      <OutlinedInfoIcon />
                    </Box>
                  </Tooltip>
                )}
                <CategoryActionsMenu
                  categorySubdomains={categoryDomains}
                  listId={listId}
                  componentType={DOMAIN_RESEARCH_COMPONENT_TYPES.CATEGORY}
                />
              </Stack>
            ) : (
              <Box width={categoryOnHoverBoxSize} />
            )}
          </Stack>
        )}
        <Stack gap="16px" sx={{ overflowY: 'auto' }}>
          <Stack gap="8px">
            {isCaTriggered && <LoadingAnimation loadingText={"We're digging deeper"} />}
            {categoryDomains.map((subdomain) => (
              <SubdomainItem
                key={subdomain.id}
                subdomain={subdomain}
                onClickSubdomain={onClickSubdomain}
                isSelected={selectedDomains.has(subdomain.id)}
                discoveryRecordId={ranSubdomains?.[subdomain.id]}
                hasSubdomains={idx !== 0 && exploredDomains.has(subdomain.id)}
                listId={listId}
                backgroundStyle={backgroundStyle}
                width={idx === 0 ? 'fit-content' : '350px'}
              />
            ))}
            {(isAdding || isCategoryEmpty) && (
              <SubdomainItem
                parentId={parentId}
                inputName={inputName}
                setInputName={setInputName}
                onPressEnter={submitUserSubdomain}
                placeholder={
                  isEmpty(categoryDomains)
                    ? 'Type a name or a description of the field you’re interested in...'
                    : 'Enter item name'
                }
                width={idx === 0 ? 'fit-content' : '350px'}
                backgroundStyle={backgroundStyle}
              />
            )}
          </Stack>

          {!isLoading && idx !== 0 && (
            <Stack direction="row" justifyContent="flex-start" gap="8px">
              {isAdding && (
                <Button
                  id="add-followup-button"
                  disabled={!inputName}
                  variant="contained"
                  onClick={submitUserSubdomain}>
                  Add Item
                </Button>
              )}
              {isAdding && <Button onClick={onCloseAdd}>Cancel</Button>}
              {!isAdding && (
                <Button
                  onClick={() => {
                    setIsAdding(true);
                    setTimeout(scrollToAddItem, 5);
                  }}>
                  + Add
                </Button>
              )}
            </Stack>
          )}

          {isCategoryEmpty && (
            <Button
              sx={{ width: 'calc(100% - 12px)' }}
              variant="contained"
              onClick={submitUserSubdomain}>
              Start Mapping
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

SubdomainsCatrgory.propTypes = {
  listId: PropTypes.nucategoryDomainsmber,
  categoryDomains: PropTypes.array,
  namesMap: PropTypes.object,
  parentId: PropTypes.number,
  backgroundColor: PropTypes.string,
  idx: PropTypes.number,
  selectedDomains: PropTypes.object,
  onClickSubdomain: PropTypes.func,
  exploredDomains: PropTypes.object,
  prefix: PropTypes.string
};

export default SubdomainsCatrgory;
