import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { selectWorkflowByQueryAction } from 'actions/workflows/add-workflow-by-query-action';
import CustomAvatar from 'components/tile/CustomAvatar';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatTimestamp } from 'utils/time-utils';

import WorkflowsCenterDialogRowMenu from './WorkflowsCenterDialogRowMenu';

function WorkflowsCenterDialogRow({ query, listId }) {
  const dispatch = useDispatch();
  const onOpenWorkflow = () => dispatch(selectWorkflowByQueryAction(listId, query));

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        onClick={() => onOpenWorkflow(query)}
        sx={{ cursor: 'pointer' }}>
        <Stack direction="row" alignItems="center" gap="8px">
          {query.Icon && <query.Icon />}
          <Typography variant="text1_Medium">{query.name}</Typography>
        </Stack>
      </TableCell>
      <TableCell align="right">
        {query.time_created && formatTimestamp(query.time_created)}
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" sx={{ width: '100%', justifyContent: 'flex-end' }}>
          <CustomAvatar name={query.owner.username} pictureUrl={query.owner.picture} />
        </Stack>
      </TableCell>
      <TableCell align="right">
        <WorkflowsCenterDialogRowMenu query={query} listId={listId} />
      </TableCell>
    </TableRow>
  );
}

WorkflowsCenterDialogRow.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  query: PropTypes.object
};

export default WorkflowsCenterDialogRow;
