import { Button, Stack, Typography } from '@mui/material';
import { PEOPLE_INSIGHTS_PATH } from 'constants/app-routes';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router';
import { formatTimestamp } from 'utils/time-utils';

import AdminTabOrgLogo from './AdminTabOrgLogo';

function LeadersTab({ persons, hidden, filter }) {
  const navigate = useNavigate();

  const onClickPerson = (personId) => {
    navigate(`${PEOPLE_INSIGHTS_PATH}?personId=${personId}`);
  };
  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        {Object.keys(persons)
          .filter((key) => {
            return filter ? filter(persons, key) : true;
          })
          .sort((key1, key2) => persons[key2].id - persons[key1].id)
          .map((key) => {
            const person = persons[key];
            return (
              <Stack
                key={key}
                direction="row"
                gap="32px"
                alignItems="center"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button
                  onClick={() => onClickPerson(key)}
                  size="small"
                  variant="contained"
                  sx={{ width: '60px' }}>
                  {person?.id ? person.id : key}
                </Button>
                <AdminTabOrgLogo logoUrl={person?.ownerOrganizationLogoUrl} />

                <Typography variant="text1_Normal">{person?.recordId}</Typography>
                <Typography variant="text1_Normal">
                  {person?.timeSubmitted ? formatTimestamp(person.timeSubmitted) : 'NA'}
                </Typography>
                <Typography variant="text1_Normal">{person.name}</Typography>
                <Typography variant="text1_Normal" sx={{ marginLeft: 'auto' }}>
                  {person.owner}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default LeadersTab;

LeadersTab.propTypes = {
  persons: PropTypes.object,
  hidden: PropTypes.bool,
  filter: PropTypes.func
};
