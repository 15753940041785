import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-disable id-length */
function StackedBarTooltipContent({ label, maxWidth, children }) {
  return (
    <Stack
      direction="column"
      gap="8px"
      sx={{
        padding: '10px',
        textAlign: 'left',
        maxWidth,
        color: 'colors.ui_border',
        borderRadius: '2px',
        backgroundColor: 'colors.dark_bg'
      }}>
      {label && (
        <Typography color="colors.text_on_primary" variant="text1_Medium">
          {label}
        </Typography>
      )}
      <Typography variant="text1_Normal">{children}</Typography>
    </Stack>
  );
}

StackedBarTooltipContent.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  maxWidth: PropTypes.number
};

export default StackedBarTooltipContent;
