import { Link, Stack, Typography } from '@mui/material';
import LinkedinButton from 'components/LinkedinButton';
import { useAppState } from 'hooks/state-context';
import AniversaryIcon from 'icons/AniversaryIcon';
import HeadcountIcon from 'icons/HeadcountIcon';
import LocationIcon from 'icons/LocationIcon';
import PropTypes from 'prop-types';
import React from 'react';
import {
  getRunCompanyDomainById,
  getRunCompanyFoundedDateById,
  getRunCompanyHQById,
  getRunCompanyLinkedinUrlById,
  getRunCompanySizeById
} from 'selectors/runs';
import { simplifyDomain } from 'utils/string-utils';

export default function CompanyDetailsComponent({ recordId }) {
  const { state } = useAppState();
  const hq = getRunCompanyHQById(state, recordId);
  const size = getRunCompanySizeById(state, recordId);
  const yearFounded = getRunCompanyFoundedDateById(state, recordId);
  const domain = getRunCompanyDomainById(state, recordId);
  const linkedinUrl = getRunCompanyLinkedinUrlById(state, recordId);
  return (
    <Stack direction="row" gap="32px" alignItems="center" flexWrap="wrap">
      <Typography
        variant="text1_Normal"
        color="colors.text_on_primary"
        sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <LocationIcon />
        {hq}
      </Typography>
      <Typography
        variant="text1_Normal"
        color="colors.text_on_primary"
        sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <HeadcountIcon />
        {size}
      </Typography>
      <Typography
        variant="text1_Normal"
        color="colors.text_on_primary"
        sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <AniversaryIcon />
        {yearFounded}
      </Typography>
      <LinkedinButton linkedinUrl={linkedinUrl} />
      {domain?.length > 0 && (
        <Link href={`https://${domain}`} underline="none" target="_blank" rel="noreferrer">
          <Typography variant="text1_Bold" color="colors.link">
            {simplifyDomain(domain)}
          </Typography>
        </Link>
      )}
    </Stack>
  );
}

CompanyDetailsComponent.propTypes = {
  recordId: PropTypes.string
};
