import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { DOMAIN_REPORT_TASK_TYPES } from 'constants/domain-reports';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  getLatestDRCompanyMetaIdsByListId,
  getLatestDRTaskByListId
} from 'selectors/domain-reports';
import { getCompanyMetasByListId } from 'selectors/explore';
import { clusterBreakdownFromTaggingData } from 'utils/domain-report-utils';

import DomainReportBox from '../DomainReportBox';
import ClusterTreeMap from './ClusterTreeMap';
import DomainReportClusterItem from './DomainReportClusterItem';

const determineSize = (companies, numTotalCompanies) => {
  const size = Math.ceil((companies.length / numTotalCompanies) * 10);
  return size > 5 ? 5 : size;
};

function DomainReportCluster({ listId }) {
  const companyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const companyMetaIds = useSelector((state) => getLatestDRCompanyMetaIdsByListId(state, listId));
  const filteredCompanies = companyMetas.filter((company) =>
    companyMetaIds.includes(company.company_meta_id)
  );
  const { data } = useSelector((state) => getLatestDRTaskByListId(state, listId, 'TAGGING')) || {};
  const { clusters } = clusterBreakdownFromTaggingData(data, filteredCompanies);
  return (
    <DomainReportBox
      title="Cluster Analysis"
      listId={listId}
      taskType={DOMAIN_REPORT_TASK_TYPES.TAGGING}
      editable>
      <Typography variant="text1_Normal">
        Breakdown of the domain&apos;s distinct segments
      </Typography>
      <Stack gap="6px">
        <Box sx={{ marginBottom: '10px' }}>
          {clusters ? (
            <ClusterTreeMap
              height={478}
              data={Object.keys(clusters).map((cluster) => ({
                name: cluster,
                size: clusters[cluster].companies.length
              }))}
            />
          ) : (
            <Skeleton variant="rounded" width="100%" height={478} />
          )}
        </Box>
        <Stack>
          {clusters ? (
            Object.values(clusters)
              .sort((cl1, cl2) => cl2.companies.length - cl1.companies.length)
              .map((cluster, index) => (
                <React.Fragment key={index}>
                  <DomainReportClusterItem
                    id={`domain-report-cluster-${index}`}
                    key={index}
                    title={cluster.name}
                    companies={cluster.companies}
                    description={cluster.description}
                    size={determineSize(cluster.companies, filteredCompanies.length)}
                  />
                  {index < Object.keys(clusters).length - 1 && <Divider flexItem />}
                </React.Fragment>
              ))
          ) : (
            <React.Fragment>
              <DomainReportClusterItem loading />
              <DomainReportClusterItem loading />
              <DomainReportClusterItem loading />
              <DomainReportClusterItem loading />
              <DomainReportClusterItem loading />
            </React.Fragment>
          )}
        </Stack>
      </Stack>
    </DomainReportBox>
  );
}

DomainReportCluster.propTypes = {
  listId: PropTypes.number
};

export default DomainReportCluster;
