export const getAssistantSlice = (state) => state.assistant;
export const getAssistantThreads = (state) => getAssistantSlice(state)?.threads || {};
export const getOrderedAssistantThreadIds = (state) => {
  const threads = getAssistantThreads(state);
  return Object.keys(threads).sort((id1, id2) => threads[id1].timestamp - threads[id2].timestamp);
};
export const getAssistantThreadById = (state, threadId) => getAssistantThreads(state)?.[threadId];
export const getAssistantThreadMessagesById = (state, threadId) =>
  getAssistantThreadById(state, threadId)?.messages || [];
export const getLatestThread = (state) => {
  const threads = getAssistantThreads(state);
  return Object.values(threads).sort(
    (thread1, thread2) => thread2.timestamp - thread1.timestamp
  )[0];
};
export const getLatestThreadId = (state) => getLatestThread(state)?.id;

export const getAssistantPromptConfig = (state) => getAssistantSlice(state)?.promptConfig;
export const getAssistantPromptConfigQuestion = (state) =>
  getAssistantPromptConfig(state)?.question;
