import PropTypes from 'prop-types';
import React from 'react';

function ColumnsIcon({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none">
      <path
        d="M9.39844 3.875V21.875M15.3984 3.875V21.875M3.39844 4.875C3.39844 4.60978 3.50379 4.35543 3.69133 4.16789C3.87887 3.98036 4.13322 3.875 4.39844 3.875H20.3984C20.6637 3.875 20.918 3.98036 21.1055 4.16789C21.2931 4.35543 21.3984 4.60978 21.3984 4.875V20.875C21.3984 21.1402 21.2931 21.3946 21.1055 21.5821C20.918 21.7696 20.6637 21.875 20.3984 21.875H4.39844C4.13322 21.875 3.87887 21.7696 3.69133 21.5821C3.50379 21.3946 3.39844 21.1402 3.39844 20.875V4.875Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ColumnsIcon.propTypes = {
  size: PropTypes.number
};

ColumnsIcon.defaultProps = {
  size: 24
};

export default ColumnsIcon;
