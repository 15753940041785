import { Box, Button, Stack, Typography } from '@mui/material';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React from 'react';

function CustomAnalyticsUseFilteredCompaniesDialog({
  totalNumOfComapnies,
  filteredNumOfCompanies,
  submitOptions,
  open,
  onClose
}) {
  return (
    <ModalComponent
      title="Choose Companies"
      maxWidth={600}
      open={open}
      onClose={onClose}
      bottomBar={
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            width: '100%',
            paddingX: '16px'
          }}>
          <Button
            variant="outlined"
            onClick={() => {
              submitOptions?.all?.();
              onClose?.();
            }}>
            Use all companies
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              submitOptions?.filtered?.();
              onClose?.();
            }}>
            Use only filtered companies
          </Button>
        </Stack>
      }>
      <Box padding="24px">
        <Typography variant="text1_Medium">
          {'Filters have been applied. We are showing '}
          <Typography variant="text1_Bold">{filteredNumOfCompanies}</Typography>
          {' out of '}
          <Typography variant="text1_Bold">{totalNumOfComapnies}</Typography>
          {' companies. Do you want to use all companies or only the filtered companies?'}
        </Typography>
      </Box>
    </ModalComponent>
  );
}

CustomAnalyticsUseFilteredCompaniesDialog.propTypes = {
  totalNumOfComapnies: PropTypes.number,
  filteredNumOfCompanies: PropTypes.number,
  submitOptions: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default CustomAnalyticsUseFilteredCompaniesDialog;
