import { Avatar, Box, Link } from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import LinkedinIcon from 'icons/LinkedinIcon';
import SourceIcon from 'icons/SourceIcon';
import PropTypes from 'prop-types';
import React from 'react';

export const tableIcons = Object.freeze({
  linkedin: {
    component: <LinkedinIcon />,
    align: 'right',
    tag: 'Go to Linkedin Profile'
  },
  source: {
    component: <SourceIcon />,
    align: 'right',
    tag: 'Go to Source'
  },
  website: {
    component: <SourceIcon />,
    align: 'right',
    tag: 'Go to Website'
  },
  avatar: {}
});

export default function RenderUrlIcon({ value, colDef }) {
  if (!value) {
    return null;
  }
  if (colDef.icon === 'avatar') {
    return (
      <Avatar
        src={value}
        border="1px solid"
        borderColor="colors.ui_border"
        sx={{ width: 24, height: 24 }}
      />
    );
  }
  const linkPath = value.indexOf('http') === 0 ? value : `https://${value}`;
  return (
    <Box
      className="MuiDataGrid-IconButton"
      sx={{ visibility: value === 'null' && colDef.invisibleIfNull ? 'hidden' : 'visible' }}>
      <Link href={linkPath} target="_blank" rel="noopener">
        <MLIconButton tooltipText={colDef.icon in tableIcons ? tableIcons[colDef.icon].tag : null}>
          {colDef.icon in tableIcons ? (
            tableIcons[colDef.icon].component
          ) : (
            <span style={{ color: 'colors.link' }}>{colDef.icon}</span>
          )}
        </MLIconButton>
      </Link>
    </Box>
  );
}

RenderUrlIcon.propTypes = {
  value: PropTypes.string,
  colDef: PropTypes.object
};
