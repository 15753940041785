import { Button, Checkbox, Dialog, FormControlLabel, Stack, Typography } from '@mui/material';
import { copyRunToUsersAction } from 'actions/runs/copy-run-to-users-action';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import { useState } from 'react';

export default function CopyRunToUserDialog({
  open,
  onClose,
  runs,
  selectedRunId,
  type,
  users,
  addToast
}) {
  const [checkedStatusSet, setCheckedStatusSet] = useState(new Set([]));
  const handleSingleChange = (event, userId) => {
    const newCheckStatus = new Set(checkedStatusSet);
    if (event.target.checked) {
      newCheckStatus.add(userId);
    } else {
      newCheckStatus.delete(userId);
    }
    setCheckedStatusSet(newCheckStatus);
  };

  const handleClose = () => {
    setCheckedStatusSet(new Set([]));
    onClose();
  };

  const onSubmit = () => {
    if (type === 'company') {
      dispatch(copyRunToUsersAction(selectedRunId, Array.from(checkedStatusSet), addToast));
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack padding="24px 50px">
        <Typography
          variant="h3_Bold"
          color="colors.primary_text"
          paddingBottom="24px"
          textAlign="center">
          {runs[selectedRunId].name}
        </Typography>
        <Typography variant="h3_Bold" color="colors.primary_text" paddingBottom="24px">
          Select users to add this run to
        </Typography>
        <Stack height="60vh" overflow="scroll">
          {users &&
            Object.values(users)
              .sort((user1, user2) => user1.email?.localeCompare(user2.email))
              .map((user) => (
                <FormControlLabel
                  key={user.id}
                  label={
                    <Typography variant="text1_Normal" color="colors.primary_text">
                      {user.email}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={checkedStatusSet.has(user.id)}
                      onChange={(event) => handleSingleChange(event, user.id)}
                    />
                  }
                />
              ))}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" paddingTop="24px">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={checkedStatusSet.size === 0}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

CopyRunToUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  runs: PropTypes.object,
  selectedRunId: PropTypes.string,
  type: PropTypes.string,
  users: PropTypes.object,
  addToast: PropTypes.func
};
