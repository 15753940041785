import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useScrollPositionFilterByDirection } from 'hooks/useScrollPositionFilterByDirection';
import ExportIcon from 'icons/ExportIcon';
import UpIcon from 'icons/UpIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { DISCOVERY_SCROLL_ELEMENT_ID } from 'screens/DiscoveryRunBaseScreen';
import {
  getLatestDRProgressByListId,
  getLatestDRStatusByListId,
  getLatestDomainReportByListId
} from 'selectors/domain-reports';
import { getCompanyListNameByListId, getCompanyMetasByListId } from 'selectors/explore';
import { getExploreFilteredCompanies } from 'selectors/explore-table-ui';

import DomainReportButton from './DomainReportButton';

const determineSubtitle = (status, time_submitted) => {
  if (status === 'FAILED') {
    return 'Failed to create report';
  }
  if (status === 'SUCCEEDED') {
    return `Created on: ${new Date(time_submitted).toLocaleDateString()}`;
  }
  return 'Updating report...';
};
function DomainReportHeader({ listId, toPDF, exporting }) {
  const domainReport = useSelector((state) => getLatestDomainReportByListId(state, listId));
  const name = useSelector((state) => getCompanyListNameByListId(state, listId));
  const status = useSelector((state) => getLatestDRStatusByListId(state, listId));
  const progress = useSelector((state) => getLatestDRProgressByListId(state, listId));
  const companyMetas = useSelector((state) => getCompanyMetasByListId(state, listId));
  const filteredListMetaIds = useSelector((state) => getExploreFilteredCompanies(state, listId));
  const scrollPosition = useScrollPositionFilterByDirection(
    'DomainReportHeader',
    DISCOVERY_SCROLL_ELEMENT_ID,
    false
  );

  const { time_submitted, parameters } = domainReport || {};
  const subtitle = determineSubtitle(status, time_submitted);
  const { company_meta_ids: companyMetaIds } = parameters || {};
  const currentFilteredCompanies = companyMetas.filter((company) =>
    filteredListMetaIds.includes(company.company_list_meta_id)
  );
  const outdated = companyMetaIds?.length !== currentFilteredCompanies?.length;
  const theme = useTheme();
  const bgColor = theme.vars.palette.colors.hover_on_gray_bg;
  const bgColorTransparent = `${bgColor}B8`;
  const minify = scrollPosition > 24;
  const goToTop = () => {
    document.getElementById(DISCOVERY_SCROLL_ELEMENT_ID).scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <Stack
      gap="8px"
      onClick={minify ? goToTop : null}
      sx={{
        position: 'sticky',
        top: '61px',
        padding: minify ? '16px 8px' : null,
        backdropFilter: 'blur(6px)',
        cursor: minify ? 'pointer' : null,
        backgroundColor: bgColorTransparent,
        backgroundOp: 0.7,
        maxHeight: minify ? '49px' : null,
        zIndex: 'domainReportHeader'
      }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ marginTop: minify ? '0px' : '8px' }}>
        <Typography variant={minify ? 'text1_Bold' : 'h2_Bold'} color="colors.primary_text">
          Domain Report for {name}
        </Typography>
        {minify ? (
          <UpIcon />
        ) : (
          <Button
            onClick={toPDF}
            startIcon={<ExportIcon />}
            disabled={exporting}
            loading={exporting}>
            Export PDF
          </Button>
        )}
      </Stack>
      {!minify && (
        <Stack direction="row" alignItems="center" gap="8px">
          <Typography variant="text1_Normal" color="colors.secondary_text">
            {subtitle}
            {outdated ? ' (Outdated)' : ''}
          </Typography>
          {status === 'RUNNING' && progress && progress < 1 ? (
            <Box
              sx={{
                marginLeft: '4px',
                borderRadius: '9px',
                width: '280px',
                height: '6px',
                backgroundColor: 'colors.selected'
              }}>
              <Box
                sx={{
                  borderRadius: '9px',
                  width: `${progress * 280}px`,
                  height: '6px',
                  backgroundColor: 'graphColors.graph13'
                }}
              />
            </Box>
          ) : null}
          {['FAILED', 'SUCCEEDED'].includes(status) && (
            <React.Fragment>
              <Divider
                variant="middle"
                orientation="vertical"
                color="colors.secondary_text"
                flexItem
              />
              <DomainReportButton listId={listId} status={status} />
            </React.Fragment>
          )}
        </Stack>
      )}
    </Stack>
  );
}

DomainReportHeader.propTypes = {
  listId: PropTypes.number,
  toPDF: PropTypes.func,
  exporting: PropTypes.bool
};

export default DomainReportHeader;
