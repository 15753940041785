import { Stack } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { getOrderedAssistantThreadIds } from 'selectors/assistant';

import AssistantConversationThread from './AssistantConversationThread';

function AssistantConversation() {
  const threadIds = useSelector(getOrderedAssistantThreadIds);
  return (
    <Stack gap="12px" sx={{ paddingX: '16px', overflowY: 'scroll', overflowX: 'none' }}>
      {threadIds.map((threadId, index) => (
        <AssistantConversationThread key={index} threadId={threadId} />
      ))}
    </Stack>
  );
}

export default AssistantConversation;
