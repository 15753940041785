import { Chip, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

export default function RenderChip({ value }) {
  return (
    <Stack direction="row">
      {value?.text && (
        <Chip
          label={value.text}
          color="primary"
          variant="outlined"
          sx={{
            ...appTheme.typography.text1_Medium,
            width: 90,
            borderColor: value?.color,
            color: value?.color,
            fontSize: 11,
            backgroundColor: 'colors.primary_bg',
            margin: 0
          }}
        />
      )}
    </Stack>
  );
}

RenderChip.propTypes = {
  value: PropTypes.object
};
