import { Divider, Stack, Typography } from '@mui/material';
import { useAppState } from 'hooks/state-context';
import { useScrollPositionFilterByDirection } from 'hooks/useScrollPositionFilterByDirection';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { getRunCompanyDescriptionById } from 'selectors/runs';

const DESCRIPTION_CUT_THRESHOLD = 320;

export default function SummaryCompanyDescriptionComponent({ recordId, is_deep_dive_screen }) {
  const { state } = useAppState();
  const boxRef = useRef();
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [boxHeight, setBoxHeight] = useState();
  const scrollPosition = useScrollPositionFilterByDirection(
    'SummaryCompanyDescriptionComponent',
    is_deep_dive_screen ? 'deep-dive-screen' : 'root'
  );

  const description = getRunCompanyDescriptionById(state, recordId);
  const shouldCutDescription = description && description.length > DESCRIPTION_CUT_THRESHOLD;

  useEffect(() => {
    if (!boxRef.current?.offsetHeight) return;

    setBoxHeight(boxRef.current?.offsetHeight);
  }, [boxRef, description, showFullDescription]);

  return (
    <Stack
      ref={boxRef}
      direction="column"
      gap="16px"
      sx={{
        height: scrollPosition === 0 ? '100%' : Math.max(0, boxHeight - scrollPosition),
        opacity: scrollPosition === 0 ? 1 : Math.min(1, 1 - scrollPosition / boxHeight)
      }}>
      <Divider sx={{ borderColor: 'colors.secondary_text', maxWidth: '750px' }} />
      <Typography
        variant="text1_Normal"
        color="colors.text_on_primary"
        textAlign="left"
        sx={{ maxWidth: '750px' }}>
        {showFullDescription || !shouldCutDescription
          ? description
          : description.substring(0, DESCRIPTION_CUT_THRESHOLD)}
        {shouldCutDescription && !showFullDescription && '...'}
        {shouldCutDescription && (
          <Typography
            variant="text1_Bold"
            color="colors.link"
            onClick={() => setShowFullDescription(!showFullDescription)}
            textTransform="capitalize"
            sx={{ cursor: 'pointer' }}>
            {showFullDescription ? `       Less` : `  Read More`}
          </Typography>
        )}
      </Typography>
    </Stack>
  );
}

SummaryCompanyDescriptionComponent.propTypes = {
  recordId: PropTypes.string,
  is_deep_dive_screen: PropTypes.bool
};
