import { translateFilterParams } from 'api/Utils';
import { ACTIONS } from 'constants/actions';

export function updateAllAdminCompanyLists(lists) {
  return { type: ACTIONS.UPDATE_ALL_ADMIN_COMPANY_LISTS, lists };
}

export function translateAdminCompanyObjectForState(company) {
  if (!company) {
    return {};
  }
  return {
    recordId: company.record_id,
    companyListId: company.company_list_id,
    timeSubmitted: company.time_submitted,
    name: company.name,
    userEmail: company.user_email,
    ownerOrganizationId: company.organization_id,
    actions: company.actions
  };
}

export function translateAdminCompanyListForState(company_lists) {
  if (!company_lists) {
    return {};
  }
  return company_lists.map((company) => translateAdminCompanyObjectForState(company));
}

export function loadAllAdminListsAction(params) {
  return (_state, dispatch, apiClient) => {
    apiClient.companiesLists
      .getAllAdminCompaniesLists(translateFilterParams(params))
      .then((res) => {
        if (res.ok && res.data) {
          dispatch(
            updateAllAdminCompanyLists(translateAdminCompanyListForState(res.data.company_lists))
          );
        }
      });
  };
}
