import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { deleteCompaniesListAction } from 'actions/company_lists/delete-companies-list-action';
import { resubmitMismatchedCompanyAction } from 'actions/company_lists/resubmit-mismathced-company';
import { updateIgnoreAmbiguousInCompanyMeta } from 'actions/company_metas/update-ignore-ambiguous';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'icons/CloseIcon';
import WarningIcon from 'icons/WarningIcon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearExploreMismatchCompanies, removeExploreMismatchCompany } from 'reducer/explore-slice';

import MismatchRowOnDialog from './MismatchRowOnDialog';
import ResolvedMismatchMessage from './ResolvedMismatchMessage';

export function MismatchDialog({ open, onClose, listId, companies }) {
  const dispatch = useDispatch();
  const [resolvedCompany, setResolvedCompany] = useState(null);
  const resolvedCompanyName =
    (resolvedCompany && resolvedCompany?.name) || resolvedCompany?.parameters?.name || null;
  const closeDialog = () => {
    dispatch(clearExploreMismatchCompanies({ listId }));
    onClose();
  };

  useEffect(() => {
    if (!resolvedCompany) return;
    const companyListMetaId = resolvedCompany.company_list_meta_id;
    dispatch(removeExploreMismatchCompany({ companyListMetaId, listId }));
  }, [resolvedCompany, listId, dispatch]);

  const onDeleteCompany = (companyListMetaId, deleteFromDB) => {
    if (deleteFromDB) {
      dispatch(deleteCompaniesListAction(listId, [companyListMetaId]));
    }
    dispatch(removeExploreMismatchCompany({ companyListMetaId, listId }));
  };

  const onKeepCompany = (companyListMetaId, companyMetaId, isambiguous) => {
    if (isambiguous) {
      dispatch(updateIgnoreAmbiguousInCompanyMeta(companyMetaId, true, listId));
    }
    dispatch(removeExploreMismatchCompany({ companyListMetaId, listId }));
  };

  const onReplaceCompany = (oldCompanyId, newSearchTerm) => {
    dispatch(resubmitMismatchedCompanyAction(listId, oldCompanyId, newSearchTerm));
  };

  const onRemoveAll = () => {
    const numCompanies = companies?.length || 0;
    for (let index = 0; index < numCompanies; index++) {
      const companyListMetaId = companies[index]?.company_list_meta_id;
      if (companyListMetaId) {
        onDeleteCompany(companyListMetaId, true);
      }
    }
    closeDialog();
  };

  const onKeepAll = () => {
    const numCompanies = companies?.length || 0;
    for (let index = 0; index < numCompanies; index++) {
      const { company_list_meta_id, company_meta_id } = companies[index];
      if (company_list_meta_id && company_meta_id) {
        onKeepCompany(
          company_list_meta_id,
          company_meta_id,
          companies[index]?.parameters?.ambiguous_website
        );
      }
    }
    closeDialog();
  };

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="1024px">
      <Stack width="100%" height="100%" direction="column" gap="24px">
        <Stack
          backgroundColor="#FFFAE9"
          direction="row"
          alignItems="center"
          borderBottom="1px solid"
          borderBottomColor="colors.ui_border"
          padding="12px 24px"
          gap="8px">
          <Box>
            <WarningIcon />
          </Box>
          <Typography variant="text1_Bold" color="colors.primary_text">
            Website and LinkedIn mismatch
          </Typography>
          <MLIconButton onClick={closeDialog} sx={{ marginLeft: 'auto', marginRight: '24px' }}>
            <CloseIcon />
          </MLIconButton>
        </Stack>
        <Stack padding="0px 24px" gap="4px" color="colors.primary_text">
          <Typography variant="text1_Normal">
            {"We couldn't match the LinkedIn page and website for the following companies."}
          </Typography>
          <Typography variant="text1_Normal">
            {
              "Try searching for the full company name, or the company's website and refresh the results."
            }
          </Typography>
          <Typography variant="text1_Semibold" marginTop="16px">
            {"If the Linkedin page and website look correct, click 'Keep Company'."}
          </Typography>
          <Typography variant="text1_Semibold">
            {'If the problem persists, you may have to remove it and try again later.'}
          </Typography>
        </Stack>
        {resolvedCompanyName && (
          <ResolvedMismatchMessage
            resolvedCompanyName={resolvedCompanyName}
            setResolvedCompany={setResolvedCompany}></ResolvedMismatchMessage>
        )}
        <Stack
          width="100%"
          height="100%"
          direction="column"
          sx={{ maxHeight: resolvedCompanyName ? '300px' : '400px', overflow: 'scroll' }}>
          {companies?.map((company) => (
            <MismatchRowOnDialog
              key={company.company_list_meta_id}
              company={company}
              onReplaceCompany={onReplaceCompany}
              onDeleteCompany={onDeleteCompany}
              onKeepCompany={onKeepCompany}
              setResolvedCompany={setResolvedCompany}
            />
          ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          padding="16px 32px"
          gap="8px"
          bgcolor="colors.gray_bg"
          sx={{
            borderTop: '1px solid',
            borderColor: 'colors.ui_border'
          }}>
          <Button onClick={onKeepAll} variant="outlined">
            Keep All
          </Button>
          <Button onClick={onRemoveAll} variant="contained">
            Remove All
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

MismatchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listId: PropTypes.number,
  companies: PropTypes.array,
  companiesLoading: PropTypes.object
};
