import { Button, Stack, Typography } from '@mui/material';
import { deleteOrganizationUserAction } from 'actions/organizations/delete-organization-user-action';
import ModalComponent from 'components/modal/ModalComponent';
import { TOAST_TYPES } from 'constants/toasts';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import DeleteIcon from 'icons/DeleteIcon';
import PropTypes from 'prop-types';
import React from 'react';

function DeleteViewerUserDialog({ user, open, onDeleteStart, onDeleteEnd, onClose }) {
  const closeDialog = () => {
    onClose();
  };

  const handleDeleteUser = () => {
    oldDispatch(
      deleteOrganizationUserAction(
        user.id,
        (error) => onDeleteEnd(TOAST_TYPES.ERROR, error),
        (message) => onDeleteEnd(TOAST_TYPES.SUCCESS, message)
      )
    );
    onDeleteStart();
    closeDialog();
  };

  return (
    <ModalComponent
      open={open}
      maxWidth={450}
      onClose={closeDialog}
      title="Delete Viewer User"
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Button variant="contained" startIcon={<DeleteIcon />} onClick={handleDeleteUser}>
            Delete Viewer
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="24px" padding="24px">
        <Typography variant="text1_Semibold">
          Are you sure you want to delete the viewer user?
        </Typography>
        <Typography variant="text1_Normal">
          {'This action will revoke the access of the user with email '}
          <Typography variant="text1_Semibold">{user?.email}</Typography>
          {' to the paltform'}
        </Typography>
      </Stack>
    </ModalComponent>
  );
}

DeleteViewerUserDialog.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDeleteStart: PropTypes.func,
  onDeleteEnd: PropTypes.func
};

export default DeleteViewerUserDialog;
