import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import MLIconButton from 'design-system/MLIconButton';
import CloseIcon from 'icons/CloseIcon';
import React from 'react';

export interface DSButtonProps extends ButtonProps {
  label: string;
}

interface MLModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  primaryButtonProps: DSButtonProps;
  secondaryButtonProps?: DSButtonProps;
  additionalButtons?: DSButtonProps[];
  children: React.ReactNode;
}

const buttonFromConfig = ({ key, label, ...props }: DSButtonProps) => {
  return (
    <Button key={key} {...props}>
      {label}
    </Button>
  );
};

function MLModal({
  open,
  onClose,
  title,
  primaryButtonProps,
  secondaryButtonProps,
  additionalButtons,
  children
}: MLModalProps) {
  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle sx={{ padding: '16px' }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h2_Semibold" sx={{ marginTop: '16px', marginLeft: '6px' }}>
            {title}
          </Typography>
          <MLIconButton
            onClick={onClose}
            tooltipText="Close"
            sx={{ width: '32px', height: '32px', padding: '0px' }}>
            <CloseIcon color="currentColor" />
          </MLIconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ backgroundColor: 'colors.gray_bg', padding: '16px' }}>
        {additionalButtons &&
          additionalButtons.map((button, index) => buttonFromConfig({ ...button, key: index }))}
        {secondaryButtonProps && (
          <Button variant="outlined" {...secondaryButtonProps}>
            {secondaryButtonProps.label}
          </Button>
        )}
        <Button variant="contained" {...primaryButtonProps}>
          {primaryButtonProps.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MLModal;
