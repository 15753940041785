import { Button, Stack, Typography } from '@mui/material';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import PropTypes from 'prop-types';
import { React } from 'react';
import { useNavigate } from 'react-router';
import { formatTimestamp } from 'utils/time-utils';

import AdminTabOrgLogo from './AdminTabOrgLogo';

function DeepDiveTabAdmin({ runs, hidden }) {
  const navigate = useNavigate();

  const onClickRun = (runId) => {
    navigate(`${COMPANY_INSIGHTS_PREFIX}/${runId}/${OVERVIEW_TAB.path}`);
  };
  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        {runs &&
          Object.keys(runs)
            .sort((key1, key2) => runs[key2].id - runs[key1].id)
            .map((key) => {
              const run = runs[key];
              return (
                <Stack
                  key={key}
                  direction="row"
                  gap="32px"
                  alignItems="center"
                  sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                  <Button
                    onClick={() => onClickRun(key)}
                    size="small"
                    variant="contained"
                    sx={{ width: '60px' }}>
                    {run?.id ? run.id : key}
                  </Button>
                  <AdminTabOrgLogo logoUrl={run?.ownerOrganizationLogoUrl} />
                  <Typography>
                    {run?.timeSubmitted ? formatTimestamp(run.timeSubmitted) : 'NA'}
                  </Typography>
                  <Typography>{run.name}</Typography>
                  <Typography sx={{ marginLeft: 'auto' }}>{run.owner}</Typography>
                </Stack>
              );
            })}
      </Stack>
    </Stack>
  );
}

export default DeepDiveTabAdmin;

DeepDiveTabAdmin.propTypes = {
  runs: PropTypes.object,
  hidden: PropTypes.bool
};
