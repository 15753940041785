import { Chip, Stack } from '@mui/material';
import SelectWithAnswerTypeComponent from 'components/SelectWithAnswerTypeComponent';
import { PROMPT_PREFIX } from 'constants/custom-analytics';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import PromptErrorComponent from './PromptErrorComponent';
import { ANSWER_TYPES } from './PromptInputComponent';
import PromptTextFieldComponent from './PromptTextFieldComponent';
import SlimMultiSelectPromptInputComponent from './SlimMultiSelectComponent';

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
function PromptInputComponent1({
  handleSubmit,
  isProccessing,
  dontAllowSubmitCA,
  isDev,
  promptConfig,
  setPromptConfig,
  showError,
  setShowError,
  getIsInputDisabled,
  ColumnSelector,
  toggleField,
  errorStatus
}) {
  const getPlaceholder = () => {
    return 'Ask anything...';
  };

  const searchPlaces = useMemo(() => {
    const searchItems = [];
    if (promptConfig.searchTheWeb) {
      searchItems.push('web');
    }

    if (promptConfig.useTable) {
      searchItems.push('company_data');
    }

    return searchItems;
  }, [promptConfig]);

  const defualtSources = [
    { id: 'web', name: 'Web' },
    { id: 'company_data', name: 'Data' }
  ];

  const handleSearchPlaceChange = (newSearchPlaces) => {
    const searchPlaceSet = new Set(newSearchPlaces);

    setPromptConfig((prev) => ({
      ...prev,
      searchTheWeb: searchPlaceSet.has('web'),
      useTable: searchPlaceSet.has('company_data')
    }));
  };

  const selectStyle = {
    width: 'auto',
    maxWidth: '300px',
    height: '32px',
    textAlign: 'left',
    backgroundColor: 'colors.primary_bg',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'colors.ui_border'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'colors.disabled_text',
      borderWidth: '1px'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'colors.disabled_text'
    }
  };

  return (
    <Stack
      justifyContent="center"
      gap="16px"
      sx={{
        boxSizing: 'border-box',
        border: '1px solid',
        borderColor: 'colors.hover_on_gray_bg',
        borderRadius: '4px',
        margin: '16px',
        padding: '16px 16px 16px 16px',
        paddingTop: '16px',
        boxShadow: '0px 1px 8px 0px #19213D14'
      }}>
      <Stack direction="row" gap="8px" alignItems="center">
        <SelectWithAnswerTypeComponent
          currentAnswerType={promptConfig.answerType || Object.keys(ANSWER_TYPES)[0]}
          setAnswerType={(newAnswerType) =>
            setPromptConfig((prev) => ({ ...prev, answerType: newAnswerType }))
          }
          selectStyle={selectStyle}
        />
        <SlimMultiSelectPromptInputComponent
          selectedValues={searchPlaces}
          defualtSources={defualtSources}
          onValueChange={handleSearchPlaceChange}
          allTag="Search everywhere"
          emptyTag="Select sources"
          sx={{ width: '180px' }}
          selectStyle={selectStyle}
        />
        <Chip
          label={PROMPT_PREFIX.USING_CARD.title}
          onClick={() => toggleField('useColumn')}
          sx={{
            width: 'fit-content',
            cursor: 'pointer',
            border: '1px solid',
            borderColor: promptConfig.useColumn ? 'colors.hover_on_gray_bg' : 'colors.ui_border',
            backgroundColor: promptConfig.useColumn ? 'colors.hover_on_gray_bg' : 'transparent',
            boxShadow: promptConfig.useColumn ? '0px 1px 5px 0px #1018281A' : 'none'
          }}
        />
        {promptConfig.useColumn && ColumnSelector}
      </Stack>

      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        gap="4px"
        sx={{
          border: '1px solid',
          borderColor: showError ? 'colors.negative_red' : 'colors.ui_border',
          borderRadius: '4px',
          padding: '8px',
          backgroundColor: 'colors.primary_bg',
          boxShadow: '0px 1px 5px 0px #1018281A',
          color: 'colors.secondary_text'
        }}>
        <PromptTextFieldComponent
          {...{
            getPlaceholder,
            getIsInputDisabled,
            handleSubmit,
            isProccessing,
            setPromptConfig,
            promptConfig,
            isDev,
            dontAllowSubmitCA,
            setShowError
          }}
        />
      </Stack>
      {showError && <PromptErrorComponent errorStatus={errorStatus} showError={showError} />}
    </Stack>
  );
}

PromptInputComponent1.propTypes = {
  promptConfig: PropTypes.object,
  setPromptConfig: PropTypes.func,
  showError: PropTypes.bool,
  getIsInputDisabled: PropTypes.func,
  handleSubmit: PropTypes.func,
  isProccessing: PropTypes.bool,
  dontAllowSubmitCA: PropTypes.bool,
  isDev: PropTypes.bool,
  ColumnSelector: PropTypes.element,
  setShowError: PropTypes.func,
  toggleField: PropTypes.func,
  errorStatus: PropTypes.string
};

export default PromptInputComponent1;
