import { Link, Stack, Typography } from '@mui/material';
import BaseTooltipComponent from 'components/elements/BaseTooltipComponent';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { getObjectLength, isEmpty } from 'utils/objects-utils';
import { toTitleCase, toTypeTitle } from 'utils/string-utils';

/* eslint-disable id-length */
function ArtScatterTooltip({ payload, clickedDot, removedPeers }) {
  const prettifyNumber = (num) => {
    if (num > 1) {
      return Math.round(num).toString();
    }
    return num.toPrecision(2);
  };
  const data = useMemo(() => {
    if (isEmpty(payload) && isEmpty(clickedDot)) {
      return null;
    }
    if (clickedDot) {
      return clickedDot;
    }
    return { name: payload[0].name, ...payload[0].payload, ...payload[1].payload };
  }, [payload, clickedDot]);

  if (!data) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <BaseTooltipComponent>
      <Stack gap="6px">
        {removedPeers?.[data?.similarArtworksIndex] && (
          <Typography variant="h3_Bold">Removed</Typography>
        )}
        {data?.link ? (
          <Link href={data?.link} underline="none" target="_blank" rel="noreferrer">
            <Typography variant="text1_Normal" color="colors.secondary_text">
              {toTitleCase(data?.label || data?.name)}: {prettifyNumber(data.year)}
            </Typography>
          </Link>
        ) : (
          <Typography variant="text1_Normal">Year: {prettifyNumber(data.year)}</Typography>
        )}
        {data.execution_year && (
          <Typography variant="text1_Normal">
            Execution Year: {prettifyNumber(data.execution_year)}
          </Typography>
        )}

        <Typography variant="text1_Normal">Value: ${prettifyNumber(data.price / 1000)}k</Typography>
        {data.artType && (
          <Typography variant="text1_Normal">Type: {toTypeTitle(data.artType)}</Typography>
        )}
        {getObjectLength(data.size) === 2 && (
          <Typography variant="text1_Normal">
            Size: {data.size[0]}X{data.size[1]}cm
          </Typography>
        )}

        {data.medium_higher_level && (
          <Typography variant="text1_Normal">
            Medium Higher Level: {data.medium_higher_level}
          </Typography>
        )}

        {data.medium_lower_level && (
          <Typography variant="text1_Normal">
            Medium Lower Level: {data.medium_lower_level}
          </Typography>
        )}

        {data.subject && (
          <Typography variant="text1_Normal">Subjects: {data.subject.join(', ')}</Typography>
        )}
      </Stack>
    </BaseTooltipComponent>
  );
}

ArtScatterTooltip.propTypes = {
  payload: PropTypes.array,
  clickedDot: PropTypes.object,
  removedPeers: PropTypes.object
};

export default ArtScatterTooltip;
