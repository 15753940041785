import { Button, Stack, Typography } from '@mui/material';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import React from 'react';
import { DOMAIN_RESEARCH_COMPONENT_TYPES } from 'utils/subdomains';

function DeleteSubdomainsDialog({ open, onClose, onDelete, componentType, numSelectedSubdomains }) {
  const getDeleteMessage = () => {
    if (componentType === DOMAIN_RESEARCH_COMPONENT_TYPES.MAIN_DOMAIN) {
      return 'The whole domain exploration will be removed.';
    }
    return 'All decedents will also be removed.';
  };

  const getTitle = () => {
    switch (componentType) {
      case DOMAIN_RESEARCH_COMPONENT_TYPES.MAIN_DOMAIN:
        return `Delete the main domain?`;
      case DOMAIN_RESEARCH_COMPONENT_TYPES.ITEM:
        return `Delete item${numSelectedSubdomains > 1 ? 's' : ''}?`;
      case DOMAIN_RESEARCH_COMPONENT_TYPES.CATEGORY:
        return 'Delete follow-up pillar?';
      case DOMAIN_RESEARCH_COMPONENT_TYPES.LEVEL:
        return 'Delete follow-up level?';
      default:
        return 'Delete items?';
    }
  };

  return (
    <ModalComponent
      title={getTitle()}
      open={open}
      onClose={onClose}
      maxWidth={600}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button id="delete-subdomain-dialog-button" variant="contained" onClick={onDelete}>
            Delete
          </Button>
        </Stack>
      }>
      <Stack direction="column" gap="8px" sx={{ padding: '24px' }}>
        <Typography variant="text1_Normal">{getDeleteMessage()}</Typography>
        <Typography variant="text1_Normal">
          Note: Any companies added to the list will not be deleted.
        </Typography>
      </Stack>
    </ModalComponent>
  );
}

DeleteSubdomainsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  componentType: PropTypes.oneOf([
    DOMAIN_RESEARCH_COMPONENT_TYPES.ITEM,
    DOMAIN_RESEARCH_COMPONENT_TYPES.CATEGORY,
    DOMAIN_RESEARCH_COMPONENT_TYPES.LEVEL,
    DOMAIN_RESEARCH_COMPONENT_TYPES.MAIN_DOMAIN
  ]),
  numSelectedSubdomains: PropTypes.number
};

export default DeleteSubdomainsDialog;
