import MLIconButton from 'design-system/MLIconButton';
import EditIcon from 'icons/EditIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getDomainReportTaskDefaultConfig,
  getLatestDRTaskByListId
} from 'selectors/domain-reports';

import DomainReportEditQueryDialog from './DomainReportEditQueryDialog';

function DomainReportEditButton({ listId, taskType }) {
  const [open, setOpen] = useState(false);
  const { parameters } =
    useSelector((state) => getLatestDRTaskByListId(state, listId, taskType)) || {};
  const defaultConfig = useSelector((state) => getDomainReportTaskDefaultConfig(state, taskType));
  return (
    <React.Fragment>
      {defaultConfig && (
        <MLIconButton size="small" tooltipText="Edit" onClick={() => setOpen(true)}>
          <EditIcon />
        </MLIconButton>
      )}
      <DomainReportEditQueryDialog
        listId={listId}
        open={open}
        taskType={taskType}
        onClose={() => setOpen(false)}
        config={parameters}
        defaultConfig={defaultConfig}
      />
    </React.Fragment>
  );
}

DomainReportEditButton.propTypes = {
  listId: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired
};

export default DomainReportEditButton;
