import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { loadAllClustersAction } from 'actions/clusters/load-all-clusters-action';
import { loadClustersForDiscoveryAction } from 'actions/clusters/load-clusters-for-discovery-action';
import { updateCurrentDiscoveryCluster } from 'actions/clusters/update-current-cluster-action';
import { makeRunFiles } from 'actions/runs/make-new-download-run-files-action';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import MLIconButton from 'design-system/MLIconButton';
import { dispatch } from 'hooks/AppStateProvider';
import { useAppState } from 'hooks/state-context';
import SettingsIcon from 'icons/SettingsIcon';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDiscoveriesClusters } from 'selectors/discovery-clusters';
import { getExploreAllClustersSearchIds } from 'selectors/explore';
import { formatTimestamp } from 'utils/time-utils';

function ClustersSettingsComponent({
  selectedTopN,
  setSelectedTopN,
  seeFullTags,
  setSeeFullTags,
  setIsLoading,
  onTriggerClusters,
  isZoomedClusters,
  handleZoomOutClusters,
  showTags,
  setShowTags,
  listId,
  clustersId
}) {
  const { state } = useAppState();
  const reduxDispatch = useDispatch();
  const [topNAnchorEl, setTopNAnchorEl] = useState(null);
  const [oldRunsAnchorEl, setOldRunsAnchorEl] = useState(null);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const discoveryClustersSearchIds = useSelector((reduxState) =>
    getExploreAllClustersSearchIds(reduxState, listId)
  );
  const allClusters = getDiscoveriesClusters(state);

  useEffect(() => {
    reduxDispatch(loadAllClustersAction(listId));
  }, [listId, reduxDispatch]);

  const onSelectTopN = (number) => {
    setSelectedTopN(number);
    setIsLoading(true);
    setTopNAnchorEl(null);
    setSettingsDialogOpen(false);
  };

  const onSwitchFullTags = () => {
    setSeeFullTags(!seeFullTags);
    setIsLoading(true);
    setSettingsDialogOpen(false);
  };

  return (
    <Stack direction="row" alignItems="center">
      {isZoomedClusters && <Button onClick={handleZoomOutClusters}>Zoom Out</Button>}
      <MLIconButton tooltipText="Settings (dev-only)" onClick={() => setSettingsDialogOpen(true)}>
        <SettingsIcon />
      </MLIconButton>
      <Dialog open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)}>
        <Stack width="300px" justifyContent="center" alignItems="center">
          <Box
            width="300px"
            display="flex"
            flexDirection="column"
            gap="16px"
            padding="24px"
            justifyContent="center"
            alignItems="center">
            <Typography variant="h3_Bold" paddingBottom="24px">
              Settings
            </Typography>
            <Button variant="outlined" onClick={(event) => setTopNAnchorEl(event.currentTarget)}>
              Change top N
            </Button>
            <Stack direction="row" gap="8px">
              <Button
                variant="outlined"
                onClick={() => {
                  setShowTags(!showTags);
                  setIsLoading(true);
                  setSettingsDialogOpen(false);
                }}>
                {showTags ? 'Hide Tags' : 'Show Tags'}
              </Button>
              {showTags && (
                <FormControlLabel
                  control={<Switch checked={seeFullTags} onChange={onSwitchFullTags} />}
                  label={'Full Tags'}
                />
              )}
            </Stack>
            <Button variant="outlined" onClick={() => onTriggerClusters()}>
              Rerun
            </Button>
            {discoveryClustersSearchIds?.length && discoveryClustersSearchIds.length > 0 && (
              <Button
                variant="outlined"
                onClick={(event) => setOldRunsAnchorEl(event.currentTarget)}>
                See Old Runs
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={() => {
                reduxDispatch(makeRunFiles(listId, 'tagging', '1'));
                setSettingsDialogOpen(false);
              }}>
              Download Current Run Files
            </Button>
          </Box>
        </Stack>
      </Dialog>
      <Menu
        id="simple-menu"
        anchorEl={topNAnchorEl}
        open={Boolean(topNAnchorEl)}
        onClose={() => setTopNAnchorEl(null)}>
        {Array.from({ length: 10 }, (_elem, index) => index + 1).map((number) => {
          return (
            <MenuItem
              key={number}
              value={number}
              selected={number === selectedTopN}
              onClick={() => onSelectTopN(number)}>
              {number}
            </MenuItem>
          );
        })}
      </Menu>

      <Menu
        id="simple-menu"
        anchorEl={oldRunsAnchorEl}
        open={Boolean(oldRunsAnchorEl)}
        onClose={() => setOldRunsAnchorEl(null)}>
        {discoveryClustersSearchIds &&
          allClusters &&
          Object.keys(allClusters)
            .filter((id) => discoveryClustersSearchIds.includes(id))
            ?.sort((id1, id2) => id2 - id1)
            ?.map((id) => {
              return (
                <MenuItem
                  key={id}
                  value={id}
                  selected={parseInt(id, 10) === parseInt(clustersId, 10)}
                  onClick={() => {
                    setIsLoading(true);
                    dispatch(updateCurrentDiscoveryCluster(null));
                    reduxDispatch(
                      loadClustersForDiscoveryAction(
                        id,
                        {
                          top_n: selectedTopN,
                          get_tags: showTags,
                          full_tags: seeFullTags
                        },
                        listId
                      )
                    );
                    setOldRunsAnchorEl(null);
                    setSettingsDialogOpen(false);
                  }}>
                  {formatTimestamp(allClusters[id].timeSubmitted)}
                </MenuItem>
              );
            })}
      </Menu>
    </Stack>
  );
}

ClustersSettingsComponent.propTypes = {
  selectedTopN: PropTypes.number,
  setSelectedTopN: PropTypes.func,
  seeFullTags: PropTypes.bool,
  setSeeFullTags: PropTypes.func,
  setIsLoading: PropTypes.func,
  onTriggerClusters: PropTypes.func,
  isZoomedClusters: PropTypes.func,
  handleZoomOutClusters: PropTypes.func,
  showTags: PropTypes.func,
  setShowTags: PropTypes.func,
  listId: PropTypes.number,
  clustersId: PropTypes.number
};

export default wrapWithError(ClustersSettingsComponent);
