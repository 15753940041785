import { Message } from 'models/assistant/types';
import PropTypes from 'prop-types';
import React from 'react';

import MessageComponent from './MessageComponent';

interface UserMessageComponentProps {
  message: Message;
}

function UserMessageComponent({ message }: UserMessageComponentProps) {
  return <MessageComponent role="user" text={message.content.text} />;
}

UserMessageComponent.propTypes = {
  message: PropTypes.object
};

export default UserMessageComponent;
