import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { updatePromptClipboardActions } from 'actions/custom-analytics/update-prompt-clipboard-action';
import { updateResourcePermissionsAction } from 'actions/permissions/update-resource-permissions-action';
import { deleteSavedQueryAction } from 'actions/saved_queries/delete-saved-query-action';
import { renameSavedQueryAction } from 'actions/saved_queries/rename-saved-query-action';
import SetQueryNameDialog from 'components/discovery/SetQueryNameDialog';
import { RESOURCE_TYPES } from 'constants/resources';
import MLIconButton from 'design-system/MLIconButton';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import AnswerIcon from 'icons/AnswerIcon';
import CloseIcon from 'icons/CloseIcon';
import ColumnsIcon from 'icons/ColumnsIcon';
import CopyIcon from 'icons/CopyIcon';
import EditIcon from 'icons/EditIcon';
import MenuIcon from 'icons/MenuIcon';
import PrivateIcon from 'icons/PrivateIcon';
import ShareIcon from 'icons/ShareIcon';
import { ToastIcons } from 'icons/ToastIcons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSavedQueryIsMine,
  getSavedQueryIsShared,
  getSavedQueryOwner
} from 'selectors/saved-queries';
import { getUserId, getUserOrganizationId } from 'selectors/user';
import { appTheme } from 'theme';
import { SAVED_QUERY_ACTION_TYPES } from 'utils/platter-utils';

import RenderStr from '../../RenderStr';
import CustomAnalyticsPlatterItemTooltip from './CustomAnalyticsPlatterItemTooltip';

/* eslint-disable max-lines */
export const PlatterItemComponent = ({
  onClose,
  onClick,
  isColumnInList,
  title,
  type,
  parameters,
  listId,
  id,
  actionType
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = type?.includes('custom_analytics');
  const dispatch = useDispatch();
  const { addToast } = useAppUtils();

  const [sharedWithOrg, setSharedWithOrg] = useState(false);
  const organizationId = useOldStateSelector(getUserOrganizationId);
  const userId = useOldStateSelector(getUserId);
  const resourceOwner = useSelector((state) => getSavedQueryOwner(state, id));
  const isSharedFromDb = useSelector((state) => getSavedQueryIsShared(state, id));
  const isMine = useSelector((state) => getSavedQueryIsMine(state, id, userId));

  const [columnName, setColumnName] = useState('');
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);

  const handleActionsClick = (event) => {
    event?.stopPropagation();
    setAnchorEl(event?.target);
  };

  const handleMenuClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  useEffect(() => {
    setSharedWithOrg(isSharedFromDb);
  }, [isSharedFromDb]);

  useEffect(() => {
    if (title) {
      setColumnName(title);
    }
  }, [title]);

  const handleShareToOrg = () => {
    dispatch(
      updateResourcePermissionsAction(
        id,
        RESOURCE_TYPES.SAVED_QUERY,
        [],
        sharedWithOrg ? [] : [organizationId],
        [],
        addToast
      )
    );
    setSharedWithOrg(!sharedWithOrg);
    handleActionsClick();
  };

  const onCopyClick = (event) => {
    handleMenuClick(event);
    dispatch(updatePromptClipboardActions(listId, null, parameters, addToast));
    onClose();
  };

  const onRemove = (event) => {
    handleMenuClick(event);
    dispatch(deleteSavedQueryAction(id, addToast));
  };

  const onItemClick = () => {
    if (isColumnInList) return;
    onClick();
  };

  const closeRenameDialog = () => {
    setIsRenameDialogOpen(false);
    setColumnName(title);
  };
  const onOpenRenameDialog = () => {
    setIsRenameDialogOpen(true);
  };
  const handleRenameSubmit = () => {
    dispatch(renameSavedQueryAction(id, columnName));
    closeRenameDialog();
  };

  const menuItems = [
    {
      isVisible: type?.includes('custom_analytics'),
      text: 'Copy query to chat',
      onClick: onCopyClick,
      endIcon: <CopyIcon />
    },
    {
      isVisible: type === 'custom_analytics' && isMine,
      text: 'Rename',
      onClick: onOpenRenameDialog,
      endIcon: <EditIcon useDefaultColor />
    },
    {
      isVisible: type === 'custom_analytics' && isMine,
      text: sharedWithOrg ? 'Change to private' : 'Change to shared',
      onClick: handleShareToOrg,
      endIcon: sharedWithOrg ? (
        <PrivateIcon useDefaultColor />
      ) : (
        <ShareIcon color={appTheme.vars.palette.colors.dark_bg} />
      )
    },
    {
      isVisible: type === 'custom_analytics' && isMine,
      text: 'Remove from library',
      onClick: onRemove,
      endIcon: <CloseIcon />
    }
  ].filter((item) => item.isVisible);

  const itemStyle = {
    padding: '8px',
    width: '230px',
    height: '40px',
    backgroundColor: isColumnInList ? 'colors.hover_on_gray_bg' : 'white',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'colors.hover_on_gray_bg',
    boxShadow: '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.2)',
    ':hover': {
      backgroundColor: isColumnInList ? 'colors.hover_on_gray_bg' : 'colors.gray_bg'
    }
  };

  const getItemIcon = () => {
    if (type === 'custom_analytics') {
      return sharedWithOrg ? <ShareIcon /> : <PrivateIcon />;
    }
    if (actionType === SAVED_QUERY_ACTION_TYPES.ASK) {
      return <AnswerIcon />;
    }
    return <ColumnsIcon />;
  };

  return (
    <div>
      <Tooltip
        disableInteractive
        arrow
        slotProps={{
          tooltip: {
            sx: {
              maxWidth: 350
            }
          }
        }}
        title={
          (isColumnInList || parameters?.question) && (
            <Stack padding="4px">
              {isColumnInList && (
                <Stack direction="row" alignItems="center">
                  <ToastIcons type="success" contained={false} mainColor="white" />
                  <Typography paddingRight="8px" variant="text1_Medium">
                    Already added to the list
                  </Typography>
                </Stack>
              )}
              {parameters?.question && (
                <CustomAnalyticsPlatterItemTooltip
                  isInList={isColumnInList}
                  title={title}
                  question={parameters.question}
                  isMine={isMine}
                  resourceOwner={resourceOwner}
                />
              )}
            </Stack>
          )
        }>
        <Box sx={{ padding: 0 }}>
          <Button
            onClick={onItemClick}
            sx={{
              padding: 0,
              cursor: isColumnInList ? 'auto' : 'pointer',
              width: '100%',
              height: '100%'
            }}>
            <Box display="flex" alignItems="center" gap="8px" sx={itemStyle}>
              <Box
                sx={{
                  backgroundColor: 'colors.hover_on_primary_bg',
                  borderRadius: '4px',
                  padding: '4px',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                {getItemIcon()}
              </Box>
              <RenderStr
                value={title}
                color={isColumnInList ? 'colors.disabled_text' : 'colors.primary_text'}
                variant="text1_Medium"
                disableTooltip={true}
              />
              {showMenu && (
                <MLIconButton
                  id="platter-item-menu"
                  variant="secondary"
                  sx={{ padding: 0, marginLeft: 'auto' }}
                  onClick={handleActionsClick}>
                  <MenuIcon />
                </MLIconButton>
              )}
            </Box>
          </Button>
        </Box>
      </Tooltip>
      {showMenu && (
        <Menu
          id="more-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setAnchorEl(null);
          }}
          sx={{ '.MuiMenu-list': { padding: 0 } }}>
          {menuItems.map((item, index) => (
            <MenuItem key={index} onClick={item.onClick}>
              <ListItemIcon>{item.endIcon}</ListItemIcon>
              <Typography variant="text1_Normal">{item.text}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
      <SetQueryNameDialog
        title={`Rename ${actionType === SAVED_QUERY_ACTION_TYPES.COLUMN ? 'Column' : 'Question'}`}
        open={isRenameDialogOpen}
        onClose={closeRenameDialog}
        columnName={columnName}
        setColumnName={setColumnName}
        onSaveQuery={handleRenameSubmit}
      />
    </div>
  );
};

PlatterItemComponent.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  isColumnInList: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  question: PropTypes.string,
  listId: PropTypes.number,
  parameters: PropTypes.object,
  id: PropTypes.number,
  actionType: PropTypes.string
};
