import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import ProductIcon from 'icons/ProductIcon';
import SettingsIconSmall from 'icons/SettingsIconSmall';
import SubdomainIcon from 'icons/SubdomainIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { EXTEND_DOMAIN_TYPES } from 'utils/subdomains';

function SelectFollowupMenu({ anchorEl, setAnchorEl, onCustomQuery, onSubmitSubdomains }) {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      id: 'subdomains',
      title: 'Subdomains',
      onClick: () => onSubmitSubdomains(EXTEND_DOMAIN_TYPES.SUBDOMAINS),
      icon: <SubdomainIcon />
    },
    {
      id: 'products',
      title: 'Products',
      onClick: () => onSubmitSubdomains(EXTEND_DOMAIN_TYPES.PRODUCTS),
      icon: <ProductIcon />
    },
    {
      id: 'custom_query',
      title: 'Use a custom query',
      onClick: onCustomQuery,
      icon: <SettingsIconSmall color="secondary.secondary5_100" />
    }
  ];

  const onClickItem = (onClick) => {
    onClick();
    handleClose();
  };

  return (
    <Menu
      MenuListProps={{ sx: { py: 0 } }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}>
      {menuItems.map((item) => (
        <MenuItem key={item.id} onClick={() => onClickItem(item.onClick)} sx={{ padding: '8px' }}>
          <Stack direction="row" gap="4px" justifyContent="flex-start" alignItems="center">
            {item.icon}
            <Typography paddingRight="4px" variant="text1_Normal">
              {item.title}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </Menu>
  );
}

SelectFollowupMenu.propTypes = {
  onCustomQuery: PropTypes.func,
  onSubmitSubdomains: PropTypes.func,
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func
};

export default SelectFollowupMenu;
