import { Typography } from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { handleErrorAction } from 'actions/users/handle-error-action';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { convertCardFilterValue } from 'utils/card-filters-utils';
import {
  determinAlign,
  determineCellBorder,
  determineRenderCell,
  determineRenderHeader,
  getColumnFlex,
  getColumnMinWidth,
  getColumnSort,
  getColumnWidth
} from 'utils/table-utils';

import BenchmarkHeader from './BenchmarkHeader';
import CustomPagination from './CustomPagination';
import { tableIcons } from './RenderUrlIcon';
import './TableComponent.scss';
import TableComponentToolbar from './TableComponentToolbar';

export default function TableComponent({
  title,
  columns,
  showTitle,
  customFilter,
  pageSize,
  elementId,
  useToolbar,
  usePagination,
  onCellClick,
  cardFilters
}) {
  const parentRef = useRef();
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);

  const apiRef = useGridApiRef();
  useEffect(() => {
    if (!parentRef.current) return;

    if (width !== parentRef.current.offsetWidth) {
      setWidth(parentRef.current.offsetWidth);
    }

    if (height !== parentRef.current.offsetHeight) {
      setHeight(parentRef.current.offsetHeight);
    }
  }, [parentRef.current?.offsetWidth, parentRef.current?.offsetHeight, width, height]);

  useEffect(() => {
    if (!customFilter) return;

    setCurrentPage(0);
  }, [customFilter]);

  const sxExtra = determineCellBorder(elementId)
    ? {
        '.MuiDataGrid-cell': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'colors.ui_border'
        },
        '.MuiDataGrid-columnsPanelRow': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'colors.ui_border'
        },
        '.MuiDataGrid-columnHeaders': {
          border: determineCellBorder(elementId) ? '0.5px solid' : 'none',
          borderColor: 'colors.ui_border',
          backgroundColor: 'colors.gray_bg'
        }
      }
    : {};

  const dataGridColumns = useMemo(() => {
    return columns.map((col) => ({
      field: col.id,
      headerName: col.name,
      flex: col.data_type === 'url_icon' ? null : getColumnFlex(elementId, col.id),
      width: col.data_type === 'url_icon' ? 60 : getColumnWidth(elementId, col.id),
      minWidth: col.data_type === 'url_icon' ? 60 : getColumnMinWidth(elementId, col.id),
      url: col?.url,
      icon: col?.icon,
      type: col?.type,
      description: col?.description,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons:
        col.data_type === 'url_icon' ||
        col.data_type === 'url' ||
        col.data_type === 'multiple_url_icons',
      sortable:
        col.data_type !== 'url_icon' ||
        col.data_type === 'url' ||
        col.data_type === 'multiple_url_icons',
      align: determinAlign(col, tableIcons),
      headerAlign: 'left',
      renderHeader: col?.benchmarkLabel
        ? (props) => <BenchmarkHeader {...props} benchmarkLabel={col.benchmarkLabel} />
        : determineRenderHeader(col?.description ? 'variable' : col.data_type),
      renderCell: determineRenderCell(col.data_type, col?.type),
      hide: col.data_type === 'meta',
      valueFormatter: col.valueFormatter,
      ...(getColumnSort(elementId, col.id) && {
        sortComparator: getColumnSort(elementId, col.id)
      }),
      valueOptions: col.valueOptions,
      ...(col.filterOperators && {
        filterOperators: col.filterOperators
      }),
      filterable: !col.disableFilter,
      disableExport: col?.disableExport
    }));
  }, [columns, elementId]);

  const dataGridRows = useMemo(() => {
    let localDataGridRows = [];
    const numRows = columns[0].values.length;
    for (let index = 0; index < numRows; index++) {
      const isMatchFilter = (filters, tableColumns, rowIndex) => {
        if (!filters || filters.length === 0) return true;
        for (let filterIndex = 0; filterIndex < filters.length; ++filterIndex) {
          const value = filters[filterIndex].key?.convert?.table
            ? convertCardFilterValue(
                tableColumns[filters[filterIndex].key.mapping.table].values[rowIndex],
                filters[filterIndex].key?.convert?.table
              )
            : tableColumns[filters[filterIndex].key.mapping.table].values[rowIndex];
          if (filters[filterIndex].value !== value) {
            return false;
          }
        }
        return true;
      };
      if (isMatchFilter(cardFilters, columns, index)) {
        localDataGridRows.push(
          columns.reduce((obj, col) => ({ ...obj, [col.id]: col.values[index] }), {
            id: index
          })
        );
      }
    }
    if (customFilter) {
      if (customFilter.L1 === 'Other') {
        const otherLabelSet = new Set(customFilter.OtherLabels);
        localDataGridRows = localDataGridRows.filter((row) => {
          return customFilter.L2 ? row.L2 === customFilter.L2 : otherLabelSet.has(row.L2);
        });
      } else {
        Object.keys(customFilter).forEach((key) => {
          if (customFilter[key]) {
            localDataGridRows = localDataGridRows.filter((row) => row[key] === customFilter[key]);
          }
        });
      }
    }
    return localDataGridRows;
  }, [columns, customFilter, cardFilters]);

  if (columns.length < 1) {
    return <Typography variant="h3_Bold">No table data found.</Typography>;
  }
  return (
    <div style={{ width: '100%' }}>
      {showTitle && (
        <React.Fragment>
          <Typography variant="text1_Bold" color="colors.primary_text">
            {title}
          </Typography>
          <br />
        </React.Fragment>
      )}
      <DataGridPro
        onError={(errorData) => dispatch(handleErrorAction(errorData.error, errorData.errorInfo))}
        apiRef={apiRef}
        rows={dataGridRows}
        columns={dataGridColumns}
        headerHeight={42}
        rowHeight={42}
        autoHeight
        disableSelectionOnClick={true}
        pageSize={pageSize || Math.min(4, dataGridRows.length)}
        rowsPerPageOptions={[Math.min(4, dataGridRows.length)]}
        hideFooterRowCount={!usePagination}
        hideFooter={!usePagination}
        pagination={usePagination}
        page={currentPage}
        onPageChange={(newPage) => setCurrentPage(newPage)}
        onCellClick={onCellClick}
        slots={{
          pagination: CustomPagination,
          toolbar: useToolbar ? TableComponentToolbar : null
        }}
        slotProps={{
          toolbar: {
            csvOptions: {
              fileName: title
            },
            elementId: elementId
          }
        }}
        sx={{
          marginTop: '6px',
          ...sxExtra
        }}
      />
    </div>
  );
}

TableComponent.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  customFilter: PropTypes.object,
  showTitle: PropTypes.bool,
  elementId: PropTypes.string,
  pageSize: PropTypes.number,
  useToolbar: PropTypes.bool,
  usePagination: PropTypes.bool,
  onCellClick: PropTypes.func,
  cardFilters: PropTypes.array
};

TableComponent.defaultProps = {
  usePagination: true
};
