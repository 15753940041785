import { Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function ArtTargetDetail({ text, title, collapse }) {
  const [clickedShowMore, setClickedShowMore] = React.useState(false);
  return (
    <Stack digap="8px" direction={collapse ? 'column' : 'row'} width={collapse ? '100%' : '250px'}>
      <Stack direction="row" width={collapse ? '250px' : 'auto'} alignItems="center" gap="16px">
        <Typography variant="text1_Bold">{title}:</Typography>
        {text && collapse && (
          <Button
            onClick={() => setClickedShowMore(!clickedShowMore)}
            sx={{ backgroundColor: 'colors.gray_bg', marginLeft: 'auto' }}>
            <Typography variant="text1_Bold" fontSize="12px" color="colors.primary_text">
              {clickedShowMore ? 'Show Less' : 'Show More'}
            </Typography>
          </Button>
        )}
      </Stack>

      {(clickedShowMore || !collapse) && (
        <Typography variant="text1_Normal" marginLeft="auto">
          {text || 'Unknown'}
        </Typography>
      )}
    </Stack>
  );
}

ArtTargetDetail.propTypes = {
  collapse: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string
};

export default ArtTargetDetail;
