import { Stack, Typography } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import AddDeepDiveDynamicCardDialog from 'components/deep-dive/AddDeepDiveDynamicCardDialog';
import EntrichTableDialog from 'components/discovery/EntrichTableDialog';
import { CUSTOM_ANALYTICS_PLACEMENT, PROMPT_PREFIX } from 'constants/custom-analytics';
import MLIconButton from 'design-system/MLIconButton';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import AssistantWandAvatarIcon from 'icons/AssistantWandAvatarIcon';
import CloseIcon from 'icons/CloseIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCompanyMetasMergedRowsMapByListId } from 'selectors/companyMetas';
import { getCustomAnalyticsThreadPostsById } from 'selectors/custom-analytics';
import { getCompanyListTotalCompaniesByListId } from 'selectors/explore';
import { getUserFeatureFlags } from 'selectors/user';
import { INITIAL_PROMPT_CONFIG } from 'utils/prompts-utils';

import ChooseEntrichDomainKnowledgeNameDialog from './ChooseEntrichDomainKnowledgeNameDialog';
import CustomAnalyticsThread from './CustomAnalyticsThread';
import PromptInputComponent from './PromptInputComponent';

function CustomAnalyticsWidget({ listId, deepDiveId, companyMetaId, placement, onClose }) {
  const postsContainerRef = useRef();

  const threadKey = getThreadKey(listId, companyMetaId);
  const thread = useSelector((reduxState) =>
    getCustomAnalyticsThreadPostsById(reduxState, threadKey)
  );
  const companies = useSelector((reduxState) =>
    getCompanyMetasMergedRowsMapByListId(reduxState, listId)
  );
  const totalNumOfComapnies = useSelector((reduxState) =>
    getCompanyListTotalCompaniesByListId(reduxState, listId)
  );
  const featureFlags = useOldStateSelector(getUserFeatureFlags);

  const [promptConfig, setPromptConfig] = useState({
    ...INITIAL_PROMPT_CONFIG
  });

  const [enrichPostId, setEnrichPostId] = useState(null);
  const [domainKnowledgePostId, setDomainKnowledgePostId] = useState(null);
  const [scrollToEnd, setScrollToEnd] = useState(false);

  useEffect(() => {
    const newPromptConfig = {
      ...INITIAL_PROMPT_CONFIG
    };
    if (!listId) {
      newPromptConfig.forEachCompany = true;
      newPromptConfig.searchTheWeb = true;
      newPromptConfig.useDiscover = false;
      newPromptConfig.useTable = true;
    }
    setPromptConfig(newPromptConfig);
  }, [listId, deepDiveId, placement]);

  useEffect(() => {
    if (listId && totalNumOfComapnies === 0) {
      const newPromptConfig = {
        ...INITIAL_PROMPT_CONFIG
      };
      newPromptConfig.forEachCompany = false;
      newPromptConfig.searchTheWeb = false;
      newPromptConfig.useDiscover = true;
      newPromptConfig.useTable = false;
      setPromptConfig((prev) => {
        return { ...prev, useDiscover: true };
      });
    }
  }, [listId, totalNumOfComapnies]);

  useEffect(() => {
    if (scrollToEnd) {
      postsContainerRef.current.scrollTop = postsContainerRef.current.scrollHeight;
    }
    setScrollToEnd(false);
  }, [scrollToEnd]);

  const shouldShowAddToDomainKnowledge = (postId) =>
    (placement === CUSTOM_ANALYTICS_PLACEMENT.EXPLORE && thread[postId].status) === '100%' &&
    !thread[postId].query.includes(`{{${PROMPT_PREFIX.FOR_EACH_COMPANY.prefix}}}`) &&
    !(thread[postId]?.body?.plan?.is_workflow || thread[postId]?.plan?.is_workflow);

  const getEnrichButtonText = () => {
    return placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE && 'Create Card';
  };

  return (
    <React.Fragment>
      {onClose && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: '48px',
            width: '100%',
            zIndex: '1',
            padding: '0px 24px 0px 24px',
            color: 'secondary.secondary150',
            backgroundColor: 'colors.hover_on_gray_bg',
            borderBottom: '1px solid',
            borderColor: 'colors.selected',
            boxShadow: '0px 3px 6px 0px rgba(84, 106, 140, 0.08)',
            boxSizing: 'border-box'
          }}>
          <Stack direction="row" alignItems="center" gap="12px">
            <AssistantWandAvatarIcon />
            <Typography variant="text1_Bold" color="colors.primary_text">
              Custom Analytics Assistant
            </Typography>
          </Stack>
          <MLIconButton onClick={onClose}>
            <CloseIcon onClick={onClose} />
          </MLIconButton>
        </Stack>
      )}
      <React.Fragment>
        <Stack
          ref={postsContainerRef}
          direction="column"
          gap="48px"
          padding="0 16px 0px 16px"
          sx={{
            boxSizing: 'border-box',
            overflowY: 'auto',
            overflowX: 'hidden',
            marginTop: 'auto'
          }}>
          <Stack direction="row" alignItems="center" gap="16px">
            <Stack
              sx={{
                minWidth: 32,
                height: 32,
                backgroundColor: 'colors.primary',
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 16
              }}>
              <AssistantWandAvatarIcon />
            </Stack>
            <Typography variant="text1_Normal" color="colors.primary_text">
              Welcome! How can I help you?
            </Typography>
          </Stack>
          {thread &&
            Object.keys(thread)
              .filter((postId) => !thread[postId]?.isWorkflow && !thread[postId]?.body?.hidePost)
              .map((postId) => (
                <CustomAnalyticsThread
                  key={postId}
                  post={thread[postId]}
                  listId={listId}
                  companyMetaId={companyMetaId}
                  companies={companies}
                  enrichButtonText={getEnrichButtonText()}
                  setEnrichPostId={setEnrichPostId}
                  setDomainKnowledgePostId={setDomainKnowledgePostId}
                  shouldShowAddToDomainKnowledge={shouldShowAddToDomainKnowledge(postId)}
                  showToolCallHistory={featureFlags.includes('ca_planner')}
                  deepDiveId={deepDiveId}
                  setPromptConfig={setPromptConfig}
                  placement={placement}
                />
              ))}
        </Stack>

        <PromptInputComponent
          mode={deepDiveId ? 1 : 2}
          {...{
            listId,
            companyMetaId,
            deepDiveId,
            promptConfig,
            setPromptConfig,
            placement,
            onAddNewPost: () => setScrollToEnd(true)
          }}
        />

        {listId && (
          <EntrichTableDialog
            open={Boolean(enrichPostId)}
            onClose={() => setEnrichPostId(null)}
            listId={listId}
            enrichedPostId={enrichPostId}
          />
        )}
        {placement === CUSTOM_ANALYTICS_PLACEMENT.DEEP_DIVE && (
          <AddDeepDiveDynamicCardDialog
            open={Boolean(enrichPostId)}
            onClose={() => setEnrichPostId(null)}
            deepDiveId={deepDiveId}
            companyMetaId={companyMetaId}
            enrichedPostId={enrichPostId}
          />
        )}
        {listId && (
          <ChooseEntrichDomainKnowledgeNameDialog
            open={Boolean(domainKnowledgePostId)}
            onClose={() => setDomainKnowledgePostId(null)}
            listId={listId}
            domainKnowledgePostId={domainKnowledgePostId}
          />
        )}
      </React.Fragment>
    </React.Fragment>
  );
}

CustomAnalyticsWidget.propTypes = {
  listId: PropTypes.number,
  deepDiveId: PropTypes.number,
  companyMetaId: PropTypes.number,
  placement: PropTypes.string,
  onClose: PropTypes.func
};

export default wrapWithError(CustomAnalyticsWidget);
