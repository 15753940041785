import { Autocomplete, Button, Dialog, Stack, Switch, TextField, Typography } from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { updateResourcePermissionsAction } from 'actions/permissions/update-resource-permissions-action';
import RenderTeamOption from 'components/autocomplete/RenderTeamOption';
import RenderUserOption from 'components/autocomplete/RenderUserOption';
import { RESOURCE_TYPES } from 'constants/resources';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyListCollaborateInfo, getCompanyListNameByListId } from 'selectors/explore';
import { getFolderCollaborateInfo, getFolderNameById } from 'selectors/folders';
import { getPersonCollaborateInfo, getPersonNameById } from 'selectors/persons';
import { getRunCollaborateInfo, getRunCompanyNameById } from 'selectors/runs';
import { getTeams } from 'selectors/teams';
import {
  getUserId,
  getUserOrganizationId,
  getUserOrganizationName,
  getUserOrganizationUsers
} from 'selectors/user';
import { alphabeticalCaseInsensitive } from 'utils/string-utils';

import CollaborateChip from './CollaborateChip';

/* eslint-disable max-lines */
// eslint-disable-next-line max-lines-per-function
function CollaborateDialogInner({
  open,
  onClose,
  id,
  resourceType,
  resourceName,
  resourceUsers,
  resourceTeams,
  resourceOrgs,
  resourceOwner,
  folderId
}) {
  const { state } = useAppState();
  const userId = getUserId(state);
  const { addToast } = useAppUtils();
  const [permittedUsers, setPermittedUsers] = useState([]);
  const [permittedTeams, setPermittedTeams] = useState([]);
  const [shareWithOrg, setShareWithOrg] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectTeamValue, setSelectTeamValue] = useState(null);
  const [inputTeamValue, setInputTeamValue] = useState('');
  const reduxDispatch = useDispatch();
  const receivedPermissions = resourceUsers && resourceTeams && resourceOrgs && resourceOwner;
  const organization = getUserOrganizationName(state);
  const organizationId = getUserOrganizationId(state);
  const {
    resourceUsers: folderUsers,
    resourceOrgs: folderOrgs,
    resourceTeams: folderTeams,
    resourceOwner: folderOwner
  } = useSelector((reduxState) => getFolderCollaborateInfo(reduxState, folderId));
  const folderName = useSelector((reduxState) => getFolderNameById(reduxState, folderId));

  const shareWithOrgViaFolder = useMemo(
    () => organizationId && folderOrgs && folderOrgs.some((item) => item.id === organizationId),
    [organizationId, folderOrgs]
  );
  const ownsBoth = folderOwner && resourceOwner && folderOwner.id === resourceOwner.id;
  const orgUsers = getUserOrganizationUsers(state);
  const allTeams = useSelector(getTeams);
  const teams = useMemo(() => Object.values(allTeams), [allTeams]);
  const usernames =
    orgUsers &&
    resourceOwner &&
    orgUsers
      .filter(
        (item) =>
          (item.id !== userId || item.id !== resourceOwner.id) &&
          !permittedUsers.includes(item.username)
      )
      .map((item) => item.username)
      .sort(alphabeticalCaseInsensitive);
  const teamNames =
    teams &&
    teams
      .filter((item) => !permittedTeams.includes(item.name))
      .map((item) => item.name)
      .sort(alphabeticalCaseInsensitive);
  const noChange =
    receivedPermissions &&
    JSON.stringify(permittedUsers) === JSON.stringify(resourceUsers.map((item) => item.username)) &&
    JSON.stringify(permittedTeams) === JSON.stringify(resourceTeams.map((item) => item.name)) &&
    shareWithOrg === resourceOrgs.some((item) => item.id === organizationId);

  useEffect(() => {
    if (shareWithOrgViaFolder) {
      setShareWithOrg(true);
    }
    if (receivedPermissions) {
      setPermittedUsers(resourceUsers.map((item) => item.username));
      setPermittedTeams(resourceTeams.map((item) => item.name));
      if (organizationId) {
        if (resourceOrgs.some((item) => item.id === organizationId)) {
          setShareWithOrg(true);
        } else if (shareWithOrgViaFolder) {
          setShareWithOrg(true);
        } else {
          setShareWithOrg(false);
        }
      }
    }
  }, [
    receivedPermissions,
    organizationId,
    shareWithOrgViaFolder,
    resourceOrgs,
    resourceTeams,
    resourceUsers
  ]);

  const clearInput = () => {
    setSelectValue(null);
    setInputValue('');
  };

  const clearTeamInput = () => {
    setSelectTeamValue(null);
    setInputTeamValue('');
  };

  const handleClose = () => {
    onClose();
    clearInput();
    clearTeamInput();
  };

  const handleSaveChanges = () => {
    reduxDispatch(
      updateResourcePermissionsAction(
        id,
        resourceType,
        orgUsers.filter((item) => permittedUsers.includes(item.username)).map((item) => item.id),
        shareWithOrg ? [organizationId] : [],
        (teams || []).filter((item) => permittedTeams.includes(item.name)).map((item) => item.id),
        addToast
      )
    );
    handleClose();
  };

  const handleSelect = (_event, newValue) => {
    setPermittedUsers((prev) => [...prev, newValue]);
    clearInput();
  };

  const handleTeamSelect = (_event, newValue) => {
    setPermittedTeams((prev) => [...prev, newValue]);
    clearTeamInput();
  };

  const handleRevokeAccess = (username) => {
    setPermittedUsers((prev) => prev.filter((item) => item !== username));
  };

  const handleRevokeTeamAccess = (name) => {
    setPermittedTeams((prev) => prev.filter((item) => item !== name));
  };
  return (
    <Dialog open={open} onClose={handleClose} onClick={(event) => event.stopPropagation()}>
      <Stack padding="40px 50px" width="400px" gap="24px">
        <Typography variant="h3_Bold" color="colors.primary_text">
          Collaborate on {RESOURCE_TYPES[resourceType]} &quot;{resourceName}&quot;
        </Typography>
        <Stack gap="8px">
          <Typography variant="text1_Bold" color="colors.primary_text">
            Organization
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {shareWithOrgViaFolder ? (
              <Typography variant="text1_Normal" color="colors.primary_text">
                Shared with all of {organization} via folder &quot;{folderName}&quot;
              </Typography>
            ) : (
              <Typography variant="text1_Normal" color="colors.primary_text">
                Share with all of {organization}
              </Typography>
            )}
            <Switch
              checked={shareWithOrg}
              disabled={shareWithOrgViaFolder}
              onChange={(event) => {
                setShareWithOrg(event.target.checked);
                event.stopPropagation();
              }}
            />
          </Stack>
        </Stack>
        {teams && teams.length > 0 && (
          <Stack gap="16px">
            <Typography variant="text1_Bold" color="colors.primary_text">
              Teams
            </Typography>
            <Stack direction="row" gap="8px" maxWidth="500px" flexWrap="wrap">
              {folderTeams?.length
                ? folderTeams.map((team, index) => (
                    <CollaborateChip
                      key={index}
                      name={team.name}
                      picture={team.picture}
                      isViaFolder
                      folderName={folderName}
                    />
                  ))
                : null}

              {permittedTeams?.length
                ? permittedTeams.map((name, index) => (
                    <CollaborateChip
                      key={index}
                      name={name}
                      picture={teams?.find((team) => team.name === name)?.picture}
                      onRevoke={() => handleRevokeTeamAccess(name)}
                    />
                  ))
                : null}

              {!permittedTeams?.length && !folderTeams?.length && (
                <Typography variant="text1_Normal" color="colors.primary_text">
                  No teams collaborating on this list. Add below:
                </Typography>
              )}
            </Stack>
            {teamNames && (
              <Autocomplete
                options={teamNames}
                value={selectTeamValue}
                renderOption={(props, option) => (
                  <RenderTeamOption {...props} option={option} teams={teams} />
                )}
                size="small"
                onChange={handleTeamSelect}
                inputValue={inputTeamValue}
                onInputChange={(_event, newInputValue) => {
                  setInputTeamValue(newInputValue);
                }}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Select Teams" />}
              />
            )}
          </Stack>
        )}
        <Stack gap="16px">
          <Typography variant="text1_Bold" color="colors.primary_text">
            Specific Users
          </Typography>
          <Stack direction="row" gap="8px" maxWidth="500px" flexWrap="wrap">
            {resourceOwner && (
              <CollaborateChip
                name={resourceOwner.username}
                picture={resourceOwner.picture}
                isOwner
              />
            )}
            {folderOwner && !ownsBoth && (
              <CollaborateChip
                name={folderOwner.username}
                picture={folderOwner.picture}
                isViaFolder
              />
            )}
            {folderUsers?.length > 0 &&
              folderUsers.map((user, index) => (
                <CollaborateChip
                  key={index}
                  name={user.username}
                  picture={user.picture}
                  isViaFolder
                  folderName={folderName}
                />
              ))}
            {permittedUsers?.length > 0
              ? permittedUsers.map((username, index) => (
                  <CollaborateChip
                    key={index}
                    name={username}
                    picture={
                      orgUsers && orgUsers.find((user) => user.username === username)?.picture
                    }
                    onRevoke={() => handleRevokeAccess(username)}
                  />
                ))
              : null}
            {!permittedUsers?.length && !folderUsers?.length && (
              <Typography variant="text1_Normal" color="colors.primary_text">
                {resourceOwner && 'No existing collaborators for this list. Add below:'}
              </Typography>
            )}
          </Stack>
          {usernames && (
            <Autocomplete
              options={usernames}
              value={selectValue}
              size="small"
              onChange={handleSelect}
              inputValue={inputValue}
              renderOption={(props, option) => (
                <RenderUserOption {...props} option={option} users={orgUsers} />
              )}
              onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Select Users" />}
            />
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={noChange}
            variant="contained"
            onClick={handleSaveChanges}
            sx={{ width: '130px' }}>
            {noChange ? 'No Changes' : 'Save Changes'}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

function ListCollaborateDialogConnector({ id, ...props }) {
  const resourceName = useSelector((reduxState) => getCompanyListNameByListId(reduxState, id));
  const collaborateInfo = useSelector((reduxState) =>
    getCompanyListCollaborateInfo(reduxState, id)
  );
  return (
    <CollaborateDialogInner id={id} resourceName={resourceName} {...collaborateInfo} {...props} />
  );
}

function FolderCollaborateDialogConnector({ id, ...props }) {
  const resourceName = useSelector((reduxState) => getFolderNameById(reduxState, id));
  const collaborateInfo = useSelector((reduxState) => getFolderCollaborateInfo(reduxState, id));
  return (
    <CollaborateDialogInner id={id} resourceName={resourceName} {...collaborateInfo} {...props} />
  );
}

function DeepDiveCollaborateDialogConnector({ id, searchId, ...props }) {
  const { state: oldState } = useAppState();
  const resourceName = getRunCompanyNameById(oldState, searchId);
  const collaborateInfo = getRunCollaborateInfo(oldState, searchId);
  return (
    <CollaborateDialogInner id={id} resourceName={resourceName} {...collaborateInfo} {...props} />
  );
}

function PersonCollaborateDialogConnector({ id, searchId, ...props }) {
  const { state: oldState } = useAppState();
  const resourceName = getPersonNameById(oldState, searchId);
  const collaborteInfo = getPersonCollaborateInfo(oldState, searchId);
  return (
    <CollaborateDialogInner id={id} resourceName={resourceName} {...collaborteInfo} {...props} />
  );
}

function CollaborateDialog({ open, onClose, id, resourceType, searchId, folderId }) {
  switch (resourceType) {
    case RESOURCE_TYPES.LIST:
      return (
        <ListCollaborateDialogConnector
          open={open}
          onClose={onClose}
          id={id}
          resourceType={resourceType}
          folderId={folderId}
        />
      );
    case RESOURCE_TYPES.DEEP_DIVE:
      return (
        <DeepDiveCollaborateDialogConnector
          open={open}
          onClose={onClose}
          id={id}
          searchId={searchId}
          resourceType={resourceType}
          folderId={folderId}
        />
      );
    case RESOURCE_TYPES.PERSON:
      return (
        <PersonCollaborateDialogConnector
          open={open}
          onClose={onClose}
          id={id}
          searchId={searchId}
          resourceType={resourceType}
          folderId={folderId}
        />
      );
    case RESOURCE_TYPES.FOLDER:
      return (
        <FolderCollaborateDialogConnector
          open={open}
          onClose={onClose}
          id={id}
          resourceType={resourceType}
        />
      );
    default:
      return null;
  }
}

CollaborateDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  resourceType: PropTypes.string,
  searchId: PropTypes.number,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default React.memo(CollaborateDialog);

CollaborateDialogInner.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  resourceType: PropTypes.string,
  resourceName: PropTypes.string,
  resourceOrgs: PropTypes.array,
  resourceTeams: PropTypes.array,
  resourceUsers: PropTypes.array,
  resourceOwner: PropTypes.object,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

ListCollaborateDialogConnector.propTypes = {
  id: PropTypes.number
};

DeepDiveCollaborateDialogConnector.propTypes = {
  id: PropTypes.number,
  searchId: PropTypes.number
};

PersonCollaborateDialogConnector.propTypes = {
  id: PropTypes.number,
  searchId: PropTypes.number
};

FolderCollaborateDialogConnector.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
