import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useAppUtils } from 'AppUtilsProvider';
import { enrichContentWithCustomAnalyticsPostResponseAction } from 'actions/custom-analytics/enrich-content-with-custom-analytics-post-response-action';
import { updateColumnCustomAnalyticsPostAction } from 'actions/custom-analytics/update-column-custom-analytics-post-action';
import SelectWithSearchComponent from 'components/SelectWithSearchComponent';
import { TOAST_TYPES } from 'constants/toasts';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey } from 'reducer/custom-analytics';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import { getCustomAnalyticsThreadSpecificPostById } from 'selectors/custom-analytics';
import { getListCustomColumnsByListId } from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import { getUserIsDev } from 'selectors/user';
import { getCompaniesAndColumns } from 'utils/prompts-utils';

function EntrichTableDialog({ open, onClose, listId, enrichedPostId }) {
  const dispatch = useDispatch();
  const toastRef = useRef();
  const { addToast, cancelToast } = useAppUtils();

  const [columnName, setColumnName] = useState('');
  const [existColumn, setExistColumn] = useState();
  const [enrichType, setEnrichType] = useState('addNewColumn');

  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const isDev = useOldStateSelector(getUserIsDev);
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));

  const threadKey = getThreadKey(listId);
  const post = useSelector((state) =>
    getCustomAnalyticsThreadSpecificPostById(state, threadKey, enrichedPostId)
  );

  const columns = useMemo(() => {
    const { columns: allColumns } = getCompaniesAndColumns(
      listId,
      rows,
      customColumns,
      columnsSettings,
      isDev
    );
    // eslint-disable-next-line require-unicode-regexp
    const regexToRemoveTags = /\s*\{\{.*?\}\}\s*/g;
    return Object.values(allColumns).filter(
      (column) =>
        column.columnType === 'custom_analytics' &&
        customColumns?.[column.id]?.info?.question?.replace(regexToRemoveTags, '')?.trim() ===
          post?.body?.question?.replace(regexToRemoveTags, '')?.trim()
    );
  }, [rows, customColumns, listId, columnsSettings, isDev, post]);

  useEffect(() => {
    if (open && !existColumn && columns.length > 0) {
      setExistColumn(columns[0].id);
    }
  }, [open, existColumn, columns]);

  const closeDialog = () => {
    setColumnName('');
    setExistColumn();
    setEnrichType('addNewColumn');
    onClose();
  };

  const handleColumnNameChange = (event) => {
    setColumnName(event.target.value);
  };

  const handleEnrichContent = () => {
    if (enrichType === 'addNewColumn') {
      dispatch(
        enrichContentWithCustomAnalyticsPostResponseAction(
          listId,
          enrichedPostId,
          columnName,
          (toastType) => {
            cancelToast(toastRef.current);
            toastRef.current = null;
            const toastMessage =
              toastType === TOAST_TYPES.SUCCESS
                ? 'Adding new column succeed'
                : 'Adding new column failed';
            const id = addToast(toastMessage, toastType);
            setTimeout(() => cancelToast(id), 2000);
          }
        )
      );
      toastRef.current = addToast('Adding new column', TOAST_TYPES.INFO);
    } else {
      dispatch(
        updateColumnCustomAnalyticsPostAction(listId, enrichedPostId, existColumn, (toastType) => {
          cancelToast(toastRef.current);
          toastRef.current = null;
          const toastMessage =
            toastType === TOAST_TYPES.SUCCESS
              ? 'Update column content succeed'
              : 'Update column content failed';

          const id = addToast(toastMessage, toastType);

          setTimeout(() => cancelToast(id), 2000);
        })
      );
      toastRef.current = addToast('Update column content', TOAST_TYPES.INFO);
    }
    closeDialog();
  };

  const onKeyDown = (event) => {
    if (event?.key === 'Enter') {
      handleEnrichContent();
      event.preventDefault();
      return;
    }
    event.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: '500px' } }}>
      <DialogTitle id="alert-dialog-title">Enrich table with custom analytics data</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {columns.length > 0 && (
          <ToggleButtonGroup
            exclusive
            value={enrichType}
            onChange={(_event, newValue) => setEnrichType(newValue)}
            sx={{ height: '32px' }}>
            <ToggleButton value="addNewColumn" sx={{ width: '150px' }}>
              <Typography variant="text1_Normal">Add new column</Typography>
            </ToggleButton>
            <ToggleButton value="addToExistColumn" sx={{ width: '150px' }}>
              <Typography variant="text1_Normal">Update exist column</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Box sx={{ padding: '24px 0' }}>
          {enrichType === 'addNewColumn' ? (
            <Input
              value={columnName}
              onChange={handleColumnNameChange}
              placeholder="Write the new column name here"
              sx={{
                width: '100%'
              }}
              onKeyDown={onKeyDown}
            />
          ) : (
            <SelectWithSearchComponent
              options={columns}
              value={existColumn || columns[0].id}
              searchPlaceHolder="Search for a column"
              onChange={(event) => setExistColumn(event.target.value)}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          onClick={handleEnrichContent}
          disabled={
            (enrichType === 'addNewColumn' && columnName.length <= 0) ||
            (enrichType === 'addToExistColumn' && !existColumn)
          }>
          {enrichType === 'addNewColumn' ? 'Add' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EntrichTableDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listId: PropTypes.number,
  enrichedPostId: PropTypes.string
};

export default EntrichTableDialog;
