import { Box } from '@mui/material';
import React from 'react';

interface MLIconProps {
  icon: React.ReactNode;
  color?: string;
  backgroundColor?: string;
  size: 'large' | 'medium' | 'small' | 'tiny';
  sx?: object;
}

const sizeMap = {
  large: {
    padding: '8px',
    width: '40px',
    height: '40px'
  },
  medium: {
    padding: '4px',
    width: '32px',
    height: '32px'
  },
  small: {
    padding: '0px',
    width: '24px',
    height: '24px'
  },
  tiny: {
    padding: '0px',
    width: '16px',
    height: '16px'
  }
};

export default function MLIcon({ icon, color, backgroundColor, size = 'medium', sx }: MLIconProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        color,
        backgroundColor,
        ...sizeMap[size],
        ...sx
      }}>
      {icon}
    </Box>
  );
}
