import { Typography } from '@mui/material';
import MLInlineStack from 'design-system/MLInlineStack';
import AddIcon from 'icons/AddIcon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import WorkflowFormAddStepMenu from './WorkflowFormAddStepMenu';

function WorkflowFormEmptyAddStep({ listId }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenNewStep = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNewStep = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <MLInlineStack
        onClick={handleOpenNewStep}
        sx={{
          cursor: 'pointer',
          padding: '16px',
          margin: '8px 16px',
          border: '1px dashed',
          borderColor: 'accent_colors.bright_blue',
          borderRadius: '4px'
        }}>
        <AddIcon />
        <Typography variant="text1_Normal" color="colors.primary_text">
          Add step
        </Typography>
      </MLInlineStack>
      <WorkflowFormAddStepMenu listId={listId} anchorEl={anchorEl} onClose={handleCloseNewStep} />
    </React.Fragment>
  );
}

WorkflowFormEmptyAddStep.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default WorkflowFormEmptyAddStep;
