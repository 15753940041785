import { Avatar, Stack, Typography } from '@mui/material';
import { useAppState } from 'hooks/state-context';
import PropTypes from 'prop-types';
import React from 'react';
import { Routes } from 'react-router-dom';
import { getUser, getUserIsActive } from 'selectors/user';

function ActiveRoutes({ children }) {
  const { state } = useAppState();
  const user = getUser(state);
  const isActive = getUserIsActive(state);
  if (isActive) {
    return <Routes>{children}</Routes>;
  } else if (user && !isActive) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        gap="16px"
        minHeight="calc(100vh - 50px)"
        sx={{ margin: 'auto' }}>
        <Avatar sx={{ width: '100px', height: '100px', margin: '30px' }}></Avatar>
        {!isActive && <Typography variant="h1_Bold">User licence not yet activated.</Typography>}
        <Typography variant="text1_Normal">
          You may need to refresh this page for changes to take effect.
        </Typography>
      </Stack>
    );
  }
}

ActiveRoutes.propTypes = {
  children: PropTypes.any
};

export default ActiveRoutes;
