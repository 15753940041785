import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { DisableListActionTooltip } from 'components/DisableListActionTooltip';
import { LIST_ACTIONS, maxCompaniesFromActionType } from 'constants/discovery';
import MLIconButton from 'design-system/MLIconButton';
import ExtendIcon from 'icons/ExtendIcon';
import { MinimizeIcon } from 'icons/MinimizeIcon';
import { NewActionIcon } from 'icons/NewActionIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';

import SelectedCompanyInList from './SelectedCompanyInList';

function ClustersCompaniesSelectionsList({
  selectedCompanies,
  listId,
  onExtend,
  onNewList,
  headerHeight,
  initialLeft
}) {
  const dispatch = useDispatch();
  const isEmpty = !selectedCompanies || selectedCompanies.length === 0;
  const numSelected = selectedCompanies && selectedCompanies.length;
  const actionsColor = (actionType) => {
    return numSelected > maxCompaniesFromActionType(actionType) || isEmpty
      ? 'colors.disabled_text'
      : 'colors.dark_bg';
  };
  const actionsDisabled = (actionType) => {
    return numSelected > maxCompaniesFromActionType(actionType) || isEmpty;
  };
  const [collapseList, setCollapseList] = useState(true);

  const buttonProps = [
    {
      onClick: onExtend,
      tag: 'Discover more companies',
      icon: <ExtendIcon />,
      actionType: LIST_ACTIONS.EXTEND
    }
  ];

  useEffect(() => {
    if (numSelected === 0) {
      setCollapseList(true);
    } else if (numSelected === 1) {
      setCollapseList(false);
    }
  }, [numSelected]);

  const onClearAll = () => {
    dispatch(clearExploreTableUIState({ listId }));
    setCollapseList(true);
  };
  return (
    <Draggable>
      <Box
        sx={{
          position: 'fixed',
          left: initialLeft,
          top: headerHeight + 24,
          borderRadius: '6px',
          boxShadow: '0px 0px 11px rgba(66, 82, 110, 0.21)',
          backgroundColor: 'colors.primary_bg',
          border: '1px solid',
          borderColor: 'colors.ui_border',
          width: collapseList ? 'fit-content' : '260px'
        }}>
        <Stack
          direction="row"
          alignItems="center"
          padding="6px 16px"
          gap="8px"
          sx={{ cursor: 'move' }}
          borderBottom="1px solid"
          borderBottomColor="colors.ui_border"
          backgroundColor="colors.hover_on_primary_bg">
          <Typography variant="text1_Bold" color="colors.primary_text">
            {`My List ${numSelected > 0 ? `(${numSelected})` : ''}`}
          </Typography>
          {numSelected > 0 && !collapseList && (
            <Button onClick={onClearAll} flex={1}>
              Clear All
            </Button>
          )}
          <MLIconButton
            onClick={() => setCollapseList(!collapseList)}
            sx={{ marginLeft: 'auto', marginRight: '8px' }}>
            <MinimizeIcon />
          </MLIconButton>
        </Stack>
        {!collapseList && (
          <Stack>
            <Stack
              height={400}
              overflow="scroll"
              paddingTop="8px"
              borderBottom="1px solid"
              borderBottomColor="colors.ui_border">
              {selectedCompanies &&
                selectedCompanies.map((company) => (
                  <SelectedCompanyInList
                    key={company.companyListMetaId}
                    company={company}></SelectedCompanyInList>
                ))}
            </Stack>
            <Stack direction="row" gap="6px" padding="8px" justifyContent="center">
              <MLIconButton tooltipText="Add To List" onClick={onNewList} disabled={isEmpty}>
                <NewActionIcon disabled={isEmpty} />
              </MLIconButton>
              <Divider
                flexItem
                orientation="vertical"
                sx={{ height: '16px', alignSelf: 'center' }}
              />
              {buttonProps.map((buttonProp, index) => (
                <React.Fragment key={index}>
                  <DisableListActionTooltip
                    actionDisabled={actionsDisabled(buttonProp.actionType)}
                    actionType={buttonProp.actionType}
                    isEmpty={isEmpty}
                    showDescription={false}
                    tag={buttonProp.tag}>
                    <MLIconButton
                      sx={{ color: actionsColor(buttonProp.actionType), gap: '4px' }}
                      onClick={buttonProp.onClick}
                      disabled={actionsDisabled(buttonProp.actionType)}>
                      {buttonProp.icon}
                    </MLIconButton>
                  </DisableListActionTooltip>
                </React.Fragment>
              ))}
            </Stack>
          </Stack>
        )}
      </Box>
    </Draggable>
  );
}

ClustersCompaniesSelectionsList.propTypes = {
  selectedCompanies: PropTypes.array,
  listId: PropTypes.number,
  onExtend: PropTypes.func,
  onNewList: PropTypes.func,
  headerHeight: PropTypes.number,
  initialLeft: PropTypes.number
};

ClustersCompaniesSelectionsList.defaultProps = { initialLeft: 24 };

export default React.memo(ClustersCompaniesSelectionsList);
