import { Chip, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { appTheme } from 'theme';

export default function RenderChips({ value }) {
  const first = 'frequently compared';
  return (
    <Stack direction="row" gap="4px">
      {value
        .sort((item1, item2) => {
          if (item1.label === first) {
            return -1;
          }
          return item2.label === first ? 1 : 0;
        })
        .map((chipData) => {
          if (chipData.label === first) {
            chipData.fill = 'graphColors.graph1';
          }
          return (
            <Chip
              key={chipData.label}
              label={chipData.label}
              sx={{
                ...appTheme.typography.text1_Medium,
                border: chipData.color ? '1.5px solid' : 'none',
                borderColor: chipData.color,
                backgroundColor: chipData.fill,
                color: chipData.fill && 'white'
              }}
            />
          );
        })}
    </Stack>
  );
}

RenderChips.propTypes = {
  value: PropTypes.array
};
