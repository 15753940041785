import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';

function ResizeableWidthStack({ maxWidth, minWidth, initialWidth, children, ...args }) {
  const stackRef = useRef();

  const [trackMove, setTrackMove] = useState(false);
  const [hoverHandler, setHoverHandler] = useState(false);

  const handleMouseMoveEvent = useCallback(
    (event) => {
      if (!stackRef.current) return;
      const newWidth = stackRef.current.clientWidth + event.movementX;

      if (newWidth < maxWidth && newWidth > minWidth) {
        stackRef.current.style.width = `${newWidth}px`;
      }
      event.stopPropagation();
      event.preventDefault();
    },
    [maxWidth, minWidth]
  );

  const handleMouseUpEvent = useCallback(() => {
    document.body.removeEventListener('mousemove', handleMouseMoveEvent, true);
    setTrackMove(false);
  }, [handleMouseMoveEvent]);

  const handleMouseDownEvent = useCallback(() => {
    setTrackMove(true);
    document.body.addEventListener('mouseup', handleMouseUpEvent, true);

    document.body.addEventListener('mousemove', handleMouseMoveEvent, true);
  }, [handleMouseUpEvent, handleMouseMoveEvent]);

  useEffect(() => {
    if (!trackMove) {
      document.body.removeEventListener('mouseup', handleMouseUpEvent, true);
      document.body.removeEventListener('mousemove', handleMouseMoveEvent, true);
    }
  }, [trackMove, handleMouseUpEvent, handleMouseMoveEvent]);

  return (
    <Stack ref={stackRef} direction="row" height="100%" width={initialWidth}>
      <Stack {...args} width="100%" height="100%" position="relative">
        {children}
      </Stack>
      <Stack
        justifyContent="center"
        width="3px"
        height="100%"
        sx={{
          backgroundColor: hoverHandler || trackMove ? 'colors.primary' : 'transparent',
          cursor: 'col-resize',
          zIndex: 10
        }}
        onMouseEnter={() => setHoverHandler(true)}
        onMouseLeave={() => setHoverHandler(false)}
        onMouseDown={handleMouseDownEvent}></Stack>
    </Stack>
  );
}

ResizeableWidthStack.propTypes = {
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  initialWidth: PropTypes.number,
  children: PropTypes.any
};

export default ResizeableWidthStack;
