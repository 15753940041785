import { pusherBindToCARecordId } from 'actions/custom-analytics/create-new-post-in-custom-analytics-thread-async-action';
import { CHAT_GPT_MODEL_VERSION } from 'constants/custom-analytics';
import { WORKFLOW_STATUSES } from 'constants/workflows';
import {
  addPostAnswerToThread,
  addPostQueryToThread,
  addPostRecordIdToThread,
  getThreadKey,
  updateThreadProccessingMode
} from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowEditModeByIdAndListId,
  updateWorkflowStatusByIdAndListId
} from 'reducer/workflows-slice';
import { getWorkflowSelectedPostIdByListId } from 'selectors/workflows';

export function generateWorkflowAction(listId, mainPrompt, isRegenerate) {
  return (dispatch, getState, apiClient) => {
    const postId = isRegenerate
      ? new Date().getTime()
      : getWorkflowSelectedPostIdByListId(getState(), listId);

    const threadKey = getThreadKey(listId);
    const body = {
      question: mainPrompt,
      company_list_id: listId,
      post_id: postId,
      model: CHAT_GPT_MODEL_VERSION,
      action: 'prepare_workflow'
    };
    dispatch(updateWorkflowEditModeByIdAndListId({ listId, workflowId: postId, isEditMode: true }));
    dispatch(addPostQueryToThread({ threadKey, postId, query: mainPrompt, body }));
    dispatch(
      updateWorkflowStatusByIdAndListId({
        listId,
        workflowId: postId,
        status: WORKFLOW_STATUSES.GENERATING
      })
    );

    apiClient.customAnalytics
      .queryCustomAnalyticsAssistantAsync(body)
      .then((res) => {
        if (res.ok) {
          const customAnalyticsRecordId = res.data.record_id;
          dispatch(
            addPostRecordIdToThread({
              threadKey,
              postId,
              customAnalyticsRecordId
            })
          );
          dispatch(
            pusherBindToCARecordId({
              listId,
              recordId: customAnalyticsRecordId,
              threadKey,
              postId,
              isWorkFlowPlan: true
            })
          );
        } else {
          dispatch(
            addPostAnswerToThread({
              threadKey,
              postId,
              message: { answer: 'Something went wrong, please try again' },
              currentStepId: 'end'
            })
          );
        }
        dispatch(
          updateWorkflowStatusByIdAndListId({
            listId,
            workflowId: postId,
            status: res.ok ? WORKFLOW_STATUSES.GENERATED : WORKFLOW_STATUSES.GENERATE_FAILED
          })
        );
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      })
      .catch(() => {
        dispatch(
          addPostAnswerToThread({
            threadKey,
            postId,
            message: { answer: 'Something went wrong, please try again' },
            currentStepId: 'end'
          })
        );
        dispatch(
          updateWorkflowStatusByIdAndListId({
            listId,
            workflowId: postId,
            status: WORKFLOW_STATUSES.GENERATE_FAILED
          })
        );
        dispatch(updateThreadProccessingMode({ threadKey, isProccessing: false }));
      });

    if (isRegenerate) {
      dispatch(updateSelectedWorkflowIdByListId({ listId, selectedWorkflowId: postId }));
    }
  };
}
