import { Stack, TextField, useTheme } from '@mui/material';
import MultiSectionsSelectComponent from 'components/discovery/MultiSectionsSelectComponent';
import SlimMultiSelectPromptInputComponent from 'components/discovery/SlimMultiSelectComponent';
import { PROMPT_SELECT_STYLE } from 'constants/prompt2';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyMetasMergedRowsByCompanyListId } from 'selectors/companyMetas';
import { getListCustomColumnsByListId } from 'selectors/explore';
import { getExploreUiColumnsByListId } from 'selectors/explore-table-ui';
import {
  getContextSections,
  getContextTextAndTooltip,
  getHandleSearchPlaceChange,
  promptConfigToSearchPlaces
} from 'utils/prompt-dropdown-utils';
import { getCompaniesAndColumns } from 'utils/prompts-utils';

import DREQDKeyValue from './DREQDKeyValue';

const DREQDConfigEditable = ({ listId, promptConfig, localPC, setLocalPC }) => {
  const searchPlaces = promptConfigToSearchPlaces(localPC);
  const handleSearchPlaceChange = getHandleSearchPlaceChange(setLocalPC, null);

  const rows = useSelector((state) => getCompanyMetasMergedRowsByCompanyListId(state, listId));
  const customColumns = useSelector((state) => getListCustomColumnsByListId(state, listId));
  const columnsSettings = useSelector((state) => getExploreUiColumnsByListId(state, listId));
  const { columns } = useMemo(() => {
    return getCompaniesAndColumns(listId, rows, customColumns, columnsSettings);
  }, [rows, customColumns, listId, columnsSettings]);
  const contextSections = useMemo(
    () =>
      getContextSections({
        allColumns: columns,
        useDomainKnowledge: localPC.useDomainKnowledge,
        columns: localPC.columns,
        resources: [localPC.resources],
        setPromptConfig: setLocalPC,
        useTable: localPC.useTable
      }),
    [
      localPC.useDomainKnowledge,
      localPC.columns,
      localPC.resources,
      setLocalPC,
      localPC.useTable,
      columns
    ]
  );
  const { text: contextSelectorTitle, tooltipText: contextSelectorTooltip } = useMemo(
    () => getContextTextAndTooltip(localPC.useTable, localPC.useDomainKnowledge, contextSections),
    [localPC.useTable, localPC.useDomainKnowledge, contextSections]
  );
  const theme = useTheme();
  return (
    <Stack gap="24px">
      <DREQDKeyValue title="Query">
        <TextField
          multiline
          maxRows={10}
          variant="outlined"
          value={localPC.question}
          size="small"
          autoFocus
          disableUnderline={true}
          slotProps={{
            input: {
              disableUnderline: true,
              sx: {
                ...theme.typography.text1_Normal
              }
            }
          }}
          sx={{
            ...theme.typography.text1_Normal,
            minWidth: '100%'
          }}
          onChange={(event) => {
            setLocalPC((prev) => ({ ...prev, question: event.target.value }));
          }}
        />
      </DREQDKeyValue>
      <DREQDKeyValue title="Search">
        <SlimMultiSelectPromptInputComponent
          selectedValues={searchPlaces}
          onValueChange={handleSearchPlaceChange}
          allTag="Search everywhere"
          emptyTag="Select sources"
          sx={{ width: '250px' }}
        />
      </DREQDKeyValue>
      <DREQDKeyValue title="Context">
        <MultiSectionsSelectComponent
          containerSx={{
            ...PROMPT_SELECT_STYLE,
            flex: 1,
            maxWidth: '250px'
          }}
          sections={contextSections}
          title={contextSelectorTitle}
          tooltipText={contextSelectorTooltip}
          promptConfig={promptConfig}
        />
      </DREQDKeyValue>
    </Stack>
  );
};

DREQDConfigEditable.propTypes = {
  promptConfig: PropTypes.object,
  editable: PropTypes.bool,
  listId: PropTypes.number,
  localPC: PropTypes.object,
  setLocalPC: PropTypes.func
};

export default DREQDConfigEditable;
