import { Box, Collapse, IconButton, Stack } from '@mui/material';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import ResizeableWidthStack from 'components/ResizeableWidthStack';
import { useWindowSize } from 'hooks/useWindowSize';
import CollapseIcon from 'icons/CollapseIcon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateThreadCompanyListId } from 'reducer/assistant-slice';
import { getLatestThread } from 'selectors/assistant';
import { getCurrentFolderId } from 'selectors/folders';

import AssistantConversation from './conversation/AssistantConversation';
import AssistantPrompt from './prompt/AssistantPromptConnector';

function AssistantLeftPane({ heights, isCollapsed, setIsCollapsed }) {
  const { listId: listIdStr } = useParams();
  const folderId = useSelector(getCurrentFolderId);
  const listId = parseInt(listIdStr, 10);
  const windowSize = useWindowSize();
  const onCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const dispatch = useDispatch();
  const latestThread = useSelector(getLatestThread);

  useEffect(() => {
    if (latestThread.company_list_id !== listId) {
      dispatch(updateThreadCompanyListId({ threadId: latestThread.id, company_list_id: listId }));
    }
  }, [dispatch, listId, latestThread.id, latestThread.company_list_id]);

  return (
    <Stack
      direction="column"
      overflowx="hidden"
      overflowy="auto"
      sx={{
        height: `${windowSize[1] - heights.navBarHeight}px`,
        backgroundColor: 'colors.primary_bg',
        borderRight: '1px solid',
        borderColor: 'colors.ui_border'
      }}>
      <Collapse
        in={isCollapsed}
        orientation="horizontal"
        sx={{ flex: isCollapsed ? 1 : 0, height: '100%' }}>
        <Stack width="32px" height="100%" backgroundColor="colors.grey_bg" borderRadius="4px">
          <Box
            component="span"
            onClick={onCollapse}
            sx={{
              cursor: 'pointer',
              boxSizing: 'border-box',
              height: '40px',
              padding: '8px',
              backgroundColor: 'colors.ui_border',
              '&:hover': {
                backgroundColor: 'greyColors.grey50'
              }
            }}>
            <IconButton
              sx={{
                padding: 0,
                width: '16px',
                transform: 'rotate(180deg)',
                '&:hover': {
                  backgroundColor: 'greyColors.grey50'
                }
              }}>
              <CollapseIcon />
            </IconButton>
          </Box>
        </Stack>
      </Collapse>
      <Collapse
        in={!isCollapsed}
        orientation="horizontal"
        sx={{ flex: isCollapsed ? 0 : 1, height: '100%' }}>
        <ResizeableWidthStack
          maxWidth={700}
          minWidth={565}
          initialWidth={Math.max(565, windowSize[0] / 3)}
          direction="column"
          gap="8px"
          sx={{
            backgroundColor: 'colors.primary_bg',
            overflowY: 'hidden',
            outline: 'none',
            zIndex: 1,
            borderRadius: '4px'
          }}>
          <Stack
            direction="row"
            gap="16px"
            height="48px"
            sx={{
              padding: '12px',
              overflowY: 'auto'
            }}>
            <IconButton
              id="collapse-explore-pannel"
              onClick={onCollapse}
              sx={{ marginLeft: 'auto', height: '24px', width: '24px', padding: 0 }}>
              <CollapseIcon />
            </IconButton>
          </Stack>
          <AssistantConversation />
          <Stack sx={{ marginTop: 'auto', padding: '16px' }}>
            <AssistantPrompt listId={listId} folderId={folderId} />
          </Stack>
        </ResizeableWidthStack>
      </Collapse>
    </Stack>
  );
}
AssistantLeftPane.propTypes = {
  listId: PropTypes.number,
  name: PropTypes.string,
  rows: PropTypes.array,
  heights: PropTypes.object,
  onError: PropTypes.func,
  isDev: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
  disableCollapse: PropTypes.bool
};

export default wrapWithError(AssistantLeftPane);
