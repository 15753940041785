import { Button, Stack, Typography } from '@mui/material';
import {
  COMPANY_INSIGHTS_PREFIX,
  EXPLORE_PREFIX,
  PEOPLE_INSIGHTS_PATH
} from 'constants/app-routes';
import { OVERVIEW_TAB } from 'constants/company-insights';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { formatTimestamp } from 'utils/time-utils';

import AdminTabOrgLogo from './AdminTabOrgLogo';

const RecordType = Object.freeze({
  DISCOVERY: 1,
  COMPANY: 2,
  PERSON: 3,
  CLUSTERS: 5
});

function ExamplesTab({ searches, hidden, filter }) {
  const navigate = useNavigate();

  const onClickSearch = (runId, recordType) => {
    if (recordType === RecordType.DISCOVERY) {
      navigate(`${EXPLORE_PREFIX}/${runId}`);
      return;
    }
    if (recordType === RecordType.COMPANY) {
      navigate(`${COMPANY_INSIGHTS_PREFIX}/${runId}/${OVERVIEW_TAB.path}`);
      return;
    }
    if (recordType === RecordType.PERSON) {
      navigate(`${PEOPLE_INSIGHTS_PATH}?personId=${runId}`);
    }
  };

  const combinedSearches = { ...searches };
  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        {Object.keys(combinedSearches)
          .filter((key) => {
            return filter ? filter(combinedSearches, key) : true;
          })
          .sort(
            (key1, key2) =>
              new Date(combinedSearches[key2].timeSubmitted).getTime() -
              new Date(combinedSearches[key1].timeSubmitted).getTime()
          )
          .map((key) => {
            const search = combinedSearches[key];
            const isDiscovery = search.recordType === RecordType.DISCOVERY;
            const listId = search.companyListId;
            const runId = isDiscovery ? listId : search?.id || key;
            return (
              <Stack
                key={key}
                direction="row"
                gap="32px"
                alignItems="center"
                sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                <Button
                  onClick={() => onClickSearch(runId, search.recordType)}
                  size="small"
                  variant="contained"
                  sx={{ width: '60px' }}
                  disabled={search.recordType === RecordType.CLUSTERS}>
                  {runId}
                </Button>
                <AdminTabOrgLogo logoUrl={search?.ownerOrganizationLogoUrl} />
                <Typography variant="text1_Normal" width="60px">
                  {Object.keys(RecordType).find((type) => RecordType[type] === search.recordType)}
                </Typography>

                <Typography variant="text1_Normal">
                  {search?.timeSubmitted ? formatTimestamp(search.timeSubmitted) : 'NA'}
                </Typography>
                <Typography variant="text1_Normal">{search.name}</Typography>
                <Typography variant="text1_Normal" sx={{ marginLeft: 'auto' }}>
                  {search.owner}
                </Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}

export default ExamplesTab;

ExamplesTab.propTypes = {
  searches: PropTypes.object,
  hidden: PropTypes.bool,
  filter: PropTypes.func
};
