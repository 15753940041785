import PropTypes from 'prop-types';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { appTheme } from 'theme';

import './EventTimelinePieceComponent.scss';
import ScatterShape from './ScatterShape';
import TimelineTooltipContent from './TimelineTooltipContent';

export default function EventTimelinePieceComponent({
  data,
  dataKeyX,
  dataKeyY,
  yAxisName,
  domain,
  tick,
  tickFormatter,
  fill,
  mode,
  selected,
  onClick
}) {
  // eslint-disable-next-line no-nested-ternary
  const height = tick ? (mode === 'monitoring' ? 92 : 112) : mode === 'monitoring' ? 60 : 82;
  return (
    <ResponsiveContainer className="event-timeline-piece-component" width="100%" height={height}>
      <ScatterChart
        overflow="visible"
        style={{
          fontSize: appTheme.typography.text1_Normal.fontSize,
          color: appTheme.vars.palette.colors.ui_border,
          fontFamily: appTheme.typography.fontFamily,
          fontWeight: appTheme.typography.text1_Normal.fontWeight
        }}
        margin={{
          top: mode === 'monitoring' ? 5 : 20,
          right: 20,
          bottom: mode === 'monitoring' ? 5 : 20,
          left: 100
        }}>
        <CartesianGrid
          horizontal={false}
          vertical={false}
          // height={42}
          stroke="blue"
          fill={appTheme.vars.palette.colors.gray_bg}
          style={{
            border: `2px solid blue`
          }}
        />
        <XAxis
          type="number"
          dataKey={dataKeyX}
          tick={tick ? { dy: 6 } : false}
          hide={!tick}
          domain={domain}
          padding={{ left: 60, right: 60 }}
          stroke={appTheme.vars.palette.colors.secondary_text}
          axisLine={false}
          tickLine={false}
          tickFormatter={tickFormatter}
        />
        <YAxis
          type="number"
          dataKey={dataKeyY}
          name={yAxisName}
          tick={false}
          height={42}
          width={65}
          domain={[-10, 10]}
          label={{
            value: yAxisName,
            position: 'insideRight',
            offset: 14,
            fill:
              selected === yAxisName
                ? appTheme.vars.palette.colors.primary_text
                : appTheme.vars.palette.colors.secondary_text,
            fontWeight: selected === yAxisName ? 700 : 100,
            formatter: (value) => value.replace('and', '&'),
            onClick: () => onClick?.(yAxisName),
            cursor: onClick ? 'pointer' : 'default'
          }}
          axisLine={false}
        />
        <Scatter data={data} fill={fill} shape={<ScatterShape />}></Scatter>
        <Tooltip wrapperStyle={{ zIndex: 1 }} cursor={false} content={<TimelineTooltipContent />} />
      </ScatterChart>
    </ResponsiveContainer>
  );
}

EventTimelinePieceComponent.propTypes = {
  data: PropTypes.array,
  dataKeyX: PropTypes.string,
  dataKeyY: PropTypes.string,
  yAxisName: PropTypes.string,
  domain: PropTypes.array,
  tick: PropTypes.bool,
  tickFormatter: PropTypes.func,
  fill: PropTypes.string,
  mode: PropTypes.string,
  selected: PropTypes.string,
  onClick: PropTypes.func
};
