import { Alert, AlertProps, Box, Popover, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface MLButtonWrapperProps {
  tooltipText?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  tooltipArrow?: boolean;
  alertMessage?: string;
  alertSeverity?: AlertProps['severity'];
  children: React.ReactNode;
}

export default function MLButtonWrapper({
  tooltipText,
  tooltipPlacement = 'bottom',
  tooltipArrow = true,
  alertMessage,
  alertSeverity,
  children
}: MLButtonWrapperProps) {
  const [messageAnchorEl, setMessageAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!messageAnchorEl) return;
    const timerHandler = setTimeout(() => setMessageAnchorEl(null), 2000);
    return () => clearTimeout(timerHandler);
  }, [messageAnchorEl]);

  const handleCloseMessage = () => {
    setMessageAnchorEl(null);
  };

  const openMessage = Boolean(messageAnchorEl);

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setMessageAnchorEl(event.currentTarget);
  };

  return (
    <React.Fragment>
      {tooltipText ? (
        <Tooltip title={tooltipText} arrow={tooltipArrow} placement={tooltipPlacement}>
          <Box sx={{ width: 'fit-content' }} onClick={onClick}>
            {children}
          </Box>
        </Tooltip>
      ) : (
        children
      )}
      {alertMessage && (
        <Popover
          open={openMessage}
          sx={{
            pointerEvents: 'none',
            '.MuiPopover-paper': {
              padding: 0,
              margin: 0,
              backgroundColor: 'success'
            }
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          anchorEl={messageAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={handleCloseMessage}
          TransitionProps={{ style: { transform: 'translateX(-50%)' } }}
          disableRestoreFocus>
          <Alert severity={alertSeverity ? alertSeverity : 'success'} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Popover>
      )}
    </React.Fragment>
  );
}
