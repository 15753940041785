import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { wrapWithError } from 'components/ErrorBoundaryComponent';
import InsightItemComponent from 'components/InsightItemComponent';
import ElementComponent from 'components/elements/ElementComponent';
import { COMPANY_INSIGHTS_PREFIX } from 'constants/app-routes';
import ArrowIcon from 'icons/ArrowIcon';
import ShareIcon from 'icons/ShareIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isIosDevice } from 'utils/device-utils';

import MLIconButton from '../design-system/MLIconButton';
import LeadershipOverviewComponent from './LeadershipOverviewComponent';

const NUMBER_OF_INSIGHTS_THRESHOLD = 5;
const LEADERSHIP_NUMBER_OF_INSIGHTS_THRESHOLD = 8;

/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable require-unicode-regexp */
function InsightsGroupComponent({
  recordId,
  groupType,
  title,
  color,
  points,
  onOpenInsight,
  element,
  isMinified
}) {
  const { tabPath } = useParams();
  const [isOnHover, setIsOnHover] = useState(false);
  const sx = tabPath === 'allCards' ? { width: 750, height: 750, maxWidth: '100%' } : {};
  const numberOfPoints =
    element && element.element_id === 'LeadershipOverviewElement'
      ? element.data?.columns?.[0]?.values?.length
      : points?.length;
  const numberOfPointsThreshold =
    element && element.element_id === 'LeadershipOverviewElement'
      ? numberOfPoints - LEADERSHIP_NUMBER_OF_INSIGHTS_THRESHOLD
      : numberOfPoints - NUMBER_OF_INSIGHTS_THRESHOLD;
  const [filteredResults, setFilteredResults] = useState();

  useEffect(() => {
    setFilteredResults(tabPath === 'allCards' ? 0 : numberOfPointsThreshold);
  }, [numberOfPointsThreshold, tabPath]);

  const filterResultHandler = () => {
    setFilteredResults(filteredResults === 0 ? numberOfPointsThreshold : 0);
  };

  const copyCardUrlToClipboard = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${COMPANY_INSIGHTS_PREFIX}/${recordId}/${groupType}`
    );
  };

  const showFilterResult = numberOfPointsThreshold > 0;

  return (
    <Card
      onMouseEnter={() => setIsOnHover(!isIosDevice())}
      onMouseLeave={() => setIsOnHover(false)}
      sx={{
        padding: { xs: '16px', md: '32px' },
        borderRadius: '4px',
        boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)'
      }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap="32px">
        <Typography
          variant="h3_Bold"
          textAlign="left"
          color={'colors.primary_text'}
          sx={{
            borderBottom: '4px solid',
            borderBottomColor: color,
            width: 'fit-content',
            paddingBottom: '8px'
          }}>
          {title}
        </Typography>
        <Stack direction="row" alignItems="center" gap={{ xs: '8px' }} sx={{ height: '1px' }}>
          {isOnHover && (
            <MLIconButton
              tooltipText="Share"
              variant="secondary"
              onClick={copyCardUrlToClipboard}
              alertMessage="The section link was copied to your clipboard.">
              <ShareIcon />
            </MLIconButton>
          )}
        </Stack>
      </Stack>

      {element && element.element_id === 'LeadershipOverviewElement' ? (
        <Box sx={{ marginTop: '40px' }}>
          <LeadershipOverviewComponent
            numberOfPeopleToShow={numberOfPoints - filteredResults}
            columns={element?.data?.columns || []}
            onClick={onOpenInsight}
            isMinified={isMinified}
          />
        </Box>
      ) : (
        <Grid container spacing={5} sx={{ marginTop: '0' }}>
          <Grid item xs={12} md={isMinified ? 12 : 8}>
            {points &&
              points
                ?.slice(0, numberOfPoints - filteredResults)
                .map((point) => (
                  <InsightItemComponent
                    key={point.insight_id}
                    text={point.message}
                    type={point.insight_id}
                    onOpenInsight={onOpenInsight}
                    recordId={recordId}
                  />
                ))}
            {showFilterResult && (
              <Stack
                direction="row"
                gap="8px"
                alignItems="center"
                marginLeft="16px"
                onClick={filterResultHandler}
                sx={{ cursor: 'pointer' }}>
                <ArrowIcon rotation={filteredResults > 0 ? 180 : 0} />
                <Typography variant="text1_Bold" color="colors.informative_blue">
                  {filteredResults > 0
                    ? `Show ${filteredResults} more ${filteredResults > 1 ? 'insights' : 'insight'}`
                    : 'Show less'}
                </Typography>
              </Stack>
            )}
          </Grid>
          {!isMinified && (
            <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ borderColor: 'colors.ui_border', display: { xs: 'none', md: 'block' } }}
              />
              <Box sx={{ flex: 1, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                {element && (
                  <Box className="overview-element" id={`overview-element-${groupType}`} sx={sx}>
                    <ElementComponent
                      element={element}
                      onClick={onOpenInsight}
                      mode="overview"
                      noTitle={tabPath === 'allCards'}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </Card>
  );
}

InsightsGroupComponent.propTypes = {
  recordId: PropTypes.string.isRequired,
  groupType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  points: PropTypes.array,
  element: PropTypes.object,
  onOpenInsight: PropTypes.func,
  isMinified: PropTypes.bool
};

export default wrapWithError(InsightsGroupComponent);
