import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { deleteSubdomainAction } from 'actions/domain-research/delete-subdomain-action';
import { submitSubdomainAction } from 'actions/domain-research/submit-subdomain-action';
import DeleteIcon from 'icons/DeleteIcon';
import ProductIcon from 'icons/ProductIcon';
import SettingsIconSmall from 'icons/SettingsIconSmall';
import SubdomainIcon from 'icons/SubdomainIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { EXTEND_DOMAIN_TYPES } from 'utils/subdomains';

function DomainResearchEmptyStateMenu({ onFollowUp, listId, mainDomainId, name }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const buttonRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // Automatically open the menu on the first render
    // timeout handles the cases where Grow component is not mounted yet
    const timeout = setTimeout(() => {
      if (buttonRef.current) {
        setAnchorEl(buttonRef.current);
      }
    }, 0);

    return () => clearTimeout(timeout);
  }, [buttonRef]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getSubdomains = (extendType) => {
    dispatch(submitSubdomainAction(extendType, listId, mainDomainId, name, 1));
    handleClose();
  };

  const onStartOver = () => {
    dispatch(deleteSubdomainAction(listId, mainDomainId));
    handleClose();
  };

  const menuItems = [
    {
      id: 'generate-subdomains',
      title: 'Add Subdomains',
      onClick: () => getSubdomains(EXTEND_DOMAIN_TYPES.SUBDOMAINS),
      icon: <SubdomainIcon />
    },
    {
      id: 'generate-products',
      title: 'Add Products',
      onClick: () => getSubdomains(EXTEND_DOMAIN_TYPES.PRODUCTS),
      icon: <ProductIcon />
    },
    {
      id: 'generate-followups',
      title: 'Custom Query',
      onClick: onFollowUp,
      icon: <SettingsIconSmall color="currentColor" />
    },
    {
      id: 'start-over',
      title: 'Start Over',
      onClick: onStartOver,
      icon: <DeleteIcon />
    }
  ];

  return (
    <React.Fragment>
      <Typography
        ref={buttonRef}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="text1_Normal"
        fontSize={20}
        color="colors.secondary_text"
        textAlign="center"
        padding="3px"
        sx={{
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'colors.selected'
          },
          borderRadius: '50%',
          backgroundColor: '#CFE7FF',
          height: '24px',
          width: '24px'
        }}>
        +
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ autoFocusItem: false, sx: { py: 0 } }}>
        {menuItems.map((item) => (
          <MenuItem key={item.id} onClick={item.onClick} sx={{ px: '6px', py: '8px' }}>
            <Stack
              direction="row"
              paddingRight="6px"
              gap="4px"
              justifyContent="flex-start"
              alignItems="center"
              color="secondary.secondary5_100">
              {item.icon}
              <Typography color="primary.primary150" variant="text1_Normal">
                {item.title}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

DomainResearchEmptyStateMenu.propTypes = {
  onFollowUp: PropTypes.func,
  listId: PropTypes.number,
  mainDomainId: PropTypes.number,
  name: PropTypes.string
};

export default DomainResearchEmptyStateMenu;
