import { Stack, Typography } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import CAPlanChart from 'components/custom-analytics/CAPlanChart';
import ModalComponent from 'components/modal/ModalComponent';
import { PlanIcon } from 'icons/PlanIcon';
import PropTypes from 'prop-types';
import React from 'react';

function CAPlannerDialog({ plan, promptConfig, open, onClose }) {
  return (
    <ModalComponent
      title={promptConfig.question}
      titleIcon={<PlanIcon />}
      open={open}
      onClose={onClose}>
      <Stack padding="16px">
        <Typography variant="text1_Normal">
          Time: {Math.round(plan.time_taken_to_generate)}s. Cost: $
          {Math.round(plan.cost * 10000) / 10000}.
        </Typography>
        <CAPlanChart plan={plan.steps} mainPrompt={promptConfig.question} />
      </Stack>
    </ModalComponent>
  );
}
CAPlannerDialog.propTypes = {
  plan: PropTypes.object,
  promptConfig: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default withErrorBoundary(CAPlannerDialog);
