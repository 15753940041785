import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { Box } from '@mui/system';
import { useAppUtils } from 'AppUtilsProvider';
import { makeNewOrganizationAction } from 'actions/organizations/make-new-organization-action';
import { TOAST_TYPES } from 'constants/toasts';
import { dispatch } from 'hooks/AppStateProvider';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

export const CreateNewOrganizationDialog = ({ openDialog, setOpenDialog }) => {
  const [name, setName] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);
  const { addToast } = useAppUtils();

  const onClose = () => {
    setOpenDialog(false);
    setName(null);
    setLogoUrl(null);
  };

  const onError = (message) => {
    addToast(message, TOAST_TYPES.ERROR);
  };
  const onSuccess = (message) => {
    addToast(message, TOAST_TYPES.SUCCESS);
  };
  const onSubmit = () => {
    const payload = { name, logoUrl };
    dispatch(makeNewOrganizationAction(payload, onError, onSuccess));
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }
      }}
      open={openDialog}
      onClose={onClose}>
      <DialogTitle variant="text1_Bold" color="colors.primary_text" fontSize="24px">
        Create New Organization
      </DialogTitle>
      <FormControl sx={{ margin: 1, width: '30ch' }} variant="outlined">
        <InputLabel>Name</InputLabel>
        <OutlinedInput
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          label="Name"
        />
      </FormControl>
      <FormControl sx={{ margin: 1, width: '30ch' }} variant="outlined">
        <InputLabel>Logo URL</InputLabel>
        <OutlinedInput
          type="text"
          value={logoUrl}
          onChange={(event) => setLogoUrl(event.target.value)}
          label="Logo URL"
        />
      </FormControl>
      <DialogActions>
        <Box>
          <Button variant="contained" onClick={onSubmit} disabled={!name?.length}>
            Submit Organization
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

CreateNewOrganizationDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func
};
