import { Stack, StackProps } from '@mui/material';
import React from 'react';

interface MLInlineStackProps extends StackProps {
  children: React.ReactNode;
}

export default function MLInlineStack({ children, ...extraProps }: MLInlineStackProps) {
  return (
    <Stack direction="row" alignItems="center" gap="8px" {...extraProps}>
      {children}
    </Stack>
  );
}
