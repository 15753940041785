import { Box, Button, Stack, Typography } from '@mui/material';
import { deleteRunDynamicCardAction } from 'actions/runs/delete-run-dynamic-card-action';
import ModalComponent from 'components/modal/ModalComponent';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getDeepDiveDynamicCardDataById } from 'selectors/deep-dive';

function DeleteDynamicCardDialog({ open, deepDiveId, cardId, onClose }) {
  const dispatch = useDispatch();

  const cardData = useSelector((state) =>
    getDeepDiveDynamicCardDataById(state, deepDiveId, cardId)
  );

  const closeHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    onClose?.();
  };

  const handleDelete = (event) => {
    dispatch(deleteRunDynamicCardAction(deepDiveId, cardId));
    closeHandler(event);
  };

  return (
    <ModalComponent
      title="Delete Custom Card"
      open={open}
      onClose={closeHandler}
      maxWidth={738}
      bottomBar={
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%', padding: '8px' }}>
          <Button variant="outlined" onClick={closeHandler}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleDelete}>
            Delete Card
          </Button>
        </Stack>
      }>
      <Box padding="32px 16px">
        <Typography variant="text1_Medium">{`Are you sure you want to delete the custom card "${cardData?.name}"`}</Typography>
      </Box>
    </ModalComponent>
  );
}

DeleteDynamicCardDialog.propTypes = {
  open: PropTypes.bool,
  deepDiveId: PropTypes.number,
  cardId: PropTypes.number,
  onClose: PropTypes.func
};

export default DeleteDynamicCardDialog;
