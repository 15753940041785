import { Stack, Typography } from '@mui/material';
import MLIcon from 'design-system/MLIcon';
import OrganizationIcon from 'design-system/icons/OrganizationIcon';
import ColumnsIcon from 'icons/ColumnsIcon';
import PropTypes from 'prop-types';
import React from 'react';

export const iconFromSuggestionIdType = (suggestionIdType) => {
  switch (suggestionIdType) {
    case 'company_meta_id':
      return <OrganizationIcon />;
    case 'supporting_resource_id':
      return <OrganizationIcon />;
    case 'column_id':
      return <ColumnsIcon />;
    default:
      return <ColumnsIcon />;
  }
};

const suggestionIdTypeToSx = (suggestionIdType) => {
  switch (suggestionIdType) {
    case 'company_meta_id':
      return { color: 'colors.link', backgroundColor: 'colors.selected' };
    case 'supporting_resource_id':
      return { color: 'colors.poor_orange', backgroundColor: 'colors.selected' };
    case 'column_id':
      return { color: 'colors.primary', backgroundColor: 'colors.selected' };
    default:
      return { color: 'colors.primary', backgroundColor: 'colors.selected' };
  }
};

function Suggestion({ id, display, focused }) {
  // Choose the icon based on the suggestion's category.
  const [suggestionIdType] = id.split(':');
  return (
    <Stack
      gap="8px"
      direction="row"
      alignItems="center"
      sx={{
        padding: '8px 12px',
        borderRadius: focused ? '4px' : null,
        backgroundColor: focused ? 'colors.selected' : null
      }}>
      <MLIcon
        icon={iconFromSuggestionIdType(suggestionIdType)}
        size="small"
        sx={suggestionIdTypeToSx(suggestionIdType)}
      />
      <Typography variant="text1_Normal">{display}</Typography>
    </Stack>
  );
}

Suggestion.propTypes = {
  display: PropTypes.string,
  id: PropTypes.string,
  focused: PropTypes.bool
};

export default Suggestion;
