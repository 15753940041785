import { v4 as uuidv4 } from 'uuid';

import { AssitantActionSubmissionInfo, Message } from './types';

class AssistantThread {
  id: string;
  timestamp: number;
  company_list_id?: number;
  folder_id?: number;
  messages: Message[];
  action?: object;
  submission_info: AssitantActionSubmissionInfo;

  constructor(params: Partial<AssistantThread> = {}) {
    this.id = uuidv4();
    this.timestamp = Date.now();
    this.messages = [];
    this.submission_info = { submitted: false };
    Object.assign(this, params);
  }
}

export const createNewThreadForState = (payload: Partial<AssistantThread> = {}) => {
  return new AssistantThread(payload);
};

export default AssistantThread;
