import { Button } from '@mui/material';
import { updateSavedQueryAction } from 'actions/saved_queries/update-saved-query-action';
import { launchWorkflowAction } from 'actions/workflows/launch-workflow-action';
import { stopWorkflowAction } from 'actions/workflows/stop-workflow-action';
import SaveQueryDialog from 'components/discovery/SaveQueryDialog';
import ModalComponent from 'components/modal/ModalComponent';
import MLIconButton from 'design-system/MLIconButton';
import MLInlineStack from 'design-system/MLInlineStack';
import { useOldStateSelector } from 'hooks/useOldStateSelector';
import CodeIcon from 'icons/CodeIcon';
import { WorkflowIcon } from 'icons/WorkflowIcon';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getThreadKey, updatePostSavedQueryId } from 'reducer/custom-analytics';
import {
  updateSelectedWorkflowIdByListId,
  updateWorkflowEditModeByIdAndListId
} from 'reducer/workflows-slice';
import {
  getCurrentlyRunningPostForWorkflowInList,
  getCustomAnalyticsPlanByThreadAndPostId,
  getCustomAnalyticsThreadSpecificPostById,
  getIsPostWorkflowRunByThreadIdAndPostId,
  getPostNameByThreadIdAndPostId,
  getPostSavedQueryIdByThreadIdAndPostId
} from 'selectors/custom-analytics';
import { getSavedQueryById } from 'selectors/saved-queries';
import { getUserIsDev } from 'selectors/user';
import {
  getWorkflowEditModeByListIdAndWorkflowId,
  getWorkflowSelectedPostIdByListId
} from 'selectors/workflows';

import AllWorkflowsDialogStep from './AllWorkflowsDialogStep';
import ForgotSaveWorkflowDialog from './ForgotSaveWorkflowDialog';
import LaunchWorkflowButton from './LaunchWorkflowButton';
import WorkflowPlannerDialogStep from './WorkflowPlannerDialogStep';

function WorkflowsCenterDialog({ open, listId, onClose, isWorflowRun }) {
  const dispatch = useDispatch();

  const [isJsonViewOpen, setIsJsonViewOpen] = useState(false);
  const [isSaveChangesModalOpen, setIsSaveChangesModalOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const threadKey = getThreadKey(listId);
  const isDev = useOldStateSelector(getUserIsDev);
  const selectedWorkflowId = useSelector((state) =>
    getWorkflowSelectedPostIdByListId(state, listId)
  );
  const plan = useSelector((state) =>
    getCustomAnalyticsPlanByThreadAndPostId(state, threadKey, selectedWorkflowId)
  );
  const isEditMode = useSelector((state) =>
    getWorkflowEditModeByListIdAndWorkflowId(state, listId, selectedWorkflowId)
  );
  const originalPlan = useSelector((state) =>
    getSavedQueryById(state, selectedWorkflowId)
  )?.parameters;
  const isWorkflowRun = useSelector((state) =>
    getIsPostWorkflowRunByThreadIdAndPostId(state, threadKey, selectedWorkflowId)
  );
  const post = useSelector((state) => {
    return isWorkflowRun
      ? getCustomAnalyticsThreadSpecificPostById(state, threadKey, selectedWorkflowId)
      : getCurrentlyRunningPostForWorkflowInList(state, threadKey, plan);
  });
  const actualPostId = post?.postId;
  const currentStepId = post?.currentStepId;
  const isRunning = currentStepId && currentStepId !== 'end';

  const workflowSavedQueryId = useSelector((state) =>
    getPostSavedQueryIdByThreadIdAndPostId(state, threadKey, selectedWorkflowId)
  );

  const hasChanges = useMemo(
    () => JSON.stringify(plan) !== JSON.stringify(originalPlan),
    [plan, originalPlan]
  );

  const workflowName = useSelector((state) =>
    getPostNameByThreadIdAndPostId(state, getThreadKey(listId), selectedWorkflowId)
  );

  const goToAllWorkflowsStep = () => {
    dispatch(updateSelectedWorkflowIdByListId({ listId, selectedWorkflowId: null }));
    setIsJsonViewOpen(false);
  };

  const handleSubmitPlan = () => {
    dispatch(
      launchWorkflowAction(
        listId,
        plan,
        plan.question,
        plan.steps[0].step_id,
        workflowName,
        workflowSavedQueryId
      )
    );
  };

  const onStopWorkflow = () => {
    const runId = post?.recordId;
    if (!runId) {
      return;
    }
    dispatch(stopWorkflowAction(runId, listId, actualPostId));
  };

  const handleSaveChanges = () => {
    dispatch(updateSavedQueryAction(selectedWorkflowId, plan));
  };

  const onOpenJsonView = () => {
    setIsJsonViewOpen(true);
  };

  const closeDialog = () => {
    if (originalPlan && hasChanges) {
      setIsSaveChangesModalOpen(true);
    } else {
      goToAllWorkflowsStep();
      onClose();
    }
  };

  const closeForgotToSave = () => {
    setIsSaveChangesModalOpen(false);
    goToAllWorkflowsStep();
    onClose();
  };

  const toggleEditMode = () => {
    dispatch(
      updateWorkflowEditModeByIdAndListId({
        listId,
        workflowId: selectedWorkflowId,
        isEditMode: !isEditMode
      })
    );
  };

  const isPlannerStep = Boolean(selectedWorkflowId);

  const onSaveQuery = (savedQueryId) => {
    dispatch(updatePostSavedQueryId({ threadKey, postId: selectedWorkflowId, savedQueryId }));
  };

  return (
    <React.Fragment>
      <ModalComponent
        title="Workflows"
        titleIcon={isPlannerStep ? null : <WorkflowIcon />}
        open={open}
        maxWidth={1200}
        height="100%"
        onClose={closeDialog}
        onBack={isPlannerStep ? goToAllWorkflowsStep : null}
        topBarActions={
          plan?.steps && (
            <MLInlineStack>
              {isDev && (
                <Button onClick={toggleEditMode}>{isEditMode ? 'Show Mode' : 'Edit Mode'}</Button>
              )}
              {isDev && (
                <MLIconButton
                  size="small"
                  sx={{ backgroundColor: 'colors.selected', borderRadius: '4px', padding: '4px' }}
                  onClick={onOpenJsonView}>
                  <CodeIcon />
                </MLIconButton>
              )}
              <SaveQueryDialog
                prompt={{ ...plan, saved_query_id: workflowSavedQueryId }}
                existColumnName={workflowName}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
                listId={listId}
                showIsBookmarked={true}
                isWorkflow={true}
                onSaveQuery={onSaveQuery}
              />
              {originalPlan && (
                <Button variant="outlined" onClick={handleSaveChanges} disabled={!hasChanges}>
                  Save Changes
                </Button>
              )}
              {!isWorflowRun && (
                <LaunchWorkflowButton isRunning={isRunning} handleSubmitPlan={handleSubmitPlan} />
              )}
              {isRunning && (
                <Button
                  onClick={onStopWorkflow}
                  sx={{
                    color: 'colors.hover_on_negative_red',
                    borderColor: 'colors.hover_on_negative_red',
                    ':hover': { color: 'colors.hover_on_negative_red' }
                  }}
                  variant="outlined">
                  Stop
                </Button>
              )}
            </MLInlineStack>
          )
        }>
        {isPlannerStep ? (
          <WorkflowPlannerDialogStep
            listId={listId}
            isJsonViewOpen={isJsonViewOpen}
            setIsJsonViewOpen={setIsJsonViewOpen}
          />
        ) : (
          <AllWorkflowsDialogStep listId={listId} />
        )}
      </ModalComponent>
      <ForgotSaveWorkflowDialog
        workflowId={selectedWorkflowId}
        plan={plan}
        open={isSaveChangesModalOpen}
        onClose={closeForgotToSave}
      />
    </React.Fragment>
  );
}

WorkflowsCenterDialog.propTypes = {
  listId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isWorflowRun: PropTypes.bool
};

export default WorkflowsCenterDialog;
