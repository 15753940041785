import { Button, ButtonProps } from '@mui/material';
import React from 'react';

import MLButtonWrapper, { MLButtonWrapperProps } from './base/MLButtonWrapper';

interface MLButtonProps extends ButtonProps, MLButtonWrapperProps {
  children: React.ReactNode;
}

export default function MLButton({
  id,
  tooltipText,
  tooltipPlacement = 'bottom',
  tooltipArrow = true,
  alertMessage,
  alertSeverity,
  children,
  ...extraProps
}: MLButtonProps) {
  const inner = (
    <Button id={id} {...extraProps}>
      {children}
    </Button>
  );
  return (
    <MLButtonWrapper
      tooltipText={tooltipText}
      tooltipPlacement={tooltipPlacement}
      tooltipArrow={tooltipArrow}
      alertMessage={alertMessage}
      alertSeverity={alertSeverity}>
      {inner}
    </MLButtonWrapper>
  );
}
