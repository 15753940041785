import { configureStore } from '@reduxjs/toolkit';
import { getAPIClient } from 'api/client-api';
import adminReducer from 'reducer/admin-slice';
import artReducer from 'reducer/art-slice';
import assistantReducer from 'reducer/assistant-slice';
import customAnalyticsReducer from 'reducer/custom-analytics';
import deepDiveReducer from 'reducer/deep-dive';
import domainReportsReducer from 'reducer/domain-reports-slice';
import exploreReducer from 'reducer/explore-slice';
import exploreTableUIReducer from 'reducer/explore-table-ui';
import filesReducer from 'reducer/files';
import foldersReducer from 'reducer/folders-slice';
import llmQueriesReducer from 'reducer/llm-queries-slice';
import notificationsReducer from 'reducer/notifications-slice';
import permissionsReducer from 'reducer/permissions-slice';
import publicShareLinksReducer from 'reducer/puclic-shares';
import resourcesReducer from 'reducer/resources-slice';
import savedQueriesReducer from 'reducer/saved-queries-slice';
import schedulesReducer from 'reducer/schedules-slice';
import teamsReducer from 'reducer/teams-slice';
import workflowsReducer from 'reducer/workflows-slice';

import generalSlice from './general-slice';

export const store = configureStore({
  reducer: {
    customAnalytics: customAnalyticsReducer,
    deepDive: deepDiveReducer,
    explore: exploreReducer,
    publicShareLinks: publicShareLinksReducer,
    exploreTableUI: exploreTableUIReducer,
    permissions: permissionsReducer,
    teams: teamsReducer,
    art: artReducer,
    files: filesReducer,
    folders: foldersReducer,
    admin: adminReducer,
    schedules: schedulesReducer,
    general: generalSlice,
    resources: resourcesReducer,
    notifications: notificationsReducer,
    llmQueries: llmQueriesReducer,
    savedQueries: savedQueriesReducer,
    domainReports: domainReportsReducer,
    workflows: workflowsReducer,
    assistant: assistantReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: getAPIClient()
      }
    })
});
