import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { submitDomainReportTaskAction } from 'actions/explore/submit-domain-report-task-action';
import { DOMAIN_REPORT_TASK_TYPES_TO_TITLES } from 'constants/domain-reports';
import MLModal from 'design-system/MLModal';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestDomainReportIdByListId } from 'selectors/domain-reports';
import { isTaskPromptConfigEqual, promptConfigToTaskParams } from 'utils/domain-report-utils';
import { determineErrorStatus, promptToPromptConfig } from 'utils/prompts-utils';

import DREQDConfig from './DREQDConfig';
import DREQDConfigEditable from './DREQDConfigEditable';

const QueryTypes = Object.freeze({
  DEFAULT: 'Default',
  CUSTOM: 'Custom'
});

function DomainReportEditQueryDialog({ listId, taskType, open, onClose, config, defaultConfig }) {
  const dispatch = useDispatch();
  const title = DOMAIN_REPORT_TASK_TYPES_TO_TITLES[taskType];

  const domainReportId = useSelector((state) => getLatestDomainReportIdByListId(state, listId));
  const customPromptConfig = config?.question ? promptToPromptConfig(config.question) : null;
  const defaultPromptConfig = defaultConfig?.question
    ? promptToPromptConfig(defaultConfig.question)
    : null;
  const isDefault = isTaskPromptConfigEqual(defaultPromptConfig, customPromptConfig);
  const [queryType, setQueryType] = React.useState(QueryTypes.DEFAULT);
  const [edited, setEdited] = React.useState(false);
  const [localPC, setLocalPC] = React.useState(customPromptConfig);

  const errorStatus = useMemo(() => (localPC ? determineErrorStatus(localPC) : null), [localPC]);

  const resetLocalPC = () => {
    setLocalPC(customPromptConfig);
    setEdited(false);
  };

  useEffect(() => {
    if (!edited) {
      setQueryType(isDefault ? QueryTypes.DEFAULT : QueryTypes.CUSTOM);
    }
  }, [edited, isDefault, setQueryType]);

  const handleChange = (event) => {
    if (!edited) {
      setEdited(true);
    }
    setQueryType(event.target.value);
  };
  const handleSave = () => {
    if (!domainReportId) {
      return;
    }
    let taskPromptConfig = null;
    if (queryType === QueryTypes.DEFAULT) {
      // Retrigger default
      taskPromptConfig = defaultPromptConfig;
      resetLocalPC();
    } else {
      // Save custom
      taskPromptConfig = localPC;
    }
    const parameters = promptConfigToTaskParams(taskPromptConfig);
    dispatch(submitDomainReportTaskAction({ listId, domainReportId, taskType, parameters }));
    onClose();
  };
  const closeAndReset = () => {
    onClose();
    resetLocalPC();
  };
  const primaryButtonProps = {
    label: 'Save and regenerate',
    onClick: handleSave,
    disabled: errorStatus && errorStatus.length > 0
  };
  const secondaryButtonProps = {
    label: 'Cancel',
    onClick: closeAndReset
  };
  return (
    <MLModal
      open={open}
      onClose={closeAndReset}
      title={`"${title}" settings`}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}>
      <Stack gap="12px">
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={queryType}
            onChange={handleChange}
            row>
            <FormControlLabel
              value={QueryTypes.DEFAULT}
              control={<Radio />}
              label={<Typography variant="text1_Normal">Default</Typography>}
            />
            <FormControlLabel
              value={QueryTypes.CUSTOM}
              control={<Radio />}
              label={<Typography variant="text1_Normal">Custom</Typography>}
            />
          </RadioGroup>
        </FormControl>
        {queryType === QueryTypes.DEFAULT && <DREQDConfig promptConfig={defaultPromptConfig} />}
        {queryType === QueryTypes.CUSTOM && (
          <DREQDConfigEditable
            listId={listId}
            config={config}
            localPC={localPC}
            setLocalPC={setLocalPC}
            editable
          />
        )}
      </Stack>
    </MLModal>
  );
}

DomainReportEditQueryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  config: PropTypes.object,
  defaultConfig: PropTypes.object,
  taskType: PropTypes.string,
  listId: PropTypes.number
};

export default DomainReportEditQueryDialog;
