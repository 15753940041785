import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Button, Stack, Typography } from '@mui/material';
import { EXPLORE_PREFIX } from 'constants/app-routes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { formatTimestamp } from 'utils/time-utils';

import CustomAnalyticsModal from './CustomAnalyticsModal';

function CustomAnalyticsAdminTab({ hidden, customAnalytics }) {
  const [openRecordId, setOpenRecordId] = useState(null);
  const [modalProps, setModalProps] = useState({});
  const handleOpenRecord = (record) => {
    setModalProps({
      open: true,
      title: `List ${record.company_list_id} - Record ${record.record_id}`,
      response: record.response,
      parameters: record.parameters,
      listId: record.company_list_id
    });
    setOpenRecordId(record.record_id);
  };
  const navigate = useNavigate();
  const onClickList = (listId) => {
    navigate(`${EXPLORE_PREFIX}/${listId}`);
  };
  const handleModalClose = () => {
    setOpenRecordId(null);
    setModalProps({});
  };
  return (
    <Stack
      hidden={hidden}
      direction="column"
      gap="16px"
      sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction="column" gap="0px" sx={{ textAlign: 'left' }}>
        <Stack
          direction="row"
          gap="32px"
          alignItems="center"
          sx={{ padding: '8px', borderBottom: '1px solid black' }}>
          <Button size="small" variant="contained" disabled sx={{ width: '60px' }}>
            List ID
          </Button>
          <Button size="small" variant="contained" disabled sx={{ width: '60px' }}>
            <QuestionAnswerIcon />
          </Button>
          <Typography variant="text1_Normal" width="140px">
            Timestamp
          </Typography>
          <Typography variant="text1_Normal" width="80px">
            Status
          </Typography>
          <Typography variant="text1_Normal">Question</Typography>
          <Typography variant="text1_Normal" sx={{ marginLeft: 'auto' }}>
            User
          </Typography>
        </Stack>
        {customAnalytics &&
          Object.keys(customAnalytics)
            .sort((key1, key2) => {
              return (
                new Date(customAnalytics[key2].timeSubmitted) -
                new Date(customAnalytics[key1].timeSubmitted)
              );
            })
            .map((recordId, index) => {
              const record = customAnalytics[recordId];
              return (
                <Stack
                  key={index}
                  direction="row"
                  gap="32px"
                  alignItems="center"
                  sx={{ padding: '8px', borderBottom: '1px solid black' }}>
                  <Button
                    onClick={() => onClickList(record.companyListId)}
                    size="small"
                    variant="contained"
                    sx={{ width: '60px' }}>
                    {record.companyListId}
                  </Button>
                  <Button
                    onClick={() => handleOpenRecord(record)}
                    size="small"
                    variant="outlined"
                    disabled={!record.response}
                    sx={{ width: '60px' }}>
                    <QuestionAnswerIcon />
                  </Button>
                  <Typography>{formatTimestamp(record.timeSubmitted)}</Typography>
                  <Typography color={record.status === 'FAILED' ? 'red' : null}>
                    {record.status}
                  </Typography>
                  <Typography variant="text1_Normal" width="400px">
                    {record.parameters.question}
                  </Typography>
                  <Typography variant="text1_Normal" sx={{ marginLeft: 'auto' }}>
                    {record.user.email}
                  </Typography>
                </Stack>
              );
            })}
      </Stack>
      {modalProps && (
        <CustomAnalyticsModal open={openRecordId} onClose={handleModalClose} {...modalProps} />
      )}
    </Stack>
  );
}

export default CustomAnalyticsAdminTab;

CustomAnalyticsAdminTab.propTypes = {
  hidden: PropTypes.bool,
  customAnalytics: PropTypes.object
};
