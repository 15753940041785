import {
  Box,
  Collapse,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import SelectWithAnswerTypeComponent from 'components/SelectWithAnswerTypeComponent';
import SelectFiltersComponent from 'components/tile/discovery/SelectFiltersComponent';
import {
  COMPANIES_OPTIONS,
  COMPANIES_OPTION_OBJECTS,
  OPTIONS,
  OPTION_OBJECTS,
  PROMPT_SELECT_STYLE
} from 'constants/prompt2';
import MLIconButton from 'design-system/MLIconButton';
import ArrowIcon from 'icons/ArrowIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearExploreTableUIState } from 'reducer/explore-table-ui';
import { oldPromptConfigToNewPromptConfig } from 'utils/prompt2-utils';
import {
  getContextSections,
  getContextTextAndTooltip,
  getHandleSearchPlaceChange,
  promptConfigToSearchPlaces
} from 'utils/prompt-dropdown-utils';

import MultiSectionsSelectComponent from './MultiSectionsSelectComponent';
import PromptEndAdornment from './PromptEndAdornment';
import PromptErrorComponent from './PromptErrorComponent';
import { ANSWER_TYPES } from './PromptInputComponent';
import PromptTextFieldComponent from './PromptTextFieldComponent';
import SlimMultiSelectPromptInputComponent from './SlimMultiSelectComponent';

const buttonStyle = {
  height: '28px'
};

const bigButtonStyle = {
  ...buttonStyle,
  gap: '4px',
  textAlign: 'left',
  width: '100px',
  paddingX: '8px',
  height: '32px'
};

/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
const PromptInputComponent2 = ({
  listId,
  promptConfig,
  setPromptConfig,
  isDev,
  isProccessing,
  handleSubmit,
  showError,
  filters,
  setFilters,
  setShowError,
  getIsInputDisabled,
  dontAllowSubmitCA,
  CompaniesSelector,
  toggleField,
  columns,
  knowledge,
  errorStatus
}) => {
  const [option, setOption] = useState(OPTIONS.COMPANIES);
  const [collapsePrompt, setCollapsePrompt] = useState(false);
  const [companiesOption, setCompaniesOption] = useState(
    option === OPTIONS.COMPANIES ? COMPANIES_OPTIONS.QUERY : null
  );
  const appTheme = useTheme();
  const dispatch = useDispatch();

  const handleChangeOption = (_event, newOption) => {
    if (!newOption || newOption === option) {
      return;
    }
    if (newOption === OPTIONS.COMPANIES || option === OPTIONS.COMPANIES) {
      toggleField('useDiscover');
    }
    if (newOption === OPTIONS.ASK) {
      toggleField('forEachCompany-off');
    }
    if (newOption === OPTIONS.COLUMN) {
      toggleField('forEachCompany-on');
    }
  };

  const showTextField = () => {
    if (option === OPTIONS.COMPANIES) {
      return companiesOption !== COMPANIES_OPTIONS.PEERS;
    }
    return true;
  };

  const getPlaceholder = () => {
    if (option === OPTIONS.COMPANIES) {
      return COMPANIES_OPTION_OBJECTS?.[companiesOption]?.placeholder || '';
    }
    return OPTION_OBJECTS?.[option]?.placeholder || '';
  };

  const isFilterMissing = Boolean(Object.values(filters).find((filter) => !filter.length));
  const handleCompanyOptionChange = useCallback(
    (_event, newCompanyOption) => {
      if (newCompanyOption === null) {
        return;
      }
      setShowError(false);
      if (newCompanyOption === COMPANIES_OPTIONS.PEERS) {
        setPromptConfig((prev) => ({ ...prev, discoverMethod: newCompanyOption, question: '' }));
      } else {
        setPromptConfig((prev) => ({ ...prev, discoverMethod: newCompanyOption }));
        dispatch(clearExploreTableUIState({ listId }));
      }
    },
    [listId, dispatch, setPromptConfig, setShowError]
  );

  useEffect(() => {
    const newPromptConfig = oldPromptConfigToNewPromptConfig(promptConfig);
    setOption(newPromptConfig.option);
    setCompaniesOption(newPromptConfig.companiesOption);
  }, [promptConfig]);

  const searchPlaces = useMemo(() => promptConfigToSearchPlaces(promptConfig), [promptConfig]);

  const contextSections = useMemo(
    () =>
      getContextSections({
        allColumns: columns,
        useDomainKnowledge: promptConfig.useDomainKnowledge,
        columns: promptConfig.columns,
        resources: promptConfig.resources,
        setPromptConfig,
        knowledge,
        useTable: promptConfig.useTable
      }),
    [
      columns,
      promptConfig.useDomainKnowledge,
      promptConfig.columns,
      promptConfig.resources,
      setPromptConfig,
      knowledge,
      promptConfig.useTable
    ]
  );

  const { text: contextSelectorTitle, tooltipText: contextSelectorTooltip } = useMemo(
    () =>
      getContextTextAndTooltip(
        promptConfig.useTable,
        promptConfig.useDomainKnowledge,
        contextSections
      ),
    [promptConfig.useTable, promptConfig.useDomainKnowledge, contextSections]
  );

  const handleSearchPlaceChange = getHandleSearchPlaceChange(setPromptConfig, knowledge);

  const selectStyle = PROMPT_SELECT_STYLE;

  return (
    <Stack
      justifyContent="center"
      gap="12px"
      sx={{
        boxSizing: 'border-box',
        border: '1px solid',
        borderColor: 'colors.hover_on_gray_bg',
        borderRadius: '16px',
        margin: '16px',
        padding: '12px',
        backgroundColor: 'colors.primary_bg',
        boxShadow: '0px 1px 8px 0px #19213D14',
        transform: collapsePrompt ? 'translateY(32px)' : 'none',
        overflowY: 'none'
      }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="8px"
        sx={{
          borderBottom: collapsePrompt ? 'none' : '1px solid',
          borderColor: 'colors.ui_border'
        }}>
        <Collapse in={!collapsePrompt} timeout="auto" unmountOnExit>
          <Tabs
            value={option}
            onChange={handleChangeOption}
            textColor="secondary"
            sx={{
              minHeight: '32px'
            }}>
            {Object.keys(OPTION_OBJECTS).map((value, index) => {
              const data = OPTION_OBJECTS[value];
              return (
                <Tab
                  key={index}
                  value={value}
                  color="primary"
                  sx={{
                    minHeight: '32px',
                    paddingBottom: '0px',
                    gap: '8px',
                    '&:hover': {
                      color: appTheme.vars.palette.colors.primary
                    },
                    '&.Mui-selected': {
                      color: appTheme.vars.palette.colors.primary
                    }
                  }}
                  label={
                    <Stack direction="row" alignItems="center" sx={{ minHeight: '32px' }}>
                      <Typography paddingBottom="6px" variant="text1_Medium">
                        {data.label}
                      </Typography>
                    </Stack>
                  }
                />
              );
            })}
          </Tabs>
        </Collapse>
        <MLIconButton
          onClick={() => setCollapsePrompt(!collapsePrompt)}
          variant="outlined"
          color="primary"
          size="small"
          sx={{
            padding: 0,
            marginBottom: collapsePrompt ? '12px' : 0,
            transform: `rotate(${collapsePrompt ? 0 : 180}deg)`,
            marginLeft: 'auto'
          }}>
          <ArrowIcon color={appTheme.vars.palette.colors.primary} />
        </MLIconButton>
      </Stack>
      <Collapse in={!collapsePrompt} timeout="auto" unmountOnExit>
        <Stack direction="row" gap="16px" justifyContent="space-between" alignItems="end">
          {option === OPTIONS.COMPANIES && (
            <Stack gap="4px" textAlign="left">
              <ToggleButtonGroup
                value={companiesOption}
                onChange={handleCompanyOptionChange}
                exclusive
                size="small">
                {Object.keys(COMPANIES_OPTION_OBJECTS).map((value, index) => {
                  const data = COMPANIES_OPTION_OBJECTS[value];
                  return (
                    <ToggleButton key={index} value={value} sx={bigButtonStyle}>
                      <Typography variant="text1_Medium">{data.label}</Typography>
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </Stack>
          )}
          {[OPTIONS.ASK, OPTIONS.COLUMN].includes(option) && (
            <Stack direction="row" gap="8px" alignItems="end" width="100%">
              <SelectWithAnswerTypeComponent
                currentAnswerType={promptConfig.answerType || Object.keys(ANSWER_TYPES)[0]}
                setAnswerType={(newAnswerType) =>
                  setPromptConfig((prev) => ({ ...prev, answerType: newAnswerType }))
                }
                selectStyle={selectStyle}
              />
              <SlimMultiSelectPromptInputComponent
                selectedValues={searchPlaces}
                onValueChange={handleSearchPlaceChange}
                allTag="Search everywhere"
                emptyTag="Select sources"
                sx={{ width: '200px' }}
                selectStyle={selectStyle}
                knowledge={knowledge}
              />
              <MultiSectionsSelectComponent
                containerSx={{
                  ...selectStyle,
                  flex: 1,
                  maxWidth: '250px'
                }}
                sections={contextSections}
                title={contextSelectorTitle}
                tooltipText={contextSelectorTooltip}
                promptConfig={promptConfig}
              />
            </Stack>
          )}
          {option === OPTIONS.COMPANIES && (
            <SelectFiltersComponent
              filters={filters}
              setFilters={setFilters}
              isFilterMissing={isFilterMissing}
            />
          )}
        </Stack>
      </Collapse>
      <Collapse in={!collapsePrompt} timeout="auto" unmountOnExit>
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          gap="4px"
          sx={{
            border: '1px solid',
            borderColor: showError ? 'colors.negative_red' : 'colors.ui_border',
            borderRadius: '4px',
            padding: '8px',
            backgroundColor: 'colors.primary_bg',
            boxShadow: '0px 1px 5px 0px #1018281A',
            color: 'colors.secondary_text'
          }}>
          {showTextField() ? (
            <PromptTextFieldComponent
              {...{
                getPlaceholder,
                getIsInputDisabled,
                handleSubmit,
                isProccessing,
                setPromptConfig,
                promptConfig,
                isDev,
                dontAllowSubmitCA,
                setShowError,
                listId
              }}
            />
          ) : (
            <Stack
              direction="row"
              flexWrap="no-wrap"
              justifyContent="space-between"
              alignItems="flex-end"
              paddingTop="1px"
              width="100%"
              height="105px">
              <Box alignItems="flex-start" height="100%">
                {companiesOption === COMPANIES_OPTIONS.PEERS && CompaniesSelector}
              </Box>
              <PromptEndAdornment
                handleSubmit={handleSubmit}
                isProccessing={isProccessing}
                setPromptConfig={setPromptConfig}
                promptConfig={promptConfig}
                isDev={isDev}
                standalone
              />
            </Stack>
          )}
        </Stack>
        {showError && !collapsePrompt && (
          <PromptErrorComponent errorStatus={errorStatus} showError={showError} />
        )}
      </Collapse>
    </Stack>
  );
};

PromptInputComponent2.propTypes = {
  listId: PropTypes.number,
  promptConfig: PropTypes.object,
  setPromptConfig: PropTypes.func,
  isDev: PropTypes.bool,
  isProccessing: PropTypes.bool,
  handleSubmit: PropTypes.func,
  showError: PropTypes.bool,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  setShowError: PropTypes.func,
  getIsInputDisabled: PropTypes.func,
  dontAllowSubmitCA: PropTypes.bool,
  CompaniesSelector: PropTypes.node,
  toggleField: PropTypes.func,
  columns: PropTypes.object,
  knowledge: PropTypes.object,
  errorStatus: PropTypes.string
};

export default PromptInputComponent2;
