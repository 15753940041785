export const getQueryParamsString = (params) => {
  const queryParams = new URLSearchParams(params);
  return queryParams.toString();
};

export const translateFilterParams = (params) => {
  const frontToBackMapping = {
    organizationId: 'organization_id',
    userId: 'user_id',
    num: 'N',
    all: 'all'
  };

  return Object.entries(params).reduce((acc, [key, value]) => {
    if (frontToBackMapping[key]) {
      acc[frontToBackMapping[key]] = value;
    }
    return acc;
  }, {});
};
