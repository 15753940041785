import PropTypes from 'prop-types';
import React from 'react';
import { BaseEdge, MarkerType, getBezierPath } from 'reactflow';
import 'reactflow/dist/style.css';
import { applyThemeColorOpacity } from 'utils/theme-utils';

function WorkFlowPlanEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition
}) {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  return (
    <React.Fragment>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{ stroke: applyThemeColorOpacity('colors.primary_text', 38) }}
        markerEnd={{
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: applyThemeColorOpacity('colors.primary_text', 38)
        }}
      />
    </React.Fragment>
  );
}

WorkFlowPlanEdge.propTypes = {
  id: PropTypes.string,
  sourceX: PropTypes.number,
  sourceY: PropTypes.number,
  sourcePosition: PropTypes.string,
  targetX: PropTypes.number,
  targetY: PropTypes.number,
  targetPosition: PropTypes.string
};

export default WorkFlowPlanEdge;
