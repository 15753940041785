import { Stack } from '@mui/material';
import { makeNewClustersAction } from 'actions/clusters/make-new-clusters-action';
import { updateCurrentDiscoveryCluster } from 'actions/clusters/update-current-cluster-action';
import { addCompaniesToListAction } from 'actions/company_lists/add-companies-to-list-action';
import { createNewDiscoveryInCustomAnalyticsThreadAction } from 'actions/custom-analytics/create-new-discovery-in-custom-analytics-thread-action';
import { changeDiscoveryNameAction } from 'actions/explore/change-discovery-name';
import { loadAllExploreLists } from 'actions/explore/load-all-explores-action';
import { loadDomainReportAction } from 'actions/explore/load-domain-report-action';
import { loadExplore } from 'actions/explore/load-explore-action';
import { loadExploreView } from 'actions/explore/load-explore-view-action';
import { loadAllFoldersAction } from 'actions/folders/load-all-folders-action';
import { makeNewMultipleRunsAction } from 'actions/runs/make-new-multiple-runs-action';
import { sendExploreEvent } from 'actions/users/send-user-event-action';
import AssistantLeftPane from 'components/assistant/AssistantLeftPane';
import DiscoveryRunScreenClusters from 'components/clusters/DiscoveryRunScreenClusters';
import { CreateListDialog } from 'components/discovery/CreateListDialog';
import CreateListFromCompaniesDialog from 'components/discovery/CreateListFromCompaniesDialog';
import DiscoveryLeftPane from 'components/discovery/DiscoveryLeftPane';
import { ErrorDialog } from 'components/discovery/ErrorDialog';
import { MismatchDialog } from 'components/discovery/MismatchDialog';
import SelectAddToListMenu from 'components/discovery/SelectAddToListMenu';
import SelectExistingListDialog from 'components/discovery/SelectExistingListDialog';
import DomainKnowledgeTab from 'components/domain-knowledge/DomainKnowledgeTab';
import DomainReportTab from 'components/domain-report/DomainReportTab';
import PusherListListener from 'components/pusher/PusherListListener';
import DomainResearchTab from 'components/subdomains/DomainResearchTab';
import ListSummaryComponent from 'components/summary/list/ListSummaryComponent';
import NoClustersComponent from 'components/tile/discovery/NoClustersComponentConnector';
import {
  DISCOVERY_TYPES,
  MAX_CSV_SIZE,
  MAX_CSV_SIZE_ERROR_TEXT,
  NOT_FOUND_COMPANIES_ERROR_TEXT,
  VIEW_TYPES
} from 'constants/discovery';
import { RESEARCH_TYPES } from 'constants/researches';
import { TOAST_TYPES } from 'constants/toasts';
import { USER_EVENTS } from 'constants/userEvents';
import MLIconButton from 'design-system/MLIconButton';
import { dispatch as oldDispatch } from 'hooks/AppStateProvider';
import { useOnError } from 'hooks/OnErrorProvider';
import RerunIcon from 'icons/RerunIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearExploreMismatchCompanies } from 'reducer/explore-slice';
import { updateExploreCsv, updateSelectedView } from 'reducer/explore-table-ui';
import { updateCurrentFolderId } from 'reducer/folders-slice';
import { updateCurrentOpenResourceName } from 'reducer/resources-slice';
import { getExploreListSelectedView } from 'selectors/explore-table-ui';
import { csvToArray } from 'utils/companyList-utils';

import DiscoveryRunScreenTable from './DiscoveryRunScreenTable';

export const DISCOVERY_SCROLL_ELEMENT_ID = 'discovery-scroll-element';

/* eslint-disable require-unicode-regexp */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
function DiscoveryRunBaseScreen({
  viewId,
  name,
  listId,
  rows,
  companiesLoading,
  areCompaniesNotFound,
  addToast,
  haveSelectedCompanies,
  mismatchedComapanyMetas,
  currentClusterLabel,
  clustersElement,
  isDev,
  isBasicUser,
  clustersId,
  customColumns,
  columnsSettings,
  runningRows,
  featureFlags,
  folderId,
  highlightedColumns
}) {
  const reduxDispatch = useDispatch();

  const scrollElementRef = useRef();
  const [openNewListDialog, setOpenNewListDialog] = useState(false);
  const [isLoadingRows, setIsLoadingRows] = useState(false);
  const [loadingName, setLoadingName] = useState(null);
  const [isCSVPassLimit, setIsCSVPassLimit] = useState(false);
  const [showCompaniesNotFoundDialog, setShowCompaniesNotFoundDialog] = useState(false);
  const [openSelectExistingListDialog, setOpenSelectExistingListDialog] = React.useState(false);
  const [selectAddToListMenuAnchorEl, setSelectAddToListMenuAnchorEl] = React.useState(null);
  const [isCollapsed, setIsCollapsed] = useState(
    (featureFlags || []).includes('unify') || isBasicUser
  );
  const [unifyIsCollapsed, setUnifyIsCollapsed] = useState(isBasicUser);
  const { forbiddenResource } = useOnError();
  const openSelectAddToListMenu = Boolean(selectAddToListMenuAnchorEl);
  const selectedView = useSelector((reduxState) => getExploreListSelectedView(reduxState, listId));

  useEffect(() => {
    reduxDispatch(updateSelectedView({ listId, selectedView: VIEW_TYPES.TABLE }));
  }, [reduxDispatch, listId]);

  useEffect(() => {
    reduxDispatch(loadAllFoldersAction());
    reduxDispatch(updateCurrentFolderId({ folderId }));
  }, [reduxDispatch, folderId]);

  useEffect(() => {
    if (name) {
      reduxDispatch(
        updateCurrentOpenResourceName({
          resourceName: name,
          resourceType: RESEARCH_TYPES.EXPLORE.id,
          resourceId: listId
        })
      );
    }
  }, [reduxDispatch, name, listId]);

  useEffect(() => {
    reduxDispatch(loadExplore(listId, forbiddenResource));
    reduxDispatch(loadDomainReportAction(listId));
    if (viewId) {
      reduxDispatch(loadExploreView(listId, viewId));
    }
    oldDispatch(updateCurrentDiscoveryCluster(null));
  }, [listId, viewId, reduxDispatch, forbiddenResource]);

  useEffect(() => {
    if (!areCompaniesNotFound) {
      setShowCompaniesNotFoundDialog(false);
    }
    if (companiesLoading) {
      setIsLoadingRows(true);
    } else {
      setIsLoadingRows(false);
      setLoadingName(null);
      setShowCompaniesNotFoundDialog(areCompaniesNotFound);
    }
  }, [companiesLoading, areCompaniesNotFound]);

  const onNewList = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenNewListDialog(true);
  }, []);

  const onDeepDive = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      reduxDispatch(makeNewMultipleRunsAction(listId));
    },
    [listId, reduxDispatch]
  );

  const onEvent = useCallback(
    (message, toastType) => {
      addToast(message, toastType);
    },
    [addToast]
  );

  const onError = useCallback(
    (message) => {
      onEvent(message, TOAST_TYPES.ERROR);
    },
    [onEvent]
  );

  const enterNewCompany = useCallback(
    (newName) => {
      setIsLoadingRows(true);
      setIsCSVPassLimit(false);
      setShowCompaniesNotFoundDialog(false);
      setLoadingName(newName);
      reduxDispatch(addCompaniesToListAction(listId, [newName], onEvent));
      oldDispatch(
        sendExploreEvent(USER_EVENTS.COMPANY_ADDED, listId, `Added company ${newName}`, {
          name: newName
        })
      );
    },
    [listId, onEvent, reduxDispatch]
  );

  const handleFiles = useCallback(
    (files) => {
      if (files.length > 1) {
        /* eslint-disable no-alert */
        alert('Please only submit one CSV file!');
        return;
      }
      const reader = new FileReader();
      const isCsv = files[0]?.name.split('.').pop().toLowerCase().includes('csv');
      if (isCsv) {
        setIsCSVPassLimit(false);
        setShowCompaniesNotFoundDialog(false);
        setIsLoadingRows(true);
        reader.onload = () => {
          const csvArray = csvToArray(reader.result);
          reduxDispatch(updateExploreCsv({ listId, csv: csvArray }));
          const matchingFirstColumnTitle = /\(\d+\)/g;
          const names = csvArray
            .map((row) =>
              row[0].trim().replace(',', '').replace(matchingFirstColumnTitle, '').trim()
            )
            .filter(
              (companyName) =>
                !['company', 'companyname', 'name', 'company name'].includes(
                  companyName.toLowerCase()
                )
            )
            .filter((companyName) => companyName.length);
          if (names.length > MAX_CSV_SIZE) {
            setIsLoadingRows(false);
            setIsCSVPassLimit(true);
          } else {
            reduxDispatch(addCompaniesToListAction(listId, names, onEvent));
            oldDispatch(
              sendExploreEvent(
                USER_EVENTS.COMPANIES_CSV_UPLOADED,
                listId,
                `Added ${names.length} companies via CSV`,
                { names }
              )
            );
          }
        };
        reader.readAsText(files[0]);
      } else {
        /* eslint-disable no-alert */
        alert('File must be a CSV file!');
      }
    },
    [listId, onEvent, reduxDispatch]
  );

  const onTriggerClusters = () => {
    reduxDispatch(makeNewClustersAction(listId));
  };

  const onExtend = useCallback(() => {
    reduxDispatch(
      createNewDiscoveryInCustomAnalyticsThreadAction(listId, DISCOVERY_TYPES.PEERS, {})
    );
  }, [listId, reduxDispatch]);

  const heights = useMemo(
    () => ({
      navBarHeight: 50,
      paddingTop: 16,
      gap: 8,
      name: 45
    }),
    []
  );

  heights.tableAndName = heights.navBarHeight + heights.paddingTop;
  heights.aboveMainComponent =
    heights.name + heights.navBarHeight + heights.gap + heights.paddingTop;

  const noClustersComponent = (
    <NoClustersComponent
      listId={listId}
      onTriggerClustersRun={onTriggerClusters}
      isListEmpty={!rows || rows.length === 0}
      headerHeight={heights.aboveMainComponent}
    />
  );

  const onTitleChange = (newTitle) => {
    if (newTitle === name) return;
    reduxDispatch(changeDiscoveryNameAction(listId, newTitle, onError));
  };

  const actionsForClusters = {
    onTriggerClusters,
    onExtend,
    onNewList: (event) => setSelectAddToListMenuAnchorEl(event.currentTarget)
  };

  const onNewListAction = useCallback(
    (event) => setSelectAddToListMenuAnchorEl(event.currentTarget),
    []
  );

  const actionsForTable = {
    onDeepDive,
    onNewList: onNewListAction,
    addToast,
    enterNewCompany,
    onError,
    handleFiles,
    onExtend
  };

  const clustersRerunButton = clustersId && (
    <MLIconButton
      sx={{ color: 'colors.primary' }}
      disabled={clustersElement && !clustersElement.ready}
      onClick={onTriggerClusters}
      tooltipText="Recreate Clusters View">
      <RerunIcon />
    </MLIconButton>
  );

  return (
    <Stack
      gap={`${heights.gap}px`}
      sx={{
        flex: 1,
        display: 'flex',
        paddingBottom: 0,
        flexDirection: 'row',
        overflow: 'hidden',
        height: `calc(100vh - ${heights.navBarHeight}px)`
      }}>
      <DiscoveryLeftPane
        listId={listId}
        heights={heights}
        rows={rows}
        onError={onError}
        isDev={isDev}
        name={name}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        disableCollapse={isBasicUser}
      />
      {(featureFlags || []).includes('unify') && (
        <AssistantLeftPane
          heights={heights}
          isCollapsed={unifyIsCollapsed}
          setIsCollapsed={setUnifyIsCollapsed}
        />
      )}
      <Stack
        ref={scrollElementRef}
        direction="column"
        id={DISCOVERY_SCROLL_ELEMENT_ID}
        flex={1}
        gap={`${heights.gap}px`}
        padding={`${
          selectedView === VIEW_TYPES.DOMAIN_REPORT ? 0 : heights.paddingTop
        }px 16px 0 16px`}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: `calc(100vh - ${heights.tableAndName}px)`,
          minHeight: `calc(100vh - ${heights.tableAndName}px)`
        }}>
        {rows && (
          <ListSummaryComponent
            listId={listId}
            name={name}
            paddingTop={selectedView === VIEW_TYPES.DOMAIN_REPORT ? heights.paddingTop : 0}
            numCompanies={rows?.length}
            onTitleChange={onTitleChange}
            backTag="Go to all List"
            isCollapsed={isCollapsed}>
            {selectedView === VIEW_TYPES.CLUSTERS && clustersRerunButton}
          </ListSummaryComponent>
        )}
        {selectedView === VIEW_TYPES.CLUSTERS && (
          <DiscoveryRunScreenClusters
            clustersElement={clustersElement}
            currentClusterLabel={currentClusterLabel}
            rows={rows}
            listId={listId}
            noClustersComponent={noClustersComponent}
            setOpenSelectExistingListDialog={setOpenSelectExistingListDialog}
            isDev={isDev}
            headerHeight={heights.aboveMainComponent}
            {...actionsForClusters}
          />
        )}
        {selectedView === VIEW_TYPES.TABLE && (
          <DiscoveryRunScreenTable
            {...{
              viewId,
              isLoadingRows,
              tableHeaderHeight: heights.aboveMainComponent,
              haveSelectedCompanies,
              name,
              rows,
              loadingName,
              setOpenSelectExistingListDialog,
              customColumns,
              columnsSettings,
              listId,
              isDev,
              scrollElement: scrollElementRef.current,
              runningRows,
              featureFlags,
              highlightedColumns
            }}
            {...actionsForTable}></DiscoveryRunScreenTable>
        )}
        {selectedView === VIEW_TYPES.DOMAIN_KNOWLEDGE && <DomainKnowledgeTab listId={listId} />}
        {selectedView === VIEW_TYPES.DOMAIN_REPORT && <DomainReportTab listId={listId} />}
        {selectedView === VIEW_TYPES.DOMAIN_RESEARCH && (
          <DomainResearchTab listId={listId} headerHeight={heights.aboveMainComponent} />
        )}
      </Stack>
      <MismatchDialog
        open={Boolean(mismatchedComapanyMetas)}
        onClose={() => reduxDispatch(clearExploreMismatchCompanies({ listId }))}
        companies={mismatchedComapanyMetas}
        listId={listId}></MismatchDialog>
      {isCSVPassLimit && (
        <ErrorDialog
          onClose={() => setIsCSVPassLimit(false)}
          text={MAX_CSV_SIZE_ERROR_TEXT}></ErrorDialog>
      )}
      {showCompaniesNotFoundDialog && (
        <ErrorDialog
          onClose={() => setShowCompaniesNotFoundDialog(false)}
          text={NOT_FOUND_COMPANIES_ERROR_TEXT}></ErrorDialog>
      )}
      <CreateListDialog
        open={openNewListDialog}
        onClose={() => {
          setOpenNewListDialog(false);
        }}
        listId={listId}
        folderId={folderId}></CreateListDialog>
      <SelectExistingListDialog
        open={openSelectExistingListDialog}
        setOpen={setOpenSelectExistingListDialog}
        listId={listId}
        addToast={addToast}
      />
      <SelectAddToListMenu
        open={openSelectAddToListMenu}
        anchorEl={selectAddToListMenuAnchorEl}
        onClose={() => setSelectAddToListMenuAnchorEl(null)}
        onNewList={onNewList}
        onSelectAddToList={() => {
          setOpenSelectExistingListDialog(true);
          reduxDispatch(loadAllExploreLists());
        }}
      />
      <CreateListFromCompaniesDialog />
      <PusherListListener listId={listId} />
    </Stack>
  );
}

DiscoveryRunBaseScreen.propTypes = {
  discoveryId: PropTypes.string,
  viewId: PropTypes.string,
  name: PropTypes.string,
  listId: PropTypes.number,
  rows: PropTypes.array,
  companiesLoading: PropTypes.object,
  areCompaniesNotFound: PropTypes.bool,
  addToast: PropTypes.func,
  haveSelectedCompanies: PropTypes.bool,
  mismatchedComapanyMetas: PropTypes.array,
  currentClusterLabel: PropTypes.string,
  clustersElement: PropTypes.object,
  allDiscoveries: PropTypes.object,
  isDev: PropTypes.bool,
  isBasicUser: PropTypes.bool,
  clustersId: PropTypes.number,
  customColumns: PropTypes.object,
  columnsSettings: PropTypes.object,
  runningRows: PropTypes.array,
  featureFlags: PropTypes.array,
  folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  highlightedColumns: PropTypes.array
};

export default React.memo(DiscoveryRunBaseScreen);
