import { Box, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function RunTooltip({ run, visible, children }) {
  const info = { recordId: run.recordId, ...run.parameters };
  const keysToDelete = ['description', 'logo_url', 'existing_record_id', 'time_submitted'];
  keysToDelete.forEach((element) => delete info[element]);
  const statuses = { ...run.statuses };
  const [isOnHover, setIsOnHover] = useState(false);
  const tooltip = (
    <Stack>
      {info &&
        Object.keys(info).map((key) => (
          <Typography variant="text1_Normal" key={key}>
            <Typography variant="text1_Semibold" sx={{ marginRight: '8px' }}>
              {key}:
            </Typography>
            {info[key]}
          </Typography>
        ))}
      {statuses && (
        <Typography variant="text1_Semibold" sx={{ marginRight: '8px' }}>
          Statuses:
        </Typography>
      )}
      {statuses &&
        Object.keys(statuses).map((key) => (
          <Typography variant="text1_Normal" key={key}>
            <Typography variant="text1_Semibold" sx={{ marginRight: '8px' }}>
              {key}:
            </Typography>
            {statuses[key]}
          </Typography>
        ))}
    </Stack>
  );
  return (
    <Tooltip title={tooltip} open={visible && isOnHover}>
      <Box onMouseEnter={() => setIsOnHover(true)} onMouseLeave={() => setIsOnHover(false)}>
        {children}
      </Box>
    </Tooltip>
  );
}

RunTooltip.propTypes = {
  run: PropTypes.object,
  visible: PropTypes.bool,
  children: PropTypes.any
};

export default RunTooltip;
