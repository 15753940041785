import { Box, Button, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getUtcTimeDiffFromNowInSeconds } from 'utils/time-utils';

export default function RunningSearchAction({
  searchTimestamp,
  progress,
  progressExistInState,
  handleCancel,
  timeColor,
  mode,
  children
}) {
  const [elapseTime, setElapseTime] = useState('');
  const [transitionProgress, setTransitionProgress] = useState(false);

  useEffect(() => {
    if (progressExistInState) {
      setTransitionProgress(true);
    }
    if (progress === 100) {
      setTransitionProgress(false);
    }
  }, [progressExistInState, progress]);

  useEffect(() => {
    if (!searchTimestamp || progress === 100) {
      return;
    }
    const tickerId = setInterval(() => {
      const timeElapseInSeconds = getUtcTimeDiffFromNowInSeconds(searchTimestamp);
      const seconds = Math.floor(timeElapseInSeconds % 60);
      const minutes = Math.floor(timeElapseInSeconds / 60);
      setElapseTime(`${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);
    }, 1000);
    return () => clearInterval(tickerId);
  }, [searchTimestamp, progress]);

  return (
    <Stack direction="column" alignItems="center" sx={{ width: '100%' }}>
      <Stack direction="row" alignItems="center" width="100%">
        {children}

        {handleCancel && (
          <Button
            marginLeft={2}
            onClick={handleCancel}
            sx={{ padding: mode === 'tile' ? '0 8px' : '6px 8px' }}>
            <Typography variant="text2_Normal">Cancel</Typography>
          </Button>
        )}
      </Stack>
      <Stack direction="row" alignItems="center" width="100%">
        <Box
          sx={{
            backgroundColor: 'colors.selected',
            borderRadius: '2px',
            height: '8px',
            display: 'flex',
            alignItems: 'center'
          }}
          flex={1}>
          <Box
            sx={{
              transition: transitionProgress ? 'width 15s linear' : 'none',
              height: '6px',
              width: `${progress}%`,
              borderRadius: '1px',
              backgroundColor: 'colors.primary'
            }}
          />
        </Box>
        <Typography
          variant="text1_Normal"
          color={timeColor || 'colors.primary_text'}
          fontSize="12px"
          marginRight="8px"
          marginLeft="24px"
          width="34px">
          {elapseTime}
        </Typography>
      </Stack>
    </Stack>
  );
}

RunningSearchAction.propTypes = {
  searchTimestamp: PropTypes.string,
  progress: PropTypes.number,
  progressExistInState: PropTypes.bool,
  handleCancel: PropTypes.func,
  children: PropTypes.element,
  timeColor: PropTypes.string,
  mode: PropTypes.oneOf(['tile', 'monitor'])
};
