import AssistantThread from 'models/assistant/AssistantThread';

import BaseClient from './BaseClient';

const URL_PREFIX = 'assistant';

export default class AssistantClient extends BaseClient {
  submit(thread: AssistantThread) {
    return this.post(`${URL_PREFIX}`, thread);
  }
}
