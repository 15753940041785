import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { LabelList, Scatter } from 'recharts';
import { appTheme } from 'theme';

import ScatterPlotBaseComponent from './ScatterPlotBaseComponent';

/* eslint-disable id-length */
export default function ScatterPlotComponent({
  title,
  xAxisValues,
  yAxisValues,
  xAxisName,
  yAxisName,
  labels,
  mode
}) {
  const data = useMemo(() => {
    return xAxisValues.map((xVal, index) => ({
      x: xVal,
      y: yAxisValues[index],
      label: labels[index].name ? labels[index].name : null,
      link: labels[index].url ? labels[index].url : null,
      textLabel: labels[index].image_url ? null : labels[index].name
    }));
  }, [xAxisValues, yAxisValues, labels]);

  return (
    <ScatterPlotBaseComponent
      title={title}
      xAxisName={xAxisName}
      yAxisName={yAxisName}
      dataKeyX="x"
      dataKeyY="y"
      mode={mode}>
      <Scatter name={title} data={data} fill={appTheme.vars.palette.graphColors.graph1}>
        <LabelList dataKey="textLabel" position="bottom" />
      </Scatter>
    </ScatterPlotBaseComponent>
  );
}

ScatterPlotComponent.propTypes = {
  title: PropTypes.string,
  xAxisValues: PropTypes.array,
  yAxisValues: PropTypes.array,
  xAxisName: PropTypes.string,
  yAxisName: PropTypes.string,
  labels: PropTypes.array,
  mode: PropTypes.oneOf(['overview', 'card', 'powerpoint']).isRequired
};
